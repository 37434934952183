package com.winiwayuser.feature_chat_consultation.data.response

import com.winiwayuser.feature_chat_consultation.domain.MsgType
import com.winiwayuser.feature_chat_consultation.domain.Sender
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ChatMessage(
    //0- concern Image
//1- Normal Msg
//2- Concern Answer
//3- Concern Question
//4- Normal Image
    @SerialName("id") val id: Int? = null,
    @SerialName("msg") val msg: String,
    @SerialName("sender") var sender: Sender,       //User -1, Expert -  0
    @SerialName("date_time") val date: String,
    @SerialName("msg_type") val msgType: MsgType?,
    @SerialName("attachment_status") val isAttachmentUploaded: Boolean = false
)
