package com.winiwayuser.feature_common.persentation.home_tab.home_screen_widget

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Chat
import androidx.compose.material.icons.filled.Call
import androidx.compose.material.icons.filled.Star
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import co.touchlab.kermit.Logger
import coil3.compose.AsyncImage
import com.winiwayuser.core.local.UserInfoProvider.getIsLogin
import com.winiwayuser.core.persentation.SampleDialog
import com.winiwayuser.core.persentation.openPlayStore
import com.winiwayuser.core.persentation.showToast
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.theme.Scale.CORNER_SMALL
import com.winiwayuser.core.theme.call_button_color
import com.winiwayuser.core.theme.call_button_offline_color
import com.winiwayuser.core.theme.primaryColor
import com.winiwayuser.core.theme.star_rating_color
import com.winiwayuser.core.theme.user_app_play_store_url
import com.winiwayuser.feature_call_consultation.persentation.call_bottomsheet.CallHostPage
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_host.ChatHostPage
import com.winiwayuser.feature_common.data.request.RequestConsultant
import com.winiwayuser.feature_common.persentation.home_tab.HomeScreenTabComponent
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.ic_filled_star
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource
import kotlin.math.round


@Composable
fun ConsultantListItemUI(
    component: HomeScreenTabComponent,
    onItemClick: (RequestConsultant) -> Unit,
    onLoginClick: () -> Unit,
    onChatConsultation: (RequestConsultant) -> Unit,
    onCallConsultation: (RequestConsultant) -> Unit,
) {
    val topConsultantData by component.topConsultant.collectAsState()
    var isChatClickSelected by remember { mutableStateOf<RequestConsultant?>(null) }
    var isCallClickSelected by remember { mutableStateOf<RequestConsultant?>(null) }

    if (topConsultantData.isNullOrEmpty()) return

    Column(
        modifier = Modifier
            .padding(horizontal = Scale.HORIZONTAL_PADDING)
            .fillMaxWidth()
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.fillMaxWidth()
        ) {
            Text(
                text = "Top Consultants",
                maxLines = 1,
                style = MaterialTheme.typography.titleMedium,
                modifier = Modifier
                    .padding(top = Scale.VERTICAL_PADDING)
                    .weight(1f)
            )
        }
        LazyRow(
            modifier = Modifier
                .fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceEvenly
        ) {
            items(topConsultantData!!) { consultant ->
                ConsultantListItem(
                    consultant = consultant,
                    onItemClick = onItemClick,
                    onCallClick = { callConsultant ->
                        isCallClickSelected = callConsultant
                    },
                    onChatClick = { chatConsultant ->
                        Logger.d("Chat Clicked")
                        isChatClickSelected = chatConsultant
                    },
                    isFreeCallApplicable = false
                )
            }
        }
    }
    if(isCallClickSelected != null){
        Logger.d("ifCallClicked")
        CallHostPage(
            callDetails = isCallClickSelected!!,
            onLoginClicked = {
                onLoginClick.invoke()
            },
            onDismiss = {
                isCallClickSelected = null
            },
            onStartCallConsultation = { onCallConsultation.invoke(isCallClickSelected!!) }
        )
    }

    if (isChatClickSelected != null) {
        Logger.d("ifChatClicked")
        ChatHostPage(
            chatDetails = isChatClickSelected!!,
            onLoginClicked = {
                onLoginClick.invoke()
            },
            onDismiss = {
                isChatClickSelected = null
            },
            onStartChatConsultation = { onChatConsultation.invoke(isChatClickSelected!!) }
        )
    }
}


fun cleanAndParseNumber(input: String): Double? {
    val cleanedInput = input.filter { it.isDigit() || it == '.' || it == '-' }
    return try {
        cleanedInput.toDouble()
    } catch (e: NumberFormatException) {
        null
    }
}


@Composable
fun ConsultantListItem(
    consultant: RequestConsultant,
    onItemClick: (RequestConsultant) -> Unit,
    onCallClick: (RequestConsultant) -> Unit,
    onChatClick: (RequestConsultant) -> Unit,
    isFreeCallApplicable: Boolean
) {
    Column(
        modifier = Modifier
            .padding(4.dp)
            .width(160.dp)
            .wrapContentHeight()
    ) {
        Card(
            colors = CardDefaults.cardColors(Color.White),
            modifier = Modifier
                .clickable { onItemClick(consultant) }
                .height(220.dp),
            shape = RoundedCornerShape(CORNER_SMALL),
            elevation = CardDefaults.cardElevation(Scale.ELEVATION_SMALL)
        ) {
            Column(
                modifier = Modifier.padding(8.dp),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally // Center the content horizontally
            ) {
                RatingRow(consultant.consultant_rating!!)

                Spacer(modifier = Modifier.height(5.dp))

                ProfileImage(consultant.profile_image)

                Spacer(modifier = Modifier.height(5.dp))

                Text(
                    text = "+${consultant.year_of_experience} Years",
                    style = TextStyle(color = Color.Black),
                    fontSize = 12.sp,
                    textAlign = TextAlign.Center,
                    modifier = Modifier.fillMaxWidth()
                )

                Text(
                    text = consultant.full_name ?: "",
                    maxLines = 1,
                    style = MaterialTheme.typography.titleMedium,
                    modifier = Modifier.fillMaxWidth(),
                    textAlign = TextAlign.Center
                )

                Text(
                    text = consultant.skills ?: "",
                    maxLines = 2,
                    modifier = Modifier.fillMaxWidth(),
                    style = MaterialTheme.typography.bodySmall,
                    overflow = TextOverflow.Ellipsis,
                    textAlign = TextAlign.Center
                )

                Box(
                    modifier = Modifier.fillMaxWidth(),
                    contentAlignment = Alignment.Center // Center the CallPrice composable within the Box
                ) {
                    CallPrice(consultant, isFreeCallApplicable)
                }
            }
        }
        val openDialog = remember { mutableStateOf(false) }
        if (openDialog.value) {
            com.winiwayuser.feature_expert_consultation.presentation.expert_list_widget.openDialog(
                openDialog
            )
        }
        ActionButtons(
            consultant,
            onCallClick = { onCallClick.invoke(consultant) },
            //onChatClick = { onChatClick.invoke(consultant) }
            onChatClick = {openDialog.value = true}
        )
    }
}

@Composable
fun openDialog(sheet: MutableState<Boolean>) {
    if (sheet.value) {
        SampleDialog(
            onDismissRequest = { sheet.value = false },
            onPlayStore = {
                sheet.value = false
                openPlayStore(user_app_play_store_url)
                //showToast("Play Store")
            }
//            onAppStore = {
//                showToast("App Store")
//            }
        )
    }
}


@Composable
fun RatingRow(rating: Double) {
    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.Start,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Icon(
            painter = painterResource(Res.drawable.ic_filled_star),
            // painter = Icon(Icons.Default.Call),
            contentDescription = null,
            tint = star_rating_color,
            modifier = Modifier.size(14.dp)
        )
        ConsultantRating(rating)
    }
}

@Composable
fun ProfileImage(profileImageUrl: String?) {
    Box(
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.Center
    ) {
        Box(
            modifier = Modifier
                .size(85.dp)
                .border(
                    width = 2.dp,
                    color = Color.Gray.copy(alpha = 0.3f),
                    shape = CircleShape
                )
                .clip(CircleShape)
                .background(Color.White)
                .padding(1.dp)
                .clip(CircleShape)
        ) {
            AsyncImage(
                model = profileImageUrl,
                modifier = Modifier
                    .size(85.dp)
                    .clip(CircleShape),
                contentScale = ContentScale.Crop,
                contentDescription = null,
            )
        }
    }
}


@Composable
fun CallPrice(consultant: RequestConsultant, isFreeCallApplicable: Boolean) {
    val callPerMinutePrice = consultant.callPerMinutePrice.toString()
    val isExpertFreeCall = consultant.isFreeCall == 1
    val flashPrice = consultant.expertFlashPrice?.let { consultant.winywayFlashPrice?.plus(it) }
    val isFreeCallApplicableCalculated =
        if (!getIsLogin()) isExpertFreeCall else isExpertFreeCall && isFreeCallApplicable

    if (isFreeCallApplicableCalculated) {
        Row {
            Text(
                text = "NEW USER",
                fontSize = 14.sp,
                textAlign = TextAlign.Center,
                color = Color.Black,
                modifier = Modifier.padding(horizontal = 1.dp)
            )
            Text(
                text = "₹ $callPerMinutePrice/Min",
                fontSize = 12.sp,
                textAlign = TextAlign.Center,
                color = Color.Gray,
                textDecoration = TextDecoration.LineThrough,
                modifier = Modifier.padding(horizontal = 1.dp)
            )
        }
    } else {
        if (flashPrice != null && flashPrice.toInt() != 0) {
            Box(
                modifier = Modifier
                    .padding(horizontal = 2.dp, vertical = 1.dp),
                contentAlignment = Alignment.Center
            ) {
                Row(
                   // modifier = Modifier.padding(horizontal = 2.dp, vertical = 1.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Text(
                        text = "₹ ${flashPrice}/Min",
                        fontSize = 14.sp,
                        textAlign = TextAlign.Center,
                        color = Color.Black,
                        fontWeight = FontWeight.Bold,
                        modifier = Modifier.padding(horizontal = 1.dp)
                    )
                    Spacer(modifier = Modifier.width(1.dp))
                    Text(
                        text = "₹ $callPerMinutePrice/Min",
                        fontSize = 12.sp,
                        textAlign = TextAlign.Center,
                        color = Color.Gray,
                        textDecoration = TextDecoration.LineThrough,
                        modifier = Modifier.padding(horizontal = 1.dp)
                    )
                }
            }
        } else {
            Box(
                modifier = Modifier
                    .padding(horizontal = 2.dp, vertical = 1.dp),
                contentAlignment = Alignment.Center
            ) {
                Text(
                    text = "₹ $callPerMinutePrice /Min",
                    fontSize = 14.sp,
                    textAlign = TextAlign.Center,
                    fontWeight = FontWeight.Bold,
                    color = Color.Black,
                    maxLines = 1,
                    modifier = Modifier.padding(horizontal = 2.dp)
                )
            }
        }
    }
}

@Composable
fun ActionButtons(
    consultant: RequestConsultant,
    onCallClick: () -> Unit,
    onChatClick: () -> Unit
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .height(50.dp),
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        // Call Button
        Button(
            onClick = {
                if(( (consultant.consultantCallAvailability()!!) == "Online")) onCallClick() else showToast("Expert is busy or offline")
//                if (consultant.callStatus.equals("Online", true)) {
//                    onCallClick()
//                } else {
//                    // Show a toast message or handle the offline scenario
//                    showToast("Expert is busy or offline")
//                }
            },
            enabled = ( (consultant.consultantCallAvailability()!!) == "Online"),
            colors = ButtonDefaults.buttonColors(
                containerColor  = if ((consultant.consultantCallAvailability()!!) == "Online") {
                    call_button_color
                } else {
                    call_button_offline_color
                }
            ),
            shape = RoundedCornerShape(CORNER_SMALL),
            modifier = Modifier
                .weight(1f)
                .height(36.dp)
        ) {
            Icon(
                imageVector = Icons.Filled.Call,
                contentDescription = "Consult Now",
                tint = Color.White,
                modifier = Modifier.padding(3.dp).size(Scale.GLOBAL_ICON_SIZE)
            )
        }

        Spacer(modifier = Modifier.weight(0.1f))

        // Chat Button
        Button(
            onClick = {
                if(( (consultant.consultantChatAvailability()!!) == "Online")) onChatClick() else showToast("Expert is busy or offline")
//                if (consultant.chatButtonStatus.equals("Online", true)) {
//                    onChatClick()
//                } else {
//                    // Show a toast message or handle the offline scenario
//                    showToast("Expert is not available for chat")
//                }
            },
            enabled = ((consultant.consultantChatAvailability()!!) == "Online"),
            colors = ButtonDefaults.buttonColors(
                containerColor  = if ((consultant.consultantChatAvailability()!!) == "Online") {
                    primaryColor
                } else {
                    call_button_offline_color
                }
            ),
            shape = RoundedCornerShape(CORNER_SMALL),
            modifier = Modifier
                .weight(1f)
                .height(36.dp)
        ) {
            Icon(
                imageVector = Icons.AutoMirrored.Filled.Chat,
                contentDescription = "Chat Now",
                tint = Color.White,
                modifier = Modifier.padding(3.dp).size(Scale.GLOBAL_ICON_SIZE)
            )
        }
    }
}


//@Composable
//fun ActionButtons(consultant: RequestConsultant, onCallClick: () -> Unit, onChatClick: () -> Unit) {
//
//    Row(
//        modifier = Modifier
//            .fillMaxWidth()
//            .height(50.dp),
//        horizontalArrangement = Arrangement.SpaceBetween
//    ) {
//        Box(
//            modifier = Modifier
//                .weight(1f)
//                .height(36.dp)
//                .background(if (consultant.isCall == 1 || consultant.callStatus.equals("Online",true))call_button_color else call_button_offline_color, RoundedCornerShape(CORNER_SMALL))
//                .clickable { if(( (consultant.consultantChatAvailability()!!) == "Online")) onCallClick() },
////                .clickable { if (consultant.isCall == 1 || consultant.callStatus.equals("Online",true)) {
////                    onCallClick()
////                }
////                           else{
////                               showToast("expert is busy or offline")
////                           }},
//            contentAlignment = Alignment.Center
//        ) {
//            Icon(
//                imageVector = Icons.Filled.Call,
//                contentDescription = "Consult Now",
//                tint = Color.White,
//                modifier = Modifier.padding(3.dp).size(Scale.GLOBAL_ICON_SIZE),
//            )
//        }
//        Spacer(modifier = Modifier.weight(0.1f))
//        Box(
//            modifier = Modifier
//                .weight(1f)
//                .height(36.dp)
//                .background(primaryColor, RoundedCornerShape(CORNER_SMALL))
//                .clickable {
//                    if (consultant.isCall == 1 || consultant.chatButtonStatus.equals("Online",true)) {
//                        onChatClick.invoke()
//                    }
//                },
//            contentAlignment = Alignment.Center
//        ) {
//            Icon(
//                imageVector = Icons.AutoMirrored.Filled.Chat,
//                contentDescription = "Chat Now",
//                tint = Color.White,
//                modifier = Modifier.padding(3.dp).size(Scale.GLOBAL_ICON_SIZE),
//            )
//        }
//    }
//}


@Composable
fun IconButtonWithCustomColor(
    iconResource: DrawableResource,
    contentDescription: String?,
    onClick: () -> Unit,
//    iconColor: Color = MaterialTheme.colorScheme.primary,
//    disabledColor: Color = MaterialTheme.colorScheme.onSurface.copy(alpha = 0.38f),
//    activeColor: Color = MaterialTheme.colorScheme.secondary,
//    busyColor: Color = MaterialTheme.colorScheme.error,
//    isEnabled: Boolean = true,
//    isActive: Boolean = false,
//    isBusy: Boolean = false
) {
//    val currentColor = when {
//        !isEnabled -> disabledColor
//        isBusy -> busyColor
//        isActive -> activeColor
//        else -> iconColor
//    }

    val iconOpacity by remember { mutableStateOf(1.0f) }
//    if (isBusy) {
//        // Indicate a busy state by making the icon semi-transparent or using other visual cues
//        iconOpacity = 0.5f
//    }

    IconButton(
        onClick = {
//            if (isEnabled && !isBusy) {
//                onClick()
//            }
        },
        //enabled = isEnabled
    ) {
        Icon(
            painter = painterResource(resource = iconResource),
            contentDescription = contentDescription,
            // tint = currentColor,
            modifier = Modifier
                .size(24.dp)
                .graphicsLayer {
                    alpha = iconOpacity
                }
        )
    }
}

@Composable
fun ConsultantRatingIcon(rating: Double?) {
    val roundedRating = rating?.coerceIn(0.0, 5.0) ?: 5.0
    Row {
        repeat(roundedRating.toInt()) {
            Icon(
                imageVector = Icons.Filled.Star,
                contentDescription = "Star",
                tint = Color.Yellow,
                modifier = Modifier.padding(end = 4.dp)
            )
        }
    }
}

@Composable
fun ConsultantRating(rating: Double?) {
    val roundedRating = rating?.coerceIn(0.0, 5.0)?.let { round(it * 10) / 10 } ?: 5.0
    val ratingText = "$roundedRating"
    Text(
        text = ratingText,
        fontSize = 12.sp,
        style = TextStyle(fontWeight = FontWeight.Bold),
        textAlign = TextAlign.Center,
        color = Color.Black,
        modifier = Modifier.padding(horizontal = 4.dp)
    )
}