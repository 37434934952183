package com.winiwayuser.feature_chat_consultation_new.persentation.chat_host

import androidx.compose.runtime.Composable
import com.winiwayuser.core.local.UserInfoProvider
import com.winiwayuser.core.persentation.LoginDialog
import com.winiwayuser.feature_common.data.request.RequestConsultant

@Composable
fun ChatHostPage(
    chatDetails: RequestConsultant,
    onLoginClicked: () -> Unit,
    onDismiss: () -> Unit,
    onStartChatConsultation: () -> Unit
) {
    val isUserLogin = UserInfoProvider.getIsLogin()
//    val sheetState = rememberModalBottomSheetState(
//        skipPartiallyExpanded = true
//    )

    if (isUserLogin.not()) {
        LoginDialog(
            onLoginClicked = {
                onLoginClicked.invoke()
            },
            onSignUpClicked = {
                onLoginClicked.invoke()
            },
            onDismiss = {
                onDismiss.invoke()
            }
        )
        return
    }

    onStartChatConsultation.invoke()
    onDismiss.invoke()
}