package com.winiwayuser.feature_winyway_wallet.core

import startPaymentInBrowser


actual fun startPayment(
    orderId: String,
    onPaymentSuccess: (String, String, String) -> Unit,
    onPaymentFailed: (Throwable) -> Unit
) {
    startPaymentInBrowser(
        orderId = orderId,
        onResponsePayment = onPaymentSuccess,
        onErrorResponse = onPaymentFailed
    )
}