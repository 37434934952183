package com.winiwayuser.feature_landing_page.persentation.widget

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material.icons.filled.Phone
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.DropdownMenu
import androidx.compose.material3.DropdownMenuItem
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedButton
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Text
import androidx.compose.material3.TextField
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.winiwayuser.feature_landing_page.data.request.CourseDetailsSubmitForm
import com.winiwayuser.feature_landing_page.persentation.LandingPageComponent
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.science_astro_icon
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.painterResource
import org.koin.dsl.module

//@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
//@Composable
//fun CourseDetailsForm(component: LandingPageComponent) {
//    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
//
//    var firstName by remember { mutableStateOf("") }
//    var lastName by remember { mutableStateOf("") }
//    var email by remember { mutableStateOf("") }
//    var phoneNumber by remember { mutableStateOf("") }
//    var selectedCourse by remember { mutableStateOf("") }
//    var referenceCode by remember { mutableStateOf("") }
//    var selectedCountryCode by remember { mutableStateOf("+91") }
//    val countryCodes = listOf("+91", "+1", "+44", "+61")
//    var expanded by remember { mutableStateOf(false) }
//    val courses = listOf("Course 1", "Course 2", "Course 3")
//    val emailPattern = "^[A-Za-z0-9+_.-]+@[A-Za-z0-9.-]+$".toRegex()
//
//    Box(modifier = Modifier.fillMaxSize().padding(16.dp), contentAlignment = Alignment.Center){
//
//        if(windowSizeClass == WindowWidthSizeClass.Compact){
//            Column(modifier = Modifier.fillMaxWidth()) {
//                Column(
//                    modifier = Modifier.weight(1f).fillMaxHeight(),
//                    horizontalAlignment = Alignment.CenterHorizontally
//                ) {
//                    Text(
//                        text = "Unlocking The Secrets Of Ancient \n Vedic Science For A Better Future",
//                        maxLines = 2,
//                        fontSize = 20.sp,
//                        fontWeight = FontWeight.Bold
//                    )
//                    Image(
//                        painter = painterResource(Res.drawable.science_astro_icon),
//                        contentDescription = null
//                    )
//                }
//                Column(
//                    modifier = Modifier
//                        .weight(1f)
//                        .border(width = 0.5.dp, color = Color.Black)
//                        .padding(10.dp)
//                ) {
//                    Text(
//                        text = "Get the Course Details",
//                        fontSize = 14.sp,
//                        fontWeight = FontWeight.Bold,
//                        textAlign = TextAlign.Center
//                    )
//
//                    // First Name and Last Name Fields
//                    Row(
//                        modifier = Modifier.fillMaxWidth(),
//                        horizontalArrangement = Arrangement.spacedBy(12.dp)
//                    ) {
//                        OutlinedTextField(
//                            value = firstName,
//                            onValueChange = { firstName = it },
//                            label = { Text("First Name") },
//                            maxLines = 1,
//                            modifier = Modifier.weight(1f)
//                        )
//                        OutlinedTextField(
//                            value = lastName,
//                            onValueChange = { lastName = it },
//                            label = { Text("Last Name") },
//                            maxLines = 1,
//                            modifier = Modifier.weight(1f)
//                        )
//                    }
//
//                    Spacer(modifier = Modifier.height(12.dp))
//
//                    // Email Field
//                    OutlinedTextField(
//                        value = email,
//                        onValueChange = { email = it },
//                        label = { Text("Email") },
//                        modifier = Modifier.fillMaxWidth(),
//                        maxLines = 1,
//                        isError = email.isNotEmpty() && !email.matches(emailPattern),
//                        keyboardOptions = KeyboardOptions(
//                            keyboardType = KeyboardType.Email,
//                            imeAction = ImeAction.Next
//                        )
//                    )
//
//                    if (email.isNotEmpty() && !email.matches(emailPattern)) {
//                        Text(
//                            text = "Please enter a valid email address",
//                            color = Color.Red,
//                            fontSize = 12.sp,
//                            modifier = Modifier.padding(start = 8.dp)
//                        )
//                    }
//
//                    Spacer(modifier = Modifier.height(12.dp))
//
//                    // Phone Number Field
//                    OutlinedTextField(
//                        value = phoneNumber,
//                        onValueChange = { phoneNumber = it },
//                        label = { Text("Phone Number") },
//                        leadingIcon = { Icon(Icons.Default.Phone, contentDescription = null) },
//                        modifier = Modifier.fillMaxWidth(),
//                        maxLines = 1,
//                        keyboardOptions = KeyboardOptions(
//                            keyboardType = KeyboardType.Number,
//                            imeAction = ImeAction.Next
//                        ),
//                        isError = phoneNumber.isNotEmpty() && phoneNumber.length < 10
//                    )
//                    if (phoneNumber.isNotEmpty() && phoneNumber.length < 10) {
//                        Text(
//                            text = "Please enter a valid phone number",
//                            color = Color.Red,
//                            fontSize = 12.sp,
//                            modifier = Modifier.padding(start = 8.dp)
//                        )
//                    }
//
//                    Spacer(modifier = Modifier.height(12.dp))
//
//                    // Course Dropdown
//                    Text(text = "Course Interested In", color = Color.Gray, fontSize = 14.sp)
//                    Box(
//                        modifier = Modifier
//                            .fillMaxWidth()
//                            .padding(top = 4.dp)
//                            .border(BorderStroke(1.dp, Color.Gray), shape = RoundedCornerShape(4.dp))
//                            .clickable { expanded = !expanded }
//                            .padding(16.dp)
//                    ) {
//                        Text(text = selectedCourse.ifEmpty { "-Select-" })
//                        Icon(Icons.Default.ArrowDropDown, contentDescription = null, Modifier.align(Alignment.CenterEnd))
//                    }
//                    DropdownMenu(
//                        expanded = expanded,
//                        onDismissRequest = { expanded = false }
//                    ) {
//                        courses.forEach { course ->
//                            DropdownMenuItem(
//                                text = { Text(course) },
//                                onClick = {
//                                    selectedCourse = course
//                                    expanded = false
//                                }
//                            )
//                        }
//                    }
//
//                    Spacer(modifier = Modifier.height(12.dp))
//
//                    // Reference Code Field
//                    OutlinedTextField(
//                        value = referenceCode,
//                        onValueChange = { referenceCode = it },
//                        label = { Text("Reference Code (Optional)") },
//                        modifier = Modifier.fillMaxWidth(),
//                        maxLines = 1,
//                        keyboardOptions = KeyboardOptions(
//                            keyboardType = KeyboardType.Text,
//                            imeAction = ImeAction.Done
//                        )
//                    )
//                    Spacer(modifier = Modifier.height(12.dp))
//
//                    Text(
//                        text = "By submitting our web form, you agree to receive promotional calls on the number shared, and such calls and SMS would be coming from a third party platform.",
//                        modifier = Modifier.fillMaxWidth(),
//                        maxLines = 3,
//                        fontSize = 10.sp
//                    )
//                    Spacer(modifier = Modifier.height(24.dp))
//                    // Submit and WhatsApp Buttons
//                    Row(
//                        modifier = Modifier.fillMaxWidth(),
//                        horizontalArrangement = Arrangement.spacedBy(12.dp)
//                    ) {
//                        Button(
//                            onClick = {
//                                CoroutineScope(Dispatchers.Default).launch {
//                                    component.postCourseDetails(
//                                        CourseDetailsSubmitForm(
//                                            firstName = firstName,
//                                            lastName = lastName,
//                                            mobile = phoneNumber,
//                                            email = email,
//                                            courseInterestedIn = selectedCourse,
//                                            referenceCode = referenceCode
//                                        )
//                                    )
//                                }
//                            },
//                            modifier = Modifier.weight(1f),
//                            enabled = firstName.isNotEmpty() && lastName.isNotEmpty() && phoneNumber.isNotEmpty() && email.isNotEmpty() && selectedCourse.isNotEmpty(),
//                            shape = RoundedCornerShape(8.dp),
//                            colors = ButtonDefaults.buttonColors(Color.Blue)
//                        ) {
//                            Text(text = "Get Course Details", color = Color.White)
//                        }
//
//                    }
//                }
//            }
//        }
//        Row( modifier = Modifier.fillMaxWidth(),
//            horizontalArrangement = Arrangement.spacedBy(12.dp),
//        ) {
//            Column(
//                modifier = Modifier.weight(1f).fillMaxHeight(),
//                horizontalAlignment = Alignment.CenterHorizontally
//            ) {
//                Text(
//                    text = "Unlocking The Secrets Of Ancient \n Vedic Science For A Better Future",
//                    maxLines = 2,
//                    fontSize = 20.sp,
//                    fontWeight = FontWeight.Bold
//                )
//                Image(
//                    painter = painterResource(Res.drawable.science_astro_icon),
//                    contentDescription = null
//                )
//            }
//            Column(
//                modifier = Modifier
//                    .weight(1f)
//                    .border(width = 0.5.dp, color = Color.Black)
//                    .padding(10.dp)
//            ) {
//                Text(
//                    text = "Get the Course Details",
//                    fontSize = 14.sp,
//                    fontWeight = FontWeight.Bold,
//                    textAlign = TextAlign.Center
//                )
//
//                // First Name and Last Name Fields
//                Row(
//                    modifier = Modifier.fillMaxWidth(),
//                    horizontalArrangement = Arrangement.spacedBy(12.dp)
//                ) {
//                    OutlinedTextField(
//                        value = firstName,
//                        onValueChange = { firstName = it },
//                        label = { Text("First Name") },
//                        maxLines = 1,
//                        modifier = Modifier.weight(1f)
//                    )
//                    OutlinedTextField(
//                        value = lastName,
//                        onValueChange = { lastName = it },
//                        label = { Text("Last Name") },
//                        maxLines = 1,
//                        modifier = Modifier.weight(1f)
//                    )
//                }
//
//                Spacer(modifier = Modifier.height(12.dp))
//
//                // Email Field
//                OutlinedTextField(
//                    value = email,
//                    onValueChange = { email = it },
//                    label = { Text("Email") },
//                    modifier = Modifier.fillMaxWidth(),
//                    maxLines = 1,
//                    isError = email.isNotEmpty() && !email.matches(emailPattern),
//                    keyboardOptions = KeyboardOptions(
//                        keyboardType = KeyboardType.Email,
//                        imeAction = ImeAction.Next
//                    )
//                )
//
//                if (email.isNotEmpty() && !email.matches(emailPattern)) {
//                    Text(
//                        text = "Please enter a valid email address",
//                        color = Color.Red,
//                        fontSize = 12.sp,
//                        modifier = Modifier.padding(start = 8.dp)
//                    )
//                }
//
//                Spacer(modifier = Modifier.height(12.dp))
//
//                // Phone Number Field
//                OutlinedTextField(
//                    value = phoneNumber,
//                    onValueChange = { phoneNumber = it },
//                    label = { Text("Phone Number") },
//                    leadingIcon = { Icon(Icons.Default.Phone, contentDescription = null) },
//                    modifier = Modifier.fillMaxWidth(),
//                    maxLines = 1,
//                    keyboardOptions = KeyboardOptions(
//                        keyboardType = KeyboardType.Number,
//                        imeAction = ImeAction.Next
//                    ),
//                    isError = phoneNumber.isNotEmpty() && phoneNumber.length < 10
//                )
//                if (phoneNumber.isNotEmpty() && phoneNumber.length < 10) {
//                    Text(
//                        text = "Please enter a valid phone number",
//                        color = Color.Red,
//                        fontSize = 12.sp,
//                        modifier = Modifier.padding(start = 8.dp)
//                    )
//                }
//
//                Spacer(modifier = Modifier.height(12.dp))
//
//                // Course Dropdown
//                Text(text = "Course Interested In", color = Color.Gray, fontSize = 14.sp)
//                Box(
//                    modifier = Modifier
//                        .fillMaxWidth()
//                        .padding(top = 4.dp)
//                        .border(BorderStroke(1.dp, Color.Gray), shape = RoundedCornerShape(4.dp))
//                        .clickable { expanded = !expanded }
//                        .padding(16.dp)
//                ) {
//                    Text(text = selectedCourse.ifEmpty { "-Select-" })
//                    Icon(Icons.Default.ArrowDropDown, contentDescription = null, Modifier.align(Alignment.CenterEnd))
//                }
//                DropdownMenu(
//                    expanded = expanded,
//                    onDismissRequest = { expanded = false }
//                ) {
//                    courses.forEach { course ->
//                        DropdownMenuItem(
//                            text = { Text(course) },
//                            onClick = {
//                                selectedCourse = course
//                                expanded = false
//                            }
//                        )
//                    }
//                }
//
//                Spacer(modifier = Modifier.height(12.dp))
//
//                // Reference Code Field
//                OutlinedTextField(
//                    value = referenceCode,
//                    onValueChange = { referenceCode = it },
//                    label = { Text("Reference Code (Optional)") },
//                    modifier = Modifier.fillMaxWidth(),
//                    maxLines = 1,
//                    keyboardOptions = KeyboardOptions(
//                        keyboardType = KeyboardType.Text,
//                        imeAction = ImeAction.Done
//                    )
//                )
//                Spacer(modifier = Modifier.height(12.dp))
//
//                Text(
//                    text = "By submitting our web form, you agree to receive promotional calls on the number shared, and such calls and SMS would be coming from a third party platform.",
//                    modifier = Modifier.fillMaxWidth(),
//                    maxLines = 3,
//                    fontSize = 10.sp
//                )
//                Spacer(modifier = Modifier.height(24.dp))
//                // Submit and WhatsApp Buttons
//                Row(
//                    modifier = Modifier.fillMaxWidth(),
//                    horizontalArrangement = Arrangement.spacedBy(12.dp)
//                ) {
//                    Button(
//                        onClick = {
//                            CoroutineScope(Dispatchers.Default).launch {
//                                component.postCourseDetails(
//                                    CourseDetailsSubmitForm(
//                                        firstName = firstName,
//                                        lastName = lastName,
//                                        mobile = phoneNumber,
//                                        email = email,
//                                        courseInterestedIn = selectedCourse,
//                                        referenceCode = referenceCode
//                                    )
//                                )
//                            }
//                        },
//                        modifier = Modifier.weight(1f),
//                        enabled = firstName.isNotEmpty() && lastName.isNotEmpty() && phoneNumber.isNotEmpty() && email.isNotEmpty() && selectedCourse.isNotEmpty(),
//                        shape = RoundedCornerShape(8.dp),
//                        colors = ButtonDefaults.buttonColors(Color.Blue)
//                    ) {
//                        Text(text = "Get Course Details", color = Color.White)
//                    }
//
//                }
//            }
//        }
//    }
//}



@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun CourseDetailsForm(component: LandingPageComponent) {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass

    var firstName by remember { mutableStateOf("") }
    var lastName by remember { mutableStateOf("") }
    var email by remember { mutableStateOf("") }
    var phoneNumber by remember { mutableStateOf("") }
    var selectedCourse by remember { mutableStateOf("") }
    var referenceCode by remember { mutableStateOf("") }
    var selectedCountryCode by remember { mutableStateOf("+91") }
    var expanded by remember { mutableStateOf(false) }
    var cuentryCodeExpanded by remember { mutableStateOf(false) }

    val countryCodes = listOf("+91", "+1", "+44", "+61")
    val courses = listOf("Course 1", "Course 2", "Course 3")
    val emailPattern = "^[A-Za-z0-9+_.-]+@[A-Za-z0-9.-]+$".toRegex()

    Box(modifier = Modifier
        .fillMaxSize()
        .padding(16.dp),
        contentAlignment = Alignment.Center
    ) {
        if (windowSizeClass == WindowWidthSizeClass.Compact) {
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(16.dp),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                // Title and Image
                Text(
                    text = "Unlocking The Secrets Of Ancient \n Vedic Science For A Better Future",
                    maxLines = 2,
                    fontSize = 20.sp,
                    fontWeight = FontWeight.Bold,
                    textAlign = TextAlign.Center,
                    modifier = Modifier.padding(bottom = 16.dp)
                )
                Image(
                    painter = painterResource(Res.drawable.science_astro_icon),
                    contentDescription = null,
                    modifier = Modifier
                        .size(120.dp)
                        .padding(bottom = 16.dp)
                )

                // Form Fields
                Column(
                    modifier = Modifier
                        .border(1.dp, Color.Gray, RoundedCornerShape(8.dp))
                        .padding(16.dp)
                        .fillMaxWidth()
                ) {

                    Text(
                        text = "Get the Course Details",
                        fontSize = 14.sp,
                        fontWeight = FontWeight.Bold,
                        textAlign = TextAlign.Center
                    )
                    // Name Fields
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        horizontalArrangement = Arrangement.spacedBy(12.dp)
                    ) {
                        OutlinedTextField(
                            value = firstName,
                            onValueChange = { firstName = it },
                            label = { Text("First Name") },
                            maxLines = 1,
                            modifier = Modifier.weight(1f)
                        )
                        OutlinedTextField(
                            value = lastName,
                            onValueChange = { lastName = it },
                            label = { Text("Last Name") },
                            maxLines = 1,
                            modifier = Modifier.weight(1f)
                        )
                    }

                    Spacer(modifier = Modifier.height(12.dp))

                    // Email Field
                    OutlinedTextField(
                        value = email,
                        onValueChange = { email = it },
                        label = { Text("Email") },
                        modifier = Modifier.fillMaxWidth(),
                        maxLines = 1,
                        isError = email.isNotEmpty() && !email.matches(emailPattern),
                        keyboardOptions = KeyboardOptions(
                            keyboardType = KeyboardType.Email,
                            imeAction = ImeAction.Next
                        )
                    )
                    if (email.isNotEmpty() && !email.matches(emailPattern)) {
                        Text(
                            text = "Please enter a valid email address",
                            color = Color.Red,
                            fontSize = 12.sp,
                            modifier = Modifier.padding(start = 8.dp, top = 4.dp)
                        )
                    }

                    Spacer(modifier = Modifier.height(12.dp))

                    // Country Code & Phone Number Field
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        horizontalArrangement = Arrangement.spacedBy(8.dp)
                    ) {
                        Box(
                            modifier = Modifier
                                .border(BorderStroke(1.dp, Color.Gray), shape = RoundedCornerShape(4.dp))
                                .clickable { cuentryCodeExpanded = !cuentryCodeExpanded }
                                .padding(12.dp)
                        ) {
                            Row {
                                Text(text = selectedCountryCode)
                                Icon(Icons.Default.ArrowDropDown, contentDescription = null)
                            }
                            //Text(text = selectedCountryCode)
                           // Icon(Icons.Default.ArrowDropDown, contentDescription = null, modifier = Modifier.align(Alignment.CenterEnd))

                        }
                        DropdownMenu(
                            expanded = cuentryCodeExpanded,
                            onDismissRequest = { cuentryCodeExpanded = false }
                        ) {
                            countryCodes.forEach { code ->
                                DropdownMenuItem(
                                    text = { Text(code) },
                                    onClick = {
                                        selectedCountryCode = code
                                        cuentryCodeExpanded = false
                                    }
                                )
                            }
                        }

                        OutlinedTextField(
                            value = phoneNumber,
                            onValueChange = { phoneNumber = it },
                            label = { Text("Phone Number") },
                            maxLines = 1,
                            modifier = Modifier.weight(1f),
                            keyboardOptions = KeyboardOptions(
                                keyboardType = KeyboardType.Number,
                                imeAction = ImeAction.Next
                            ),
                            isError = phoneNumber.isNotEmpty() && phoneNumber.length < 10
                        )
                    }
                    if (phoneNumber.isNotEmpty() && phoneNumber.length < 10) {
                        Text(
                            text = "Please enter a valid phone number",
                            color = Color.Red,
                            fontSize = 12.sp,
                            modifier = Modifier.padding(start = 8.dp, top = 4.dp)
                        )
                    }

                    Spacer(modifier = Modifier.height(12.dp))

                    // Course Dropdown
                    Text(text = "Course Interested In", fontSize = 14.sp, color = Color.Gray)
                    Box(
                        modifier = Modifier
                            .fillMaxWidth()
                            .border(1.dp, Color.Gray, RoundedCornerShape(4.dp))
                            .padding(12.dp)
                            .clickable { expanded = !expanded }
                    ) {
                        Row{

                        }
                        Text(text = selectedCourse.ifEmpty { "-Select-" })
                        Icon(Icons.Default.ArrowDropDown, contentDescription = null, Modifier.align(Alignment.CenterEnd))
                    }
                    DropdownMenu(
                        expanded = expanded,
                        onDismissRequest = { expanded = false }
                    ) {
                        courses.forEach { course ->
                            DropdownMenuItem(
                                text = { Text(course) },
                                onClick = {
                                    selectedCourse = course
                                    expanded = false
                                }
                            )
                        }
                    }

                    Spacer(modifier = Modifier.height(12.dp))

                    // Reference Code
                    OutlinedTextField(
                        value = referenceCode,
                        onValueChange = { referenceCode = it },
                        label = { Text("Reference Code (Optional)") },
                        modifier = Modifier.fillMaxWidth(),
                        maxLines = 1,
                        keyboardOptions = KeyboardOptions(
                            keyboardType = KeyboardType.Text,
                            imeAction = ImeAction.Done
                        )
                    )

                    Spacer(modifier = Modifier.height(24.dp))

                    // Terms and Submit Button
                    Text(
                        text = "By submitting our web form, you agree to receive promotional calls on the number shared, and such calls and SMS would be coming from a third party platform.",
                        modifier = Modifier.fillMaxWidth(),
                        maxLines = 4,
                        fontSize = 10.sp
                    )

                    Spacer(modifier = Modifier.height(24.dp))

                    // Buttons
                    Button(
                        onClick = {
                            CoroutineScope(Dispatchers.Default).launch {
                                component.postCourseDetails(
                                    CourseDetailsSubmitForm(
                                        firstName = firstName,
                                        lastName = lastName,
                                        mobile = selectedCountryCode + phoneNumber,
                                        email = email,
                                        courseInterestedIn = selectedCourse,
                                        referenceCode = referenceCode
                                    )
                                )
                            }
                            firstName = ""
                            lastName = ""
                            selectedCourse = ""
                            phoneNumber = ""
                            email = ""
                            selectedCountryCode = "+91"
                            referenceCode = ""
                        },
                        modifier = Modifier.fillMaxWidth(),
                        enabled = firstName.isNotEmpty() && lastName.isNotEmpty() && phoneNumber.isNotEmpty() && email.isNotEmpty() && selectedCourse.isNotEmpty(),
                        shape = RoundedCornerShape(8.dp),
                        colors = ButtonDefaults.buttonColors(Color.Blue)
                    ) {
                        Text(text = "Get Course Details", color = Color.White)
                    }
                }
            }
        }else{
            Row( modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.spacedBy(12.dp),
            ) {
                Column(
                    modifier = Modifier.weight(1f).fillMaxHeight(),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Text(
                        text = "Unlocking The Secrets Of Ancient \n Vedic Science For A Better Future",
                        maxLines = 2,
                        fontSize = 20.sp,
                        fontWeight = FontWeight.Bold
                    )
                    Image(
                        painter = painterResource(Res.drawable.science_astro_icon),
                        contentDescription = null
                    )
                }
                Column(
                    modifier = Modifier
                        .weight(1f)
                        .border(width = 0.5.dp, color = Color.Black)
                        .padding(10.dp)
                ) {
                    Text(
                        text = "Get the Course Details",
                        fontSize = 14.sp,
                        fontWeight = FontWeight.Bold,
                        textAlign = TextAlign.Center
                    )

                    // First Name and Last Name Fields
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        horizontalArrangement = Arrangement.spacedBy(12.dp)
                    ) {
                        OutlinedTextField(
                            value = firstName,
                            onValueChange = { firstName = it },
                            label = { Text("First Name") },
                            maxLines = 1,
                            modifier = Modifier.weight(1f)
                        )
                        OutlinedTextField(
                            value = lastName,
                            onValueChange = { lastName = it },
                            label = { Text("Last Name") },
                            maxLines = 1,
                            modifier = Modifier.weight(1f)
                        )
                    }

                    Spacer(modifier = Modifier.height(12.dp))

                    // Email Field
                    OutlinedTextField(
                        value = email,
                        onValueChange = { email = it },
                        label = { Text("Email") },
                        modifier = Modifier.fillMaxWidth(),
                        maxLines = 1,
                        isError = email.isNotEmpty() && !email.matches(emailPattern),
                        keyboardOptions = KeyboardOptions(
                            keyboardType = KeyboardType.Email,
                            imeAction = ImeAction.Next
                        )
                    )

                    if (email.isNotEmpty() && !email.matches(emailPattern)) {
                        Text(
                            text = "Please enter a valid email address",
                            color = Color.Red,
                            fontSize = 12.sp,
                            modifier = Modifier.padding(start = 8.dp)
                        )
                    }

                    Spacer(modifier = Modifier.height(12.dp))

                    // Country Code & Phone Number Field
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        horizontalArrangement = Arrangement.spacedBy(8.dp),
                    ) {
                        Box(
                            modifier = Modifier
                                .border(BorderStroke(1.dp, Color.Gray), shape = RoundedCornerShape(4.dp))
                                .clickable { cuentryCodeExpanded = !cuentryCodeExpanded }
                                .padding(12.dp),
                            //contentAlignment = Alignment.Center
                        ) {
                            Row {
                                Text(text = selectedCountryCode)
                                Icon(Icons.Default.ArrowDropDown, contentDescription = null)
                            }
                        }
                        DropdownMenu(
                            expanded = cuentryCodeExpanded,
                            onDismissRequest = { cuentryCodeExpanded = false }
                        ) {
                            countryCodes.forEach { code ->
                                DropdownMenuItem(
                                    text = { Text(code) },
                                    onClick = {
                                        selectedCountryCode = code
                                        cuentryCodeExpanded = false
                                    }
                                )
                            }
                        }

                        OutlinedTextField(
                            value = phoneNumber,
                            onValueChange = { phoneNumber = it },
                            label = { Text("Phone Number") },
                            maxLines = 1,
                            modifier = Modifier.weight(1f),
                            keyboardOptions = KeyboardOptions(
                                keyboardType = KeyboardType.Number,
                                imeAction = ImeAction.Next
                            ),
                            isError = phoneNumber.isNotEmpty() && phoneNumber.length < 10
                        )
                    }
                    if (phoneNumber.isNotEmpty() && phoneNumber.length < 10) {
                        Text(
                            text = "Please enter a valid phone number",
                            color = Color.Red,
                            fontSize = 12.sp,
                            modifier = Modifier.padding(start = 8.dp, top = 4.dp)
                        )
                    }

                    Spacer(modifier = Modifier.height(12.dp))

                    // Course Dropdown
                    Text(text = "Course Interested In", color = Color.Gray, fontSize = 14.sp)
                    Box(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(top = 4.dp)
                            .border(BorderStroke(1.dp, Color.Gray), shape = RoundedCornerShape(4.dp))
                            .clickable { expanded = !expanded }
                            .padding(16.dp)
                    ) {
                        Text(text = selectedCourse.ifEmpty { "-Select-" })
                        Icon(Icons.Default.ArrowDropDown, contentDescription = null, Modifier.align(Alignment.CenterEnd))
                    }
                    DropdownMenu(
                        expanded = expanded,
                        onDismissRequest = { expanded = false }
                    ) {
                        courses.forEach { course ->
                            DropdownMenuItem(
                                text = { Text(course) },
                                onClick = {
                                    selectedCourse = course
                                    expanded = false
                                }
                            )
                        }
                    }

                    Spacer(modifier = Modifier.height(12.dp))

                    // Reference Code Field
                    OutlinedTextField(
                        value = referenceCode,
                        onValueChange = { referenceCode = it },
                        label = { Text("Reference Code (Optional)") },
                        modifier = Modifier.fillMaxWidth(),
                        maxLines = 1,
                        keyboardOptions = KeyboardOptions(
                            keyboardType = KeyboardType.Text,
                            imeAction = ImeAction.Done
                        )
                    )
                    Spacer(modifier = Modifier.height(12.dp))

                    Text(
                        text = "By submitting our web form, you agree to receive promotional calls on the number shared, and such calls and SMS would be coming from a third party platform.",
                        modifier = Modifier.fillMaxWidth(),
                        maxLines = 3,
                        fontSize = 10.sp
                    )
                    Spacer(modifier = Modifier.height(24.dp))
                    // Submit and WhatsApp Buttons
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        horizontalArrangement = Arrangement.spacedBy(12.dp)
                    ) {
                        Button(
                            onClick = {
                                CoroutineScope(Dispatchers.Default).launch {
                                    component.postCourseDetails(
                                        CourseDetailsSubmitForm(
                                            firstName = firstName,
                                            lastName = lastName,
                                            mobile = selectedCountryCode + phoneNumber,
                                            email = email,
                                            courseInterestedIn = selectedCourse,
                                            referenceCode = referenceCode
                                        )
                                    )
                                }
                                firstName = ""
                                lastName = ""
                                selectedCourse = ""
                                phoneNumber = ""
                                email = ""
                                selectedCountryCode = "+91"
                                referenceCode = ""
                            },
                            modifier = Modifier.weight(1f),
                            enabled = firstName.isNotEmpty() && lastName.isNotEmpty() && phoneNumber.isNotEmpty() && email.isNotEmpty() && selectedCourse.isNotEmpty(),
                            shape = RoundedCornerShape(8.dp),
                            colors = ButtonDefaults.buttonColors(Color.Blue)
                        ) {
                            Text(text = "Get Course Details", color = Color.White)
                        }

                    }
                }
            }
        }
    }
}
