package com.winiwayuser.feature_winyway_wallet.persentation.recharge_checkout

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_winyway_wallet.data.api.WalletRechargeApi
import com.winiwayuser.feature_winyway_wallet.data.request.CheckoutRechargePlanRequest
import com.winiwayuser.feature_winyway_wallet.data.response.RechargeInfoData
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_getway.PaymentGatewayArg
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class RechargeCheckoutComponent(
    context: ComponentContext,
    private val _rechargeData: RechargeData,
    private val onProceedToPayment: (PaymentGatewayArg) -> Unit,
    private val onBackPressed:()-> Unit
) : ComponentContext by context, KoinComponent {

    val rechargeData get() = _rechargeData
    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    val api: WalletRechargeApi by inject()
    private val _state = MutableStateFlow<RechargeInfoData?>(null)
    val state get() = _state.asStateFlow()
    private val _isLoading = MutableStateFlow<Boolean>(true)
    val isLoading get() = _isLoading.asStateFlow()

    fun onBackPress() = onBackPressed.invoke()

    fun proceedToPayment(
        txnId: String,
        orderId: String,
        coinUsed: Int,
        cashPayment: String
    ) {
        val paymentGatewayArg = PaymentGatewayArg(
            txnId = txnId,
            orderId = orderId,
            coinUsed = coinUsed,
            cashPayment = cashPayment
        )
        onProceedToPayment.invoke(paymentGatewayArg)
    }

    init {
        lifecycle.doOnStart {
            Logger.d { "Home Screen Tab Component onStart" }
            getRechargeInfo()
        }

        lifecycle.doOnDestroy {
            Logger.d { "Home Screen Tab Component onDestroy" }
            coroutineScope.cancel() // Cancel all coroutines when the component is destroyed
        }
    }

    private fun getRechargeInfo() {
        coroutineScope.launch {
            val otpRequest = CheckoutRechargePlanRequest(
                planId = _rechargeData.id.toString(),
                couponCode = null
            )

            api.getRechargeInfo(otpRequest).collectLatest {
                Logger.d { "getRechargeInfo state -> $it" }
                when (it) {
//                    is Resources.Error -> {
//                        Logger.d { "getRechargeInfo state Error -> $it" }
//                        _state.value = Resources.Error(it.msg)
//                    }
                    is Resources.Loading -> {
                        Logger.d { "getRechargeInfo state Loading -> ${it.isLoading}" }
                        _isLoading.value = it.isLoading
                    }

                    is Resources.Success -> {
                        Logger.d { "getRechargeInfo state Success -> $it" }
                        _state.value = it.data
                    }

                    else -> Unit
                }
            }
        }
    }

}