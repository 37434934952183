package com.winiwayuser.feature_expert_consultation.presentation.expert_list_widget

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.lazy.grid.itemsIndexed
import androidx.compose.foundation.lazy.itemsIndexed
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Chat
import androidx.compose.material.icons.filled.Call
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import co.touchlab.kermit.Logger
import coil3.compose.AsyncImage
import com.winiwayuser.core.persentation.SampleDialog
import com.winiwayuser.core.persentation.openPlayStore
import com.winiwayuser.core.persentation.showToast
import com.winiwayuser.core.theme.Scale.CORNER_SMALL
import com.winiwayuser.core.theme.Scale.ELEVATION_SMALL
import com.winiwayuser.core.theme.call_button_background_color
import com.winiwayuser.core.theme.call_button_color
import com.winiwayuser.core.theme.call_button_offline_color
import com.winiwayuser.core.theme.chat_and_call_button_busy_color
import com.winiwayuser.core.theme.chat_button_background_color
import com.winiwayuser.core.theme.chat_button_online_color
import com.winiwayuser.core.theme.primaryColor
import com.winiwayuser.core.theme.profile_image_background_color
import com.winiwayuser.core.theme.user_app_play_store_url
import com.winiwayuser.feature_common.data.request.RequestConsultant
import com.winiwayuser.feature_common.persentation.favourite_tab.ActionIconButton
import com.winiwayuser.feature_common.persentation.favourite_tab.CategoryConsultListRowClickListener
import com.winiwayuser.feature_expert_consultation.presentation.CallChartActionButton
import com.winiwayuser.feature_expert_consultation.presentation.ConsultantRating
import com.winiwayuser.feature_expert_consultation.presentation.ExpertCallPrice
import com.winiwayuser.feature_expert_consultation.presentation.ExpertListPageComponent
import com.winiwayuser.feature_expert_consultation.presentation.ExpertListPageEvent
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.baseline_language_24
import com.winiwayuser.resources.baseline_person_4_24
import com.winiwayuser.resources.ic_filled_star
import com.winiwayuser.resources.ic_heart_primary_color
import com.winiwayuser.resources.new_caht2
import com.winiwayuser.resources.new_call1
import org.jetbrains.compose.resources.painterResource

@Composable
fun ConsultantList(
    component: ExpertListPageComponent,
    selectedOption: String,
    selectedSubCategory: String,
    consultants: List<RequestConsultant>
) {
    val experts by component.state.collectAsState()

    //val isLoading by component.isLoading.collectAsState()

    // Define sorting and filtering functions
    fun sortExperts(experts: List<RequestConsultant>, option: String): List<RequestConsultant> {
        return when (option) {
            "Popularity" -> experts
            "Price High to Low" -> experts.sortedByDescending { it.callPerMinutePrice }
            "Price Low to High" -> experts.sortedBy { it.callPerMinutePrice }
            "Experience High to Low" -> experts.sortedByDescending { it.year_of_experience }
            "Rating High to Low" -> experts.sortedByDescending { it.consultant_rating }
            "Free" -> experts.sortedByDescending { it.isFreeCall }
            "Premium" -> experts.sortedByDescending { it.premiumExpert }
//            "Free" -> experts.filter { it.isFreeCall }
//            "Premium" -> experts.filter { it.premiumExpert }
            else -> experts
        }
    }

    fun filterByCategory(experts: List<RequestConsultant>, category: String): List<RequestConsultant> {
        return if (category.equals("All", ignoreCase = true)) {
            experts
        } else {
            val completeMatches = experts.filter {
                it.skills.equals(category, ignoreCase = true)
            }

            val partialMatches = experts.filter {
                it.skills?.contains(category, ignoreCase = true) == true && !completeMatches.contains(it)
            }

            completeMatches + partialMatches
        }
    }



//    fun filterByCategory(experts: List<RequestConsultant>, category: String): List<RequestConsultant> {
//        return if (category.equals("All", ignoreCase = true)) experts else experts.filter {
//            it.skills.equals(category, ignoreCase = true)
//        }
//    }

    // Prepare sorted and filtered list
    val sortedExperts = sortExperts(consultants, selectedOption)
    val filteredExperts = filterByCategory(sortedExperts, selectedSubCategory)

    // UI Layout
    BoxWithConstraints {
        val isWideScreen = maxWidth > 600.dp
        if (isWideScreen) {
            // Grid layout for wide screens
            LazyVerticalGrid(
                columns = GridCells.Fixed(2),
                contentPadding = PaddingValues(horizontal = 12.dp, vertical = 8.dp)
            ) {
                itemsIndexed(filteredExperts) { index, expert ->
                    ConsultantItem(
                        expert = expert,
                        position = index,
                        listener = CategoryConsultListRowClickListenerImpl(component),
                        isFreeCallApplicable = selectedOption.equals("Free", ignoreCase = true)
                    )
                }
            }
        } else {
            // List layout for narrow screens
            LazyColumn(
                contentPadding = PaddingValues(horizontal = 12.dp, vertical = 8.dp)
            ) {
                itemsIndexed(filteredExperts) { index, expert ->
                    ConsultantItem(
                        expert = expert,
                        position = index,
                        listener = CategoryConsultListRowClickListenerImpl(component),
                        isFreeCallApplicable = selectedOption.equals("Free", ignoreCase = true)
                    )
                }
            }


//            // Handle bottom sheets
//            if (isChatSheetOpen) {
//                ChatConsultationBottomSheet(onDismissRequest = { isChatSheetOpen = false })
//            }
//
//            if (isSheetOpen) {
//                CallConsultationBottomSheet(onDismissRequest = { isSheetOpen = false })
//            }
        }
    }
}

@Composable
fun CategoryConsultListRowClickListenerImpl(component: ExpertListPageComponent): CategoryConsultListRowClickListener {
    val openDialog = remember { mutableStateOf(false) }
    if (openDialog.value) {
        openDialog(openDialog)
    }
    // Define the listener object
    return object : CategoryConsultListRowClickListener {
        override fun onItemClicks(position: Int, expert: RequestConsultant) {
            component.event(ExpertListPageEvent.onExpertCardClick(expertId = expert.id!!))
        }

        override fun onChatClick(position: Int, expert: RequestConsultant) {
            // Show the dialog when the chat icon is clicked
            openDialog.value = true
        }

        override fun onCallClick(position: Int, expert: RequestConsultant) {
            component.event(ExpertListPageEvent.onCallClick(
                expertId = expert.id.toString(),
                expertName = expert.full_name!!,
                expertImage = expert.profile_image_url!!,
                expertSkills = expert.skills!!
            ))
        }

        override fun onConsultNowClick(position: Int, expert: RequestConsultant) {
            showToast("${expert.full_name}")
        }
    }

    // Show the dialog if `openDialog` is true

}

@Composable
fun openDialog(sheet: MutableState<Boolean>) {
    if (sheet.value) {
        SampleDialog(
            onDismissRequest = { sheet.value = false },
            onPlayStore = {
                sheet.value = false
                openPlayStore(user_app_play_store_url)
                //showToast("Play Store")
            }
//            onAppStore = {
//                showToast("App Store")
//            }
        )
    }
}



@Composable
fun ConsultantItem(
    expert: RequestConsultant,
    position: Int,
    listener: CategoryConsultListRowClickListener,
    isFreeCallApplicable: Boolean
) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(8.dp)
            .clickable { listener.onItemClicks(position, expert) },
        shape = RoundedCornerShape(CORNER_SMALL),
        elevation = CardDefaults.elevatedCardElevation(ELEVATION_SMALL),
        colors = CardDefaults.cardColors(Color.White)
    ) {
        Row(
            modifier = Modifier
                .padding(4.dp)
                .fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        ) {
            // User Image Column
            Column(
                modifier = Modifier
                    .width(100.dp)
                    .wrapContentHeight(),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                val imageModifier = Modifier
                    .size(84.dp)
                    .border(width = 2.dp, color = Color.Gray, shape = CircleShape)
                    .clip(CircleShape)
                    .background(profile_image_background_color)

                if (expert.profile_image_url.isNullOrBlank()) {
                    Image(
                        painter = painterResource(Res.drawable.baseline_person_4_24),
                        contentDescription = null,
                        modifier = imageModifier,
                        contentScale = ContentScale.Crop
                    )
                } else {
                    AsyncImage(
                        model = expert.profile_image_url,
                        contentDescription = "Expert Image",
                        modifier = imageModifier,
                        contentScale = ContentScale.Crop
                    )
                }

                Spacer(modifier = Modifier.height(8.dp))

                Text(
                    text = "${expert.year_of_experience} yrs",
                    color = Color.Black,
                    style = MaterialTheme.typography.bodyMedium
                )

                Spacer(modifier = Modifier.height(4.dp))

                Row(verticalAlignment = Alignment.CenterVertically) {
                    Icon(
                        painter = painterResource(Res.drawable.ic_filled_star),
                        contentDescription = null,
                        tint = Color(0xFFFF9C27),
                        modifier = Modifier.size(16.dp)
                    )
                    ConsultantRating(expert.consultant_rating?.toDouble())
                }
            }

            Spacer(modifier = Modifier.width(8.dp))

            // Details Column
            Column(
                modifier = Modifier
                    .weight(1f)
                    .padding(end = 8.dp)
            ) {
                Text(
                    text = expert.full_name.orEmpty(),
                    style = MaterialTheme.typography.headlineSmall,
                    color = Color.Black,
                    fontSize = 16.sp,
                    maxLines = 1,
                    overflow = TextOverflow.Ellipsis
                )

                Spacer(modifier = Modifier.height(2.dp))

                Text(
                    text = expert.skills.orEmpty(),
                    style = MaterialTheme.typography.bodyMedium,
                    color = Color.Black,
                    maxLines = 1,
                    fontSize = 14.sp,
                    overflow = TextOverflow.Ellipsis
                )

                Spacer(modifier = Modifier.height(2.dp))

                Row(verticalAlignment = Alignment.CenterVertically) {
                    Icon(
                        painter = painterResource(Res.drawable.baseline_language_24),
                        contentDescription = null,
                        modifier = Modifier.size(14.dp),
                        tint = Color.Black
                    )
                    Spacer(modifier = Modifier.width(4.dp))
                    Text(
                        text = expert.language.orEmpty(),
                        style = MaterialTheme.typography.bodyMedium,
                        color = Color.Black,
                        maxLines = 1,
                        overflow = TextOverflow.Ellipsis
                    )
                }

                Spacer(modifier = Modifier.height(2.dp))

                ExpertCallPrice(expert, if (isFreeCallApplicable) true else expert.isFreeCall == 1)
                //ExpertCallPrice(expert, isFreeCallApplicable = expert.isFreeCall == 1 )

                Spacer(modifier = Modifier.height(2.dp))

                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceBetween,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Box(
                        modifier = Modifier.weight(1f)
                    ) {
                        CallChartActionButton(
                            text = "CALL",
                            icon = Icons.Filled.Call,
                            //icon = painterResource(Res.drawable.new_call1),
                            onClick = { listener.onCallClick(position, expert) },
                            buttonColor =
                                when (expert.consultantCallAvailability() ?: "") {
                                    "Online" -> call_button_color
                                    "Busy" -> chat_and_call_button_busy_color
                                    else -> call_button_background_color
                                },
                            consultantCallAvailability =  expert.consultantCallAvailability()!!
                        )
                    }

                    Spacer(modifier = Modifier.width(3.dp))

                    Box(
                        modifier = Modifier.weight(1f)
                    ) {

                        CallChartActionButton(
                            text = "CHAT",
                            icon = Icons.AutoMirrored.Filled.Chat,
                            //icon = painterResource(Res.drawable.new_caht2),
                            onClick = { listener.onChatClick(position, expert) },
                            buttonColor = when (expert.consultantCallAvailability() ?: "") {
                                "Online" -> primaryColor
                                "Busy" -> chat_and_call_button_busy_color
                                else -> chat_button_background_color
                            },
                            consultantCallAvailability =  expert.consultantChatAvailability()!!

                        )

                    }
                }
            }

            // Add to favourite button
            Box(modifier = Modifier.align(Alignment.Top)) {
                ActionIconButton(
                    icon = painterResource(Res.drawable.ic_heart_primary_color),
                    onClick = {
                        //listener.onConsultNowClick(position, expert)
                    },
                    //contentDescription = "Add to Favourites"
                )
            }
        }
    }
}