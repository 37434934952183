package com.winiwayuser.feature_chat_consultation.persentation.chat_bottom_sheet_info

import com.winiwayuser.feature_chat_consultation_new.data.response.chat_duration.ChatDurationData
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData

sealed class ChatInfoBottomSheetState {
    data class Error(val msg: String) : ChatInfoBottomSheetState()
    data class Loading(val isLoading: Boolean) : ChatInfoBottomSheetState()
    data class ChatInfoSuccess(val data: ChatDurationData) : ChatInfoBottomSheetState()
    data class FreeChatLowBalance(val data: ChatDurationData):ChatInfoBottomSheetState()
    data class NormalChatLowBalance(val data: ChatDurationData, val rechargeData: List<RechargeData>) :
        ChatInfoBottomSheetState()
}
