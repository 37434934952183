package com.winiwayuser.core.theme

import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowHeightSizeClass
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import com.winiwayuser.core.persentation.BrowserType
import com.winiwayuser.core.persentation.DeviceType
import com.winiwayuser.core.persentation.getDeviceType

object Scale {

    private val deviceType = getDeviceType()
    val isBrowserType = deviceType.name in BrowserType.entries.map { it.name }
    val extraSpace = if (isBrowserType) 350.dp else 0.dp
    val cardHeight = if (isBrowserType) 300.dp else 200.dp
    val cardWeight = if (isBrowserType) 200.dp else 150.dp
    val footer_max_height = if (isBrowserType) 400.dp else 700.dp
    //val deviceType = getDeviceType()


    val WINDOW_WIDTH_SIZE_COMPACT = Modifier.fillMaxWidth()
    val WINDOW_WIDTH_SIZE_MEDIUM = Modifier.fillMaxWidth(0.90f)
    val WINDOW_WIDTH_SIZE_EXPEND = Modifier.fillMaxWidth(0.80f)
    val WINDOW_WIDTH_SIZE_ELSE = Modifier.fillMaxWidth(0.70f)

    private fun getFooterHeight(deviceType: DeviceType): Dp {
        return when (deviceType) {
            DeviceType.WEB_HD -> 400.dp
            DeviceType.WEB_FULL_HD -> 400.dp
            DeviceType.WEB_2K -> 400.dp
            DeviceType.WEB_4K -> 400.dp
            DeviceType.WEB_UD -> 400.dp
            DeviceType.ANDROID -> 650.dp
            DeviceType.IOS -> 650.dp
            else -> 400.dp
        }
    }
    @OptIn(ExperimentalMaterial3WindowSizeClassApi::class)


    val isBrowserTypeMobile = deviceType.name in BrowserType.entries.map { it.name }
    val maxHeight = if (deviceType.name.equals("ANDROID", ignoreCase = false) || deviceType.name.equals("IOS", ignoreCase = false)) 650.dp else 400.dp
   // val maxHeight = getFooterHeight(deviceType)
    val footerHeightPx = getFooterHeight(deviceType)

    val GLOBAL_ICON_SIZE = 20.dp
    val ACTION_BAR_BUTTON_SIZE = 35.dp
    val WINYWAY_LOGO_SIZE = 40.dp

    val VERTICAL_PADDING = 10.dp
    val VERTICAL_PADDING_SMALL = 5.dp
    val HORIZONTAL_PADDING = 16.dp
    val HORIZONTAL_PADDING_SMALL = 8.dp

    val ELEVATION_SMALL = 1.dp
    val ELEVATION_MEDIUM = 2.dp
    val ELEVATION_LARGE = 4.dp

    val CORNER_EXTRA_SMALL = 4.dp
    val CORNER_SMALL = 6.dp
    val CORNER_MEDIUM = 8.dp
    val CORNER_LARGE = 12.dp
    val CORNER_EXTRA_LARGE = 16.dp


    /*Text Size*/
    const val HEADLINE_LARGE: Int = 40
    const val HEADLINE_MEDIUM: Int = 28
    const val HEADLINE_SMALL: Int = 24
    const val TITLE_LARGE: Int = 20
    const val TITLE_SMALL: Int = 16
    const val BODY_LARGE: Int = 16
    const val BODY_MEDIUM: Int = 15
    const val LABEL_LARGE: Int = 12
    const val LABEL_SMALL: Int = 10

    /*Text Size for Browser_Hd*/
    const val HEADLINE_LARGE_HD: Int = 40
    const val HEADLINE_MEDIUM_HD: Int = 28
    const val HEADLINE_SMALL_HD: Int = 24
    const val TITLE_LARGE_HD: Int = 20
    const val TITLE_SMALL_HD: Int = 16
    const val BODY_LARGE_HD: Int = 16
    const val BODY_MEDIUM_HD: Int = 15
    const val LABEL_LARGE_HD: Int = 12
    const val LABEL_SMALL_HD: Int = 10

    /*Text Size for Browser Full_Hd*/
    const val HEADLINE_LARGE_FULL_HD: Int = 42
    const val HEADLINE_MEDIUM_FULL_HD: Int = 30
    const val HEADLINE_SMALL_FULL_HD: Int = 26
    const val TITLE_LARGE_FULL_HD: Int = 22
    const val TITLE_SMALL_FULL_HD: Int = 18
    const val BODY_LARGE_FULL_HD: Int = 18
    const val BODY_MEDIUM_FULL_HD: Int = 17
    const val LABEL_LARGE_FULL_HD: Int = 14
    const val LABEL_SMALL_FULL_HD: Int = 12

    /*Text Size for Browser 2K*/
    const val HEADLINE_LARGE_2K: Int = 44
    const val HEADLINE_MEDIUM_2K: Int = 32
    const val HEADLINE_SMALL_2K: Int = 28
    const val TITLE_LARGE_2K: Int = 24
    const val TITLE_SMALL_2K: Int = 20
    const val BODY_LARGE_2K: Int = 20
    const val BODY_MEDIUM_2K: Int = 19
    const val LABEL_LARGE_2K: Int = 16
    const val LABEL_SMALL_2K: Int = 14

    /*Text Size for Browser 4K*/
    const val HEADLINE_LARGE_4K: Int = 46
    const val HEADLINE_MEDIUM_4K: Int = 34
    const val HEADLINE_SMALL_4K: Int = 30
    const val TITLE_LARGE_4K: Int = 26
    const val TITLE_SMALL_4K: Int = 22
    const val BODY_LARGE_4K: Int = 22
    const val BODY_MEDIUM_4K: Int = 21
    const val LABEL_LARGE_4K: Int = 18
    const val LABEL_SMALL_4K: Int = 16


    /*Live Session Card*/
    const val CARD_WIDTH = 155
    const val CARD_HEIGHT = 230
}

fun DeviceType.getBrowserTypeScale(scale: Int): Int {
    return when (name) {
        BrowserType.WEB_HD.name -> (scale * 1.4).toInt()
        BrowserType.WEB_FULL_HD.name -> (scale * 1.6).toInt()
        BrowserType.WEB_2K.name -> (scale * 1.8).toInt()
        BrowserType.WEB_4K.name -> (scale * 2)
        else -> (scale)
    }
}

fun DeviceType.getBrowserTypeScale(scale: Float): Float {
    return when (name) {
        BrowserType.WEB_HD.name -> (scale * 1.4F)
        BrowserType.WEB_FULL_HD.name -> (scale * 1.6F)
        BrowserType.WEB_2K.name -> (scale * 1.8F)
        BrowserType.WEB_4K.name -> (scale * 2)
        else -> (scale)
    }
}

fun DeviceType.getBrowserTypeScaleDegrade(scale: Float): Float {
    return when (name) {
        BrowserType.WEB_HD.name -> (scale / 1.4F)
        BrowserType.WEB_FULL_HD.name -> (scale / 1.6F)
        BrowserType.WEB_2K.name -> (scale / 1.8F)
        BrowserType.WEB_4K.name -> (scale / 2)
        else -> (scale)
    }
}
