package com.winiwayuser.feature_expert_consultation.presentation

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_authentication.persentation.otp_validation_page.OtpValidationState
import com.winiwayuser.feature_common.data.request.RequestConsultant
import com.winiwayuser.feature_common.data.response.SubCategoriesModel
import com.winiwayuser.feature_expert_consultation.data.remote.ExpertApi
import com.winiwayuser.feature_expert_consultation.data.remote.request.ExpertListRequest
import com.winiwayuser.feature_expert_consultation.data.remote.response.Consultant
import com.winiwayuser.feature_expert_consultation.expert_list_state_info.ExpertListState
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

//class ExpertListPageComponent(
//    context: ComponentContext,
//    private val categoryId: String?,
//    private val categoryName: String?,
//    private val SubCategories: List<SubCategoriesModel>?,
//    private val ExpertListPageEvent: () -> Unit,
//    private val OnBackPressed:() -> Unit,
//    private val OnSerachFieldClick:() -> Unit
//) : ComponentContext by context, KoinComponent {
//
//    val _categoryId get() = categoryId
//    val _categoryName get() = categoryName
//    val _subCategorys get() = SubCategories
//    fun event(event: ExpertListPageEvent) {
//        when (event) {
//            is ExpertListPageEvent.OnExpertDetailsPage -> ExpertListPageEvent.invoke()
//            is ExpertListPageEvent.OnErrorBottomSheetCallConsultation -> ExpertListPageEvent.invoke()
//            is ExpertListPageEvent.OnBackPressed -> OnBackPressed.invoke()
//           is ExpertListPageEvent.OnSubCategoryClick -> {
//               getExpertList()
//           }
//            is ExpertListPageEvent.OnSearchFieldClick -> OnSerachFieldClick.invoke()
//         }
//    }
//
//    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
//    private val expertApi: ExpertApi by inject()
////    private val _state = MutableStateFlow<List<RequestConsultant>>(emptyList())
////    val state: StateFlow<List<RequestConsultant>> get() = _state
//
//    private val _state = MutableStateFlow<List<RequestConsultant>>(emptyList())
//    val state: StateFlow<List<RequestConsultant>> get() = _state
//
//    private val _isLoading = MutableStateFlow(false)
//    val isLoading: StateFlow<Boolean> get() = _isLoading
//
//
//    init {
//        lifecycle.doOnStart {
//            Logger.d { "Home Screen Tab Component onStart" }
//            getExpertList()
//
//        }
//
//        lifecycle.doOnDestroy {
//            Logger.d { "Home Screen Tab Component onDestroy" }
//            coroutineScope.cancel() // Cancel all coroutines when the component is destroyed
//        }
//
//    }
//
//    private fun getExpertList() {
//        coroutineScope.launch {
//            val expertListRequest = ExpertListRequest(
//                categoryId = categoryId,
//                type =  "All"
//            )
//            expertApi.getExpertListRemote(expertListRequest).collect {
//                when (it) {
////                    is Resources.Success -> {
////                        _state.value = it.data?.consultants ?: emptyList()
////                    }
////
////                    is Resources.Loading -> _sta
////
////                    else -> Unit
//                    is Resources.Success -> {
//                        _state.value = it.data?.consultants ?: emptyList()
//                        _isLoading.value = false
//                    }
//
//                    is Resources.Loading -> {
//                        _isLoading.value = true
//                    }
//
//                    else -> {
//                        _isLoading.value = false
//                    }
//
//                }
//            }
//        }
//    }
//
//
//}

class ExpertListPageComponent(
    context: ComponentContext,
    private val categoryId: String?,
    private val categoryName: String?,
    private val subCategories: List<SubCategoriesModel>?,
    private val onExpertListPageEvent: () -> Unit,
    private val onBackPressed: () -> Unit,
    private val onSearchFieldClick: () -> Unit,
    private val onClickExpertCard: (Int) -> Unit,
    private val onCallButtonClick: (String,String,String,String) -> Unit,
    private val onChatButtonClick: (String,String,String,String) -> Unit
) : ComponentContext by context, KoinComponent {

    val _categoryId get() = categoryId
    val _categoryName get() = categoryName
    val _subCategorys get() = subCategories
    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    private val expertApi: ExpertApi by inject()

    private val _state = MutableStateFlow<ExpertListState>(ExpertListState.Loading)
    val state: StateFlow<ExpertListState> get() = _state

    init {
        lifecycle.doOnStart {
            Logger.d { "Expert List Screen Tab Component onStart" }
            fetchExpertList()
        }

        lifecycle.doOnDestroy {
            Logger.d { "Expert List Screen Tab Component onDestroy" }
            coroutineScope.cancel() // Cancel all coroutines when the component is destroyed
        }
    }

    fun event(event: ExpertListPageEvent) {
        when (event) {
            is ExpertListPageEvent.OnExpertDetailsPage -> onExpertListPageEvent.invoke()
            is ExpertListPageEvent.OnErrorBottomSheetCallConsultation -> onExpertListPageEvent.invoke()
            is ExpertListPageEvent.OnBackPressed -> onBackPressed.invoke()
            is ExpertListPageEvent.OnSubCategoryClick -> {
                fetchExpertList()
            }

            is ExpertListPageEvent.OnSearchFieldClick -> onSearchFieldClick.invoke()
            is ExpertListPageEvent.onExpertCardClick -> onClickExpertCard.invoke(event.expertId)
            //is ExpertListPageEvent.onExpertCardClick -> onClickExpertCard.invoke()
            is ExpertListPageEvent.onCallClick -> onCallButtonClick.invoke(event.expertId,event.expertName,event.expertImage,event.expertSkills)
            is ExpertListPageEvent.onChatCick -> onChatButtonClick.invoke(event.expertId,event.expertName,event.expertImage,event.expertSkills)
        }
    }

    private fun fetchExpertList() {
        coroutineScope.launch {
            val request = ExpertListRequest(
                categoryId = categoryId,
                type = "All"
            )
            expertApi.getExpertListRemote(request).collect { state ->
                _state.value = state
            }
        }
    }

    // Other methods like event() remain unchanged
}


