package com.winiwayuser.feature_chat_consultation.di

import com.winiwayuser.feature_chat_consultation.data.api.ChatConsultationApi
import com.winiwayuser.feature_chat_consultation.domain.ChatConcernRepo
import com.winiwayuser.feature_chat_consultation.domain.ChatConsultationInfoRepo
import com.winiwayuser.feature_chat_consultation.domain.ChatRoomRepo
import com.winiwayuser.feature_chat_consultation.domain.ChatWaitingRepo
import org.koin.dsl.module

val chatConsultationModule = module {
    single { ChatConsultationApi() }
    single { ChatConsultationInfoRepo(get()) }
    single { ChatWaitingRepo(get()) }
    single { ChatConcernRepo(get()) }
    single { ChatRoomRepo(get()) }
}