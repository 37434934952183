package com.winiwayuser.feature_call_consultation.persentation.call_bottomsheet

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Call
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.ModalBottomSheet
import androidx.compose.material3.Text
import androidx.compose.material3.rememberModalBottomSheetState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.theme.let_s_connect
import com.winiwayuser.core.theme.maximum_call_duration
import com.winiwayuser.core.theme.md_them_primary
import com.winiwayuser.core.theme.pricing
import com.winiwayuser.core.theme.primaryColor
import com.winiwayuser.core.theme.primaryLight50
import com.winiwayuser.core.theme.wallet_balance
import com.winiwayuser.feature_call_consultation.data.response.CallDurationData
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.ellipse
import com.winiwayuser.resources.ic_wallet
import kotlinx.coroutines.launch
import okio.Timeout
import org.jetbrains.compose.resources.painterResource

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun CallInfoSuccessBottomSheet(
    consultationComponent: CallConsultationComponent,
    callDurationData: CallDurationData?,
    onDismiss: () -> Unit
) {

    val sheetState = rememberModalBottomSheetState(skipPartiallyExpanded = true)
    val coroutineScope = rememberCoroutineScope()
    val state by consultationComponent.state.collectAsState()
    //val successState = state as? CallInfoBottomSheetState.CallInfoSuccess
    //var callDurationData: CallDurationData? = null
//    if (state is CallInfoBottomSheetState.Loading) {
//        LoadingProgressDialog { }
//    }
//    if (state is CallInfoBottomSheetState.NormalCallLowBalance) {
//        //showToast("Low balance. Please recharge.")
//        coroutineScope.launch {
//            sheetState.hide()
//        }
//        RechargeNowBottomSheetDialog(onDismissRequest = {
//            coroutineScope.launch {
//                //sheetState.hide()
//                consultationComponent.onClosePage()
//            }
//        }, onRechargeSelected = { selectedAmount ->
//            co.touchlab.kermit.Logger.d("selected amount - $selectedAmount")
//        })
//    } else if (state is CallInfoBottomSheetState.CallInfoSuccess) {
//        // Ensure that successState is not null by casting state to CallInfoSuccess
//        //val successState = state as CallInfoBottomSheetState.CallInfoSuccess
//        coroutineScope.launch {
//            sheetState.show()
//        }
//        callDurationData = successState?.data
//    }


//    if(state is CallInfoBottomSheetState.NormalCallLowBalance){
//        showToast("low balance Please Recharge")
//    }
//
//    if (successState != null && state !is CallInfoBottomSheetState.NormalCallLowBalance) {
//        coroutineScope.launch {
//            sheetState.show()
//        }
//        callDurationData = successState.data
//    }


//    if (state is CallInfoBottomSheetState.CallInfoSuccess) {
//        coroutineScope.launch {
//            sheetState.show()
//        }
//        val data = state.data
//    }




    ModalBottomSheet(
        sheetState = sheetState,
        onDismissRequest = {
            coroutineScope.launch {
                coroutineScope.launch {
                    sheetState.hide()
                    onDismiss.invoke()
                }
            }
        }
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp)
                .align(Alignment.CenterHorizontally)
        ) {
            Box(modifier = Modifier.fillMaxWidth(), contentAlignment = Alignment.Center) {
                Text(
                    text = "Call Consultation",
//                    style = MaterialTheme.typography.subtitle1,
//                    modifier = Modifier.padding(vertical = 16.dp)
                )
            }

            Spacer(modifier = Modifier.height(8.dp))
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(90.dp)
//                    .padding(top = 16.dp)
                    .clip(RoundedCornerShape(8.dp)),
//                    .background(Color.LightGray),
                verticalAlignment = Alignment.CenterVertically
            ) {
                if(consultationComponent.expertImage.isEmpty()){
                    Image(painter = painterResource(Res.drawable.ellipse), contentDescription = null)
                }else{
                    AsyncImage(
                        model = consultationComponent.expertImage,
                        contentDescription = "expert image",
                        modifier = Modifier
                            .size(85.dp)
                            .clip(CircleShape),
                        contentScale = ContentScale.Crop
                    )
                }

                Spacer(modifier = Modifier.width(16.dp))
                Column {
                    Text(
                        text = consultationComponent.expertName,
                        // style = MaterialTheme.typography.body1,
                        maxLines = 1,
                        modifier = Modifier.fillMaxWidth()
                    )
                    Spacer(modifier = Modifier.height(4.dp))
                    Text(
                        text = consultationComponent.expertSkills,
                        // style = MaterialTheme.typography.body2,
                        maxLines = 2,
                        modifier = Modifier.fillMaxWidth()
                    )
                }
            }

            Spacer(modifier = Modifier.height(8.dp))
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(90.dp)
                    .clip(RoundedCornerShape(8.dp)),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {
                // Wallet Balance
                Column(
                    modifier = Modifier.weight(1f)
                ) {

                    Row {
                        Box(modifier = Modifier.background(primaryLight50)) {
                            Image(
                                painter = painterResource(Res.drawable.ic_wallet),
                                contentDescription = null,
                                modifier = Modifier.height(35.dp).width(35.dp)
                            )
                        }
                        Spacer(modifier = Modifier.width(8.dp))
                        Column {
                            Text(
                                text = wallet_balance,
                                fontSize = 12.sp,
                                // style = MaterialTheme.typography.body1,
                                maxLines = 1,
                                modifier = Modifier.fillMaxWidth()
                            )
                            Text(
                                //text = "₹500",
                                // style = MaterialTheme.typography.body2,
                                text = callDurationData?.balance.toString(),
                                maxLines = 1,
                                modifier = Modifier.fillMaxWidth()
                            )
                        }

                    }

                }
                // Pricing
                Column(
                    modifier = Modifier.weight(1f)
                ) {
                    Row {
                        Box(modifier = Modifier.background(primaryLight50)) {
                            Image(
                               // painter = painterResource(Res.drawable.consultation_call_small_icon),
                                imageVector = Icons.Default.Call,
                                contentDescription = null,
                                modifier = Modifier.size(25.dp),
                                colorFilter = ColorFilter.tint(md_them_primary)
                            )
                        }
                        Spacer(modifier = Modifier.width(8.dp))
                        Column {
                            Text(
                                text = pricing,
                                // style = MaterialTheme.typography.body1,
                                fontSize = 12.sp,
                                maxLines = 1,
                                modifier = Modifier.fillMaxWidth()
                            )

                            Row(verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier.fillMaxWidth())
                            {
                                if(callDurationData?.type != null){
                                    Box(
                                        modifier = Modifier
                                            .background(primaryColor)
                                            .padding(horizontal = 4.dp, vertical = 4.dp),
                                    ) {
                                        Text(
                                            text = "NEW USER",
                                            color = Color.White,
                                            fontSize = 10.sp,
                                            textAlign = TextAlign.Center,
                                            modifier = Modifier.align(Alignment.Center)
                                        )
                                    }
                                }else {
                                    if (callDurationData?.flashOff.toString()
                                            .equals("0", ignoreCase = true)
                                    ) {
                                        Text(
                                            text = "${callDurationData?.perMinutePrice} /Min",
                                            maxLines = 1,
                                            modifier = Modifier.fillMaxWidth()
                                        )
                                    } else {
                                        Text(
                                            text = "${callDurationData?.flashOff} /Min",
                                            fontSize = 14.sp,
                                            color = Color.Black,
                                        )
                                        Spacer(modifier = Modifier.width(5.dp))
                                        Text(
                                            text = "${callDurationData?.perMinutePrice} /Min",
                                            textDecoration = TextDecoration.LineThrough,
                                            fontSize = 12.sp,
                                            color = Color.Black,
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }

            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .clip(RoundedCornerShape(8.dp))
                    .background(primaryLight50),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Image(
                   // painter = painterResource(Res.drawable.consultation_call_small_icon),
                    imageVector = Icons.Default.Call,
                    modifier = Modifier.size(25.dp),
                    contentDescription = null,
                    colorFilter = ColorFilter.tint(md_them_primary)
                )
                Spacer(modifier = Modifier.width(8.dp))
                Text(text = maximum_call_duration)
                Spacer(modifier = Modifier.weight(1f))
//                Box(contentAlignment = Alignment.CenterEnd) {
//                    Text(text = callDurationData?.totalCallDuration.toString(), textAlign = TextAlign.End,)
//                }
                Box(contentAlignment = Alignment.CenterEnd) {
                    // Safely get the total call duration or default to 0 if null
                    val totalCallDuration = callDurationData?.totalCallDuration?.toIntOrNull() ?: 0

                    // Calculate minutes and seconds
                    val minutes = totalCallDuration / 60
                    val seconds = totalCallDuration % 60

                    // Manually format the time as MM:SS
                    val formattedTime = "${if (minutes < 10) "0" else ""}$minutes:${if (seconds < 10) "0" else ""}$seconds"

                    // Display the formatted time in the Text composable
                    Text(
                        text = "$formattedTime Min",
                        textAlign = TextAlign.End
                    )
                }

            }

            Spacer(modifier = Modifier.height(10.dp))

            if(callDurationData?.type != null){
                Box(
                    modifier = Modifier.fillMaxWidth(),
                    contentAlignment = Alignment.Center
                ) {
                    Text(
                        text = "This is a one-time free call offered by Winyway",
                        fontSize = 12.sp,
                        textAlign = TextAlign.Center
                    )
                }
            }

            Spacer(modifier = Modifier.height(10.dp))

            Button(
                onClick = {
                    consultationComponent.onClickLetsConnectButton()
                },
                modifier = Modifier
                    .fillMaxWidth()
                    .height(55.dp)
                    .padding(bottom = 8.dp),
                shape = RoundedCornerShape(5.dp), // Set the shape to RectangleShape
                colors = ButtonDefaults.buttonColors(md_them_primary)
            ) {
                Text(text = let_s_connect, color = Color.White)
            }

            Button(
                onClick = { consultationComponent.event(CallConsultationEvent.onNavigateChoseRechargePack)},
                modifier = Modifier
                    .fillMaxWidth()
                    .height(55.dp)
                    .padding(bottom = 8.dp),
                shape = RoundedCornerShape(5.dp), // Set the shape to RectangleShape
                colors = ButtonDefaults.buttonColors(Color.Transparent),
                border = BorderStroke(1.dp, md_them_primary)
            ) {
                Text(text = "Low Balance? Recharge Wallet", color = md_them_primary)
            }
        }
    }
//    if(isCallConsultationOptionSheetOpen){
//        CallConsultationOptionScreen(
//            callDurationData?.data?.expertId.toString(),
//            callDurationData?.data?.flashOff.toString(),
//            sheetState = callConsultationOptionSheetState,
//            onDismissRequest = { isCallConsultationOptionSheetOpen = false }
//        )
//    }


//    ModalBottomSheet(
//        onDismissRequest = { onDismissRequest() }
//    ) {
//        Column(
//            modifier = Modifier
//                .fillMaxWidth()
//                .padding(16.dp)
//                .align(Alignment.CenterHorizontally)
//        ) {
//            Box(modifier = Modifier.fillMaxWidth(), contentAlignment = Alignment.Center) {
//                Text(
//                    text = "Call Consultation",
////                    style = MaterialTheme.typography.subtitle1,
////                    modifier = Modifier.padding(vertical = 16.dp)
//                )
//            }
//
//            Spacer(modifier = Modifier.height(8.dp))
//            Row(
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .height(90.dp)
////                    .padding(top = 16.dp)
//                    .clip(RoundedCornerShape(8.dp)),
////                    .background(Color.LightGray),
//                verticalAlignment = Alignment.CenterVertically
//            ) {
//
//                AsyncImage(
//                    model = expertImage,
//                    contentDescription = null,
//                    modifier = Modifier
//                        .size(74.dp)
//                        .clip(shape = CircleShape)
//                        .border(2.dp, Color(0xFFDBA514), CircleShape),
//                    contentScale = ContentScale.Fit
//                )
////                Image(painter = painterResource("drawable/ellipse.png"), contentDescription = null)
//                Spacer(modifier = Modifier.width(16.dp))
//                Column {
//                    Text(
//                        text = callDurationData?.data?.expertName.toString(),
//                        // style = MaterialTheme.typography.body1,
//                        maxLines = 1,
//                        modifier = Modifier.fillMaxWidth()
//                    )
//                    Spacer(modifier = Modifier.height(4.dp))
//                    Text(
//                        text = expertSkill,
//                        // style = MaterialTheme.typography.body2,
//                        maxLines = 2,
//                        modifier = Modifier.fillMaxWidth()
//                    )
//                }
//            }
//
//            Spacer(modifier = Modifier.height(8.dp))
//            Row(
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .height(90.dp)
//                    .clip(RoundedCornerShape(8.dp)),
//                horizontalArrangement = Arrangement.SpaceBetween,
//                verticalAlignment = Alignment.CenterVertically
//            ) {
//                // Wallet Balance
//                Column(
//                    modifier = Modifier.weight(1f)
//                ) {
//
//                    Row {
//                        Box(modifier = Modifier.background(primaryLight50)) {
//                            Image(
//                                painter = painterResource(Res.drawable.ic_wallet),
//                                contentDescription = null,
//                                modifier = Modifier.height(35.dp).width(35.dp)
//                            )
//                        }
//                        Spacer(modifier = Modifier.width(8.dp))
//                        Column {
//                            Text(
//                                text = wallet_balance,
//                                fontSize = 12.sp,
//                                // style = MaterialTheme.typography.body1,
//                                maxLines = 1,
//                                modifier = Modifier.fillMaxWidth()
//                            )
//                            Text(
//                                text = "₹  ${callDurationData?.data?.balance.toString()}",
//                                // style = MaterialTheme.typography.body2,
//                                maxLines = 1,
//                                modifier = Modifier.fillMaxWidth()
//                            )
//                        }
//
//                    }
//
//                }
//                // Pricing
//                Column(
//                    modifier = Modifier.weight(1f)
//                ) {
//                    Row {
//                        Box(modifier = Modifier.background(primaryLight50)) {
//                            Image(
//                                painter = painterResource(Res.drawable.consultation_call_small_icon),
//                                contentDescription = null,
//                                modifier = Modifier.size(48.dp),
//                                colorFilter = ColorFilter.tint(md_them_primary)
//                            )
//                        }
//                        Spacer(modifier = Modifier.width(8.dp))
//                        Column {
//                            Text(
//                                text = pricing,
//                                // style = MaterialTheme.typography.body1,
//                                fontSize = 12.sp,
//                                maxLines = 1,
//                                modifier = Modifier.fillMaxWidth()
//                            )
//
//                            Row(verticalAlignment = Alignment.CenterVertically) {
//                                Box(
//                                    modifier = Modifier.background(
//                                        Color(0xFFEF5350),
//                                        shape = RoundedCornerShape(2.dp)
//                                    )
//                                ) {
//                                    Text(text = "Free", fontSize = 10.sp, color = Color.White)
//                                }
//                                Text(
//                                    text = "₹${callDurationData?.data?.perMinutePrice.toString()}",
//                                    // style = MaterialTheme.typography.body2,
//                                    maxLines = 1,
//                                    modifier = Modifier.fillMaxWidth()
//                                )
//                            }
//                        }
//                    }
//                }
//            }
//
//            Row(
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .clip(RoundedCornerShape(8.dp))
//                    .background(primaryLight50),
//                verticalAlignment = Alignment.CenterVertically
//            ) {
//                Image(
//                    painter = painterResource(Res.drawable.ic_duration_chronic),
//                    contentDescription = null,
//                    colorFilter = ColorFilter.tint(md_them_primary)
//                )
//                Spacer(modifier = Modifier.width(8.dp))
//                Text(text = maximum_call_duration)
//                Spacer(modifier = Modifier.weight(1f))
//                Box(contentAlignment = Alignment.CenterEnd) {
//
//                    Text(text = ((callDurationData?.data?.totalCallDuration)).toString(), textAlign = TextAlign.End)
//                }
//
//            }
//
//            Spacer(modifier = Modifier.height(10.dp))
//
//            Box(
//                modifier = Modifier.fillMaxWidth(),
//                contentAlignment = Alignment.Center
//            ) {
//                Text(
//                    "This is a one-time free call offered by Winyway",
//                    fontSize = 12.sp,
//                    textAlign = TextAlign.Center
//                )
//            }
//
//            Spacer(modifier = Modifier.height(10.dp))
//
//            Button(
//                onClick = {
//                    isCallConsultationOptionSheetOpen = true
//                },
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .height(55.dp)
//                    .padding(bottom = 8.dp),
//                shape = RoundedCornerShape(5.dp), // Set the shape to RectangleShape
//                colors = ButtonDefaults.buttonColors(md_them_primary)
//            ) {
//                Text(text = let_s_connect, color = Color.White)
//            }
//
//            Button(
//                onClick = { },
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .height(55.dp)
//                    .padding(bottom = 8.dp),
//                shape = RoundedCornerShape(5.dp), // Set the shape to RectangleShape
//                colors = ButtonDefaults.buttonColors(Color.Transparent),
//                border = BorderStroke(1.dp, md_them_primary)
//            ) {
//                Text(text = "Low Balance? Recharge Wallet", color = md_them_primary)
//            }
//        }
//    }

}