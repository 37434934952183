package com.winiwayuser.feature_authentication.domain

import com.winiwayuser.core.local.UserInfoProvider
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_authentication.data.AuthApiService
import com.winiwayuser.feature_authentication.data.request.RegisterRequest
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow

class RegistrationUserUseCases(
    private val authApiService: AuthApiService
) {

    suspend fun onRegisterUser(request: RegisterRequest): Flow<Resources<Boolean>> {
        return flow {
            authApiService.registerUser(request).collect {

                when (it) {
                    is Resources.Error -> emit(Resources.Error(it.msg))
                    is Resources.Loading -> emit(Resources.Loading(it.isLoading))
                    is Resources.Success -> {
                        it.data?.let { user ->
                            UserInfoProvider.setFullName(user.user.fullName)
                            UserInfoProvider.setTotalCoin(user.totalCoin ?: 0)
                        }
                    }
                }
            }
        }

    }
}