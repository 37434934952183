
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Shapes
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import com.arkivanov.decompose.extensions.compose.stack.Children
import com.arkivanov.decompose.extensions.compose.stack.animation.slide
import com.arkivanov.decompose.extensions.compose.stack.animation.stackAnimation
import com.arkivanov.decompose.extensions.compose.subscribeAsState
import com.winiwayuser.core.decompose.RootComponent
import com.winiwayuser.core.theme.LightColorScheme
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.user_feature_authentication.persentation.login_page.loginPage
import com.winiwayuser.user_feature_authentication.persentation.otp_validation_page.OtpValidationPage
import com.winiwayuser.user_feature_authentication.persentation.register_page.RegisterPage
import com.winiwayuser.feature_call_consultation.persentation.call_bottomsheet.CallConsultationBottomSheet
import com.winiwayuser.feature_call_consultation.persentation.call_bottomsheet.call_consultation_options.CallConsultationOptionScreen
import com.winiwayuser.feature_call_consultation.persentation.call_bottomsheet.call_consultation_options.CallPlaceSuccess
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_host.ChatHostPage
import com.winiwayuser.feature_common.persentation.home_tab.HomeScreenTab
import com.winiwayuser.feature_common.persentation.more_tab.MoreScreenTab
import com.winiwayuser.feature_common.persentation.more_tab.edit_profile.EditProfileScreen
import com.winiwayuser.feature_common.persentation.more_tab.privacy_policy.PrivacyPolicyPage
import com.winiwayuser.feature_common.persentation.more_tab.terms_and_conditions.TermsAndConditions
import com.winiwayuser.feature_landing_page.persentation.LandingPageLayout
import com.winiwayuser.feature_expert_consultation.presentation.expert_search_page.DisplaySearchExpertList
import com.winiwayuser.feature_expert_consultation.presentation.expert_list_page.ExpertListPage
import com.winiwayuser.feature_expert_consultation.presentation.expert_detail_page.ExpertDetails
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_checkout.RechargeCheckout
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_failure.RechargeFailure
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_getway.PaymentGatewayPage
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.ChooseRechargePack
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_payment_success.RechargeSuccess

@Composable
fun App(
    darkTheme: Boolean,
    dynamicColor: Boolean,
    rootComponent: RootComponent
) {

    val customShape = Shapes(
        extraSmall = RoundedCornerShape(Scale.CORNER_EXTRA_SMALL),  // Square shape
        small = RoundedCornerShape(Scale.CORNER_SMALL),      // Slightly rounded corners
        medium = RoundedCornerShape(Scale.CORNER_MEDIUM),     // More rounded corners
        large = RoundedCornerShape(Scale.CORNER_LARGE),      // Larger rounded corners
        extraLarge = RoundedCornerShape(Scale.CORNER_EXTRA_LARGE), // Even larger rounded corners
    )

    MaterialTheme(
        colorScheme = LightColorScheme,
        shapes = customShape,
        typography = MaterialTheme.typography.copy(
            labelSmall = TextStyle(
                color = Color.Gray.copy(0.9f)
            ),
            titleMedium = TextStyle(
                fontWeight = FontWeight.Bold
            )
        )
    ) {

        val childStack by rootComponent.stack.subscribeAsState()

        Children(
            stack = childStack,
            animation = stackAnimation(slide())
        ) { child ->

            when (val instance = child.instance) {
                is RootComponent.Child.MoreScreenTab -> MoreScreenTab(instance.component, true)
                /*feature_authentication*/
                is RootComponent.Child.LoginPage -> loginPage(instance.component)
                is RootComponent.Child.OtpValidationPage -> OtpValidationPage(instance.component)
                is RootComponent.Child.RegistrationPage -> RegisterPage(instance.component)

                is RootComponent.Child.ExpertListPage -> ExpertListPage(instance.component)
                is RootComponent.Child.ExpertDetailsPage -> ExpertDetails(instance.component)
                is RootComponent.Child.ChoseRechargePage -> ChooseRechargePack(instance.component)
                is RootComponent.Child.HomeScreenTab -> HomeScreenTab(instance.component)

                is RootComponent.Child.MainScreen -> Unit//MainScreen(instance.component)
                is RootComponent.Child.PrivacyPolicyPage -> PrivacyPolicyPage(instance.component)
                is RootComponent.Child.TermsAndConditions -> TermsAndConditions(instance.component)
                is RootComponent.Child.EditProfileScreen -> EditProfileScreen(instance.component)

                /*feature_Winyway_wallet*/
                is RootComponent.Child.RechargeCheckout -> RechargeCheckout(instance.component)
                is RootComponent.Child.PaymentGateway -> PaymentGatewayPage(instance.component)
                is RootComponent.Child.RechargeFailure -> RechargeFailure(instance.component)
                is RootComponent.Child.RechargeSuccess -> RechargeSuccess(instance.component)
//                is RootComponent.Child.ChatConsultationInfo -> ChatConsultationBottomSheet(instance.component)
                is RootComponent.Child.DisplaySearchExpertList -> DisplaySearchExpertList(instance.component)
                is RootComponent.Child.CallConsultationInfo -> CallConsultationBottomSheet(instance.component)
//                is RootComponent.Child.ChatWaitingPage -> ChatConnectingScreen(instance.component)
                is RootComponent.Child.ChatHostChild -> ChatHostPage(instance.component)
//                is RootComponent.Child.ChatConcernPage -> {}
                is RootComponent.Child.CallConsultationOptions -> CallConsultationOptionScreen(instance.component)
                is RootComponent.Child.CallPlaceSuccess -> CallPlaceSuccess(instance.component)
                is RootComponent.Child.CallWaitingSuccess -> CallPlaceSuccess(instance.component)
                is RootComponent.Child.LandingPage -> LandingPageLayout(instance.component)
            }
        }

    }
}



