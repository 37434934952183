package com.winiwayuser.feature_chat_consultation_new.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GeoDetailsRequest(
    @SerialName("place") val cityName: String,
    @SerialName("maxRows") val maxRows: String,
)
