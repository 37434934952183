package com.winiwayuser.feature_chat_consultation_new.persentation.chat_bottom_sheet_info.widget

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.winiwayuser.core.date_time.formatSecondsToHHMMSS
import com.winiwayuser.core.persentation.CommonTextButton
import com.winiwayuser.core.persentation.CommonButton
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.theme.currency
import com.winiwayuser.core.theme.let_s_connect
import com.winiwayuser.core.theme.maximum_call_duration
import com.winiwayuser.core.theme.pricing
import com.winiwayuser.core.theme.primaryColor
import com.winiwayuser.core.theme.wallet_balance
import com.winiwayuser.feature_chat_consultation_new.data.response.chat_duration.ChatDurationData
import com.winiwayuser.feature_chat_consultation.domain.FREE_CHAT_TYPE
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.ic_wallet
import org.jetbrains.compose.resources.painterResource


@Composable
fun ChatInfoSuccessUi(
    modifier: Modifier,
    expertName: String,
    expertSkills: String,
    expertImage: String,
    chatDurationData: ChatDurationData,
    onSubmit: () -> Unit
){
    Column(
        modifier = modifier.fillMaxWidth().padding(20.dp)
    ) {
        // Title Section
        Text(
            text = "Chat Consultation",
            style = MaterialTheme.typography.bodyLarge,
            modifier = Modifier.fillMaxWidth(),
            textAlign = TextAlign.Center
        )


        ExpertCard(
            modifier = Modifier,
            expertName = expertName,
            expertImage = expertImage,
            expertSkills = expertSkills
        )

        Spacer(modifier = Modifier.height(8.dp))
        Row(
            modifier = Modifier.fillMaxWidth().height(90.dp).clip(RoundedCornerShape(8.dp)),
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically
        ) {
            // Wallet Balance
            Row(
                verticalAlignment = Alignment.CenterVertically, modifier = Modifier.weight(1f)
            ) {
                Box(
                    modifier = Modifier.background(
                        primaryColor.copy(alpha = 0.15F),
                        shape = RoundedCornerShape(Scale.CORNER_SMALL)
                    ).padding(8.dp)
                ) {
                    Image(
                        painter = painterResource(Res.drawable.ic_wallet),
                        contentDescription = null,
                        modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE_MAX),
                        colorFilter = ColorFilter.tint(primaryColor),
                    )
                }
                Spacer(modifier = Modifier.width(8.dp))
                Column {
                    Text(
                        text = wallet_balance, style = MaterialTheme.typography.labelSmall
                    )
                    Text(
                        text = "$currency${chatDurationData.balance}",
                        style = MaterialTheme.typography.titleSmall
                    )
                }
            }
           //  Pricing
            Row(
                verticalAlignment = Alignment.CenterVertically, modifier = Modifier.weight(1f)
            ) {
                Box(
                    modifier = Modifier.background(
                        primaryColor.copy(alpha = 0.15F),
                        shape = RoundedCornerShape(Scale.CORNER_SMALL)
                    ).padding(8.dp)
                ) {
                    Image(
                        painter = painterResource(Res.drawable.ic_wallet),
                        contentDescription = null,
                        modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE_MAX),
                        colorFilter = ColorFilter.tint(primaryColor),
                    )
                }
                Spacer(modifier = Modifier.width(8.dp))
                Column {
                    Text(
                        text = pricing, style = MaterialTheme.typography.labelSmall
                    )
//                    Row(verticalAlignment = Alignment.CenterVertically) {
//                        Text(
//                            text = "$currency ${chatDurationData.flashPrice}/Min",
//                            maxLines = 1,
//                            style = MaterialTheme.typography.titleSmall
//                        )
//                    }

                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier.fillMaxWidth()
                    )
                    {
                        if (chatDurationData?.type != null) {
                            Box(
                                modifier = Modifier
                                    .background(primaryColor)
                                    .padding(horizontal = 4.dp, vertical = 4.dp),
                            ) {
                                Text(
                                    text = "NEW USER",
                                    color = Color.White,
                                    fontSize = 10.sp,
                                    textAlign = TextAlign.Center,
                                    modifier = Modifier.align(Alignment.Center)
                                )
                            }
                        } else {
                            if (chatDurationData?.flashPrice == null || chatDurationData.flashPrice == 0
                            //.equals("0", ignoreCase = true) || callDurationData?.flashOff?.toString().equals("null",true)
                            ) {
                                Text(
                                    text = "$currency${chatDurationData?.perMinutePrice} /Min",
                                    maxLines = 1,
                                    modifier = Modifier.fillMaxWidth()
                                )
                            } else {
                                Text(
                                    text = "$currency${chatDurationData?.flashPrice} /Min",
                                    fontSize = 14.sp,
                                    color = Color.Black,
                                )
                                Spacer(modifier = Modifier.width(5.dp))
                                Text(
                                    text = "$currency${chatDurationData?.perMinutePrice} /Min",
                                    textDecoration = TextDecoration.LineThrough,
                                    fontSize = 12.sp,
                                    color = Color.Black,
                                )
                            }
                        }
                    }
                }
            }
        }

        Box(
            modifier = Modifier.background(
                primaryColor.copy(alpha = 0.15F), shape = RoundedCornerShape(Scale.CORNER_SMALL)
            ).fillMaxWidth().wrapContentHeight(),
        ) {
            Row(
                modifier = Modifier.fillMaxWidth().padding(12.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Icon(
                    modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE_MAX),
                    painter = painterResource(Res.drawable.ic_wallet),
                    contentDescription = null,
                    tint = primaryColor
                )
                Spacer(modifier = Modifier.width(8.dp))
                Text(text = maximum_call_duration)
                Spacer(modifier = Modifier.weight(1f))
                Box(contentAlignment = Alignment.CenterEnd) {
                    Text(
                        text = formatSecondsToHHMMSS(chatDurationData.totalChatDuration ?: "0"),
                        textAlign = TextAlign.End
                    )
                }
            }
        }

        if (chatDurationData.type?.equals(FREE_CHAT_TYPE) == true) {
            Spacer(modifier = Modifier.height(10.dp))

            Box(
                modifier = Modifier.fillMaxWidth(), contentAlignment = Alignment.Center
            ) {
                Text(
                    "This is a one-time free call offered by Winyway",
                    fontSize = 12.sp,
                    textAlign = TextAlign.Center
                )
            }
        }

        Spacer(modifier = Modifier.height(10.dp))

        CommonButton(
            onClick = {
                onSubmit.invoke()
            },
            modifier = Modifier.fillMaxWidth().height(55.dp).padding(bottom = 8.dp),
            buttonText = let_s_connect
        )

        CommonTextButton(
            onClick = { },
            modifier = Modifier.fillMaxWidth().height(55.dp),
            buttonText = "Low Balance? Recharge Wallet"
        )
    }
}


