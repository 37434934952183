package com.winiwayuser.feature_common.persentation.home_tab.home_screen_widget

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.requiredHeight
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.pager.HorizontalPager
import androidx.compose.foundation.pager.PageSize
import androidx.compose.foundation.pager.PagerDefaults
import androidx.compose.foundation.pager.rememberPagerState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import co.touchlab.kermit.Logger
import coil3.compose.AsyncImage
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.theme.md_theme_light_secondary
import com.winiwayuser.feature_authentication.persentation.login_page.widget.HorizontalPagerIndicator
import com.winiwayuser.feature_common.persentation.home_tab.HomeScreenTabComponent
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.image_loading_square
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.painterResource

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun WelcomeSlide(component: HomeScreenTabComponent) {

    val sliders by component.slidersData.collectAsState()

    sliders?.let {
        val coroutineScope = rememberCoroutineScope()
        val pagerState = rememberPagerState(
            initialPage = 0,
            initialPageOffsetFraction = 0f,
            pageCount = { it.size }
        )

        LaunchedEffect(key1 = sliders) {
            coroutineScope.launch {
                while (true) {
                    delay(3000)
                    pagerState.animateScrollToPage((pagerState.currentPage + 1) % it.size)
                }
            }
        }

        Column(horizontalAlignment = Alignment.CenterHorizontally) {
            HorizontalPager(
                modifier = Modifier,
                state = pagerState,
                userScrollEnabled = true,
                reverseLayout = false,
                pageSize = PageSize.Fill,
                flingBehavior = PagerDefaults.flingBehavior(state = pagerState),
                pageContent = { page ->
                    val imageUrl = it.getOrNull(page)?.image
                    SlideContent(imageUrl = imageUrl)
                }
            )

            HorizontalPagerIndicator(
                pageCount = it.size,
                currentPage = pagerState.currentPage,
                targetPage = pagerState.targetPage,
                currentPageOffsetFraction = pagerState.currentPageOffsetFraction,
                unselectedIndicatorSize = 14.dp,
                selectedIndicatorSize = 15.dp,
                indicatorCornerRadius = 5.dp,
                indicatorPadding = 5.dp,
                indicatorColor = md_theme_light_secondary
            )
        }
    }
}

@Composable
fun SlideContent(imageUrl: String?) {

    if (imageUrl != null) {
        Card(
            shape = RoundedCornerShape(Scale.CORNER_SMALL),
            elevation = CardDefaults.cardElevation(Scale.ELEVATION_SMALL),
            modifier = Modifier
                .fillMaxWidth()
                .padding(horizontal = Scale.HORIZONTAL_PADDING)
        ) {

            AsyncImage(
                model = imageUrl,
                contentDescription = null,
                modifier = Modifier
                    .fillMaxWidth()
                    .wrapContentHeight(),
                contentScale = ContentScale.FillWidth,
            )
        }
    } else {
        Box(
            modifier = Modifier.fillMaxSize(),
            contentAlignment = Alignment.Center
        ) {
            Image(
                painter = painterResource(Res.drawable.image_loading_square),
                contentDescription = null
            )
        }
    }
}