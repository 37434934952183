package com.winiwayuser.feature_chat_consultation_new.persentation.chat_bottom_sheet_info.widget

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.winiwayuser.core.date_time.formatSecondsToHHMMSS
import com.winiwayuser.core.persentation.AppTextButton
import com.winiwayuser.core.persentation.CommonButton
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.theme.let_s_connect
import com.winiwayuser.core.theme.maximum_call_duration
import com.winiwayuser.core.theme.pricing
import com.winiwayuser.core.theme.primaryColor
import com.winiwayuser.core.theme.wallet_balance
import com.winiwayuser.feature_chat_consultation_new.data.response.chat_duration.ChatDurationData
import com.winiwayuser.feature_chat_consultation.domain.FREE_CHAT_TYPE
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.ic_wallet
import org.jetbrains.compose.resources.painterResource


@Composable
fun ChatInfoSuccessUi(
    modifier: Modifier,
    expertName: String,
    expertSkills: String,
    expertImage: String,
    chatDurationData: ChatDurationData,
    onSubmit: () -> Unit
){
    Column(
        modifier = modifier.fillMaxWidth().padding(20.dp)
    ) {
        // Title Section
        Text(
            text = "Chat Consultation",
            style = MaterialTheme.typography.bodyLarge,
            modifier = Modifier.fillMaxWidth(),
            textAlign = TextAlign.Center
        )


        ExpertCard(
            modifier = Modifier,
            expertName = expertName,
            expertImage = expertImage,
            expertSkills = expertSkills
        )

        Spacer(modifier = Modifier.height(8.dp))
        Row(
            modifier = Modifier.fillMaxWidth().height(90.dp).clip(RoundedCornerShape(8.dp)),
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically
        ) {
            // Wallet Balance
            Row(
                verticalAlignment = Alignment.CenterVertically, modifier = Modifier.weight(1f)
            ) {
                Box(
                    modifier = Modifier.background(
                        primaryColor.copy(alpha = 0.15F),
                        shape = RoundedCornerShape(Scale.CORNER_SMALL)
                    ).padding(8.dp)
                ) {
                    Image(
                        painter = painterResource(Res.drawable.ic_wallet),
                        contentDescription = null,
                        modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE_MAX),
                        colorFilter = ColorFilter.tint(primaryColor),
                    )
                }
                Spacer(modifier = Modifier.width(8.dp))
                Column {
                    Text(
                        text = wallet_balance, style = MaterialTheme.typography.labelSmall
                    )
                    Text(
                        text = "₹${chatDurationData.balance}",
                        style = MaterialTheme.typography.titleSmall
                    )
                }
            }
            // Pricing
            Row(
                verticalAlignment = Alignment.CenterVertically, modifier = Modifier.weight(1f)
            ) {
                Box(
                    modifier = Modifier.background(
                        primaryColor.copy(alpha = 0.15F),
                        shape = RoundedCornerShape(Scale.CORNER_SMALL)
                    ).padding(8.dp)
                ) {
                    Image(
                        painter = painterResource(Res.drawable.ic_wallet),
                        contentDescription = null,
                        modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE_MAX),
                        colorFilter = ColorFilter.tint(primaryColor),
                    )
                }
                Spacer(modifier = Modifier.width(8.dp))
                Column {
                    Text(
                        text = pricing, style = MaterialTheme.typography.labelSmall
                    )
                    Row(verticalAlignment = Alignment.CenterVertically) {
//                                Box(
//                                    modifier = Modifier.background(
//                                        Color(0xFFEF5350),
//                                        shape = RoundedCornerShape(2.dp)
//                                    )
//                                ) {
//                                    Text(text = "Free", fontSize = 10.sp, color = Color.White)
//                                }
                        Text(
                            text = "₹${chatDurationData.flashPrice}/Min",
                            maxLines = 1,
                            style = MaterialTheme.typography.titleSmall
                        )
                    }
                }
            }
        }

        Box(
            modifier = Modifier.background(
                primaryColor.copy(alpha = 0.15F), shape = RoundedCornerShape(Scale.CORNER_SMALL)
            ).fillMaxWidth().wrapContentHeight(),
        ) {
            Row(
                modifier = Modifier.fillMaxWidth().padding(12.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Icon(
                    modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE_MAX),
                    painter = painterResource(Res.drawable.ic_wallet),
                    contentDescription = null,
                    tint = primaryColor
                )
                Spacer(modifier = Modifier.width(8.dp))
                Text(text = maximum_call_duration)
                Spacer(modifier = Modifier.weight(1f))
                Box(contentAlignment = Alignment.CenterEnd) {
                    Text(
                        text = formatSecondsToHHMMSS(chatDurationData.totalChatDuration ?: "0"),
                        textAlign = TextAlign.End
                    )
                }
            }
        }

        if (chatDurationData.type?.equals(FREE_CHAT_TYPE) == true) {
            Spacer(modifier = Modifier.height(10.dp))

            Box(
                modifier = Modifier.fillMaxWidth(), contentAlignment = Alignment.Center
            ) {
                Text(
                    "This is a one-time free call offered by Winyway",
                    fontSize = 12.sp,
                    textAlign = TextAlign.Center
                )
            }
        }

        Spacer(modifier = Modifier.height(10.dp))

        CommonButton(
            onClick = {
                onSubmit.invoke()
            },
            modifier = Modifier.fillMaxWidth().height(55.dp).padding(bottom = 8.dp),
            buttonText = let_s_connect
        )

        AppTextButton(
            onClick = { },
            modifier = Modifier.fillMaxWidth().height(55.dp),
            buttonText = "Low Balance? Recharge Wallet"
        )
    }
}


