package com.winiwayuser.feature_common.persentation.more_tab.transaction_history.persentation

import androidx.compose.animation.animateContentSize
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Star
import androidx.compose.material.icons.filled.StarBorder
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage
import com.winiwayuser.core.persentation.CommonTopAppBar
import com.winiwayuser.core.persentation.ExpertProfileIcon
import com.winiwayuser.core.persentation.font
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_ELSE
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import com.winiwayuser.core.theme.background_color
import com.winiwayuser.core.theme.currency
import com.winiwayuser.core.theme.primaryColor
import com.winiwayuser.core.theme.star_rating_color
import com.winiwayuser.feature_common.persentation.more_tab.transaction_history.data.response.TransactionOrderData
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.ic_wallet
import com.winiwayuser.resources.purchase_histor_bag
import com.winiwayuser.resources.purchase_icon_with_background
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import org.jetbrains.compose.resources.painterResource

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class, ExperimentalMaterial3Api::class)
@Composable
fun TransactionHistorySuccess(
    component: TransactionHistoryComponent,
    data: List<TransactionOrderData>
) {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
        else -> WINDOW_WIDTH_SIZE_ELSE
    }

    Box(
        Modifier.fillMaxSize().background(background_color),
        contentAlignment = Alignment.Center
    ) {
        Column(
            modifier = widthModifier.fillMaxSize().padding(16.dp).background(background_color)
        ) {
            CommonTopAppBar(
                modifier = Modifier,
                title = "Transaction History",
                onBackClick = { component.event(TransactionHistoryEvent.onBackButtonClick) }
            )
            Spacer(modifier = Modifier.height(10.dp))

            if (data.isEmpty()) {
                Box(
                    modifier = Modifier.fillMaxSize(),
                    contentAlignment = Alignment.Center
                ) {
                    Text(
                        text = "No data found",
                        color = Color.Gray,
                        fontSize = 18.sp,
                        style = MaterialTheme.typography.bodyMedium
                    )
                }
            } else {
                LazyColumn {
                    items(data.size) { transaction ->
                        val dataItem = data[transaction]
                        TransactionItem(dataItem)
                        Spacer(modifier = Modifier.height(8.dp))
                    }
                }
            }
        }
    }
}


@Composable
fun TransactionItem(transaction: TransactionOrderData) {
    val imgUrl = transaction.callDetails?.expertInfo?.image
        ?: transaction.chatDetails?.expertInfo?.image
        ?: transaction.emergency_call_details?.expertInfo?.image
        ?: transaction.emergency_chat_details?.expertInfo?.image
        ?: transaction.voipCallDetails?.expertInfo?.image
        ?: ""

    val isChatRated = transaction.chatDetails?.userRating == null
    val isCallRated = transaction.callDetails?.userRating == null

    val isRatingRequired = isCallRated && isChatRated

    Card(
        shape = RoundedCornerShape(Scale.CORNER_MEDIUM),
        elevation = CardDefaults.cardElevation(Scale.ELEVATION_MEDIUM),
        modifier = Modifier.fillMaxWidth(),
        colors = CardDefaults.cardColors(Color.White)
    ) {
        Column(modifier = Modifier.padding(16.dp)) {
            Row(verticalAlignment = Alignment.CenterVertically) {
                if(imgUrl.isEmpty()){
                    if(transaction.type?.contains("Custom Order", ignoreCase = true) == true){
                        Image(
                            painter = painterResource(Res.drawable.purchase_histor_bag),
                            contentDescription = "Transaction Icon",
                            modifier = Modifier.size(40.dp),
                            colorFilter = ColorFilter.tint(primaryColor)
                        )

                    }else {
                        Image(
                            painter = painterResource(Res.drawable.ic_wallet),
                            contentDescription = "Transaction Icon",
                            modifier = Modifier.size(40.dp)
                        )
                    }
                }else {

                    ExpertProfileIcon(profileImageUrl = imgUrl, modifier = Modifier.size(60.dp))
                }

                Spacer(modifier = Modifier.width(8.dp))
                Column {
                    val trType = when {
                        transaction.callDetails?.expertInfo?.name != null -> "Call with ${transaction.callDetails.expertInfo.name}"
                        transaction.chatDetails?.expertInfo?.name != null -> "Chat with ${transaction.chatDetails.expertInfo.name}"
                        transaction.emergency_call_details?.expertInfo?.name != null -> "Emergency Call with ${transaction.emergency_call_details.expertInfo.name}"
                        transaction.emergency_chat_details?.expertInfo?.name != null -> "Emergency Chat with ${transaction.emergency_chat_details.expertInfo.name}"
                        transaction.type?.contains("Custom Order", ignoreCase = true) == true -> "Purchased Custom Order"
                        transaction.type?.contains("Recharge", ignoreCase = true) == true -> "Wallet Recharge"
                        transaction.type?.contains("Voip Call", ignoreCase = true) == true -> "Voip Call with ${transaction.voipCallDetails?.expertInfo?.name}"
                        else -> "Unknown Data"
                    }
                    val createdAt = transaction.createdAt ?: "Unknown Date"
                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.Start,
                    ) {
                        if (transaction.type?.contains("Free", ignoreCase = true) == true) {
                            Text(
                                text = "FREE",
                                style = TextStyle(
                                    fontWeight = FontWeight.SemiBold,
                                    color = Color.White,
                                    fontSize = 10.sp
                                ),
                                modifier = Modifier
                                    .background(Color.Red, shape = RoundedCornerShape(Scale.CORNER_SMALL))
                                    .padding(horizontal = 6.dp, vertical = 2.dp)
                            )
                        }
                        Spacer(modifier = Modifier.width(4.dp))
                        Text(text = trType, style = MaterialTheme.typography.bodyLarge, fontSize = 18.sp)
                    }

                    Text(text = convertToIndianTime(createdAt), style = MaterialTheme.typography.bodyMedium, fontSize = 18.sp)
                }
                Spacer(modifier = Modifier.weight(1f))
                Column(horizontalAlignment = Alignment.End) {
                    val paidAmount = transaction.totalAmount ?: 0
                    Text(text = "$currency $paidAmount", style = MaterialTheme.typography.bodyMedium, fontSize = 20.sp)
                    StatusChip(transaction.paymentStatus ?: "")
                }
            }

            if (transaction.type.equals("Recharge", true).not()) {
                Row(
                    modifier = Modifier.fillMaxWidth().padding(vertical = 8.dp),
                    horizontalArrangement = Arrangement.SpaceBetween,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Text(
                        text = "How was the consultation experience?",
                        style = MaterialTheme.typography.bodyMedium,
                        fontSize = 14.sp
                    )
                    // Add RatingBar here if necessary
                    RatingBar(0,{})
                }
            }
        }
    }
}

@Composable
fun StatusChip(status: String) {
    val backgroundColor = when (status) {
        "Pending" -> Color.LightGray
        "Success" -> Color.LightGray
        "Failed" -> Color.Red
        else -> Color.LightGray
    }

    Surface(
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        color = backgroundColor,
        modifier = Modifier
            .padding(4.dp)
            //.border(1.dp, Color.DarkGray, RoundedCornerShape(Scale.CORNER_MEDIUM))
    ) {
        Box(
            modifier = Modifier.padding(horizontal = 8.dp, vertical = 4.dp),
            contentAlignment = Alignment.Center

        ) {
            Text(
                text = status,
                color = Color.Black,
                fontSize = 12.sp,
                modifier = Modifier
                    .wrapContentSize()
                    //.fillMaxSize()
                    .animateContentSize(),
                textAlign = TextAlign.Center,
                style = MaterialTheme.typography.titleMedium
            )
        }
    }
}



@Composable
fun RatingBar(rating: Int, onRatingChanged: (Int) -> Unit) {
    Row {
        for (i in 1..5) {
            Icon(
                imageVector = if (i <= rating) Icons.Default.Star else Icons.Default.StarBorder,
                contentDescription = "Star Rating",
                tint = star_rating_color,
                modifier = Modifier.size(20.dp).clickable { onRatingChanged(i) }
            )
        }
    }
}

fun convertToIndianTime(dateTimeString: String): String {
    val instant = Instant.parse(dateTimeString)
    val indiaTimeZone = TimeZone.of("UTC+05:30")
    val indiaDateTime = instant.toLocalDateTime(indiaTimeZone)
    val day = indiaDateTime.dayOfMonth
    val month = indiaDateTime.month.name.lowercase().replaceFirstChar { it.uppercase() }.take(3)
    val year = indiaDateTime.year
    val hour = if (indiaDateTime.hour % 12 == 0) 12 else indiaDateTime.hour % 12
    val minute = indiaDateTime.minute.toString().padStart(2, '0')
    val amPm = if (indiaDateTime.hour < 12) "AM" else "PM"
    return "$day $month $year | $hour:$minute $amPm"
}


