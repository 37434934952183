package com.winiwayuser.feature_call_consultation.persentation.call_bottomsheet.call_waitting_sucess

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage
import com.winiwayuser.core.local.UserInfoProvider.getPhone
import com.winiwayuser.core.theme.primaryLight50
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.ellipse
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.painterResource

//@Composable
//fun CallWaitingSuccess(
//    inComingCallMobileNumber: String,
//    expertId: String,
//    expertName: String,
//    expertImage: String,
//    expertSkills: String,
//    onTimeOutOver:() -> Unit
//) {
//
//    Column(
//        modifier = Modifier.fillMaxSize(),
//        verticalArrangement = Arrangement.Center,
//        horizontalAlignment = Alignment.CenterHorizontally
//    ) {
//        Text(text = "Thank You!")
//        Text(text = "Your Appointment is booked with ")
//        Spacer(modifier = Modifier.height(8.dp))
//        Row(
//            modifier = Modifier
//                .height(90.dp)
//                .background(Color(0xFFFFFFFFFF))
//                .padding(start =  20.dp, end = 20.dp)
//                .clip(RoundedCornerShape(8.dp)),
//            verticalAlignment = Alignment.CenterVertically
//        ) {
//            if(expertImage.isEmpty()){
//                Image(painter = painterResource(Res.drawable.ellipse), contentDescription = null)
//            }else{
//                AsyncImage(
//                    model = expertImage,
//                    modifier = Modifier
//                        .size(85.dp)
//                        .clip(CircleShape),
//                    contentScale = ContentScale.Crop,
//                    contentDescription = null,
//                )
//            }
//            Spacer(modifier = Modifier.width(16.dp))
//            Column {
//                Text(
//                    text = expertName,
//                    maxLines = 1,
//                    modifier = Modifier.fillMaxWidth()
//                )
//                Spacer(modifier = Modifier.height(4.dp))
//                Text(
//                    text = expertSkills,
//                    maxLines = 2,
//                    modifier = Modifier.fillMaxWidth()
//                )
//            }
//        }
//        Spacer(modifier = Modifier.height(32.dp))
//
//        var incomingCallNumber = inComingCallMobileNumber
//        var userNumber = getPhone()
//        val call_waiting_long_text = "You will receive a call shortly from\n$incomingCallNumber on your registered Mobile number (+91 $userNumber)"
//
//        Text(
//            text = call_waiting_long_text,
//            textAlign = TextAlign.Center,
//            modifier = Modifier.padding(start = 32.dp, end = 32.dp)
//        )
//
//        //Text(text = call_waiting_long_text, textAlign = TextAlign.Center, modifier = Modifier.padding(start = 32.dp, end = 32.dp))
//        Spacer(modifier = Modifier.height(8.dp))
//        Text(text = "Call Waiting Period", fontSize = 12.sp)
//        Spacer(modifier = Modifier.height(4.dp))
//        Timer(onTimeOutOver = onTimeOutOver)
//    }
//}
//
//@Composable
//fun Timer(onTimeOutOver: () -> Unit) {
//    var seconds by remember { mutableStateOf(120) } // 2 minutes in seconds
//    val scope = rememberCoroutineScope()
//
//    LaunchedEffect(key1 = seconds) {
//        if (seconds > 0) {
//            scope.launch {
//                delay(1000)
//                seconds--
//            }
//        } else {
//            // Timer completed, call the onTimeOutOver function
//            onTimeOutOver()
//        }
//    }
//
//    Box(
//        modifier = Modifier
//            .width(50.dp)
//            .height(50.dp)
//            .background(primaryLight50),
//        contentAlignment = Alignment.Center
//    ) {
//        Text(
//            text = "${seconds / 60}:${if (seconds % 60 < 10) "0" else ""}${seconds % 60}",
//            style = TextStyle(fontWeight = FontWeight.Bold)
//        )
//    }
//}


@Composable
fun CallWaitingSuccess(
    inComingCallMobileNumber: String,
    expertId: String,
    expertName: String,
    expertImage: String,
    expertSkills: String,
    onTimeOutOver: () -> Unit
) {
    Column(
        modifier = Modifier
            .fillMaxSize()
            .background(Color(0xFFF8F8F8))
            .padding(16.dp),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        // Thank you message
        Text(
            text = "Thank You!",
            style = TextStyle(
                fontSize = 24.sp,
                fontWeight = FontWeight.Bold,
                color = Color.Black
            ),
            modifier = Modifier.padding(bottom = 8.dp)
        )

        // Appointment confirmation text
        Text(
            text = "Your appointment is booked with",
            style = TextStyle(fontSize = 16.sp, color = Color.Gray)
        )

        Spacer(modifier = Modifier.height(16.dp))

        // Expert details
        Row(
            modifier = Modifier
                .background(Color.White, shape = RoundedCornerShape(16.dp))
                .padding(16.dp)
                .shadow(2.dp, shape = RoundedCornerShape(16.dp)),
            verticalAlignment = Alignment.CenterVertically
        ) {
            // Expert image
            if (expertImage.isEmpty()) {
                Image(
                    painter = painterResource(Res.drawable.ellipse),
                    contentDescription = "Expert placeholder",
                    modifier = Modifier
                        .size(80.dp)
                        .clip(CircleShape)
                        .background(Color.LightGray)
                )
            } else {
                AsyncImage(
                    model = expertImage,
                    modifier = Modifier
                        .size(80.dp)
                        .clip(CircleShape)
                        .background(Color.LightGray),
                    contentScale = ContentScale.Crop,
                    contentDescription = "Expert image"
                )
            }

            Spacer(modifier = Modifier.width(16.dp))

            // Expert name and skills
            Column(
                modifier = Modifier.fillMaxWidth()
            ) {
                Text(
                    text = expertName,
                    style = TextStyle(
                        fontSize = 18.sp,
                        fontWeight = FontWeight.Bold
                    ),
                    maxLines = 1,
                    overflow = TextOverflow.Ellipsis
                )
                Spacer(modifier = Modifier.height(4.dp))
                Text(
                    text = expertSkills,
                    style = TextStyle(
                        fontSize = 14.sp,
                        color = Color.Gray
                    ),
                    maxLines = 2,
                    overflow = TextOverflow.Ellipsis
                )
            }
        }

        Spacer(modifier = Modifier.height(24.dp))

        // Call info text
        val userNumber = getPhone()
        val callWaitingText = "You will receive a call shortly from $inComingCallMobileNumber on your registered mobile number (+91 $userNumber)."
        Text(
            text = callWaitingText,
            style = TextStyle(
                fontSize = 16.sp,
                color = Color.Gray,
                textAlign = TextAlign.Center
            ),
            modifier = Modifier
                .fillMaxWidth()
                .padding(horizontal = 32.dp)
        )

        Spacer(modifier = Modifier.height(16.dp))

        // Call waiting period label
        Text(
            text = "Call Waiting Period",
            fontSize = 14.sp,
            color = Color.DarkGray
        )

        Spacer(modifier = Modifier.height(8.dp))

        // Timer
        Timer(onTimeOutOver = onTimeOutOver)
    }
}

@Composable
fun Timer(onTimeOutOver: () -> Unit) {
    var seconds by remember { mutableStateOf(120) }
    val scope = rememberCoroutineScope()

    LaunchedEffect(key1 = seconds) {
        if (seconds > 0) {
            scope.launch {
                delay(1000)
                seconds--
            }
        } else {
            onTimeOutOver()
        }
    }

    // Circular progress bar for the timer
    Box(
        modifier = Modifier
            .size(80.dp),
        contentAlignment = Alignment.Center
    ) {
        CircularProgressIndicator(
            progress = seconds / 120f,
            color = Color(0xFF6200EE),
            strokeWidth = 8.dp
        )
        Text(
            text = "${seconds / 60}:${if (seconds % 60 < 10) "0" else ""}${seconds % 60}",
            style = TextStyle(
                fontSize = 16.sp,
                fontWeight = FontWeight.Bold
            )
        )
    }
}

