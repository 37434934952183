package com.winiwayuser.feature_chat_consultation_new.persentation.chat_host.decompose

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.router.stack.StackNavigation
import com.arkivanov.decompose.router.stack.childStack
import com.arkivanov.decompose.router.stack.pop
import com.arkivanov.decompose.router.stack.replaceCurrent
import com.arkivanov.decompose.value.Value
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.winiwayuser.core.agora.AgoraRtmClientApp
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_concern.ChatConcernComponent
import com.winiwayuser.feature_chat_consultation.persentation.chat_concern.ChatConcernEvent
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_room_view.ChatRoomComponent
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_room_view.ChatRoomEvent
import com.winiwayuser.feature_chat_consultation.persentation.chat_waiting_room.ChatWaitingComponent
import com.winiwayuser.feature_chat_consultation.persentation.chat_waiting_room.ChatWaitingEvent
import kotlinx.serialization.Serializable

class HostDefaultComponent(
    expertId: String,
    expertName: String,
    expertImage: String,
    expertSkills: String,
    chatDuration: String,
    category: String,
    componentContext: ComponentContext,
    private val onClose: () -> Unit
) : HostRootComponent, ComponentContext by componentContext {

    companion object {
        private const val AGORA_APP_ID =
            "414eb4458bcc43039fd697e672dd0284" //"ef00091272b04468949c56ef7aaee6c3"
        private const val WAITING_HANDSHAKE_MSG = "@winyway09853WAITING90f4HANDSHAKE34jl"
        private const val EXPERT_JOINED_WITH_ID = "joined@"
        private const val END_CHAT_MSG = "@winyway8943end978688Consultation3423h4234223jkhkj"
        private const val USER_TYPING_START = "@winyway4234Start#$@Typing"
        private const val USER_TYPING_END = "@winyway4234END#$@Typing"
    }

    init {
        componentContext.doOnDestroy {
            client?.logout()
        }
    }

    private val navigation = StackNavigation<Config>()
    private var client: AgoraRtmClientApp? = null

    private fun loginAgoraClient(
        token: String,
        uid: String,
        onLoginSuccess: () -> Unit,
        onLoginError: (String) -> Unit
    ) {
        client = AgoraRtmClientApp(AGORA_APP_ID)
        client?.initialize(
            token = token,
            userId = uid,
            onLoginSuccess = {
                onLoginSuccess.invoke()
            },
            onLoginError = { msg ->
                onLoginError.invoke(msg)
                Logger.d("Component Loggin Error -> $msg")
            })
    }

    private fun sendWaitingHandshakeMsg(
        peerId: String,
        onError: (String) -> Unit,
        onSuccess: () -> Unit
    ) {
        client?.sendMessage(
            peerId = peerId,
            messageText = WAITING_HANDSHAKE_MSG,
            onMessageSentSuccess = {
                onSuccess.invoke()
            },
            onMessageSentError = { msg ->
                onError.invoke(msg)
            }
        )
    }

    private fun onSendMsgToExpert(peerId: String, onError: (String) -> Unit, msg: String) {
        client?.sendMessage(
            peerId = peerId,
            messageText = msg,
            onMessageSentSuccess = {},
            onMessageSentError = { msg ->
                onError.invoke(msg)
            }
        )
    }

    private fun sendTypingStatus(peerId: String, isTyping: Boolean, onError: (String) -> Unit) {
        val text = if (isTyping) USER_TYPING_START else USER_TYPING_END
        client?.sendMessage(
            peerId = peerId,
            messageText = text,
            onMessageSentSuccess = {},
            onMessageSentError = { msg ->
                onError.invoke(msg)
            }
        )
    }

    private fun endChat(expertPeerId: String, onError: (String) -> Unit, onSuccess: () -> Unit) {
        client?.sendMessage(
            peerId = expertPeerId,
            messageText = END_CHAT_MSG,
            onMessageSentSuccess = {
                onSuccess.invoke()
            },
            onMessageSentError = { msg ->
                onError.invoke(msg)
            }
        )
    }

    private fun onHandshakeReceive(
        success: (String) -> Unit
    ) {
        client?.onReceiveMessage { peerId, message ->

            Logger.d("recieved msg -> $message")

            if (message.contains(EXPERT_JOINED_WITH_ID)) {
                val expertId = message.substringAfter("@")
                success(expertId)
                Logger.d("message substring -> $expertId")
            }
            println("Received message from $peerId: $message")
        }
    }

    override val childStack: Value<ChildStack<*, HostRootComponent.Child>> =
        childStack(
            source = navigation,
            serializer = Config.serializer(),
            initialConfiguration = Config.ChatConcern(
                expertId = expertId,
                expertName = expertName,
                expertImage = expertImage,
                expertSkills = expertSkills,
                chatDuration = chatDuration,
                category = category
            ),
            handleBackButton = true,
            childFactory = ::createChild,
        )

    private fun createChild(
        config: Config,
        componentContext: ComponentContext
    ): HostRootComponent.Child =
        when (config) {
            is Config.ChatConcern -> HostRootComponent.Child.ChatConcernChild(
                createChatConcern(
                    componentContext,
                    config
                )
            )

            is Config.ChatRoom -> HostRootComponent.Child.ChatRoomChild(
                createChatRoom(componentContext, config)
            )

            is Config.ChatWaiting -> HostRootComponent.Child.ChatWaitingChild(
                createChatWaiting(
                    componentContext,
                    config
                )
            )
        }

    private fun createChatConcern(
        componentContext: ComponentContext,
        config: Config.ChatConcern
    ) = ChatConcernComponent(
        context = componentContext,
        expertName = config.expertName,
        expertImage = config.expertImage,
        expertSkills = config.expertSkills,
        expertId = config.expertId,
        chatDuration = config.chatDuration,
        event = { event ->
            when (event) {
                ChatConcernEvent.CloseThis -> {
                    onClose.invoke()
                }

                is ChatConcernEvent.ConnectToExpert -> {
                    loginAgoraClient(
                        token = event.token,
                        uid = event.uid,
                        onLoginSuccess = {
                            event.onSuccess.invoke()
                        },
                        onLoginError = { msg ->
                            event.onError.invoke(msg)
                        }

                    )
                }

                is ChatConcernEvent.OnStartConsultation -> {

                    navigation.replaceCurrent(
                        Config.ChatWaiting(
                            expertId = config.expertId,
                            expertName = config.expertName,
                            expertImage = config.expertImage,
                            expertSkills = config.expertSkills,
                            chatDuration = config.chatDuration,
                            consultationId = event.chatId,
                            uid = event.userUid
                        )
                    )
                }
            }
        },
        category = config.category
    )


    private fun createChatWaiting(
        componentContext: ComponentContext,
        config: Config.ChatWaiting
    ) = ChatWaitingComponent(
        context = componentContext,
        _expertId = config.expertId,
        _expertName = config.expertName,
        _expertImage = config.expertImage,
        _expertSkills = config.expertSkills,
        maxChatDuration = config.chatDuration,
        chatId = config.consultationId,
        userUid = config.uid,
        event = { event ->
            when (event) {
                ChatWaitingEvent.CloseThis -> {
                    navigation.pop()
                    onClose.invoke()
                }

                is ChatWaitingEvent.ConnectToExpert -> {
                    onHandshakeReceive { expertUid ->
                        sendWaitingHandshakeMsg(
                            peerId = expertUid,
                            onError = { msg ->
                                event.onError(msg)
                            },
                            onSuccess = {
                                event.onSuccess.invoke()
                            }
                        )
                    }
                }

                is ChatWaitingEvent.StartConsultation -> {
                    navigation.replaceCurrent(
                        configuration = Config.ChatRoom(
                            expertId = config.expertId,
                            expertName = config.expertName,
                            expertImage = config.expertImage,
                            expertSkills = config.expertSkills,
                            chatId = config.consultationId

                        )
                    )
                }
            }
        }
    )

    private fun createChatRoom(
        componentContext: ComponentContext,
        config: Config.ChatRoom
    ) = ChatRoomComponent(
        expertName = config.expertName,
        expertImage = config.expertImage,
        expertSkills = config.expertSkills,
        expertId = config.expertId,
        context = componentContext,
        chatId = config.chatId,
        event = { chatRoomEvent ->
            when (chatRoomEvent) {
                is ChatRoomEvent.EndChatMsg -> {
                    endChat(chatRoomEvent.expertUid, onSuccess = {}, onError = {})
                    client?.logout()
                    client?.destroy()
                }

                is ChatRoomEvent.SendMsg -> {
                    onSendMsgToExpert(
                        peerId = chatRoomEvent.peerId, onError = { msg ->
                            chatRoomEvent.onError.invoke(msg)
                        }, msg = chatRoomEvent.msg
                    )
                }

                is ChatRoomEvent.StartRecieveMsg -> {
                    client?.onReceiveMessage { _, message ->
                        Logger.d("chat room recieved msg -> $message")
                        chatRoomEvent.onRecieveMsg(message)
                    }
                }

                ChatRoomEvent.GoToDashBoard -> {
                    navigation.pop()
                    onClose.invoke()
                }

                is ChatRoomEvent.OnUserTyping -> {
                    sendTypingStatus(
                        peerId = chatRoomEvent.expertUid,
                        isTyping = chatRoomEvent.isTyping,
                        onError = {}
                    )
                }
            }
        },
    )


    @Serializable // kotlinx-serialization plugin must be applied
    private sealed class Config {
        @Serializable
        data class ChatConcern(
            val expertId: String,
            val expertName: String,
            val expertImage: String,
            val expertSkills: String,
            val chatDuration: String,
            val category:String
        ) : Config()

        @Serializable
        data class ChatWaiting(
            val expertId: String,
            val expertName: String,
            val expertImage: String,
            val expertSkills: String,
            val chatDuration: String,
            val consultationId: String,
            val uid: String
        ) : Config()

        @Serializable
        data class ChatRoom(
            val expertId: String,
            val expertName: String,
            val expertImage: String,
            val expertSkills: String,
            val chatId: String
        ) : Config()
    }

}