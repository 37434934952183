package com.winiwayuser.feature_chat_consultation.domain

import co.touchlab.kermit.Logger
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_chat_consultation.data.response.place_chat_concern.ChatUserConcernData
import com.winiwayuser.feature_chat_consultation.data.api.ChatConsultationApi
import com.winiwayuser.feature_chat_consultation.data.request.PlaceChatRequestBody
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow

class ChatConcernRepo(
    private val api: ChatConsultationApi
) {

    fun placeWithChatConcern(request: PlaceChatRequestBody): Flow<Resources<ChatUserConcernData>> {
        return flow {
            emit(Resources.Loading())
            val response = api.placeChatWithConcern(request)
            Logger.d("Repo -> $response")
            emit(response)
            emit(Resources.Loading(false))
        }
    }

}