package com.winiwayuser.feature_call_consultation.persentation.call_bottomsheet.call_consultation_options

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import com.winiwayuser.core.persentation.LoadingProgressDialog
import com.winiwayuser.feature_call_consultation.call_bottom_sheet_info.CallPlaceInfoState
import com.winiwayuser.feature_call_consultation.persentation.call_bottomsheet.call_waitting_sucess.CallWaitingSuccess
import com.winiwayuser.feature_call_consultation.persentation.call_bottomsheet.expert_busy.ErrorBottomSheetCallConsultation

@Composable
fun CallPlaceSuccess(component: CallConsultationOptionsComponent){
    val state by component.placeCallState.collectAsState()

    when(val currentState = state){
        is CallPlaceInfoState.Error -> ErrorBottomSheetCallConsultation(currentState.msg, onDismiss = {component.onClose()})
        is CallPlaceInfoState.Loading -> LoadingProgressDialog { }
        is CallPlaceInfoState.PlaceCallSuccess -> {
            CallWaitingSuccess(inComingCallMobileNumber = currentState.data.emergencyNumber ,component.expertId, component.expertName, component.expertImage, component.expertSkills,component.onClosePage)
        }
    }

}