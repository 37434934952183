package com.winiwayuser.feature_authentication.di

import com.winiwayuser.feature_authentication.data.AuthApiService
import com.winiwayuser.feature_authentication.domain.OtpValidationUseCases
import com.winiwayuser.feature_authentication.domain.RegistrationUserUseCases
import com.winiwayuser.feature_expert_consultation.data.remote.ExpertApi
import org.koin.dsl.module

val authorizationModule = module {
    single { AuthApiService() }
    single { OtpValidationUseCases(get()) }
    single { RegistrationUserUseCases(get()) }
    single { ExpertApi() }
}