package com.winiwayuser.user_feature_authentication.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
data class VerifyOtpResponse(
    @SerialName("status")
    var status: Boolean = false,
    @SerialName("message")
    var message: String? = "Unexpected Error",
    @SerialName("code")
    var code: Int = 0,
    @SerialName("data") val data: VerifyOtpResponseData?
)
//@Serializable
//data class VerifyOtpResponse(
//    @SerialName("data")
//    val data: VerifyOtpResponseData
//)

@Serializable
data class VerifyOtpResponseData(
    @SerialName("token")
    val token: String = "",
    @SerialName("user")
    val user: User,
    @SerialName("joining_user_coin")
    val joiningUserBonus: Int? = null,
    @SerialName("refer_code")
    val referCode: String?,
    @SerialName("totalcoin")
    val totalCoin: Int?
)

@Serializable
data class User(
    @SerialName("mobile")
    val mobile: String = "",
    @SerialName("mobile_code")
    val mobileCode: String = "",
    @SerialName("type")
    val type: String = "",
    @SerialName("redirect_to")
    val redirectTo: String = "",
    @SerialName("whatsapp_updates")
    val whatsappUpdates: String = "",
    @SerialName("notification_token")
    val notificationToken: String = "",
    @SerialName("login_device")
    val loginDevice: String = "",
    @SerialName("device_details")
    val deviceDetails: String = "",
    @SerialName("updated_at")
    val updatedAt: String = "",
    @SerialName("created_at")
    val createdAt: String? = "",
    @SerialName("id")
    val id: Int = 0,
    @SerialName("international_flag")
    val internationalFlag: Int = 0,
    @SerialName("email")
    val email: String? = null,
    @SerialName("profile_image_url")
    val profileImageUrl: String = "",
    @SerialName("profileimg")
    val profileimg: String = "",
    @SerialName("first_name")
    val firstName: String = "",
    @SerialName("last_name")
    val lastName: String = "",
    @SerialName("full_name")
    val fullName: String = "",
    @SerialName("profile_image")
    val profileImage: String = "",
    @SerialName("gender")
    val gender: String? = null,
    @SerialName("profile_description")
    val profileDescription: String = "",
    @SerialName("language")
    val language: String = "",
    @SerialName("address")
    val address: String = "",
    @SerialName("professional_approval")
    val professionalApproval: Int = 0,
    @SerialName("bank_name")
    val bankName: String = "",
    @SerialName("account_number")
    val accountNumber: String = "",
    @SerialName("ifsc_code")
    val ifscCode: String = "",
    @SerialName("pan_number")
    val panNumber: String = "",
    @SerialName("account_type")
    val accountType: String = "",
    @SerialName("video_url")
    val videoUrl: String = "",
    @SerialName("skills")
    val skills: String? = null
)