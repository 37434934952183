package com.winiwayuser.feature_call_consultation.persentation.voip_call_page


import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.AlertDialog
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.feature_call_consultation.persentation.voip_end_page.ConsultationEndedPage

@Composable
fun VoIPCallConsultationScreen(
    componentContext: VoipCallComponent
) {


//    val callEndState by componentContext.callEndedState.collectAsState()
//
//    val state by componentContext.state.collectAsState()
//
//    if (state is Resources.Loading) {
//        LoadingPage(modifier = Modifier.fillMaxSize())
//    }
//
//    if (state is Resources.Success) {
//        CallConsultationPage(component = componentContext)
//    }
//
//    if (callEndState) {
//        AlertDialog(
//            containerColor = Color.White,
//            onDismissRequest = {
//                componentContext.onClosePage()
//            },
//            text = {
//                ConsultationEndedPage(
//                    expertInfo = componentContext.expertDetail,
//                    duration = "",
//                    onRatingSelected = {
//                        componentContext.onClosePage()
//                    },
//                    onDoneClicked = {
//                        componentContext.onClosePage()
//                    }
//                )
//            },
//            shape = RoundedCornerShape(Scale.CORNER_SMALL),
//            confirmButton = {},
//            dismissButton = {
////                componentContext.onClosePage()
//            }
//        )
//
//    }

}
