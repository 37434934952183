package com.winiwayuser.feature_expert_consultation.data.remote

import co.touchlab.kermit.Logger
import co.touchlab.kermit.NoTagFormatter
import co.touchlab.kermit.loggerConfigInit
import co.touchlab.kermit.platformLogWriter
import com.winiwayuser.core.remote.KtorApi
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_expert_consultation.data.remote.request.ExpertListRequest
import com.winiwayuser.feature_expert_consultation.data.remote.response.DataCat
import com.winiwayuser.feature_expert_consultation.data.remote.response.FetchConsultantResponse
import com.winiwayuser.feature_expert_consultation.expert_list_state_info.ExpertListState
import io.ktor.client.call.body
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import io.ktor.http.ContentType
import io.ktor.http.contentType
import io.ktor.utils.io.errors.IOException
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow

//class ExpertApi:KtorApi() {
//
//    private val storeLogger = Logger(
//        loggerConfigInit(platformLogWriter(NoTagFormatter)),
//        "StoreApi"
//    )
//
//    companion object {
//        const val FETCH_CONSULTANT_LIST = "fetch-consultants"
//    }
//
//    private suspend fun getExpertList(request: ExpertListRequest) = client.post {
//        url(FETCH_CONSULTANT_LIST)
//        contentType(ContentType.Application.Json)
//        setBody(request)
//    }.body<FetchConsultantResponse>()
//
//
//    suspend fun getExpertListRemote(request: ExpertListRequest): Flow<Resources<DataCat>> {
//
//        return flow {
//            emit(Resources.Loading(true))
//            try {
//                val response = getExpertList(request)
//
//                if (response.status.not()) {
//                    return@flow emit(Resources.Error(response.message))
//                }
//
//                emit(Resources.Success(response.data))
//                emit(Resources.Loading(false))
//
//            } catch (apiError: Exception) {
//                emit(Resources.Loading(false))
//                emit(Resources.Error(apiError.message))
//            } catch (httpException: IOException) {
//                emit(Resources.Loading(false))
//                emit(Resources.Error(httpException.message))
//            } finally {
//                emit(Resources.Loading(false))
//            }
//
//        }
//    }
//}

class ExpertApi : KtorApi() {

    private val storeLogger = Logger(
        loggerConfigInit(platformLogWriter(NoTagFormatter)),
        "StoreApi"
    )

    companion object {
        const val FETCH_CONSULTANT_LIST = "fetch-consultants"
    }

    private suspend fun getExpertList(request: ExpertListRequest): FetchConsultantResponse {
        return client.post {
            url(FETCH_CONSULTANT_LIST)
            contentType(ContentType.Application.Json)
            setBody(request)
        }.body()
    }

    suspend fun getExpertListRemote(request: ExpertListRequest): Flow<ExpertListState> {
        return flow {
            emit(ExpertListState.Loading)
            try {
                val response = getExpertList(request)

                if (response.status.not()) {
                    emit(ExpertListState.Error(response.message))
                } else {
                    emit(ExpertListState.Success(response.data?.consultants.orEmpty()))
                }
            } catch (apiError: Exception) {
                emit(ExpertListState.Error(apiError.message))
            } catch (httpException: IOException) {
                emit(ExpertListState.Error(httpException.message))
            } finally {
                // Optionally, you can handle final cleanup or state updates here if needed
            }
        }
    }
}
