package com.winiwayuser.core.date_time

fun formatSecondsToHHMMSS(secondsStr: String): String {
    val seconds = secondsStr.toIntOrNull() ?: return "Invalid input"

    // Calculate hours, minutes, and seconds
    val hours = seconds / 3600
    val minutes = (seconds % 3600) / 60
    val secs = seconds % 60

    return when {
        hours > 0 -> {
            // If hours are greater than 0, include hours in the format
            "${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}"
        }
        else -> {
            // If hours are 0, exclude hours from the format
            "${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}"
        }
    }
}

