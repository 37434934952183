package com.winiwayuser.feature_call_consultation.persentation.call_bottomsheet.expert_busy

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.ModalBottomSheet
import androidx.compose.material3.SheetState
import androidx.compose.material3.Text
import androidx.compose.material3.rememberModalBottomSheetState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp


@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun ErrorBottomSheetCallConsultation(msg:String,  onDismiss: () -> Unit) {
    val sheetState = rememberModalBottomSheetState(skipPartiallyExpanded = true)
    val coroutineScope = rememberCoroutineScope()
    ErrorBottomSheet(msg,sheetState,onDismiss,false)
}




@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun ErrorBottomSheet(
    msg:String,
    sheetState: SheetState,
    onDismiss: () -> Unit,
    isSheetOpen: Boolean
) {
            ModalBottomSheet(
            sheetState = sheetState,
            onDismissRequest = {
                onDismiss.invoke()
            }) {
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(16.dp)
                    .align(Alignment.CenterHorizontally)
            ) {
                Box(
                    modifier = Modifier
                        .fillMaxWidth(),
                    contentAlignment = Alignment.Center
                ) {
                    Text(
                        text = "Expert Offline or Busy",
                        style = MaterialTheme.typography.headlineSmall,
                        // style = MaterialTheme.typography.h6,
                        modifier = Modifier.padding(top = 12.dp)
                    )
                }
                Spacer(modifier = Modifier.height(8.dp))
//                Row(
//                    modifier = Modifier
//                        .fillMaxWidth()
//                        .height(90.dp)
//                        .padding(top = 16.dp)
//                        .clip(RoundedCornerShape(8.dp))
//                        .background(Color.LightGray),
//                    verticalAlignment = Alignment.CenterVertically
//                ) {
//                    // Assuming you have a function to load image from URL
//                    // LoadImage(url = image, modifier = Modifier.size(55.dp))
//                    // Placeholder for image loading
//                    Box(
//                        modifier = Modifier
//                            .size(55.dp)
//                            .clip(RoundedCornerShape(8.dp))
//                            .background(Color.Gray)
//                    )
//                    Spacer(modifier = Modifier.width(16.dp))
//                    Column {
//                        Text(
//                            text = "Soumya",
//                            // style = MaterialTheme.typography.body1,
//                            maxLines = 1,
//                            modifier = Modifier.fillMaxWidth()
//                        )
//                        Spacer(modifier = Modifier.height(4.dp))
//                        Text(
//                            text = "Programing",
//                            // style = MaterialTheme.typography.body2,
//                            maxLines = 2,
//                            modifier = Modifier.fillMaxWidth()
//                        )
//                    }
//                }
                Spacer(modifier = Modifier.height(16.dp))
                Box(
                    modifier = Modifier
                        .padding(top = 16.dp, bottom = 20.dp)
                        .fillMaxWidth(),
                    contentAlignment = Alignment.Center
                ) {
                    Column(
                        horizontalAlignment = Alignment.CenterHorizontally,
                        modifier = Modifier
                            .padding(horizontal = 16.dp)
                            .align(Alignment.Center)
                    ) {
                        Text(
                            text = "Oops!",
                            textAlign = TextAlign.Center,
                            fontSize = 18.sp,
                            style = MaterialTheme.typography.titleMedium,
                            color = MaterialTheme.colorScheme.onBackground
                        )
                        Spacer(modifier = Modifier.height(8.dp))
                        Text(
                            text = msg,
                            textAlign = TextAlign.Center,
                            style = MaterialTheme.typography.bodyMedium,
                            color = MaterialTheme.colorScheme.onBackground
                        )
                    }
                }

            }
        }
}