package com.winiwayuser.feature_call_consultation.persentation.call_consultation_options

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.ModalBottomSheet
import androidx.compose.material3.Text
import androidx.compose.material3.rememberModalBottomSheetState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.winiwayuser.core.persentation.LoadingProgressDialog
import com.winiwayuser.core.theme.wifi_call_coming_soon
import com.winiwayuser.feature_call_consultation.persentation.call_bottom_sheet_info.CallPlaceInfoState
import com.winiwayuser.feature_call_consultation.persentation.call_waitting_sucess.CallWaitingSuccess
import com.winiwayuser.feature_call_consultation.persentation.expert_busy.ErrorBottomSheetCallConsultation
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.sim_icon
import com.winiwayuser.resources.wifi_icon
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun CallConsultationOptionScreen(
    component: CallConsultationOptionsComponent
) {
    val sheetState = rememberModalBottomSheetState(skipPartiallyExpanded = true)
    val coroutineScope = rememberCoroutineScope()
    var simCallPlaced by remember { mutableStateOf(false) }
    val state by component.placeCallState.collectAsState()

    if(simCallPlaced){
        HandleCallState(state, component)
    }


    ModalBottomSheet(
        sheetState = sheetState,
        onDismissRequest = {
            coroutineScope.launch {
                sheetState.hide()
                component.onClosePage()
            }
        }
    ) {
        CallConsultationOptionsContent(
            onSimCallPlaced = {
                simCallPlaced = true
                coroutineScope.launch {
                    sheetState.hide()
                    //component.onClosePage()
                }
                coroutineScope.launch { component.callPlace() } },
            onInternetCallPlaced = {

                coroutineScope.launch {
                    sheetState.hide()
                    component.onInternetCalling()
                }
            }
        )
    }
}


@Composable
fun CallOptionCard(
    title: String,
    description: String,
    additionalInfo: String,
    icon: DrawableResource,
    isComingSoon: Boolean,
    onCallPlaced: () -> Unit
) {
    Card(
        shape = RoundedCornerShape(8.dp),
        elevation =  CardDefaults.elevatedCardElevation(4.dp),
        modifier = Modifier
            .fillMaxWidth()
            .height(70.dp)
            .clickable { onCallPlaced() }
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween,
            modifier = Modifier
                .fillMaxSize()
                .padding(10.dp)
        ) {
            Row(
                verticalAlignment = Alignment.CenterVertically
            ) {
                Icon(
                    painter = painterResource(icon),
                    contentDescription = null,
                    modifier = Modifier.size(24.dp)
                )

                Spacer(modifier = Modifier.width(4.dp))

                Column {
                    if (isComingSoon) {
                        Column {
                            Row(
                                verticalAlignment = Alignment.CenterVertically,
                                horizontalArrangement = Arrangement.SpaceBetween
                            ) {
                                Text(
                                    text = title,
                                    fontSize = 12.sp,
                                    fontWeight = FontWeight.Bold
                                )
                                Spacer(modifier = Modifier.width(4.dp))
                                Text(
                                    text = description,
                                    fontSize = 10.sp,
                                    fontWeight = FontWeight.Bold
                                )
                                Spacer(modifier = Modifier.width(4.dp))
                                Box(
                                    modifier = Modifier
                                        .background(
                                            color = wifi_call_coming_soon,
                                            shape = RoundedCornerShape(4.dp)
                                        )
                                        .padding(horizontal = 2.dp, vertical = 2.dp),
                                    contentAlignment = Alignment.Center
                                ) {
                                    Text(
                                        text = "Coming Soon!",
                                        color = Color.White,
                                        fontSize = 10.sp,
                                        textAlign = TextAlign.Center,
                                        maxLines = 1
                                    )
                                }
                            }

                            Text(
                                text = additionalInfo,
                                fontSize = 10.sp,
                                fontWeight = FontWeight.Normal
                            )
                        }
                    } else {
                        Row(
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceBetween
                        ) {
                            Text(
                                text = title,
                                fontSize = 12.sp,
                                fontWeight = FontWeight.Bold
                            )
                            Spacer(modifier = Modifier.width(4.dp))
                            Text(
                                text = description,
                                fontSize = 10.sp,
                                fontWeight = FontWeight.Bold
                            )
                        }

                        Text(
                            text = additionalInfo,
                            fontSize = 10.sp,
                            fontWeight = FontWeight.Normal
                        )
                    }
                }
            }
        }
    }
}


@Composable
fun HandleCallState(state: CallPlaceInfoState, component: CallConsultationOptionsComponent) {
    when (state) {
        is CallPlaceInfoState.Error -> ErrorBottomSheetCallConsultation(state.msg) {
            component.onClose()
        }
        is CallPlaceInfoState.Loading -> LoadingProgressDialog{}
        is CallPlaceInfoState.PlaceCallSuccess -> {
            CallWaitingSuccess(
                inComingCallMobileNumber =  state.data.emergencyNumber,
                expertId = component.expertId,
                expertName = component.expertName,
                expertImage = component.expertImage,
                expertSkills = component.expertSkills,
                onTimeOutOver = component.onClosePage
            )
        }
    }
}

@Composable
fun CallConsultationOptionsContent(
    onSimCallPlaced: () -> Unit,
    onInternetCallPlaced: () -> Unit
) {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .background(color = Color(0xFFF5F5F5))
            .padding(16.dp)
    ) {
        Text(
            text = "Call Consultation Option",
            modifier = Modifier
                .padding(start = 16.dp),
            fontSize = 16.sp,
            fontWeight = FontWeight.Bold
        )

        Spacer(modifier = Modifier.height(16.dp))

        CallOptionCard(
            title = "SIM",
            description = "Reliable Call Quality",
            additionalInfo = "Expert will not see your personal number",
            icon = Res.drawable.sim_icon,
            isComingSoon = false,
            onCallPlaced = onSimCallPlaced
        )

        Spacer(modifier = Modifier.height(12.dp))

        CallOptionCard(
            title = "INTERNET",
            description = "Better connectivity",
            additionalInfo = "Powerful features, Need good internet",
            icon = Res.drawable.wifi_icon,
            isComingSoon = true,
            onCallPlaced = onInternetCallPlaced
        )
    }
}


