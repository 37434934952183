package com.winiwayuser.feature_chat_consultation_new.persentation.chat_bottom_sheet_info

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import com.winiwayuser.core.persentation.LoadingProgressDialog
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_bottom_sheet_info.widget.ChatInfoSuccessBottomSheet
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_bottom_sheet_info.widget.RechargeNowBottomSheetDialog


@Composable
fun ChatConsultationBottomSheet(
    consultationComponent: ChatConsultationComponent
) {

    val state by consultationComponent.state.collectAsState()

    if (
        state is ChatInfoBottomSheetState.Loading &&
        (state as ChatInfoBottomSheetState.Loading).isLoading
    ) {
        co.touchlab.kermit.Logger.d("is Loading -> ${(state as ChatInfoBottomSheetState.Loading).isLoading}")
        LoadingProgressDialog { }
    }

    if (state is ChatInfoBottomSheetState.NormalChatLowBalance) {
//        RechargeNowBottomSheetDialog(onDismissRequest = {}, data = listOf(),  onRechargeSelected = { selectedAmount ->
//            co.touchlab.kermit.Logger.d("selected amount - $selectedAmount")
//        })
    }

    if (state is ChatInfoBottomSheetState.ChatInfoSuccess) {
        co.touchlab.kermit.Logger.d("Chat Success State -> ${(state as ChatInfoBottomSheetState.ChatInfoSuccess).data}")
        ChatInfoSuccessBottomSheet(expertName = consultationComponent.expertName,
            expertSkills = consultationComponent.expertSkills,
            onDismiss = {
                consultationComponent.onClosePage()
            },
            onSubmit = {
                consultationComponent.startConsultation()
            })
    }

}
