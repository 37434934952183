package com.winiwayuser.feature_chat_consultation_new.persentation.chat_bottom_sheet_info.widget

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.ModalBottomSheet
import androidx.compose.material3.Text
import androidx.compose.material3.rememberModalBottomSheetState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.winiwayuser.core.theme.let_s_connect
import com.winiwayuser.core.theme.maximum_call_duration
import com.winiwayuser.core.theme.md_them_primary
import com.winiwayuser.core.theme.pricing
import com.winiwayuser.core.theme.primaryLight50
import com.winiwayuser.core.theme.wallet_balance
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_bottom_sheet_info.ChatInfoBottomSheetState
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.consultation_call_small_icon
import com.winiwayuser.resources.ellipse
import com.winiwayuser.resources.ic_wallet
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.painterResource

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun ChatInfoSuccessBottomSheet(
    expertName: String,
    expertSkills: String,
    onDismiss: () -> Unit,
    onSubmit:()-> Unit
) {
    val coroutineScope = rememberCoroutineScope()
    val sheetState = rememberModalBottomSheetState(skipPartiallyExpanded = true)

    ModalBottomSheet(
        sheetState = sheetState,
        onDismissRequest = {
            coroutineScope.launch {
                sheetState.hide()
                onDismiss.invoke()
            }
        }
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp)
                .align(Alignment.CenterHorizontally)
        ) {
            Box(modifier = Modifier.fillMaxWidth(), contentAlignment = Alignment.Center) {
                Text(
                    text = "Chat Consultation",
//                    style = MaterialTheme.typography.subtitle1,
//                    modifier = Modifier.padding(vertical = 16.dp)
                )
            }

            Spacer(modifier = Modifier.height(8.dp))
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(90.dp)
//                    .padding(top = 16.dp)
                    .clip(RoundedCornerShape(8.dp)),
//                    .background(Color.LightGray),
                verticalAlignment = Alignment.CenterVertically
            ) {

                Image(painter = painterResource(Res.drawable.ellipse), contentDescription = null)
                Spacer(modifier = Modifier.width(16.dp))
                Column {
                    Text(
                        text = expertName,
                        // style = MaterialTheme.typography.body1,
                        maxLines = 1,
                        modifier = Modifier.fillMaxWidth()
                    )
                    Spacer(modifier = Modifier.height(4.dp))
                    Text(
                        text = expertSkills,
                        // style = MaterialTheme.typography.body2,
                        maxLines = 2,
                        modifier = Modifier.fillMaxWidth()
                    )
                }
            }

            Spacer(modifier = Modifier.height(8.dp))
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(90.dp)
                    .clip(RoundedCornerShape(8.dp)),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {
                // Wallet Balance
                Column(
                    modifier = Modifier.weight(1f)
                ) {

                    Row {
                        Box(modifier = Modifier.background(primaryLight50)) {
                            Image(
                                painter = painterResource(Res.drawable.ic_wallet),
                                contentDescription = null,
                                modifier = Modifier.height(35.dp).width(35.dp)
                            )
                        }
                        Spacer(modifier = Modifier.width(8.dp))
                        Column {
                            Text(
                                text = wallet_balance,
                                fontSize = 12.sp,
                                // style = MaterialTheme.typography.body1,
                                maxLines = 1,
                                modifier = Modifier.fillMaxWidth()
                            )
                            Text(
                                text = "₹500",
                                // style = MaterialTheme.typography.body2,
                                maxLines = 1,
                                modifier = Modifier.fillMaxWidth()
                            )
                        }

                    }

                }
                // Pricing
                Column(
                    modifier = Modifier.weight(1f)
                ) {
                    Row {
                        Box(modifier = Modifier.background(primaryLight50)) {
                            Image(
                                painter = painterResource(Res.drawable.consultation_call_small_icon),
                                contentDescription = null,
                                modifier = Modifier.size(48.dp),
                                colorFilter = ColorFilter.tint(md_them_primary)
                            )
                        }
                        Spacer(modifier = Modifier.width(8.dp))
                        Column {
                            Text(
                                text = pricing,
                                // style = MaterialTheme.typography.body1,
                                fontSize = 12.sp,
                                maxLines = 1,
                                modifier = Modifier.fillMaxWidth()
                            )

                            Row(verticalAlignment = Alignment.CenterVertically) {
                                Box(
                                    modifier = Modifier.background(
                                        Color(0xFFEF5350),
                                        shape = RoundedCornerShape(2.dp)
                                    )
                                ) {
                                    Text(text = "Free", fontSize = 10.sp, color = Color.White)
                                }
                                Text(
                                    text = "₹20/Min",
                                    // style = MaterialTheme.typography.body2,
                                    maxLines = 1,
                                    modifier = Modifier.fillMaxWidth()
                                )
                            }
                        }
                    }
                }
            }

            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .clip(RoundedCornerShape(8.dp))
                    .background(primaryLight50),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Image(
                    painter = painterResource(Res.drawable.consultation_call_small_icon),
                    contentDescription = null,
                    colorFilter = ColorFilter.tint(md_them_primary)
                )
                Spacer(modifier = Modifier.width(8.dp))
                Text(text = maximum_call_duration)
                Spacer(modifier = Modifier.weight(1f))
                Box(contentAlignment = Alignment.CenterEnd) {
                    Text(text = "654", textAlign = TextAlign.End)
                }

            }

            Spacer(modifier = Modifier.height(10.dp))

            Box(
                modifier = Modifier.fillMaxWidth(),
                contentAlignment = Alignment.Center
            ) {
                Text(
                    "This is a one-time free call offered by Winyway",
                    fontSize = 12.sp,
                    textAlign = TextAlign.Center
                )
            }

            Spacer(modifier = Modifier.height(10.dp))

            Button(
                onClick = {
                    onSubmit.invoke()
                },
                modifier = Modifier
                    .fillMaxWidth()
                    .height(55.dp)
                    .padding(bottom = 8.dp),
                shape = RoundedCornerShape(5.dp), // Set the shape to RectangleShape
                colors = ButtonDefaults.buttonColors(md_them_primary)
            ) {
                Text(text = let_s_connect, color = Color.White)
            }

            Button(
                onClick = { },
                modifier = Modifier
                    .fillMaxWidth()
                    .height(55.dp)
                    .padding(bottom = 8.dp),
                shape = RoundedCornerShape(5.dp), // Set the shape to RectangleShape
                colors = ButtonDefaults.buttonColors(Color.Transparent),
                border = BorderStroke(1.dp, md_them_primary)
            ) {
                Text(text = "Low Balance? Recharge Wallet", color = md_them_primary)
            }
        }
    }
}