package com.winiwayuser.feature_authentication.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class VerifyOtpRequest(
    @SerialName("mobile_code") val mobileCode: String,
    @SerialName("mobile") val mobile: String,
    @SerialName("otp") val otp: String,
    @SerialName("type") val type: String,
    @SerialName("whatsapp_updates") val whatsappUpdates: Int,
    @SerialName("notification_token") val notificationToken: String,
    @SerialName("device_details") val deviceDetails: String,
    @SerialName("login_device") val loginDevice: String
)
