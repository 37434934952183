package com.winiwayuser.feature_call_consultation.persentation.call_waitting_sucess

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage
import com.winiwayuser.core.local.UserInfoProvider.getPhone
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_ELSE
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import com.winiwayuser.core.theme.background_color
import com.winiwayuser.core.theme.primaryLight50
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.ellipse
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.painterResource

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun CallWaitingSuccess(
    inComingCallMobileNumber: String,
    expertId: String,
    expertName: String,
    expertImage: String,
    expertSkills: String,
    onTimeOutOver: () -> Unit
) {

    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
        else -> WINDOW_WIDTH_SIZE_ELSE
    }

    Box(
        modifier = Modifier.fillMaxSize().background(background_color),
        contentAlignment = Alignment.Center,
    ) {
        Column(
            modifier = widthModifier
                .fillMaxSize()
                .background(background_color)
                .padding(16.dp),
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            // Thank you message
            Text(
                text = "Thank You!",
                style = TextStyle(
                    fontSize = 24.sp,
                    fontWeight = FontWeight.Bold,
                    color = Color.Black
                ),
                modifier = Modifier.padding(bottom = 8.dp)
            )

            // Appointment confirmation text
            Text(
                text = "Your appointment is booked with",
                style = TextStyle(fontSize = 16.sp, color = Color.Gray)
            )

            Spacer(modifier = Modifier.height(16.dp))

            // Expert details in a Card
            Card(
                modifier = widthModifier
                    .wrapContentWidth()
                    .padding(8.dp),
                elevation = CardDefaults.cardElevation(Scale.ELEVATION_MEDIUM),
                shape = RoundedCornerShape(Scale.CORNER_SMALL)
            ) {
                Row(
                    modifier = Modifier
                        .background(Color.White)
                        .padding(16.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    // Expert image
                    if (expertImage.isEmpty()) {
                        Image(
                            painter = painterResource(Res.drawable.ellipse),
                            contentDescription = "Expert placeholder",
                            modifier = Modifier
                                .size(80.dp)
                                .clip(CircleShape)
                                .background(Color.LightGray)
                        )
                    } else {
                        AsyncImage(
                            model = expertImage,
                            modifier = Modifier
                                .size(80.dp)
                                .clip(CircleShape)
                                .background(Color.LightGray),
                            contentScale = ContentScale.Crop,
                            contentDescription = "Expert image"
                        )
                    }

                    Spacer(modifier = Modifier.width(16.dp))

                    // Expert name and skills
                    Column(
                        modifier = Modifier.fillMaxWidth()
                    ) {
                        Text(
                            text = expertName,
                            style = TextStyle(
                                fontSize = 18.sp,
                                fontWeight = FontWeight.Bold,
                                color = Color.Black
                            ),
                            maxLines = 1,
                            overflow = TextOverflow.Ellipsis
                        )
                        Spacer(modifier = Modifier.height(4.dp))
                        Text(
                            text = expertSkills,
                            style = TextStyle(
                                fontSize = 14.sp,
                                color = Color.Gray
                            ),
                            maxLines = 2,
                            overflow = TextOverflow.Ellipsis
                        )
                    }
                }
            }

            Spacer(modifier = Modifier.height(24.dp))

            // Call info text
            val userNumber = getPhone()
            val callWaitingText =
                "You will receive a call shortly from $inComingCallMobileNumber on your registered mobile number (+91 $userNumber)."
            Text(
                text = callWaitingText,
                style = TextStyle(
                    fontSize = 16.sp,
                    color = Color.Gray,
                    textAlign = TextAlign.Center
                ),
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(horizontal = 32.dp)
            )

            Spacer(modifier = Modifier.height(16.dp))

            // Call waiting period label
            Text(
                text = "Call Waiting Period",
                fontSize = 14.sp,
                color = Color.DarkGray
            )

            Spacer(modifier = Modifier.height(8.dp))

            Timer(onTimeOutOver = onTimeOutOver)
        }
    }
}

@Composable
fun Timer(onTimeOutOver: () -> Unit) {
    var seconds by remember { mutableStateOf(120) }
    val scope = rememberCoroutineScope()

    LaunchedEffect(key1 = seconds) {
        if (seconds > 0) {
            scope.launch {
                delay(1000)
                seconds--
            }
        } else {
            onTimeOutOver()
        }
    }

    Box(
        modifier = Modifier
            .size(100.dp),
        contentAlignment = Alignment.Center
    ) {
        CircularProgressIndicator(
            progress = seconds / 120f,
            color = Color(0xFF6200EE),
            strokeWidth = 6.dp,
            modifier = Modifier.fillMaxSize()
        )
        Text(
            text = "${seconds / 60}:${if (seconds % 60 < 10) "0" else ""}${seconds % 60}",
            style = TextStyle(
                fontSize = 20.sp,
                fontWeight = FontWeight.Bold,
                color = Color.Black
            ),
            modifier = Modifier.align(Alignment.Center)
        )
    }
}

