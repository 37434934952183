package com.winiwayuser.feature_winyway_wallet.persentation.all_coupons_page

import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.BottomSheetScaffold
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.SheetState
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.rememberBottomSheetScaffoldState
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import co.touchlab.kermit.Logger
import com.winiwayuser.feature_winyway_wallet.data.CouponApplyListener
import kotlinx.coroutines.launch

//@OptIn(ExperimentalMaterial3WindowSizeClassApi::class, ExperimentalMaterial3Api::class)
//@Composable
//fun CouponScreenList(
//    component: AllCouponComponent,
//    listener: CouponApplyListener,
//    onDismiss: () -> Unit
//) {
//    Logger.d { "CouponScreen Start" }
//    var sectedCouponCode: String? = null
//    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
//
//
//    val state by component.allCouponState.collectAsState()
//    val sheetState = remember { SheetState(skipPartiallyExpanded = true) }
//    val coroutineScope = rememberCoroutineScope()
//    val (isDialogVisible, setDialogVisible) = remember { mutableStateOf(false) }
//
//    state.let { coupons ->
//        coupons.forEach { coupon ->
//            Logger.d { "CouponScreenList: $coupons" }
//
//            if (windowSizeClass == WindowWidthSizeClass.Compact) {
//                // Show CouponCard in Bottom Sheet
//                BottomSheetScaffold(
//                    sheetContent = {
//                        LazyColumn {
//                            items(coupons.size) { couponIndex ->
//                                CouponCard(data = coupon.coupon, listener = listener, onDismiss = {
//                                    setDialogVisible(false)
//                                    onDismiss.invoke()
//                                })
//                            }
//                        }
//                    },
//                    scaffoldState = rememberBottomSheetScaffoldState(sheetState),
//                    content = { /* Main Content */ }
//                )
//
//                // Toggle the bottom sheet visibility based on sheet state
//                LaunchedEffect(sheetState) {
//                    if (sheetState.isVisible) {
//                        sheetState.show()
//                    } else {
//                        sheetState.hide()
//                        //component.onBackPress()
//                    }
//                }
//
//            } else {
//                if (isDialogVisible) {
//                    // Show CouponCard in Dialog
//                    AlertDialog(
//                        onDismissRequest = {
//                            setDialogVisible(false)
//                            onDismiss.invoke()
//                        }, // Dismiss the dialog
//                        title = { Text(text = "Coupons") },
//                        text = {
//                            LazyColumn {
//                                items(coupons.size) { couponIndex ->
//                                    CouponCard(
//                                        data = coupon.coupon,
//                                        listener = listener,
//                                        onDismiss = { setDialogVisible(false) })
//                                }
//                            }
//                        },
//                        confirmButton = {
////                            TextButton(onClick = {
////                                setDialogVisible(false)
////                                //component.onBackPress()
////                                onDismiss.invoke()
////                            }) {
////                                Text("OK")
////                            }
//                        },
//                        dismissButton = {
////                            TextButton(onClick = {
////                                setDialogVisible(false)
////                                // component.onBackPress()
////                                onDismiss.invoke()
////                            }
////                            ) {
////                                Text("Cancel")
////                            }
//                        }
//                    )
//                }
//            }
//
//            // Open or close the bottom sheet/dialog based on window size
//            LaunchedEffect(coupons) {
//                if (windowSizeClass == WindowWidthSizeClass.Compact) {
//                    coroutineScope.launch {
//                        sheetState.show()
//                    }
//                } else {
//                    setDialogVisible(true)
//                }
//            }
//        }
//    }
//}

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class, ExperimentalMaterial3Api::class)
@Composable
fun CouponScreenList(
    component: AllCouponComponent,
    listener: CouponApplyListener,
    onDismiss: () -> Unit
) {
    Logger.d { "CouponScreen Start" }

    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val state by component.allCouponState.collectAsState()
    val sheetState = remember { SheetState(skipPartiallyExpanded = true) }
    val coroutineScope = rememberCoroutineScope()
    val (isDialogVisible, setDialogVisible) = remember { mutableStateOf(false) }

    // Proceed only if we have coupons in the state
    if (state.isNotEmpty()) {
        when (windowSizeClass) {
            WindowWidthSizeClass.Compact -> {
                // Show CouponCard in Bottom Sheet
                BottomSheetScaffold(
                    sheetContent = {
                        LazyColumn {
                            items(state) { coupon ->
                                CouponCard(
                                    data = coupon.coupon,
                                    listener = listener,
                                    onDismiss = {
                                        setDialogVisible(false)
                                        onDismiss.invoke()
                                    }
                                )
                            }
                        }
                    },
                    scaffoldState = rememberBottomSheetScaffoldState(sheetState),
                    content = { /* Main Content */ }
                )

                // Show or hide the bottom sheet based on visibility
                LaunchedEffect(sheetState.isVisible) {
                    if (sheetState.isVisible) {
                        // Only show the sheet if it is not already visible
                        sheetState.show()
                    } else {
                        // Ensure we hide the sheet properly
                        sheetState.hide()
                    }
                }
            }
            else -> {
                // Show dialog for larger window sizes
                if (isDialogVisible) {
                    AlertDialog(
                        onDismissRequest = {
                            setDialogVisible(false)
                            onDismiss.invoke()
                        },
                        title = { Text(text = "Coupons") },
                        text = {
                            LazyColumn {
                                items(state) { coupon ->
                                    CouponCard(
                                        data = coupon.coupon,
                                        listener = listener,
                                        onDismiss = { setDialogVisible(false) }
                                    )
                                }
                            }
                        },
                        confirmButton = {
//                            TextButton(onClick = {
//                                setDialogVisible(false)
//                                onDismiss.invoke()
//                            }) {
//                                Text("OK")
//                            }
                        },
                        dismissButton = {
//                            TextButton(onClick = {
//                                setDialogVisible(false)
//                                onDismiss.invoke()
//                            }) {
//                                Text("Cancel")
//                            }
                        }
                    )
                }
            }
        }

        // Control the visibility of the dialog or bottom sheet based on window size
        LaunchedEffect(windowSizeClass) {
            if (windowSizeClass == WindowWidthSizeClass.Compact) {
                coroutineScope.launch {
                    sheetState.show()
                }
            } else {
                setDialogVisible(true)
            }
        }
    } else {
        // Handle the case where there are no coupons, if needed
        Text("No coupons available.")
    }
}


