package com.winiwayuser.feature_chat_consultation_new.persentation.chat_concern.widget

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.KeyboardType
import com.winiwayuser.feature_chat_consultation_new.data.response.concern_question.ConcernQuestions
import com.winiwayuser.feature_chat_consultation_new.data.response.geo_details.GeoName
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_concern.ChatConcernComponent



//@Composable
//fun DynamicFieldForm(
//    modifier: Modifier,
//    questions: ConcernQuestions
//) {
//    Logger.d("Dynamic Field Form -> $questions")
//    when (questions.inputType) {
//        "Number" -> {
//            OutlinedInputField(
//                modifier = modifier,
//                label = questions.questions,
//                inputType = KeyboardType.Number,
//                maxLength = questions.characterLength ?: 100,
//                isRequired = returnBooleanRequired(questions.required)
//            )
//        }
//
//        "Character" -> {
//            val isMultiLine = questions.characterLength != null && questions.characterLength > 100
//            OutlinedInputField(
//                modifier = modifier,
//                label = questions.questions,
//                inputType = if (isMultiLine) KeyboardType.Text else KeyboardType.Text,
//                maxLength = questions.characterLength ?: 100,
//                isRequired = returnBooleanRequired(questions.required),
//                isMultiLine = isMultiLine
//            )
//        }
//
//        "Document" -> {
//            DocumentPickerField(
//                modifier = modifier,
//                label = questions.questions,
//                isRequired = returnBooleanRequired(questions.required)
//            )
//        }
//
//        "Date" -> {
//            DatePickerField(
//                modifier = modifier,
//                label = questions.questions,
//                isRequired = returnBooleanRequired(questions.required)
//            )
//        }
//
//        "Time" -> {
//            TimePickerField(
//                modifier = modifier,
//                label = questions.questions,
//                isRequired = returnBooleanRequired(questions.required)
//            )
//        }
//
//        else -> {
//            OutlinedInputField(
//                modifier = modifier,
//                label = questions.questions,
//                inputType = KeyboardType.Text,
//                maxLength = questions.characterLength ?: 100,
//                isRequired = returnBooleanRequired(questions.required)
//            )
//        }
//    }
//}

@Composable
fun DynamicFieldForm(
    component: ChatConcernComponent,
    modifier: Modifier = Modifier,
    questions: ConcernQuestions,
    onValueChange: (String) -> Unit,
    onValueChangeGeo: (GeoName) -> Unit
) {

    when (questions.inputType) {
        "Number" -> {
            OutlinedInputField(
                modifier = modifier,
                label = questions.questions,
                inputType = KeyboardType.Number,
                maxLength = questions.characterLength ?: 100,
                isRequired = returnBooleanRequired(questions.required),
                onValueChange = onValueChange
            )
        }

        "Character" -> {
            val isMultiLine = questions.characterLength != null && questions.characterLength > 100
            OutlinedInputField(
                modifier = modifier,
                label = questions.questions,
                inputType = if (isMultiLine) KeyboardType.Text else KeyboardType.Text,
                maxLength = questions.characterLength ?: 100,
                isRequired = returnBooleanRequired(questions.required),
                isMultiLine = isMultiLine,
                onValueChange = onValueChange
            )
        }

        "Dropdown" -> {
            DropdownField(
                modifier = modifier,
                label = questions.questions,
                isRequired = returnBooleanRequired(questions.required),
                onSelectedItem = { geo ->
                    onValueChangeGeo.invoke(geo)
                },
                component = component
            )
        }

        "Document" -> {
            DocumentPickerField(
                modifier = modifier,
                label = questions.questions,
                isRequired = returnBooleanRequired(questions.required),
                onValueChange = onValueChange
            )
        }

        "Date" -> {
            DatePickerField(
                modifier = modifier,
                label = questions.questions,
                isRequired = returnBooleanRequired(questions.required),
                onValueChange = onValueChange
            )
        }

        "Time" -> {
            TimePickerField(
                modifier = modifier,
                label = questions.questions,
                isRequired = returnBooleanRequired(questions.required),
                onValueChange = onValueChange
            )
        }

        "Latitude", "Longitude", "Country Code" -> {
//            NullBoxField(
//                modifier = modifier,
//                label = questions.questions,
//                isRequired = returnBooleanRequired(questions.required),
//                onValueChange = onValueChange
//            )
        }

        else -> {
            OutlinedInputField(
                modifier = modifier,
                label = questions.questions,
                inputType = KeyboardType.Text,
                maxLength = questions.characterLength ?: 100,
                isRequired = returnBooleanRequired(questions.required),
                onValueChange = onValueChange
            )
        }
    }
}


fun returnBooleanRequired(required: Int): Boolean {
    return required == 1 // Return true if required is 1, otherwise false
}
