package com.winiwayuser.core.agora

import kotlin.js.Promise

@JsModule("agora-rtm-sdk")
@JsNonModule
external object AgoraRTM {
    fun createInstance(appId: String): RtmClient
}

external interface RtmClient {
    fun login(options: dynamic): Promise<Unit>
    fun logout(): Promise<Unit>
    fun sendMessageToPeer(message: RtmMessage, peerId: String): Promise<Unit>
    fun createMessage(content: String): RtmMessage
    fun on(event: String, callback: (Any) -> Unit)
    fun destroy()
}

external interface RtmMessage {
    var text: String
}

inline fun <T> jsObject(builder: T.() -> Unit): T = (js("{}") as T).apply(builder)

// In jsMain (AgoraRtmClientJS class)
class AgoraRtmClientJS(private val appId: String) {

    private var client: RtmClient? = null

    // Define a callback to handle received messages
    var onReceiveMessage: ((String, String) -> Unit)? = null

    fun initialize(
        token: String,
        userId: String,
        onLoginSuccess: () -> Unit,
        onLoginError: (String) -> Unit
    ) {
        client = AgoraRTM.createInstance(appId)

        client?.on("ConnectionStateChanged") { newState ->
            println("Connection state changed: $newState")
        }

        // Correct handling of the MessageFromPeer event
        client?.on("MessageFromPeer") { messageEvent: dynamic ->
            val peerId = messageEvent.peerId as String
            val messageText = messageEvent.message.text as String
            onReceiveMessage?.invoke(peerId, messageText)
        }

        val options = jsObject<dynamic> {
            this.token = token
            this.uid = userId
        }

        client?.login(options)?.then {
            println("Login successful")
            onLoginSuccess.invoke()
        }?.catch { error ->
            println("Login failed: ${error.message}")
            onLoginError.invoke(error.message ?: "Agora Login Failed")
        }
    }

    fun sendMessage(
        peerId: String,
        messageText: String,
        onMessageSentSuccess: () -> Unit,
        onMessageSentError: (String) -> Unit
    ) {
        client?.let {
//            val message = it.createMessage(messageText)
            val message = jsObject<dynamic> {
                this.text = messageText
            }
            it.sendMessageToPeer(message, peerId).then {
                println("Message sent successfully")
                onMessageSentSuccess.invoke()
            }.catch { error ->
                println("Failed to send message: ${error.message}")
                onMessageSentError.invoke(error.message ?: "Agora Message Fail to Send")
            }
        } ?: run {
            println("Client is not initialized. Call initialize() first.")
            onMessageSentError.invoke("Agora not initialized")
        }
    }

    fun logout() {
        client?.logout()?.then {
            println("Logout successful")
            destroy()
        }?.catch { error ->
            println("Logout failed: ${error.message}")
            destroy()
        }
    }

    fun destroy() {
        client?.destroy()
        client = null
        println("Client destroyed")
    }
}












