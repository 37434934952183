package com.winiwayuser.feature_chat_consultation_new.persentation.chat_bottom_sheet_info

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.ModalBottomSheet
import androidx.compose.material3.rememberModalBottomSheetState
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import co.touchlab.kermit.Logger
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.feature_chat_consultation.persentation.chat_bottom_sheet_info.ChatInfoBottomSheetState
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_bottom_sheet_info.widget.ChatInfoSuccessUi
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_bottom_sheet_info.widget.ConsultationErrorUi
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_bottom_sheet_info.widget.LowOnFreeChatConsultation
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_bottom_sheet_info.widget.RechargeForConsultation
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData
import kotlinx.coroutines.launch


@OptIn(ExperimentalMaterial3Api::class, ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun ChatConsultationBottomSheet(
    consultationComponent: ChatConsultationComponent,
) {

    val sheetState = rememberModalBottomSheetState(skipPartiallyExpanded = true)
    val coroutineScope = rememberCoroutineScope()
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val state by consultationComponent.state.collectAsState()

    val content: @Composable () -> Unit = {

        val modifier = Modifier

        if (
            state is ChatInfoBottomSheetState.Loading && (state as ChatInfoBottomSheetState.Loading).isLoading
        ) {
            Box(
                modifier = Modifier.size(80.dp),
                contentAlignment = Alignment.Center
            ) {
                CircularProgressIndicator(
                    modifier = Modifier.size(40.dp),
                    color = Color.Blue
                )
            }
        }

        if (state is ChatInfoBottomSheetState.NormalChatLowBalance) {
            val data = (state as ChatInfoBottomSheetState.NormalChatLowBalance)
            RechargeForConsultation(
                modifier = modifier,
                data = data.data,
                expertImage = consultationComponent.expertImage,
                expertName = consultationComponent.expertName,
                expertSkills = consultationComponent.expertSkills,
                rechargeData = data.rechargeData,
                onRechargeSelected = { rechargeData ->
                    consultationComponent.onRechargeAgain(rechargeData)
                }
            )
        }

        if (state is ChatInfoBottomSheetState.ChatInfoSuccess) {
            val data = (state as ChatInfoBottomSheetState.ChatInfoSuccess).data
            ChatInfoSuccessUi(
                modifier = modifier,
                expertName = consultationComponent.expertName,
                expertSkills = consultationComponent.expertSkills,
                expertImage = consultationComponent.expertImage,
                chatDurationData = data,
                onSubmit = {
                    consultationComponent.startConsultation(
                        data.totalChatDuration ?: "",
                        data.expertCategory ?: ""
                    )
                })
        }

        if (state is ChatInfoBottomSheetState.Error) {
            Logger.d("Chat Error State -> ${(state as ChatInfoBottomSheetState.Error).msg}")
            ConsultationErrorUi(
                modifier = Modifier,
                expertName = consultationComponent.expertName,
                expertSkills = consultationComponent.expertSkills,
                expertImage = consultationComponent.expertImage,
                errorMsg = (state as ChatInfoBottomSheetState.Error).msg,
            )
        }

        if (state is ChatInfoBottomSheetState.FreeChatLowBalance){
            val data = (state as ChatInfoBottomSheetState.FreeChatLowBalance).data
            val plandData = RechargeData(data.planAmount, data.planId, data.planName, null)
            LowOnFreeChatConsultation(
               modifier = Modifier,
                expertName = consultationComponent.expertName,
                expertSkills = consultationComponent.expertSkills,
                expertImage = consultationComponent.expertImage,
                chatDurationData = data,
                onSubmit = {
                    consultationComponent.onRechargeAgain(plandData)
                }
            )
        }
    }

    if (windowSizeClass == WindowWidthSizeClass.Compact) {
        ModalBottomSheet(
            containerColor = Color.White,
            sheetState = sheetState,
            onDismissRequest = {
                coroutineScope.launch {
                    sheetState.hide()
                    consultationComponent.onClosePage()
                }
            },
            shape = RoundedCornerShape(topStart = Scale.CORNER_SMALL, topEnd = Scale.CORNER_SMALL),
            content = {
                Box(
                    modifier = Modifier.padding(start = 12.dp, end = 20.dp, bottom = 12.dp)
                ) {
                    content.invoke()
                }
            }
        )
    } else {
        AlertDialog(
            containerColor = Color.White,
            onDismissRequest = {
                consultationComponent.onClosePage()
            },
            text = content,
            shape = RoundedCornerShape(Scale.CORNER_SMALL),
            confirmButton = {},
            dismissButton = {}
        )
    }

}
