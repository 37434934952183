package com.winiwayuser.feature_winyway_wallet.persentation.recharge_checkout.widget

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Face
import androidx.compose.material.icons.filled.Info
import androidx.compose.material3.Button
import androidx.compose.material3.Divider
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.material3.TextField
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.feature_winyway_wallet.data.response.RechargeInfoData
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.wallet_transaction_icon
import org.jetbrains.compose.resources.painterResource

@Composable
fun OrderSummary(
    selectedAmount: Int,
    rechargeInfoData: RechargeInfoData,
    onPaymentClicked: () -> Unit = {}
) {
    BoxWithConstraints {
        val maxWidthDp = maxWidth.value
        val widthModifier = if (maxWidthDp > 700) {
           // Modifier.width(700.dp).fillMaxHeight(0.8f)
            Modifier.width(700.dp).fillMaxHeight()
        } else {
            Modifier.fillMaxWidth().fillMaxHeight()
        }

        Column(
            modifier = widthModifier
                .background(Color.White, shape = RoundedCornerShape(8.dp))
                .padding(16.dp).fillMaxHeight().align(Alignment.Center)
        ) {
            OrderHeaderNew(
                selectedAmount = selectedAmount
            )
            Spacer(modifier = Modifier.height(16.dp))
            HorizontalDivider()
            Spacer(modifier = Modifier.height(16.dp))
            CouponSection()
            Spacer(modifier = Modifier.height(16.dp))
            //AppliedCoupon()
            Spacer(modifier = Modifier.height(16.dp))
            HorizontalDivider()
            Spacer(modifier = Modifier.height(16.dp))
            PaymentDetails(
                rechargeInfoData = rechargeInfoData
            )
            Spacer(modifier = Modifier.height(24.dp))
            ProceedToPaymentButton(
                onPaymentClicked = onPaymentClicked
            )
        }
    }
}

@Composable
fun OrderHeaderNew(
    selectedAmount: Int
) {

    Row(verticalAlignment = Alignment.CenterVertically) {
//        Image(
//            painter = painterResource(Res.drawable.wallet_transaction_icon),
//            contentDescription = "Wallet Icon",
//            contentScale = ContentScale.Crop,
//            modifier = Modifier
//                .size(48.dp)
//                .background(Color.Gray, shape = RoundedCornerShape(Scale.CORNER_SMALL))
//                .clip(RoundedCornerShape(Scale.CORNER_SMALL))
//        )
        Spacer(modifier = Modifier.width(16.dp))
        Column(modifier = Modifier.weight(1f)) {
            Text("Order #1", fontWeight = FontWeight.Bold)
            Text("Wallet Recharge", fontSize = 14.sp, fontWeight = FontWeight.Medium)
            Text("Selected Wallet Recharge Amount", fontSize = 12.sp, color = Color.Gray)
        }
        Text("₹$selectedAmount", fontWeight = FontWeight.Bold, fontSize = 16.sp)
    }
}

@Composable
fun CouponSection() {
    Column {
        Row(verticalAlignment = Alignment.CenterVertically) {
            Icon(imageVector = Icons.Default.Info, contentDescription = null)
            Spacer(modifier = Modifier.width(8.dp))
            Text("Do you have coupon ?", modifier = Modifier.weight(1f))
            Text("All Coupons", color = Color.Blue, modifier = Modifier.padding(start = 8.dp))
        }
        Spacer(modifier = Modifier.height(8.dp))
        Row {
            TextField(
                value = "",
                onValueChange = {},
                placeholder = { Text("Add Coupon") },
                modifier = Modifier.weight(1f)
            )
            Spacer(modifier = Modifier.width(8.dp))
            Button(onClick = {

            }) {
                Text("Apply")
            }
        }
    }
}

@Composable
fun AppliedCoupon() {
    Column {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .background(Color(0xFFDFF5D9), shape = RoundedCornerShape(4.dp))
                .padding(8.dp),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Text(
                "NEW100 - Applied",
                fontWeight = FontWeight.Bold,
                modifier = Modifier.weight(1f)
            )
            Text(
                "Remove",
                color = Color.Red,
                modifier = Modifier.padding(start = 8.dp)
            )
        }
        Spacer(modifier = Modifier.height(4.dp))
        Text("You will get 300 cashback after recharge done.", fontSize = 12.sp, color = Color.Gray)
    }
}

@Composable
fun PaymentDetails(
    rechargeInfoData: RechargeInfoData
) {
    Column {
        Row(verticalAlignment = Alignment.CenterVertically) {
//            Icon(imageVector = Icons.Default.Face, contentDescription = null)
//            Spacer(modifier = Modifier.width(8.dp))
            Text("Payment Details:", fontWeight = FontWeight.Bold)
        }
        Spacer(modifier = Modifier.height(8.dp))
        PaymentDetailRow(label = "Amount", value = "₹${rechargeInfoData.referbalance.amount?: 0}")
        PaymentDetailRow(
            label = "Service Tax (18% GST)",
            value = "₹${rechargeInfoData.referbalance.gstPercentageAmount ?: 0}"
        )
        PaymentDetailRow(
            label = "Coin Applied Discount",
            value = "- ₹${rechargeInfoData.referbalance.coinPercentageValue ?: 0}",
            isDiscount = true
        )
//        PaymentDetailRow(label = "Wallet Used", value = "- ₹180", isDiscount = true)
        HorizontalDivider(modifier = Modifier.padding(vertical = 8.dp))
        PaymentDetailRow(
            label = "Total amount to be paid",
            value = "₹${rechargeInfoData.referbalance.cashAmount ?: 0}",
            isTotal = true
        )
        PaymentDetailRow(
            label = "Cashback Value",
            value = "₹${rechargeInfoData.transationDetails.cashbackValue ?: 0}",
            isTotal = true
        )
    }
}

@Composable
fun PaymentDetailRow(
    label: String,
    value: String,
    isDiscount: Boolean = false,
    isTotal: Boolean = false
) {
    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        Text(
            text = label,
            fontSize = 14.sp,
            color = if (isTotal) Color.Black else Color.Gray,
            fontWeight = if (isTotal) FontWeight.Bold else FontWeight.Normal
        )
        Text(
            text = value,
            fontSize = 14.sp,
            color = when {
                isTotal -> Color.Black
                isDiscount -> Color(0xFF4CAF50) // Green color for discounts
                else -> Color.Black
            },
            fontWeight = if (isTotal) FontWeight.Bold else FontWeight.Normal
        )
    }
}

@Composable
fun ProceedToPaymentButton(
    onPaymentClicked: () -> Unit
) {
    Button(
        onClick = {
            onPaymentClicked.invoke()
                  },
        modifier = Modifier
            .fillMaxWidth()
            .padding(horizontal = 16.dp)
    ) {
        Text("PROCEED TO PAYMENT")
    }
}