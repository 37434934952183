package com.winiwayuser.feature_call_consultation.data.api

import co.touchlab.kermit.Logger
import com.winiwayuser.core.remote.KtorApi
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_call_consultation.data.request.GetCallDurationRequest
import com.winiwayuser.feature_call_consultation.data.request.RequestCallDuration
import com.winiwayuser.feature_call_consultation.data.request.RequestCallPlace
import com.winiwayuser.feature_call_consultation.data.response.CallDurationData
import com.winiwayuser.feature_call_consultation.data.response.CallDurationResponse
import com.winiwayuser.feature_call_consultation.data.response.CallWaitingResponse
import com.winiwayuser.feature_call_consultation.data.response.Data
import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.ServerResponseException
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext

class CallConsultationApi : KtorApi() {
    companion object {
        const val GET_CALL_DURATION_INFO = "call-duration-check"
        const val POST_ACTION_TO_CALL_PLACE = "book-call"
        //const val AUTH_TOKEN ="eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjAwYmFlYmUzYzI5YmE3ZDdjNzgxZjMzMWI3YjU2ZWMwYTg0MjJiMWE2MzgzNTJjMGQ4NGI2ZWZhYWM0ZjFmNmJhZDIzYTQ4MzQzNzc0ZjgwIn0.eyJhdWQiOiIxIiwianRpIjoiMDBiYWViZTNjMjliYTdkN2M3ODFmMzMxYjdiNTZlYzBhODQyMmIxYTYzODM1MmMwZDg0YjZlZmFhYzRmMWY2YmFkMjNhNDgzNDM3NzRmODAiLCJpYXQiOjE3MTcxNTgwOTgsIm5iZiI6MTcxNzE1ODA5OCwiZXhwIjoxNzQ4Njk0MDk4LCJzdWIiOiI1MzYxOSIsInNjb3BlcyI6W119.RpgGuFb8AIbSF7nYNPuhRmJdRx05GFwsUAd5aUOV9syHdJM3C1BWn_X0pET3xSuCPpeFjmDprq8MUyk9P0ddhhKsCnF22zx4p6pHL4cv1QahZboK2DoeiJnKfA8S5SUQvbuo4UEpe8wSyEdLwF-GW0b41nrffKonu-h0nzYyLX-76HiI-yQ_S1sPb1ku9fTv_9XR_r6NbOl7iKxlQyUdwzTyscy_K8x9ImWODT_JlKvGpHUtwg_HPVI87kHxOa4eSr6ezXN9Bzt1Rh98Bp3ZbOEB1X04fiH0lSjZAU_HCoIsh0jC-CPOj8bDSWTjTdj8dwXPHsRjZ1miSkuTvWV1_jUBN3AQbJu8r1zB0DQKxUKxZHrJrbO3VhZQncANOg-Y0U-8bm1aqpRYWi7TzgK689unYx6ywmSKV11MWWBE65mVbog0wAvJlgFDDsiVLA6r8GsVTMFHjWLLpglpk8yR653ZJUIQWQnjeZzQAeL4d1WIuIUZH0Rc4uq1xvWUMoz16UbuFCWUsSEc1AsHk5AvLRTsElQzvwaHkZ34LPhPWOZmlNZ7Fj0gM1N4ygfy7vQpryhU7CksbzVzYbynNkH-yCJVU4dqZ3Sv0OrrT76IVtlu3wI5tsqo6LPpEvO7ghYAnlqRwqnwtwXwNzNKi6bXL32BN3LICyH2Zrphi43ELik"
    }

    private suspend fun callDurationInfoApi(request: RequestCallDuration)= client.post {
        url(GET_CALL_DURATION_INFO)
        setBody(request)
    }.body<CallDurationResponse>()

    private suspend fun placeCallApi(request: GetCallDurationRequest)= client.post{
        url(POST_ACTION_TO_CALL_PLACE)
        setBody(request)
    }.body<CallWaitingResponse>()

    suspend fun getCallDurationInfo(request: RequestCallDuration): Resources<CallDurationData> {
        return withContext(Dispatchers.Default) {
            try {
                val callDurationInfoResponse = callDurationInfoApi(request)

                if (callDurationInfoResponse.status.not()) {
                    Logger.d("Error state ${callDurationInfoResponse.message} ")
                    return@withContext Resources.Error(
                        callDurationInfoResponse.message ?: "Unexpected Error"
                    )
                }
//                if(!callDurationInfoResponse.status || callDurationInfoResponse.data != null){
//                    return@withContext Resources.Error(callDurationInfoResponse.message?: "Unexpected error")
//                }

//                if(topConsultantResponse.status.f){
//
//                }
                Logger.d("Success state ${callDurationInfoResponse.message} ")

                return@withContext Resources.Success(callDurationInfoResponse.data)

            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }

    suspend fun placeCall(request: GetCallDurationRequest): Resources<Data>{
        return withContext(Dispatchers.Default){
            try{
                val callPlaceResponse = placeCallApi(request)

                if(callPlaceResponse.status.not()) {
                    return@withContext Resources.Error(callPlaceResponse.message?: "Unexpected error")
                }

//                if(!callPlaceResponse.status || callPlaceResponse.data != null){
//                    return@withContext Resources.Error(callPlaceResponse.message?: "Unexpected error")
//                }

                return@withContext Resources.Success(callPlaceResponse.data)

            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }
}