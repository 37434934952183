package com.winiwayuser.feature_authentication.persentation.login_page.widget

import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.text.ClickableText
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material3.Button
import androidx.compose.material3.Checkbox
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.DropdownMenu
import androidx.compose.material3.DropdownMenuItem
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.*
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import co.touchlab.kermit.Logger
import co.touchlab.kermit.NoTagFormatter
import co.touchlab.kermit.loggerConfigInit
import co.touchlab.kermit.platformLogWriter
import com.winiwayuser.core.persentation.CommonLoadingButton
import com.winiwayuser.core.persentation.settings
import com.winiwayuser.core.persentation.showToast
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.ic_whatsapp
import org.jetbrains.compose.resources.painterResource

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun LoginInputBox(
    onClickSubmit: (String, String, Boolean) -> Unit,
    onClickTerms: () -> Unit
) {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass

    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> Modifier.fillMaxWidth()
        WindowWidthSizeClass.Medium -> Modifier.fillMaxWidth(0.6f)
        else -> Modifier.fillMaxWidth(0.4f)
    }
//    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
//   // Logger.d("WindowSizeClass -> $windowSizeClass")
//    val widthModifier = when (windowSizeClass) {
//        WindowWidthSizeClass.Compact -> Modifier.fillMaxWidth()
//        WindowWidthSizeClass.Medium -> Modifier.fillMaxWidth(0.6f)
//        else -> Modifier.fillMaxWidth(0.4f)
//    }

    var countryCode by remember { mutableStateOf("+91") }
    var mobileNumber by rememberSaveable { mutableStateOf(TextFieldValue()) }

    val countries = listOf("+1", "+91", "+44", "+86")
    var isDropdownVisible by remember { mutableStateOf(false) }
    var isChecked by remember { mutableStateOf(true) }
    var receivePromotionalMessages by remember { mutableStateOf(true) }
    var isMobileNumberValid by remember { mutableStateOf(false) }

    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        modifier = Modifier
            .fillMaxWidth()
            .verticalScroll(rememberScrollState()) // Make the Column scrollable on mobile
    ) {
        Spacer(modifier = Modifier.height(16.dp))

        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.spacedBy(8.dp),
            modifier = widthModifier.align(Alignment.CenterHorizontally)
        ) {
            val countryCodeWidthModifier = when (windowSizeClass) {
                WindowWidthSizeClass.Compact -> Modifier.width(100.dp)
                WindowWidthSizeClass.Medium -> Modifier.width(115.dp)
                else -> Modifier.width(120.dp)
            }

            // Country code dropdown
            Box(
                modifier = countryCodeWidthModifier.wrapContentHeight()
            ) {
                OutlinedTextField(
                    value = countryCode,
                    onValueChange = { countryCode = it },
                    readOnly = true,
                    label = { Text(text = "Country") },
                    trailingIcon = {
                        IconButton(onClick = { isDropdownVisible = !isDropdownVisible }) {
                            Icon(
                                imageVector = Icons.Default.ArrowDropDown,
                                contentDescription = "Drop Down"
                            )
                        }
                    }
                )
                DropdownMenu(
                    expanded = isDropdownVisible,
                    onDismissRequest = { isDropdownVisible = false },
                    modifier = Modifier.width(250.dp)
                ) {
                    countries.forEach { country ->
                        DropdownMenuItem(onClick = {
                            countryCode = country
                            isDropdownVisible = false
                        }, text = {
                            Text(text = country)
                        })
                    }
                }
            }

            // TextField for entering mobile number
            OutlinedTextField(
                modifier = Modifier
                    .weight(1f)
                    .fillMaxWidth(),
                value = mobileNumber,
                onValueChange = {
                    val newText = it.text.filter { char -> char.isDigit() }
                    if (newText.length <= 10) {
                        mobileNumber = it.copy(text = newText)
                        isMobileNumberValid = newText.isNotEmpty()
                    }
                },
                maxLines = 1,
                isError = !isMobileNumberValid,
                label = { Text(text = "Mobile Number") },
                keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Phone),
                placeholder = { Text("Mobile number") }
            )
        }

        // Terms and privacy policy checkbox
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Start,
            modifier = widthModifier
        ) {
            Checkbox(
                checked = isChecked,
                onCheckedChange = { isChecked = it }
            )
            Text(text = "I agree to ", style = MaterialTheme.typography.bodyMedium)
            ClickableText(
                text = AnnotatedString(
                    text = "Terms & Condition", spanStyle = SpanStyle(
                        color = Color.Blue,
                    )
                ),
                onClick = { onClickTerms.invoke() },
                modifier = Modifier.padding(vertical = 4.dp),
                style = MaterialTheme.typography.bodyMedium
            )
        }

        // Promotional messages checkbox
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Start,
            modifier = widthModifier
        ) {
            Checkbox(
                checked = receivePromotionalMessages,
                onCheckedChange = { receivePromotionalMessages = it }
            )
            Text(
                text = "Get Update on",
                style = MaterialTheme.typography.bodyMedium
            )
            Image(
                painter = painterResource(Res.drawable.ic_whatsapp),
                contentDescription = null,
                modifier = Modifier
                    .width(25.dp)
                    .size(Scale.GLOBAL_ICON_SIZE)
                    .padding(start = 4.dp)
            )
            Text(
                text = "WhatsApp",
                style = MaterialTheme.typography.bodyMedium,
                fontSize = 12.sp,
                modifier = Modifier.padding(4.dp)
            )
        }

        // State to manage loading status
        val isLoading by remember { mutableStateOf(false) }

        // Submit button
        CommonLoadingButton(
            text = "Sign In/ Sign Up",
            isLoading = isLoading,
            onClick = {
                if (mobileNumber.text.trim().isEmpty()) {
                    isMobileNumberValid = false
                    showToast("Mobile number cannot be empty")
                    return@CommonLoadingButton
                }

                if (mobileNumber.text.length != 10) {
                    showToast("Mobile number must be 10 digits")
                    return@CommonLoadingButton
                }

                onClickSubmit(mobileNumber.text, countryCode, receivePromotionalMessages)
            },
            modifier = widthModifier
        )

        Spacer(modifier = Modifier.height(16.dp))
    }
}







//@Composable
//fun LoginInputBox(
//    onClickSubmit: (String, String, Boolean) -> Unit,
//    onClickTerms: () -> Unit
//) {
//    var countryCode by remember { mutableStateOf("+91") }
//    var mobileNumber by rememberSaveable { mutableStateOf(TextFieldValue()) }
//
//    val countries = listOf("+1", "+91", "+44", "+86") // List of country codes
//    var isDropdownVisible by remember { mutableStateOf(false) }
//    var isChecked by remember { mutableStateOf(true) }
//    var receivePromotionalMessages by remember { mutableStateOf(true) }
//    var isMobileNumberValid by remember { mutableStateOf(false) }
//
//    Column(
//        horizontalAlignment = Alignment.Start,
//        modifier = Modifier
//            .fillMaxWidth()
//            .wrapContentHeight()
//    ) {
//        Row(
//            verticalAlignment = Alignment.CenterVertically,
//            horizontalArrangement = Arrangement.Center,
//            modifier = Modifier.padding(horizontal = 12.dp).fillMaxWidth()
//        ) {
//            // Dropdown for selecting country code
//            Box(
//                modifier = Modifier.width(100.dp).wrapContentHeight()
//            ) {
//                OutlinedTextField(
//                    value = countryCode,
//                    onValueChange = { countryCode = it },
//                    readOnly = true,
//                    label = { Text(text = "Country") },
//                    trailingIcon = {
//                        IconButton(onClick = { isDropdownVisible = !isDropdownVisible }) {
//                            Icon(
//                                imageVector = Icons.Default.ArrowDropDown,
//                                contentDescription = "Drop Down"
//                            )
//                        }
//                    }
//                )
//                DropdownMenu(
//                    expanded = isDropdownVisible, // Show dropdown based on boolean state
//                    onDismissRequest = { isDropdownVisible = false }, // Dismiss on outside click
//                    modifier = Modifier.width(200.dp)
//                ) {
//                    countries.forEach { country ->
//                        val countryWithoutPrefix = country.removePrefix("+")
//                        DropdownMenuItem(onClick = {
//                            countryCode = countryWithoutPrefix
//                            isDropdownVisible = false // Close the dropdown menu after selection
//                        }, text = {
//                            Text(text = country)
//                        })
//                    }
//                }
//            }
//
//            Spacer(modifier = Modifier.width(16.dp))
//            // TextField for entering mobile number
//            OutlinedTextField(
//                modifier = Modifier.weight(1f).wrapContentHeight(),
//                value = mobileNumber,
//                onValueChange = {
//                    if (it.text.length <= 10) {
//                        mobileNumber = it
//                        if (it.text.isNotEmpty()) {
//                            isMobileNumberValid = true
//                        }
//                    }
//                },
//                maxLines = 1,
//                isError = isMobileNumberValid.not(),
//                label = { Text(text = "Mobile Number") },
//                keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Phone),
//                placeholder = { Text("Enter your mobile number") }
//            )
//
////            if (isMobileNumberValid.not()) {
////                Text(
////                    text = "Mobile number cannot be empty",
////                    color = MaterialTheme.colorScheme.error,
////                    style = MaterialTheme.typography.bodySmall,
////                    modifier = Modifier.padding(start = 12.dp, top = 4.dp)
////                )
////            }
//        }
//
//        // Checkbox for accepting privacy policy
//        Row(
//            verticalAlignment = Alignment.CenterVertically,
//        ) {
//            // Checkbox for accepting terms and privacy policy
//            Checkbox(
//                checked = isChecked,
//                onCheckedChange = { isChecked = it },
//            )
//
//            // Text for terms and privacy policy
//            Text(text = "I agree to ", style = MaterialTheme.typography.titleMedium)
//
//            // Clickable text for privacy policy
//            ClickableText(
//                text = AnnotatedString(
//                    text = "Terms & Condition", spanStyle = SpanStyle(
//                        color = Color.Blue,
//                        fontSize = 14.sp
//                    )
//                ),
//                onClick = {onClickTerms.invoke()},
//                modifier = Modifier.padding(start = 4.dp),
//            )
//        }
//
//        // Checkbox for receiving promotional messages
//        Row(
//            verticalAlignment = Alignment.CenterVertically,
//        ) {
//            Checkbox(
//                checked = receivePromotionalMessages,
//                onCheckedChange = { receivePromotionalMessages = it },
//            )
//
//            Text(
//                text = "Get Update on",
//                style = MaterialTheme.typography.titleMedium
//            )
//
//            // WhatsApp icon
//            Image(
//                painter = painterResource(Res.drawable.ic_whatsapp),
//                contentDescription = null, // Provide proper content description
//                modifier = Modifier
//                    .width(35.dp)
//                // Add other constraints as needed
//            )
//
//            // WhatsApp text
//            Text(
//                text = "WhatsApp",
//                style = MaterialTheme.typography.titleMedium,
//                // Add other constraints as needed
//            )
//        }
//
//        Spacer(modifier = Modifier.height(12.dp))
//
//        // State to manage loading status
//        var isLoading by remember { mutableStateOf(false) }
//
//        Button(
//            onClick = {
//
//                val data = settings.getString("Test_key", "Not Found!")
//                Logger.d("Shared-Preference Data -> $data")
//
//                if (mobileNumber.text.trim().isEmpty()) {
//                    isMobileNumberValid = false
//                    showToast("Mobile number cannot be empty")
//                    return@Button
//                }
//
//                if (mobileNumber.text.length != 10) {
//                    showToast("Mobile number must be 10 digits")
//                    return@Button
//                }
//
//                onClickSubmit(mobileNumber.text, countryCode, receivePromotionalMessages)
//
////                CoroutineScope(Dispatchers.Main).launch {
////                    isLoading = true
////                    try {
////                        val response = AuthApiService().requestLoginOtp(
////                            RequestOtpRequest(
////                                mobileCode = countryCode,
////                                mobile = mobileNumber.text,
////                                type = "user",
////                                whatsappUpdate = if (receivePromotionalMessages) 1 else 0
////                            )
////                        )
////                        if (response.data?.status == true) {
////                            onClickSubmit(mobileNumber.text, countryCode, receivePromotionalMessages)
////                        } else {
////                            storeLogger.e(response.msg.toString())
////                        }
////                    } catch (e: Exception) {
////                        storeLogger.e("Error: ${e.message}")
////                    } finally {
////                        isLoading = false
////                    }
////                }
//            },
//            modifier = Modifier
//                .align(Alignment.End)
//                .fillMaxWidth()
//                .padding(horizontal = 8.dp)
//        ) {
//            if (isLoading) {
//                CircularProgressIndicator(
//                    modifier = Modifier
//                        .size(24.dp)
//                        .align(Alignment.CenterVertically),
//                    color = Color.White,
//                    strokeWidth = 5.dp
//                )
//            } else {
//                Text(text = "Sign In/ Sign Up")
//            }
//        }
//
//        Spacer(modifier = Modifier.height(24.dp))
//    }
//}


@Composable
fun TermsPrivacyPolicyBox(
    isChecked: Boolean,
    onCheckedChange: (Boolean) -> Unit
) {
//    val context = CompositionLocalContext
//    val openBrowser = rememberLauncherForActivityResult(ActivityResultContracts.StartActivityForResult()) { }

    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier.padding(16.dp)
    ) {
        // Checkbox for accepting terms and privacy policy
        Checkbox(
            checked = isChecked,
            onCheckedChange = onCheckedChange,
            modifier = Modifier.padding(end = 8.dp)
        )

        // Text for terms and privacy policy
        Text(text = "I accept the ")

        // Clickable text for privacy policy
        Text(
            text = AnnotatedString("Privacy Policy"),
            modifier = Modifier.clickable {
                // Open the privacy policy link in browser
                val privacyPolicyUrl = "https://example.com/privacy-policy"
//                val intent = Intent(Intent.ACTION_VIEW, Uri.parse(privacyPolicyUrl))
//                openBrowser.launch(intent)
            },
            style = TextStyle(
                color = Color.Blue,
                textDecoration = TextDecoration.Underline
            )
        )
    }
}

private val storeLogger = Logger(
    loggerConfigInit(platformLogWriter(NoTagFormatter)),
    "StoreApi"
)



