package com.winiwayuser.feature_expert_consultation.presentation

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import co.touchlab.kermit.Logger
import coil3.compose.AsyncImage
import com.winiwayuser.core.persentation.HomePageLoadingProgressDialog
import com.winiwayuser.core.theme.background_color
import com.winiwayuser.feature_expert_consultation.expert_list_state_info.ExpertListState


@Composable
fun ExpertListPage(
    component: ExpertListPageComponent
) {
        val state by component.state.collectAsState()

        when (state) {
            is ExpertListState.Loading -> {
                Box(
                    modifier = Modifier
                        .fillMaxSize()
                        .background(background_color),
                    contentAlignment = Alignment.Center
                ) {
                    HomePageLoadingProgressDialog(
                        onDismissRequest = {  },
                        backgroundColor = Color(0xFF2A2A2A),
                        loadingColor = Color.White
                    )
                }
            }
            is ExpertListState.Success -> {
                ExpertListSuccessPage(consultants = ((state as ExpertListState.Success).consultants), component =  component)
            }
            is ExpertListState.Error -> {
                // Show an error message or some error UI here
                Box(
                    modifier = Modifier
                        .fillMaxSize()
                        .background(Color(0xFF2A2A2A)), // Adjusted color
                    contentAlignment = Alignment.Center
                ) {
                    Text(
                        text = "Error: ${(state as ExpertListState.Error).message}",
                        color = Color.Red,
                        style = TextStyle(fontSize = 16.sp)
                    )
                }
            }
        }
}
