package com.winiwayuser.feature_common.persentation.home_tab


import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import com.winiwayuser.core.local.UserInfoProvider
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_common.data.remote.FatchWalletBalenceApiService
import com.winiwayuser.feature_common.data.remote.HomeApiService
import com.winiwayuser.feature_common.data.request.RequestCategory
import com.winiwayuser.feature_common.data.request.RequestConsultant
import com.winiwayuser.feature_common.data.request.RequestSlider
import com.winiwayuser.feature_common.data.response.RequestUpcomingLiveSessionResponse
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.async
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch

class HomeScreenTabComponent(
    context: ComponentContext,
    private val onHomePageEvent: (HomePageEvent) -> Unit
) : ComponentContext by context {

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    private val api = HomeApiService()
    private val walletBalenceApi = FatchWalletBalenceApiService()

    private val _liveSessionState = MutableStateFlow<RequestUpcomingLiveSessionResponse?>(null)
    val liveSessionState: StateFlow<RequestUpcomingLiveSessionResponse?> get() = _liveSessionState

    private val _topCategory = MutableStateFlow<List<RequestCategory>?>(null)
    val topCategory: StateFlow<List<RequestCategory>?> get() = _topCategory

    private val _topConsultant = MutableStateFlow<List<RequestConsultant>?>(null)
    val topConsultant: StateFlow<List<RequestConsultant>?> get() = _topConsultant

    private val _slidersData = MutableStateFlow<List<RequestSlider>?>(null)
    val slidersData: StateFlow<List<RequestSlider>?> get() = _slidersData

    private val _walletState = MutableStateFlow(UserInfoProvider.getTotalWalletMoney())
    val walletState: StateFlow<String> get() = _walletState

    private val _isLoadingComplete = MutableStateFlow(true)
    val isLoadingComplete: StateFlow<Boolean> get() = _isLoadingComplete

    init {
        Logger.d { "Home Screen Tab Component initialized" }

        lifecycle.doOnStart {
            Logger.d { "Home Screen Tab Component onStart" }
            fetchData()
            if (UserInfoProvider.getIsLogin()) {
                Logger.d { "Home Screen Tab Component onStart in if part " }
                fetchWalletBalance()
            }
        }

        lifecycle.doOnDestroy {
            Logger.d { "Home Screen Tab Component onDestroy" }
            coroutineScope.cancel() // Cancel all coroutines when the component is destroyed
        }
    }

    private fun fetchWalletBalance() {
        coroutineScope.launch {
            val response = walletBalenceApi.getWalletMoney()
            if (response is Resources.Success) {
                val totalWalletMoney = response.data?.toIntOrNull()?.toString() ?: response.data?.toFloatOrNull().toString() ?: "0"
                UserInfoProvider.setTotalWalletMoney(totalWalletMoney)
                _walletState.value = totalWalletMoney
               // _walletState.value = UserInfoProvider.getTotalWalletMoney()
                Logger.d { "fetchWalletBalance:- $totalWalletMoney" }
            }

        }
    }

    private fun fetchData() {
        coroutineScope.launch {
            try {

                if (UserInfoProvider.getIsLogin()) {
                    val walletBalenceResponse = async { walletBalenceApi.getWalletMoney() }
                    if (walletBalenceResponse.await() is Resources.Success) {
                        val totalWalletMoney =
                            walletBalenceResponse.await().data?.toIntOrNull()?.toString()
                                ?: walletBalenceResponse.await().data?.toFloatOrNull().toString()
                                ?: "0"
                        UserInfoProvider.setTotalWalletMoney(totalWalletMoney)
                        _walletState.value = totalWalletMoney
                        // _walletState.value = UserInfoProvider.getTotalWalletMoney()
                        Logger.d { "fetchData:- $totalWalletMoney" }
                    }
                }

                val sliderResult = async { api.requestHomeScreenSlider() }
                if (sliderResult.await() is Resources.Success) {
                    _slidersData.value = sliderResult.await().data?.data?.slider
                }

                val liveSessionResult = async { api.requestHomeScreenUpcomingLiveSessions() }
                if (liveSessionResult.await() is Resources.Success) {
                    _liveSessionState.value = liveSessionResult.await().data
                }

                val categoryResult = async { api.requestHomeScreenCategory() }
                if (categoryResult.await() is Resources.Success) {
                    _topCategory.value = categoryResult.await().data?.categories?.filter {
                        it.name in listOf(
                            "Astrologer",
                            "Mental Wellness Therapist",
                            "Scientific Astrology"
                        )
                    }
                }

                val topConsultantResult = async { api.requestHomeScreenTopConsultant() }
                if (topConsultantResult.await() is Resources.Success) {
                    _topConsultant.value = topConsultantResult.await().data?.data?.topConsultants
                }

                // After all data is fetched, mark loading as complete
                _isLoadingComplete.value = false

            } catch (e: Exception) {
                Logger.e(e) { "Error fetching data: ${e.message}" }
                // Ensure that loading is marked complete even if there is an error
                _isLoadingComplete.value = false
            }
        }
    }

    fun event(event: HomePageEvent) {
        onHomePageEvent.invoke(event)
    }
}
