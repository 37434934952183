package com.winiwayuser.feature_expert_consultation.presentation

import com.winiwayuser.feature_authentication.persentation.otp_validation_page.OtpValidationEvent

sealed class ExpertListPageEvent {
    data object OnErrorBottomSheetCallConsultation : ExpertListPageEvent()
    data object OnExpertDetailsPage : ExpertListPageEvent()
    data object OnBackPressed : ExpertListPageEvent()
    data class OnSubCategoryClick(val subCategoryName: String) : ExpertListPageEvent()
    data object OnSearchFieldClick : ExpertListPageEvent()
    data class onExpertCardClick(val expertId: Int) : ExpertListPageEvent()
    data class onCallClick(
        val expertId: String,
        val  expertName: String,
        val expertImage: String,
        val expertSkills: String) : ExpertListPageEvent()
    data class onChatCick(
        val expertId: String,
        val  expertName: String,
        val expertImage: String,
        val expertSkills: String) : ExpertListPageEvent()
}