package com.winiwayuser.core.persentation


import com.russhwolf.settings.Settings
import com.russhwolf.settings.StorageSettings
import kotlinx.browser.localStorage
import kotlinx.browser.sessionStorage
import kotlinx.browser.window

actual fun getDeviceType(): DeviceType {

    val screenWidthPx = window.innerWidth

    return when {
        screenWidthPx <= 1280 -> DeviceType.WEB_HD // WEB_HD
        screenWidthPx <= 1920 -> DeviceType.WEB_FULL_HD // WEB_FULL_HD
        screenWidthPx <= 2560 -> DeviceType.WEB_2K // WEB_2K
        screenWidthPx <= 3840 -> DeviceType.WEB_4K // WEB_4K
        else -> DeviceType.WEB_UD  // WEB_UD
    }
}

actual fun showToast(message: String) {
    window.alert(message)
}

actual val settings: Settings
    get() = StorageSettings()

actual fun openPlayStore(url: String) {
    window.open(url, "_blank")
}

actual fun clearCaptchaData() {
}

actual fun clearSessionOrCache() {
    // Clear localStorage
    js("localStorage.clear()")

    // Clear sessionStorage
    js("sessionStorage.clear()")

    // Clear cookies (if session or cache is stored in cookies)
    js("document.cookie.split(';').forEach(function(c) { document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/'); });")
}

actual fun refreshPage() {
    window.document.cookie = ""
    window.sessionStorage.clear()
    window.localStorage.clear()
    window.location.href = window.location.href + "?refresh=" + js("Date.now()")
}

actual fun clearData() {
    console.log("Attempting to clear data...")
    if (js("typeof localStorage !== 'undefined'") as Boolean) {
        console.log("Clearing localStorage...")
        localStorage.clear()
    } else {
        console.log("localStorage is not available!")
    }

    if (js("typeof sessionStorage !== 'undefined'") as Boolean) {
        console.log("Clearing sessionStorage...")
        sessionStorage.clear()
    } else {
        console.log("sessionStorage is not available!")
    }
}
