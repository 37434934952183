package com.winiwayuser.feature_authentication.persentation.register_page

import com.arkivanov.decompose.ComponentContext
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_authentication.data.request.RegisterRequest
import com.winiwayuser.feature_authentication.domain.RegistrationUserUseCases
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.collectLatest
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class RegisterComponent(
    private val context: ComponentContext,
    private val onRegistration:()  -> Unit
):ComponentContext by context, KoinComponent {


    private val userRegistrationUseCases: RegistrationUserUseCases by inject()
    private val _state = MutableStateFlow<Resources<Boolean>>(Resources.Loading(false))
    val state get() = _state


    suspend fun onRegisterUser(userName: String, referalCode:String?){
        val request = RegisterRequest(
            fullName = userName,
            referCode = referalCode
        )

        userRegistrationUseCases.onRegisterUser(request).collectLatest {
            when(it){
                is Resources.Error -> _state.value = Resources.Error(it.msg)
                is Resources.Loading -> _state.value = Resources.Loading(it.isLoading)
                is Resources.Success -> {
                    _state.value = Resources.Success(it.data)
                    delay(1000)
                    onRegistration.invoke()
                }
            }
        }


    }

}