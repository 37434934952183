package com.winiwayuser.core.persentation


import com.russhwolf.settings.Settings
import com.russhwolf.settings.StorageSettings
import kotlinx.browser.window

actual fun getDeviceType(): DeviceType {

    val screenWidthPx = window.innerWidth

    return when {
        screenWidthPx <= 1280 -> DeviceType.WEB_HD // WEB_HD
        screenWidthPx <= 1920 -> DeviceType.WEB_FULL_HD // WEB_FULL_HD
        screenWidthPx <= 2560 -> DeviceType.WEB_2K // WEB_2K
        screenWidthPx <= 3840 -> DeviceType.WEB_4K // WEB_4K
        else -> DeviceType.WEB_UD  // WEB_UD
    }
}

actual fun showToast(message: String) {
    window.alert(message)
}

actual val settings: Settings
    get() = StorageSettings()

actual fun openPlayStore(url: String) {
    window.open(url, "_blank")
}