package com.winiwayuser.feature_chat_consultation_new.persentation.chat_concern.widget

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material.icons.filled.ArrowDropUp
import androidx.compose.material3.Button
import androidx.compose.material3.DropdownMenuItem
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.ExposedDropdownMenuBox
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.onFocusChanged
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.unit.dp
import com.winiwayuser.core.date_time.CommonDatePickerDialog
import com.winiwayuser.core.date_time.CommonTimePickerDialog
import com.winiwayuser.core.date_time.convertMillisToDate
import com.winiwayuser.core.date_time.setAmPmByTime
import com.winiwayuser.feature_chat_consultation_new.data.response.geo_details.GeoName
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_concern.ChatConcernComponent

@Composable
fun OutlinedInputField(
    modifier: Modifier,
    label: String,
    inputType: KeyboardType,
    maxLength: Int,
    isRequired: Boolean,
    isMultiLine: Boolean = false,
    onValueChange: (String) -> Unit
) {
    var text by remember { mutableStateOf("") }

    OutlinedTextField(
        value = text,
        onValueChange = {
            if (it.trim().isEmpty().not()) {
                onValueChange.invoke(it)
                text = it
            }
//            if (it.length <= maxLength) text = it
        },
        label = {
            val finalText = if (isRequired) "$label (Required)" else label
            Text(finalText)
        },
        keyboardOptions = KeyboardOptions.Default.copy(
            keyboardType = inputType,
            imeAction = if (isMultiLine) ImeAction.Default else ImeAction.Done
        ),
        maxLines = if (isMultiLine) 5 else 1,
        modifier = modifier,
        singleLine = !isMultiLine,
    )
}


@Composable
fun DocumentPickerField(
    modifier: Modifier,
    label: String,
    isRequired: Boolean,
    onValueChange: (String) -> Unit
) {
    val selectedDocument by remember { mutableStateOf("") }

    Column(modifier = modifier.padding(8.dp)) {
        Text(text = label, style = MaterialTheme.typography.bodySmall)
        Button(onClick = { /* Implement logic to open document picker */ }) {
            Text(text = selectedDocument.ifEmpty { "Select Document" })
        }
        if (isRequired && selectedDocument.isEmpty()) {
            Text(text = "This field is required", color = MaterialTheme.colorScheme.error)
        }
    }
}


@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun DatePickerField(
    modifier: Modifier,
    label: String,
    isRequired: Boolean,
    onValueChange: (String) -> Unit
) {
    val selectedDate = remember { mutableStateOf("") }
//    val datePickerState = rememberDatePickerState(initialDisplayMode = DisplayMode.Picker)
    val showDatePicker = rememberSaveable { mutableStateOf(false) }

    // Conditional display of the DatePickerDialog based on the openDialog state
    if (showDatePicker.value) {

        CommonDatePickerDialog(
            onDialogDismiss = {
                showDatePicker.value = false
            },
            selectedDate = { timeInMillies ->
                val date = timeInMillies?.convertMillisToDate()
                if (date.isNullOrEmpty().not()) {
                    onValueChange.invoke(date!!)
                    selectedDate.value = timeInMillies.convertMillisToDate() ?: ""
                }
            }
        )
    }

    OutlinedTextField(
        value = selectedDate.value,
        onValueChange = {},
        label = { Text(label) },
        modifier = modifier.clickable {
            showDatePicker.value = true
        },
        readOnly = true,
        enabled = false,
        singleLine = true,
    )
}


@Composable
fun TimePickerField(
    modifier: Modifier,
    label: String,
    isRequired: Boolean,
    onValueChange: (String) -> Unit
) {
    val selectedTime = remember { mutableStateOf("") }
    var showDatePicker by rememberSaveable { mutableStateOf(false) }

    if (showDatePicker) {
        CommonTimePickerDialog(
            is24Hour = true,
            selectedTime = { hour, minute ->
//                val time = setAmPmByTime(hour, minute)
                val time = "$hour:$minute"
                onValueChange.invoke(time)
                selectedTime.value = time
            },
            onDialogDismiss = {
                showDatePicker = false
            }
        )
    }

    OutlinedTextField(
        value = selectedTime.value,
        onValueChange = {},
        label = { Text(label) },
        modifier = modifier
            .clickable {
                showDatePicker = true
            },
        readOnly = true,
        isError = isRequired && selectedTime.value.isEmpty(),
        enabled = false,
        singleLine = true
    )
}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun DropdownField(
    component: ChatConcernComponent,
    modifier: Modifier = Modifier,
    label: String,
    isRequired: Boolean,
    onSelectedItem: (GeoName) -> Unit
) {
    // Collect the town suggestions from the component's state
    val townState by component.stateTown.collectAsState()

    var expanded by remember { mutableStateOf(false) }
    var text by remember { mutableStateOf("") }

    // Add "Required" label if needed
    val finalLabel = if (isRequired) "$label (Required)" else label

    // Wrap the dropdown functionality in ExposedDropdownMenuBox
    ExposedDropdownMenuBox(
        expanded = expanded,
        onExpandedChange = { expanded = !expanded } // Toggle dropdown expansion
    ) {
        // OutlinedTextField to input text
        OutlinedTextField(
            value = text,
            onValueChange = { inputText ->
                text = inputText
                if (inputText.length >= 3) {
                    component.onChangeTownInput(inputText) // Trigger API call after 3 characters
                }
                expanded = townState?.isNotEmpty() == true // Show dropdown when there are suggestions
            },
            label = { Text(finalLabel) },
            trailingIcon = {
                Icon(
                    imageVector = if (expanded) Icons.Filled.ArrowDropUp else Icons.Filled.ArrowDropDown,
                    contentDescription = null
                )
            },
            modifier = modifier
                .menuAnchor() // Attach the dropdown menu to the text field
                .onFocusChanged { focusState ->
                    // Expand when the text field gains focus and there are suggestions
                    if (focusState.isFocused && townState?.isNotEmpty() == true) {
                        expanded = true
                    }
                },
            singleLine = true // Set to single-line for better UX
        )

        // Dropdown menu to display suggestions
        ExposedDropdownMenu(
            expanded = expanded,
            onDismissRequest = { expanded = false } // Close the dropdown on dismiss
        ) {
            townState?.forEach { suggestion ->
                DropdownMenuItem(
                    text = { Text(suggestion.placeName) },
                    onClick = {
                        text = suggestion.placeName // Set the selected suggestion in the text field
                        onSelectedItem(suggestion) // Call the callback with the selected item
                        expanded = false // Close the dropdown after selection
                    }
                )
            }
        }
    }
}


//@OptIn(ExperimentalMaterial3Api::class)
//@Composable
//fun DropdownField(
//    component: ChatConcernComponent,
//    modifier: Modifier = Modifier,
//    label: String,
//    isRequired: Boolean,
//    onSelectedItem: (String) -> Unit
//) {
//
//    // Collect the town suggestions from the component state
//    val townState by component.stateTown.collectAsState()
//
//    var expanded by remember { mutableStateOf(false) }
//    var text by remember { mutableStateOf("") }
//
//    // This defines the appearance of the label, adding "Required" if needed
//    val finalLabel = if (isRequired) "$label (Required)" else label
//
//    ExposedDropdownMenuBox(
//        expanded = if (townState.isNullOrEmpty().not()) expanded else expanded.not(),
//        onExpandedChange = { expanded = !expanded }
//    ) {
//        OutlinedTextField(
//            value = text,
//            onValueChange = {
//                text = it // Update the text state with user input
//                // Trigger action in the component
//                if (it.length >= 3) {
//                    component.onChangeTownInput(text)
//                }
//                if (townState.isNullOrEmpty().not()) {
//                    expanded = true
//                } // Expand the dropdown when text is entered
//            },
//            label = { Text(finalLabel) },
//            trailingIcon = {
//                Icon(Icons.Default.ArrowDropDown, contentDescription = null)
//            },
//            modifier = modifier
//                .menuAnchor() // Attach dropdown to the text field
//        )
//
//        ExposedDropdownMenu(
//            expanded = expanded,
//            onDismissRequest = { expanded = false }
//        ) {
//            townState?.forEach { suggestion ->
//                DropdownMenuItem(
//                    text = { Text(suggestion.placeName) },
//                    onClick = {
//                        text = suggestion.placeName // Update the text with the selected item
//                        onSelectedItem(suggestion.placeName) // Trigger the external callback
//                        expanded = false // Collapse the dropdown
//                    }
//                )
//            }
//        }
//    }
//}






