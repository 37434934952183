package com.winiwayuser.feature_common.data.remote

import com.winiwayuser.core.remote.KtorApi
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_common.data.remote.HomeApiService.Companion
import com.winiwayuser.feature_common.data.response.wallet_balance.WalletRupeeResponse
import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.ServerResponseException
import io.ktor.client.request.get
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext

class FatchWalletBalenceApiService:KtorApi() {

    companion object {
        const val GET_WALLET_MONEY = "get-wallet-balance"
    }


    suspend fun getWalletMoney(): Resources<String> {
        return withContext(Dispatchers.Default) {
            try {
                val topConsultantResponse =
                    client.get(HomeApiService.GET_WALLET_MONEY).body<WalletRupeeResponse>()

                if (topConsultantResponse.status.not()) {
                    return@withContext Resources.Error(
                        topConsultantResponse.message ?: "Unexpected Error"
                    )
                }

                return@withContext Resources.Success(topConsultantResponse.data.balance)

            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }
}