package com.winiwayuser.feature_common.persentation.home_tab.footer


import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.interaction.collectIsHoveredAsState
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Security
import androidx.compose.material.icons.filled.Verified
import androidx.compose.material3.Icon
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.input.pointer.positionChanged
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage
import coil3.compose.rememberAsyncImagePainter
import com.winiwayuser.core.persentation.openPlayStore
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.theme.Scale.WINYWAY_LOGO_SIZE
import com.winiwayuser.core.theme.Scale.footerHeightPx
import com.winiwayuser.core.theme.expert_app_play_store_url
import com.winiwayuser.core.theme.seed
import com.winiwayuser.core.theme.user_app_play_store_url
import com.winiwayuser.core.theme.winyway_facebook_page_url
import com.winiwayuser.core.theme.winyway_instagram_page_url
import com.winiwayuser.core.theme.winyway_linkedin_page_url
import com.winiwayuser.core.theme.winyway_youtube_page_url
import com.winiwayuser.feature_common.persentation.home_tab.HomePageEvent
import com.winiwayuser.feature_common.persentation.home_tab.HomeScreenTabComponent
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.facebook1
import com.winiwayuser.resources.facebook2
import com.winiwayuser.resources.footer_background
import com.winiwayuser.resources.google_play
import com.winiwayuser.resources.icon_logo
import com.winiwayuser.resources.instagram1
import com.winiwayuser.resources.instagram2
import com.winiwayuser.resources.linkedin1
import com.winiwayuser.resources.linkedin2
import com.winiwayuser.resources.youtube1
import com.winiwayuser.resources.youtube2
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource


@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun Footer(component: HomeScreenTabComponent) {

    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val heightModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> Modifier.height(650.dp)
        WindowWidthSizeClass.Medium -> Modifier.height(400.dp)
        WindowWidthSizeClass.Expanded -> Modifier.height(400.dp)
        else -> Modifier.height(400.dp)
    }

    // Logger.d("WindowSizeClass -> $windowSizeClass")
    BoxWithConstraints(
        modifier = heightModifier
            .fillMaxWidth()
          //  .wrapContentHeight()
    ) {
        val isWideScreen = maxWidth > 600.dp
        val dynamicFooterHeight = maxHeight.coerceAtLeast(footerHeightPx) // Use maxHeight to ensure dynamic sizing

        Image(
            painter = painterResource(Res.drawable.footer_background),
            contentDescription = null,
            modifier = Modifier.fillMaxSize(),
            contentScale = ContentScale.Crop
        )

        Column(
            modifier = Modifier
                .fillMaxSize()
//                .height(dynamicFooterHeight)
                .background(Color.Transparent) // To ensure the background image is visible
        ) {
            // Top section with logo and links
            Column(
                modifier = Modifier
                    .wrapContentHeight()
                    .padding(10.dp)
            ) {
                // Logo section
                Row(
                    modifier = if (isWideScreen) Modifier.fillMaxWidth() else Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = if (isWideScreen) Arrangement.Start else Arrangement.Center
                ) {
                    Image(
                        painter = painterResource(Res.drawable.icon_logo),
                        contentDescription = "Logo",
                        modifier = Modifier.size(WINYWAY_LOGO_SIZE),
                        //colorFilter = ColorFilter.tint(seed)
                    )
                    Text(
                        text = "Winyway",
                        fontSize = 16.sp,
                        color = seed,
                        fontWeight = FontWeight.Bold,
                        modifier = Modifier.padding(start = 8.dp)
                    )
                }

                // Paragraph below the logo
                Text(
                    text = "Welcome to our website! We offer a variety of services to help you succeed. Explore our offerings and get in touch with us!",
                    color = Color.White.copy(alpha = 0.8f),
                    fontSize = 14.sp,
                    modifier = Modifier.padding(top = 15.dp),
                    maxLines = 5,
                    overflow = TextOverflow.Ellipsis,
                    textAlign = TextAlign.Center
                )

                Spacer(modifier = Modifier.height(if (isWideScreen) 16.dp else 10.dp))

                // Links section for wide screens
                if (isWideScreen) {
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        horizontalArrangement = Arrangement.SpaceBetween
                    ) {
                        LinkBox(
                            title = "Quick Links",
                            links = listOf("Terms and Conditions", "Privacy Policy", "Contact Us", "Landing Page"),
                            navigateTo = { link ->
                                when (link) {
                                    "Terms and Conditions" -> {
                                        component.event(HomePageEvent.OnClickTermsAndConditions)
                                    }

                                    "Privacy Policy" -> {
                                        component.event(HomePageEvent.OnClickPrivacyPolicy)
                                    }

                                    "Contact Us" -> {
                                        // Navigate to Contact Us page
                                    }

                                    "Landing Page" ->{
                                        component.event(HomePageEvent.onLandingPageClick)
                                    }
                                }
                            },
                            imageItems = listOf(),
                            onImageClick = { _, _, _ -> }
                        )

                        LinkBox(
                            title = "Support",
                            links = listOf("Winyway Review", "Blog", "Support@winyway.com"),
                            navigateTo = { link -> /* Handle navigation */ },
                            imageItems = listOf(
                                ImageItem(
                                    defaultPainter = painterResource(Res.drawable.facebook1),
                                    hoverPainter = painterResource(Res.drawable.facebook2),
                                    identifier = "facebook"
                                ),
                                ImageItem(
                                    defaultPainter = painterResource(Res.drawable.instagram1),
                                    hoverPainter = painterResource(Res.drawable.instagram2),
                                    identifier = "instagram"
                                ),
                                ImageItem(
                                    defaultPainter = painterResource(Res.drawable.linkedin1),
                                    hoverPainter = painterResource(Res.drawable.linkedin2),
                                    identifier = "linkedin"
                                ),
                                ImageItem(
                                    defaultPainter = painterResource(Res.drawable.youtube1),
                                    hoverPainter = painterResource(Res.drawable.youtube2),
                                    identifier = "youtube"
                                )
                            ),
                            onImageClick = { defaultPainter, hoverPainter, identifier ->
                                when (identifier) {
                                    "facebook" -> {
                                        openPlayStore(winyway_facebook_page_url)
                                    }
                                    "instagram" -> {
                                        openPlayStore(winyway_instagram_page_url)
                                    }
                                    "linkedin" -> {
                                        openPlayStore(winyway_linkedin_page_url)
                                    }
                                    "youtube" -> {
                                        openPlayStore(winyway_youtube_page_url)
                                    }
                                }
                            }
                        )

                        AppLinkBox(
                            title = "Expert APP",
                            links = listOf(
                                "Play Store" to Res.drawable.google_play,
                                // "App Store" to Res.drawable.app_stor
                            )
                        ) { link ->
                            when (link) {
                                "Play Store" -> {
                                    openPlayStore(expert_app_play_store_url)
                                }

                                //"App Store" -> {}
                            }
                        }

                        AppLinkBox(
                            title = "User APP",
                            links = listOf(
                                "Play Store" to Res.drawable.google_play,
                                //"App Store" to Res.drawable.app_stor
                            )
                        ) { link ->
                            when (link) {
                                "Play Store" -> {
                                    openPlayStore(user_app_play_store_url)
                                }

                                //"App Store" -> {}
                            }
                        }

                        Column(
                            modifier = Modifier
                                .wrapContentWidth()
                                .padding(10.dp),
                            verticalArrangement = Arrangement.spacedBy(8.dp) // Adds space between each Box
                        ) {
                            // Verified Expert Astrologers Section
                            TrustIndicatorBox(
                                icon = Icons.Default.Verified,
                                text = "Verified Expert Astrologers"
                            )

                            // Secure Payments Section
                            TrustIndicatorBox(
                                icon = Icons.Default.Security,
                                text = "100% Secure Payments"
                            )

                        }
                    }
                } else {
                    // Use a LazyVerticalGrid with 2 columns
                    LazyVerticalGrid(
                        columns = GridCells.Fixed(2),
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(5.dp),
                        verticalArrangement = Arrangement.spacedBy(5.dp),
                        userScrollEnabled = false
                    ) {
                        item {
                            LinkBox(
                                title = "Quick Links",
                                links = listOf("Terms and Conditions", "Privacy Policy", "Contact Us", "Landing Page"),
                                navigateTo = { link ->
                                    when (link) {
                                        "Terms and Conditions" -> {
                                            component.event(HomePageEvent.OnClickTermsAndConditions)
                                        }

                                        "Privacy Policy" -> {
                                            component.event(HomePageEvent.OnClickPrivacyPolicy)
                                        }

                                        "Contact Us" -> {
                                            // Navigate to Contact Us page
                                        }

                                        "Landing Page" -> {
                                            component.event(HomePageEvent.onLandingPageClick)
                                        }
                                    }
                                },
                                imageItems = listOf(),
                                onImageClick = { _, _, _ -> }
                            )
                        }
                        item {
                            LinkBox(
                                title = "Support",
                                links = listOf("Winyway Review", "Blog", "Support@winyway.com"),
                                navigateTo = { link -> /* Handle navigation */ },
                                imageItems = listOf(
                                    ImageItem(
                                        defaultPainter = painterResource(Res.drawable.facebook1),
                                        hoverPainter = painterResource(Res.drawable.facebook2),
                                        identifier = "facebook"
                                    ),
                                    ImageItem(
                                        defaultPainter = painterResource(Res.drawable.instagram1),
                                        hoverPainter = painterResource(Res.drawable.instagram2),
                                        identifier = "instagram"
                                    ),
                                    ImageItem(
                                        defaultPainter = painterResource(Res.drawable.linkedin1),
                                        hoverPainter = painterResource(Res.drawable.linkedin2),
                                        identifier = "linkedin"
                                    ),
                                    ImageItem(
                                        defaultPainter = painterResource(Res.drawable.youtube1),
                                        hoverPainter = painterResource(Res.drawable.youtube2),
                                        identifier = "youtube"
                                    )
                                ),
                                onImageClick = { defaultPainter, hoverPainter, identifier ->
                                    when (identifier) {
                                        "facebook" -> {
                                            openPlayStore(winyway_facebook_page_url)
                                        }
                                        "instagram" -> {
                                            openPlayStore(winyway_instagram_page_url)
                                        }
                                        "linkedin" -> {
                                            openPlayStore(winyway_linkedin_page_url)
                                        }
                                        "youtube" -> {
                                            openPlayStore(winyway_youtube_page_url)
                                        }
                                    }
                                }
                            )
                        }
                        item {
                            AppLinkBox(
                                title = "Expert APP",
                                links = listOf(
                                    "Play Store" to Res.drawable.google_play,
                                    //  "App Store" to Res.drawable.app_stor
                                )
                            ) { link ->
                                when (link) {
                                    "Play Store" -> {
                                        openPlayStore(expert_app_play_store_url)
                                    }

                                    // "App Store" -> {}
                                }
                            }
                        }

                        item {
                            AppLinkBox(
                                title = "User App",
                                links = listOf(
                                    "Play Store" to Res.drawable.google_play,
                                   // "App Store" to Res.drawable.app_stor
                                )
                            ) { link ->
                                when (link) {
                                    "Play Store" -> {
                                        openPlayStore(user_app_play_store_url)
                                    }

                                    //"App Store" -> {}
                                }
                            }
                        }

                        item {
                            Row(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .wrapContentHeight()
                                    .padding(5.dp),
                                horizontalArrangement = Arrangement.spacedBy(2.dp) // Adds space between each Box
                            ) {
                                // Verified Expert Astrologers Section
                                TrustIndicatorBox(
                                    icon = Icons.Default.Verified,
                                    text = "Verified Expert Astrologers"
                                )

//                                // Secure Payments Section
//                                TrustIndicatorBox(
//                                    icon = Icons.Default.Security,
//                                    text = "100% Secure \n Payments"
//                                )
                            }
                        }
                        item {
                            Row(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .wrapContentHeight()
                                    .padding(5.dp),
                                horizontalArrangement = Arrangement.spacedBy(2.dp) // Adds space between each Box
                            ) {
//                                // Verified Expert Astrologers Section
//                                TrustIndicatorBox(
//                                    icon = Icons.Default.Verified,
//                                    text = "Verified Expert \n Astrologers"
//                                )

                                // Secure Payments Section
                                TrustIndicatorBox(
                                    icon = Icons.Default.Security,
                                    text = "100% Secure Payments"
                                )
                            }
                        }
                    }
                }
            }

            Spacer(modifier = Modifier.weight(0.1f))

            // Bottom details

            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .background(Color.Black)
                    .height(if (isWideScreen) 30.dp else 25.dp),
                    //.padding(vertical = if (isWideScreen) 10.dp else 5.dp)
            ) {
                Row(
                    modifier = Modifier
                        .align(Alignment.Center)
                        .fillMaxSize(),
                    horizontalArrangement = Arrangement.Center,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Text(
                        text = "© 2024 Winyway. All rights reserved.",
                        color = Color.White,
                        fontSize = if (isWideScreen) 12.sp else 10.sp,
                        textAlign = TextAlign.Center
                    )
                }
            }
        }
    }
}




//@Composable
//fun Footer(component: HomeScreenTabComponent) {
//    val deviceType = getDeviceType()
//    BoxWithConstraints(
//        modifier = Modifier
//            .fillMaxWidth()
//            .heightIn(max = footerHeightPx)
//    ) {
//        val isWideScreen = maxWidth > 600.dp
//
//        Image(
//            painter = painterResource(Res.drawable.footer_background),
//            contentDescription = null,
//            modifier = Modifier.fillMaxSize(),
//            contentScale = ContentScale.Crop
//        )
//
//        Column(
//            modifier = Modifier
//                .fillMaxSize()
//                .background(Color.Transparent) // To ensure the background image is visible
//        ) {
//            // Top section with logo and links
//            Column(
//                modifier = Modifier
//                   // .fillMaxSize()
//                    //.fillMaxWidth()
//                    .wrapContentHeight()
//                    .padding(10.dp)
//            ) {
//                // Logo section
//                Row(
//                    modifier = if (isWideScreen) Modifier.fillMaxWidth() else Modifier.fillMaxWidth(),
//                    verticalAlignment = Alignment.CenterVertically,
//                    horizontalArrangement = if (isWideScreen) Arrangement.Start else Arrangement.Center
//                ) {
//                    Image(
//                        painter = painterResource(Res.drawable.icon_logo),
//                        contentDescription = "Logo",
//                        modifier = Modifier.size(if (isWideScreen) 40.dp else 30.dp),
//                        colorFilter = ColorFilter.tint(seed)
//                    )
//                    Text(
//                        text = "Winyway",
//                        fontSize = if (isWideScreen) 25.sp else 20.sp,
//                        color = seed,
//                        fontWeight = FontWeight.Bold,
//                        modifier = Modifier.padding(start = 8.dp)
//                    )
//                }
//
//                // Paragraph below the logo
//                Text(
//                    text = "Welcome to our website! We offer a variety of services to help you succeed. Explore our offerings and get in touch with us!",
//                    color = Color.White.copy(alpha = 0.8f),
//                    fontSize = if (isWideScreen) 16.sp else 12.sp,
//                    modifier = Modifier.padding(top = 15.dp),
//                    maxLines = 5,
//                    overflow = TextOverflow.Ellipsis,
//                    textAlign = TextAlign.Center
//                )
//
//                Spacer(modifier = Modifier.height(if (isWideScreen) 16.dp else 10.dp))
//
//                // Links section for wide screens
//                if (isWideScreen) {
//                    Row(
//                        modifier = Modifier.fillMaxWidth(),
//                        horizontalArrangement = Arrangement.SpaceBetween
//                    ) {
//                            LinkBox(
//                                title = "Quick Links",
//                                links = listOf("Terms and Conditions", "Privacy Policy", "Contact Us"),
//                                navigateTo = { link ->
//                                    when (link) {
//                                        "Terms and Conditions" -> {
//                                            component.event(HomePageEvent.OnClickTermsAndConditions)
//                                        }
//
//                                        "Privacy Policy" -> {
//                                            component.event(HomePageEvent.OnClickPrivacyPolicy)
//                                        }
//
//                                        "Contact Us" -> {
//                                            // Navigate to Contact Us page
//                                        }
//                                    }
//                                },
//                                imageItems = listOf(),
//                                onImageClick = {_,_,_ ->}
//                            )
//
//                        LinkBox(
//                            title = "Support",
//                            links = listOf("Winyway Review", "Blog", "Support@winyway.com"),
//                            navigateTo = { link -> /* Handle navigation */ },
//                            imageItems = listOf(
//                                ImageItem(
//                                    defaultPainter = painterResource(Res.drawable.facebook1),
//                                    hoverPainter = painterResource(Res.drawable.facebook2),
//                                    identifier = "facebook"
//                                ),
//                                ImageItem(
//                                    defaultPainter = painterResource(Res.drawable.instagram1),
//                                    hoverPainter = painterResource(Res.drawable.instagram2),
//                                    identifier = "instagram"
//                                ),
//                                ImageItem(
//                                    defaultPainter = painterResource(Res.drawable.linkedin1),
//                                    hoverPainter = painterResource(Res.drawable.linkedin2),
//                                    identifier = "linkedin"
//                                ),
//                                ImageItem(
//                                    defaultPainter = painterResource(Res.drawable.youtube1),
//                                    hoverPainter = painterResource(Res.drawable.youtube2),
//                                    identifier = "youtube"
//                                )
//                            ),
//                            onImageClick = { defaultPainter, hoverPainter, identifier ->
//                                when(identifier) {
//                                    "facebook" -> {
//                                        openPlayStore(winyway_facebook_page_url)
//                                    }
//                                    "instagram" -> {
//                                        openPlayStore(winyway_instagram_page_url)
//                                    }
//                                    "linkedin" -> {
//                                        openPlayStore(winyway_linkedin_page_url)
//                                    }
//                                    "youtube" -> {
//                                        openPlayStore(winyway_youtube_page_url)
//                                    }
//                                }
//                            }
//                        )
//
//                        AppLinkBox(
//                            title = "Expert APP",
//                            links = listOf(
//                                "Play Store" to Res.drawable.google_play,
//                                // "App Store" to Res.drawable.app_stor
//                            )
//                        ) { link ->
//                            when (link) {
//                                "Play Store" -> {
//                                    openPlayStore(expert_app_play_store_url)
//                                }
//
//                                //"App Store" -> {}
//                            }
//                        }
//
//                        AppLinkBox(
//                            title = "User APP",
//                            links = listOf(
//                                "Play Store" to Res.drawable.google_play,
//                                "App Store" to Res.drawable.app_stor
//                            )
//                        ) { link ->
//                            when (link) {
//                                "Play Store" -> {
//                                    openPlayStore(user_app_play_store_url)
//                                }
//
//                                "App Store" -> {}
//                            }
//                        }
//                    }
//                } else {
//                    // Use a LazyVerticalGrid with 2 columns
//                    LazyVerticalGrid(
//                        columns = GridCells.Fixed(2),
//                        modifier = Modifier
//                            .fillMaxWidth()
//                            .padding(5.dp),
//                        verticalArrangement = Arrangement.spacedBy(5.dp),
//                        userScrollEnabled = false
//                    ) {
//                        item {
//                            LinkBox(
//                                title = "Quick Links",
//                                links = listOf("Terms and Conditions", "Privacy Policy", "Contact Us"),
//                                navigateTo = { link ->
//                                    when (link) {
//                                        "Terms and Conditions" -> {
//                                            component.event(HomePageEvent.OnClickTermsAndConditions)
//                                        }
//
//                                        "Privacy Policy" -> {
//                                            component.event(HomePageEvent.OnClickPrivacyPolicy)
//                                        }
//
//                                        "Contact Us" -> {
//                                            // Navigate to Contact Us page
//                                        }
//                                    }
//                                },
//                                imageItems = listOf(),
//                                onImageClick = {_,_,_ ->}
//                            )
//                        }
//                        item {
//                            LinkBox(
//                                title = "Support",
//                                links = listOf("Winyway Review", "Blog", "Support@winyway.com"),
//                                navigateTo = { link -> /* Handle navigation */ },
//                                imageItems = listOf(
//                                    ImageItem(
//                                        defaultPainter = painterResource(Res.drawable.facebook1),
//                                        hoverPainter = painterResource(Res.drawable.facebook2),
//                                        identifier = "facebook"
//                                    ),
//                                    ImageItem(
//                                        defaultPainter = painterResource(Res.drawable.instagram1),
//                                        hoverPainter = painterResource(Res.drawable.instagram2),
//                                        identifier = "instagram"
//                                    ),
//                                    ImageItem(
//                                        defaultPainter = painterResource(Res.drawable.linkedin1),
//                                        hoverPainter = painterResource(Res.drawable.linkedin2),
//                                        identifier = "linkedin"
//                                    ),
//                                    ImageItem(
//                                        defaultPainter = painterResource(Res.drawable.youtube1),
//                                        hoverPainter = painterResource(Res.drawable.youtube2),
//                                        identifier = "youtube"
//                                    )
//                                ),
//                                onImageClick = { defaultPainter, hoverPainter, identifier ->
//                                    when(identifier) {
//                                        "facebook" -> {
//                                            openPlayStore(winyway_facebook_page_url)
//                                        }
//                                        "instagram" -> {
//                                            openPlayStore(winyway_instagram_page_url)
//                                        }
//                                        "linkedin" -> {
//                                            openPlayStore(winyway_linkedin_page_url)
//                                        }
//                                        "youtube" -> {
//                                            openPlayStore(winyway_youtube_page_url)
//                                        }
//                                    }
//                                }
//                            )
//
//                        }
//                        item {
//                            AppLinkBox(
//                                title = "Expert APP",
//                                links = listOf(
//                                    "Play Store" to Res.drawable.google_play,
//                                    //  "App Store" to Res.drawable.app_stor
//                                )
//                            ) { link ->
//                                when (link) {
//                                    "Play Store" -> {
//                                        openPlayStore(expert_app_play_store_url)
//                                    }
//
//                                    // "App Store" -> {}
//                                }
//                            }
//                        }
//
//                        item {
//                            AppLinkBox(
//                                title = "User App",
//                                links = listOf(
//                                    "Play Store" to Res.drawable.google_play,
//                                    "App Store" to Res.drawable.app_stor
//                                )
//                            ) { link ->
//                                when (link) {
//                                    "Play Store" -> {
//                                        openPlayStore(user_app_play_store_url)
//                                    }
//
//                                    "App Store" -> {}
//                                }
//                            }
//                        }
//                    }
//                }
////                Spacer(modifier = Modifier.weight(1f))
////
////                // Bottom details
////                Box(
////                    modifier = Modifier
////                        .fillMaxWidth()
////                        .background(Color.Black)
////                        .padding(vertical = if (isWideScreen) 10.dp else 5.dp)
////                ) {
////                    Row(
////                        modifier = Modifier
////                            .align(Alignment.Center)
////                            .height(if (isWideScreen) 25.dp else 15.dp),
////                        horizontalArrangement = Arrangement.Center,
////                        verticalAlignment = Alignment.CenterVertically
////                    ) {
////                        Text(
////                            text = "© 2024 Winyway. All rights reserved.",
////                            color = Color.White,
////                            fontSize = if (isWideScreen) 12.sp else 10.sp,
////                            textAlign = TextAlign.Center
////                        )
////                    }
////                }
//            }
//            Spacer(modifier = Modifier.weight(0.1f))
//
//            // Bottom details
//            Box(
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .background(Color.Black)
//                    .padding(vertical = if (isWideScreen) 10.dp else 5.dp)
//            ) {
//                Row(
//                    modifier = Modifier
//                        .align(Alignment.Center)
//                        .height(if (isWideScreen) 25.dp else 15.dp),
//                    horizontalArrangement = Arrangement.Center,
//                    verticalAlignment = Alignment.CenterVertically
//                ) {
//                    Text(
//                        text = "© 2024 Winyway. All rights reserved.",
//                        color = Color.White,
//                        fontSize = if (isWideScreen) 12.sp else 10.sp,
//                        textAlign = TextAlign.Center
//                    )
//                }
//            }
//        }
//    }
//}


//@Composable
//fun Footer(component: HomeScreenTabComponent) {
//    val deviceType = getDeviceType()
//    BoxWithConstraints(
//        modifier = Modifier
//            .fillMaxWidth()
//            //.heightIn(max = footer_max_height)
//            .heightIn(max = footerHeightPx)
//            //.heightIn(max = 650.dp)
//            //.wrapContentHeight()
//           // .heightIn(max = maxHeight * 0.7f)
//            //.heightIn(max = Dp.Hairli ne)
//    ) {
//        val isWideScreen = maxWidth > 600.dp
//
//        Image(
//            painter = painterResource(Res.drawable.footer_background),
//            contentDescription = null,
//            modifier = Modifier
//                .fillMaxSize(),
//                //.fillMaxWidth()
//                //.heightIn(max = maxHeight),
//            contentScale = ContentScale.Crop
//        )
//
//        Column(
//            modifier = Modifier
//                .fillMaxSize()
//                .background(Color.Transparent) // To ensure the background image is visible
//        ) {
//            // Top section with logo and links
//            Column(
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .wrapContentHeight()
//                    .padding(16.dp)
//            ) {
//                // Logo section
//                Row(
//                    modifier = if (isWideScreen) Modifier.fillMaxWidth() else Modifier.fillMaxWidth(),
//                    verticalAlignment = Alignment.CenterVertically,
//                    horizontalArrangement = if (isWideScreen) Arrangement.Start else Arrangement.Center
//                ) {
//                    Image(
//                        painter = painterResource(Res.drawable.icon_logo),
//                        contentDescription = "Logo",
//                        modifier = Modifier.size(if (isWideScreen) 40.dp else 5.dp ),
//                        colorFilter = ColorFilter.tint(seed)
//                    )
//                    Text(
//                        text = "Winyway",
//                        fontSize = if(isWideScreen) 25.sp else 15.sp,
//                        color = seed,
//                        fontWeight = FontWeight.Bold,
//                        modifier = Modifier.padding(start = 8.dp)
//                    )
//                }
//
//                // Paragraph below the logo
//                Text(
//                    text = "Welcome to our website! We offer a variety of services to help you succeed. Explore our offerings and get in touch with us!",
//                    color = Color.White.copy(alpha = 0.8f),
//                    fontSize = if(isWideScreen) 16.sp else 12.sp,
//                    modifier = Modifier.padding(top = 15.dp),
//                    maxLines = 5,
//                    overflow = TextOverflow.Ellipsis,
//                    textAlign = TextAlign.Center
//                )
//
//                Spacer(modifier = Modifier.height(if(isWideScreen) 16.dp else 5.dp))
//
//                // Links section for wide screens
//                if (isWideScreen) {
//                    Row(
//                        modifier = Modifier.fillMaxWidth(),
//                        horizontalArrangement = Arrangement.SpaceBetween
//                    ) {
//                        LinkBox(
//                            title = "Quick Links",
//                            links = listOf("Terms and Conditions", "Privacy Policy", "Contact Us")
//                        ) { link ->
//                            when (link) {
//                                "Terms and Conditions" -> {
//                                    component.event(HomePageEvent.OnClickTermsAndConditions)
//                                }
//
//                                "Privacy Policy" -> {
//                                    component.event(HomePageEvent.OnClickPrivacyPolicy)
//                                }
//
//                                "Contact Us" -> {
//                                    // Navigate to Contact Us page
//                                }
//                            }
//                        }
//
//                        LinkBox(
//                            title = "Support",
//                            links = listOf("Winyway Review", "Blog", "Support@winyway.com"),
//                            {}
//                        )
//
//                        AppLinkBox(
//                            title = "Expert APP",
//                            links = listOf(
//                                "Play Store" to Res.drawable.google_play,
//                               // "App Store" to Res.drawable.app_stor
//                            )
//                        ) { link ->
//                            when (link) {
//                                "Play Store" -> {
//                                    openPlayStore(expert_app_play_store_url)
//                                }
//
//                                //"App Store" -> {}
//                            }
//                        }
//
//                        AppLinkBox(
//                            title = "User APP",
//                            links = listOf(
//                                "Play Store" to Res.drawable.google_play,
//                                "App Store" to Res.drawable.app_stor
//                            )
//                        ) { link ->
//                            when (link) {
//                                "Play Store" -> {
//                                    openPlayStore(user_app_play_store_url)
//                                }
//
//                                "App Store" -> {}
//                            }
//                        }
//                    }
//                } else {
//                    // Use a LazyVerticalGrid with 2 columns
//                    LazyVerticalGrid(
//                        columns = GridCells.Fixed(2),
//                        modifier = Modifier
//                            .fillMaxSize()
////                            .fillMaxWidth()
////                            .fillMaxHeight()
////                            .wrapContentHeight()
//                            .padding(5.dp),
//                        verticalArrangement = Arrangement.spacedBy(5.dp),
//                        userScrollEnabled = false
//                    ) {
//                        item {
//                            LinkBox(
//                                title = "Quick Links",
//                                links = listOf("Terms and Conditions", "Privacy Policy", "Contact Us")
//                            ) { link ->
//                                when (link) {
//                                    "Terms and Conditions" -> {
//                                        component.event(HomePageEvent.OnClickTermsAndConditions)
//                                    }
//
//                                    "Privacy Policy" -> {
//                                        component.event(HomePageEvent.OnClickPrivacyPolicy)
//                                    }
//
//                                    "Contact Us" -> {
//                                        // Navigate to Contact Us page
//                                    }
//                                }
//                            }
//                        }
//
//                        item {
//                            LinkBox(
//                                title = "Support",
//                                links = listOf("Winyway Review", "Blog", "Support@winyway.com"),
//                                {}
//                            )
//                        }
//
//                        item {
//                            AppLinkBox(
//                                title = "Expert APP",
//                                links = listOf(
//                                    "Play Store" to Res.drawable.google_play,
//                                  //  "App Store" to Res.drawable.app_stor
//                                )
//                            ) { link ->
//                                when (link) {
//                                    "Play Store" -> {
//                                        openPlayStore(expert_app_play_store_url)
//                                    }
//
//                                   // "App Store" -> {}
//                                }
//                            }
//                        }
//
//                        item {
//                            AppLinkBox(
//                                title = "User App",
//                                links = listOf(
//                                    "Play Store" to Res.drawable.google_play,
//                                    "App Store" to Res.drawable.app_stor
//                                )
//                            ) { link ->
//                                when (link) {
//                                    "Play Store" -> {
//                                        openPlayStore(user_app_play_store_url)
//                                    }
//
//                                    "App Store" -> {}
//                                }
//                            }
//                        }
//                    }
//                }
//            }
//
//            Spacer(modifier = Modifier.weight(1f))
//
//            // Bottom details
//            Box(
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .background(Color.Black)
//                    .border(1.dp, Color.Black)
////                    .padding(bottom = 10.dp),
//            ) {
//                Row(
//                    modifier = Modifier
//                        .align(Alignment.Center)
//                        .height(if(isWideScreen) 50.dp else 25.dp),
//                    horizontalArrangement = Arrangement.Center,
//                    verticalAlignment = Alignment.CenterVertically
//                ) {
//                    Text(
//                        text = "© 2024 Winyway. All rights reserved.",
//                        color = Color.White,
//                        fontSize = 12.sp,
//                        textAlign = TextAlign.Center
//                    )
//                }
//            }
//        }
//    }
//}
//
//@Composable
//fun LinkBox(
//    title: String,
//    links: List<String>,
//    navigateTo: (String) -> Unit,
//    imagePainters: List<Pair<Painter, Painter>> = emptyList(), // List of pairs (default image, hover image)
//    onImageClick: (Painter) -> Unit = {}
//) {
//    Column(
//        modifier = Modifier.padding(vertical = 8.dp)
//    ) {
//        Text(
//            text = title,
//            color = Color.White,
//            fontWeight = FontWeight.Bold,
//            fontSize = 18.sp,
//            modifier = Modifier.padding(bottom = 4.dp)
//        )
//        Spacer(modifier = Modifier.height(8.dp))
//        links.forEach { link ->
//            Text(
//                text = link,
//                color = Color.White.copy(alpha = 0.8f),
//                fontSize = 15.sp,
//                modifier = Modifier
//                    .clickable { navigateTo(link) }
//                    .padding(vertical = 5.dp)
//            )
//        }
//        Spacer(modifier = Modifier.height(8.dp))
//        if (imagePainters.isNotEmpty()) {
//            LazyRow(
//                horizontalArrangement = Arrangement.spacedBy(8.dp) // Adjust spacing as needed
//            ) {
//                items(imagePainters.size) { pair ->
//                    val image = imagePainters[pair]
//                    var isHovered by remember { mutableStateOf(false) }
//
//                    val painter = if (isHovered) image.second else image.first
//
//                    Image(
//                        painter = painter,
//                        contentDescription = null, // Provide content description if needed
//                        modifier = Modifier
//                            .detectHover { isHovered = it }
//                            .clickable { onImageClick(image.first) }
//                            .size(40.dp) // Adjust size as needed
//                    )
//                }
//            }
//        }
//    }
//}

@Composable
fun LinkBox(
    title: String,
    links: List<String>,
    navigateTo: (String) -> Unit,
    imageItems: List<ImageItem> = emptyList(),
    onImageClick: (Painter, Painter, String) -> Unit = { _, _, _ -> }
) {
    Column(
        modifier = Modifier.padding(vertical = 8.dp)
    ) {
        Text(
            text = title,
            color = Color.White,
            fontWeight = FontWeight.Bold,
            fontSize = 18.sp,
            modifier = Modifier.padding(bottom = 4.dp)
        )
        Spacer(modifier = Modifier.height(8.dp))
        links.forEach { link ->
            Text(
                text = link,
                color = Color.White.copy(alpha = 0.8f),
                fontSize = 15.sp,
                modifier = Modifier
                    .clickable { navigateTo(link) }
                    .padding(vertical = 5.dp)
            )
        }
        Spacer(modifier = Modifier.height(8.dp))
        if (imageItems.isNotEmpty()) {
            LazyRow(
                horizontalArrangement = Arrangement.spacedBy(8.dp)
            ) {
                items(imageItems.size) { index ->
                    val imageItem = imageItems[index]
                    var isHovered by remember { mutableStateOf(false) }

                    val painterToDisplay = if (isHovered) imageItem.hoverPainter else imageItem.defaultPainter

                    Image(
                        painter = painterToDisplay,
                        contentDescription = null, // Provide content description if needed
                        modifier = Modifier
                            .size(40.dp) // Adjust size as needed
                            .detectHover { isHovered = it }
                            .clickable { onImageClick(imageItem.defaultPainter, imageItem.hoverPainter, imageItem.identifier) }
                    )
                }
            }
        }
    }
}


fun Modifier.detectHover(onHoverChanged: (Boolean) -> Unit): Modifier {
    return this.pointerInput(Unit) {
        awaitPointerEventScope {
            var isHovering = false
            while (true) {
                val event = awaitPointerEvent()
                val isCurrentlyHovering = event.changes.any { it.positionChanged() && it.isConsumed }
                if (isCurrentlyHovering != isHovering) {
                    isHovering = isCurrentlyHovering
                    onHoverChanged(isHovering)
                }
            }
        }
    }
}


//@Composable
//fun LinkBox(title: String, links: List<String>,   navigateTo: (String) -> Unit,
//            imagePainters: List<Painter>, // List of Painter objects
//            onImageClick: (Painter) -> Unit) {
//    Column(
//        modifier = Modifier.padding(vertical = 8.dp)
//    ) {
//        Text(
//            text = title,
//            color = Color.White,
//            fontWeight = FontWeight.Bold,
//            fontSize = 18.sp,
//            modifier = Modifier.padding(bottom = 4.dp)
//        )
//        Spacer(modifier = Modifier.height(8.dp))
//        links.forEach { link ->
//            Text(
//                text = link,
//                color = Color.White.copy(alpha = 0.8f),
//                fontSize = 15.sp,
//                modifier = Modifier
//                    .clickable {
//                        navigateTo(link)
//                    }
//                    .padding(vertical = 5.dp)
//                    .hoverable()
//            )
//        }
//        if(imagePainters != null){
//            LazyRow(
//                horizontalArrangement = Arrangement.spacedBy(8.dp) // Adjust spacing as needed
//            ){
//                items(imagePainters.size) { index ->
//                    val painter = imagePainters[index]
//                    Image(
//                        painter = painter,
//                        contentDescription = null, // Provide content description if needed
//                        modifier = Modifier
//                            .clickable { onImageClick(painter) }
//                            .size(100.dp) // Adjust size as needed
//                    )
//                }
//            }
//        }
//    }
//}

/*@Composable
fun ImageRow(
    imagePainters: List<Painter>, // List of Painter objects
    onImageClick: (Painter) -> Unit // Callback for image click
) {
    LazyRow(
        horizontalArrangement = Arrangement.spacedBy(8.dp) // Adjust spacing as needed
    ) {
        items(imagePainters.size) { index ->
            val painter = imagePainters[index]
            Image(
                painter = painter,
                contentDescription = null, // Provide content description if needed
                modifier = Modifier
                    .clickable { onImageClick(painter) }
                    .size(100.dp) // Adjust size as needed
            )
        }
    }
}*/



@Composable
fun AppLinkBox(
    title: String,
    links: List<Pair<String, DrawableResource>>,
    navigateTo: (String) -> Unit
) {
    Column(
        modifier = Modifier.padding(vertical = 8.dp)
    ) {
        Text(
            text = title,
            color = Color.White,
            fontWeight = FontWeight.Bold,
            fontSize = 18.sp,
            modifier = Modifier.padding(bottom = 4.dp)
        )
        Spacer(modifier = Modifier.height(8.dp))
        links.forEach { (link, imageRes) ->
            Column(
                modifier = Modifier
                    .clickable {
                        if (link == "Play Store") {
                            navigateTo(link)
                        } // Do nothing or show a message for "App Store"
                    }
                    .padding(vertical = 5.dp)
                    .hoverable()
            ) {
                Text(
                    text = link,
                    color = Color.White.copy(alpha = 0.8f),
                    fontSize = 15.sp,
                )
                Spacer(modifier = Modifier.height(2.dp))
                Image(
                    painter = painterResource(imageRes),
                    contentDescription = null,
                    modifier = Modifier.height(50.dp).width(120.dp),
                    contentScale = ContentScale.FillBounds
                )

                if (link == "App Store") {
                    // Show Coming Soon message
                    Text(
                        text = "Coming Soon",
                        color = Color.White.copy(alpha = 0.5f),
                        fontSize = 12.sp,
                        modifier = Modifier.padding(top = 2.dp)
                    )
                }
            }
        }
    }
}

//
//@Composable
//fun LinkBox(title: String, links: List<String>, navigateTo: (String) -> Unit) {
//    Column(
//        modifier = Modifier.padding(vertical = 8.dp)
//    ) {
//        Text(
//            text = title,
//            color = Color.White,
//            fontWeight = FontWeight.Bold,
//            fontSize = 18.sp,
//            modifier = Modifier.padding(bottom = 4.dp)
//        )
//        Spacer(modifier = Modifier.height(8.dp))
//        links.forEach { link ->
//            Text(
//                text = link,
//                color = Color.White.copy(alpha = 0.8f),
//                fontSize = 15.sp,
//                modifier = Modifier
//                    .clickable {
//                        navigateTo(link)
//                    }
//                    .padding(vertical = 5.dp)
//            )
//        }
//    }
//}
//
//@Composable
//fun AppLinkBox(
//    title: String,
//    links: List<Pair<String, DrawableResource>>,
//    navigateTo: (String) -> Unit
//) {
//    Column(
//        modifier = Modifier.padding(vertical = 8.dp)
//    ) {
//        Text(
//            text = title,
//            color = Color.White,
//            fontWeight = FontWeight.Bold,
//            fontSize = 18.sp,
//            modifier = Modifier.padding(bottom = 4.dp)
//        )
//        Spacer(modifier = Modifier.height(8.dp))
//        links.forEach { (link, imageRes) ->
//            Column(
//                modifier = Modifier
//                    .clickable {
//                        navigateTo(link)
//                    }
//                    .padding(vertical = 5.dp)
//            ) {
//                Text(
//                    text = link,
//                    color = Color.White.copy(alpha = 0.8f),
//                    fontSize = 15.sp,
//                )
//                Spacer(modifier = Modifier.height(2.dp))
//                Image(
//                    painter = painterResource(imageRes),
//                    contentDescription = null,
//                    modifier = Modifier.size(height = 50.dp, width = 120.dp),
//                    contentScale = ContentScale.Fit
//                )
//                if (link == "App Store") {
//                    Text(
//                        text = "Coming Soon",
//                        color = Color.White.copy(alpha = 0.5f),
//                        fontSize = 12.sp,
//                        modifier = Modifier.padding(top = 2.dp)
//                    )
//                }
//            }
//        }
//    }
//}



// Extension function for hover effect
@Composable
fun Modifier.hoverable(): Modifier {
    val interactionSource = remember { MutableInteractionSource() }
    val isHovered by interactionSource.collectIsHoveredAsState()

    return this.then(
        if (isHovered) {
            Modifier.background(Color.White.copy(alpha = 0.1f)) // Change background on hover
        } else {
            Modifier
        }
    )
}


@Composable
fun TrustIndicatorBox(icon: ImageVector, text: String) {
    Box(
        modifier = Modifier
            .wrapContentSize()
            .padding(4.dp)
            .border(
                width = 1.dp,
                color = Color.White,
                shape = RoundedCornerShape(Scale.CORNER_SMALL) // Standardized corner radius
            )
            .padding(8.dp), // Additional padding for better content separation
        contentAlignment = Alignment.Center
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center,
            modifier = Modifier.padding(4.dp)
        ) {

            Icon(
                imageVector = icon,
                contentDescription = text,
                tint = Color.White, // Set icon color to white for consistency
                modifier = Modifier.size(20.dp) // Adjust the size of the icon
            )
//            AsyncImage(
//                model = imageUrl,
//                contentScale = ContentScale.Fit,
//                modifier = Modifier.size(20.dp),
//                contentDescription = text
//            )

            Spacer(modifier = Modifier.width(8.dp)) // Adds space between the image and text

            Text(
                text = text,
                color = Color.White,
                fontSize = 10.sp, // Slightly larger font size for readability
                textAlign = TextAlign.Center
            )
        }
    }
}



//@Composable
//fun SubscriptionBox() {
//    Column(
//        modifier = Modifier.padding(start = 16.dp),
//        horizontalAlignment = Alignment.Start
//    ) {
//        Text("Subscribe", color = Color.White, fontWeight = FontWeight.Bold, fontSize = 16.sp)
//        var email by remember { mutableStateOf("") }
//        OutlinedTextField(
//            value = email,
//            onValueChange = { email = it },
//            placeholder = { Text("Enter your email", color = Color.Gray) },
//            colors = TextFieldDefaults.outlinedTextFieldColors(
//                focusedBorderColor = Color(0xFFAFAFB6),
//                unfocusedBorderColor = Color(0xFFAFAFB6),
//                backgroundColor = Color(0xFF140B5C),
//                textColor = Color.White
//            )
//        )
//        Button(onClick = { /* Handle subscribe */ }, colors = ButtonDefaults.buttonColors(backgroundColor = Color.White)) {
//            Text("Subscribe", color = Color(0xFF140B5C))
//        }
//    }
//}
