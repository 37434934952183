package com.winiwayuser.feature_common.persentation.home_tab

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.FloatingActionButton
import androidx.compose.material3.Icon
import androidx.compose.material3.Scaffold
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import co.touchlab.kermit.Logger
import com.winiwayuser.core.persentation.showToast
import com.winiwayuser.core.agora.connectToAgora
import com.winiwayuser.core.persentation.HomePageLoadingProgressDialog
import com.winiwayuser.core.persentation.LoadingProgressDialog
import com.winiwayuser.core.platform.isJS
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_ELSE
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import com.winiwayuser.core.theme.background_color
import com.winiwayuser.core.theme.primaryDark
import com.winiwayuser.core.zoho_sales_iq.onClickSupport
import com.winiwayuser.feature_common.data.request.RequestCategory
import com.winiwayuser.feature_common.persentation.home_tab.footer.Footer
import com.winiwayuser.feature_common.persentation.home_tab.home_screen_widget.ConsultantListItemUI
import com.winiwayuser.feature_common.persentation.home_tab.home_screen_widget.SearchFieldPreview
import com.winiwayuser.feature_common.persentation.home_tab.home_screen_widget.ToolBar
import com.winiwayuser.feature_common.persentation.home_tab.home_screen_widget.TopCategoryList
import com.winiwayuser.feature_common.persentation.home_tab.home_screen_widget.TopLiveSessionUI
import com.winiwayuser.feature_common.persentation.home_tab.home_screen_widget.WelcomeSlide
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.baseline_support_agent_24
import org.jetbrains.compose.resources.painterResource

//@Composable
//fun HomeScreenTab(component: HomeScreenTabComponent) {
//
//    Scaffold(
//        topBar = {
//            ToolBar(
//                onClickLogin = {
//                    component.event(HomePageEvent.OnClickLogin)
//                },
//                onClickProfile = { component.event(HomePageEvent.OnClickProfileIcon) },
//                onClickWallet = { component.event(HomePageEvent.OnClickWalletIcon) },
//                component = component
//            )
//        },
//        floatingActionButton = {
//            val isBrowserType = isJS()
//            if (isBrowserType) return@Scaffold
//            FloatingActionButton(
//                onClick = {
//                    onClickSupport()
//                },
//                containerColor = primaryDark,
//                modifier = Modifier
//                    .background(primaryDark, CircleShape),
//            ) {
//                Icon(
//                    painter = painterResource(Res.drawable.baseline_support_agent_24),
//                    contentDescription = "Support",
//                    tint = Color.White
//                )
//            }
//        }
//    ) { innerPadding ->
//        LazyColumn(
//            modifier = Modifier
//                .background(background_color)
//                .fillMaxSize()
//                .padding(innerPadding)
//        ) {
//            item {
//                SearchFieldPreview()
//            }
//            item {
//                WelcomeSlide(component)
//            }
//
//            item {
//                TopCategoryList(
//                    onCategoryClick = component::onCategoryClick,
//                    component = component
//                )
//            }
//
//            item {
//                ConsultantListItemUI(
//                    component = component,
//                    onLoginClick = {
//                        component.event(HomePageEvent.OnClickLogin)
//                    },
//                    onChatConsultation = { chatData ->
//                        component.event(
//                            HomePageEvent.OnChatConsultationStart(
//                                consultantId = chatData.id!!,
//                                consultantName = chatData.full_name!!,
//                                consultantImage = chatData.profile_image!!,
//                                consultantSkills = chatData.skills!!
//                            )
//                        )
//                    },
//                    onItemClick = { consultant ->
//                        val category_id: Int? = consultant.id
//                        //component.event(HomePageEvent.OnClickTopConsultant(consultant.cats.indexOfFirst { it.category_id. }))
//                        if(category_id != null){
//                            component.event(
//                                HomePageEvent.OnClickTopConsultant(
//                                    category_id
//                                )
//                            )
//                        }
//                        //Logger.d { "HomeScreenTab-> ${consultant.full_name}" }
//                        //showToast("Top consultant item click: ${category_id}")
//                    },
//                    onCallConsultation = { callData->
//                        component.event(
//                            HomePageEvent.OnCallConsultationStart(
//                                consultantId = callData.id!!,
//                                consultantName = callData.full_name!!,
//                                consultantImage = callData.profile_image!!,
//                                consultantSkills = callData.skills!!
//                            )
//                        )
//                    }
//                )
//            }
//
//            item { TopLiveSessionUI(component = component, onClick = {}) }
//
//            item { Spacer(modifier = Modifier.height(10.dp)) }
//
//            item {
//                if (isJS()) {
//                    Footer(component)
//                }
//            }
//        }
//    }
//}
//
fun HomeScreenTabComponent.onCategoryClick(categoryId: RequestCategory) {
    event(HomePageEvent.OnClickCategory(categoryId))
}



@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun HomeScreenTab(component: HomeScreenTabComponent) {
    val isLoadingComplete by component.isLoadingComplete.collectAsState()

    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
        else -> WINDOW_WIDTH_SIZE_ELSE
    }

    if (isLoadingComplete) {
        Box(
            modifier = Modifier
                .fillMaxSize()
                .background(background_color),
            contentAlignment = Alignment.Center
        ) {
            HomePageLoadingProgressDialog(
                onDismissRequest = { /* Handle dismiss if necessary */ },
                backgroundColor = Color(0xFF2A2A2A),
                loadingColor = Color.White
            )
        }
    } else {
        Box(modifier = Modifier.fillMaxSize().background(background_color),contentAlignment = Alignment.Center,) {
            Box(
                modifier = widthModifier
                    .fillMaxSize()
                    .background(background_color),
                contentAlignment = Alignment.Center
            ) {
                Scaffold(
                    topBar = {
                        ToolBar(
                            onClickLogin = {
                                component.event(HomePageEvent.OnClickLogin)
                            },
                            onClickProfile = { component.event(HomePageEvent.OnClickProfileIcon) },
                            onClickWallet = { component.event(HomePageEvent.OnClickWalletIcon) },
                            component = component
                        )
                    },
                    floatingActionButton = {
                        if (!isJS()) {
                            FloatingActionButton(
                                onClick = { onClickSupport() },
                                containerColor = primaryDark,
                                shape = CircleShape
                            ) {
                                Icon(
                                    painter = painterResource(Res.drawable.baseline_support_agent_24),
                                    contentDescription = "Support",
                                    tint = Color.White
                                )
                            }
                        }
                    }
                ) { innerPadding ->
                    LazyColumn(
                        modifier = Modifier
                            .background(background_color)
                            .fillMaxSize()
                            .padding(innerPadding)
                    ) {
                        item {
                            SearchFieldPreview()
                        }
                        item {
                            WelcomeSlide(component)
                        }
                        item {
                            TopCategoryList(
                                onCategoryClick = component::onCategoryClick,
                                component = component
                            )
                        }
                        item {
                            ConsultantListItemUI(
                                component = component,
                                onLoginClick = {
                                    component.event(HomePageEvent.OnClickLogin)
                                },
                                onChatConsultation = { chatData ->
                                    component.event(
                                        HomePageEvent.OnChatConsultationStart(
                                            consultantId = chatData.id!!,
                                            consultantName = chatData.full_name!!,
                                            consultantImage = chatData.profile_image!!,
                                            consultantSkills = chatData.skills!!
                                        )
                                    )
                                },
                                onItemClick = { consultant ->
                                    val category_id: Int? = consultant.id
                                    if (category_id != null) {
                                        component.event(
                                            HomePageEvent.OnClickTopConsultant(
                                                category_id
                                            )
                                        )
                                    }
                                },
                                onCallConsultation = { callData ->
                                    component.event(
                                        HomePageEvent.OnCallConsultationStart(
                                            consultantId = callData.id!!,
                                            consultantName = callData.full_name!!,
                                            consultantImage = callData.profile_image!!,
                                            consultantSkills = callData.skills!!
                                        )
                                    )
                                }
                            )
                        }
                        item { TopLiveSessionUI(component = component, onClick = {}) }
                        item { Spacer(modifier = Modifier.height(10.dp)) }
                        item {
                            if (isJS()) {
                                Footer(component)
                            }
                        }
                    }
                }
            }
        }
    }
}


