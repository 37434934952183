
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.platform.LocalAccessibilityManager
import androidx.compose.ui.platform.LocalAutofill
import androidx.compose.ui.platform.LocalClipboardManager
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.platform.LocalHapticFeedback
import androidx.compose.ui.platform.LocalInputModeManager
import androidx.compose.ui.platform.LocalInspectionMode
import androidx.compose.ui.platform.LocalLayoutDirection
import androidx.compose.ui.platform.LocalSoftwareKeyboardController
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.platform.LocalViewConfiguration
import androidx.compose.ui.platform.LocalWindowInfo
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.LayoutDirection
import androidx.compose.ui.window.CanvasBasedWindow
import com.arkivanov.decompose.DefaultComponentContext
import com.winiwayuser.core.RootComponent
import com.winiwayuser.core.di.initKoin
import kotlinx.browser.window
import org.jetbrains.skiko.wasm.onWasmReady


@OptIn(ExperimentalComposeUiApi::class)
fun main() {

    initKoin()
    val lifecycle = JsLifecycleOwner()
    val root =
        RootComponent(componentContext = DefaultComponentContext(lifecycle = lifecycle.lifecycle))
    val prefersDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches

    onWasmReady {

        var density by mutableStateOf(Density(1.0f))



        fun updateDensity() {
            val dpr = window.devicePixelRatio.toFloat()
            density = Density(dpr)
        }

        // Update density initially
        updateDensity()

        // Listen for window resize events to update density
        window.addEventListener("resize", {
            updateDensity()
        })
        CanvasBasedWindow(title = "Winyway - Expert Advice Instantly on Call/Chat 24X7") {
            CompositionLocalProvider(
                LocalDensity provides density,
                LocalLayoutDirection provides LayoutDirection.Ltr,
                LocalUriHandler provides LocalUriHandler.current,
                LocalViewConfiguration provides LocalViewConfiguration.current,
                LocalInputModeManager provides LocalInputModeManager.current,
                LocalClipboardManager provides LocalClipboardManager.current,
                LocalAutofill provides LocalAutofill.current,
                LocalFocusManager provides LocalFocusManager.current,
                LocalAccessibilityManager provides LocalAccessibilityManager.current,
                LocalHapticFeedback provides LocalHapticFeedback.current,
                LocalWindowInfo provides LocalWindowInfo.current,
                LocalSoftwareKeyboardController provides LocalSoftwareKeyboardController.current,
                LocalInspectionMode provides LocalInspectionMode.current,
            ) {

                App(
                    darkTheme = prefersDarkTheme,
                    dynamicColor = true,
                    rootComponent = root
                )
            }

        }

    }
}




