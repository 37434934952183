package com.winiwayuser.core.persentation

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Chat
import androidx.compose.material.icons.filled.Call
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage
import com.winiwayuser.core.local.UserInfoProvider.getIsLogin
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.theme.currency
import com.winiwayuser.core.theme.primaryColor

enum class ButtonStatus(
    val label: String,
    val buttonColor: Color,
    val iconColor: Color,
    val enabled: Boolean,
    val icon: ImageVector
) {
    ONLINE_CHAT(
        "CHAT",
        Color(0xFF6200EE),
        Color.White,
        true,
        Icons.AutoMirrored.Filled.Chat
    ),   // Purple for online
    ONLINE_CALL(
        "CALL",
        Color(0xFF28A745),
        Color.White,
        true,
        Icons.Filled.Call
    ),   // Purple for online
    BUSY_CHAT(
        "BUSY",
        Color(0xFFFF8A65),
        Color.White,
        true,
        Icons.AutoMirrored.Filled.Chat
    ),     // Orange for busy
    BUSY_CALL(
        "BUSY",
        Color(0xFFFF8A65),
        Color.White,
        true,
        Icons.Filled.Call
    ),     // Orange for busy
    OFFLINE_CHAT(
        "OFFLINE",
        Color.Gray.copy(alpha = 0.5f),
        Color.Gray,
        false,
        Icons.AutoMirrored.Filled.Chat
    ),   // Gray for offline
    OFFLINE_CALL(
        "OFFLINE",
        Color.Gray.copy(alpha = 0.5f),
        Color.Gray,
        false,
        Icons.Filled.Call
    )   // Gray for offline
}


@Composable
fun ConsultationButton(
    modifier: Modifier,
    status: ButtonStatus,
    onClick: () -> Unit,
    isTextVisible: Boolean
) {
    Button(
        onClick = {
            if ((status.label == "BUSY").not()) onClick.invoke()
        },
        colors = ButtonDefaults.buttonColors(containerColor = status.buttonColor),
        enabled = status.enabled,
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        elevation = ButtonDefaults.elevatedButtonElevation( // Correct elevation usage
            defaultElevation = Scale.ELEVATION_MEDIUM, // Customize elevation value
            pressedElevation = Scale.ELEVATION_LARGE, // Elevation when pressed
            disabledElevation = 0.dp // Elevation when disabled
        ),
        modifier = modifier
    ) {
        Row(
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically,
        ) {
            Icon(
                imageVector = status.icon,
                contentDescription = status.label,
                tint = status.iconColor,
                modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE)
            )

            if (isTextVisible) {
                Spacer(modifier = Modifier.width(4.dp))
                AutoSizeText(
                    text = status.label,
                    color = status.iconColor,
                    maxLines = 1
                )
            }
        }
    }
}

@Composable
fun ChatConsultationButton(
    modifier: Modifier = Modifier,
    chatStatus: String?,
    callStatus: String?,
    onClick: () -> Unit,
    isTextVisible: Boolean = true
) {
    val buttonStatus = when {
        chatStatus.equals("Offline", ignoreCase = true) -> ButtonStatus.OFFLINE_CHAT
        chatStatus.equals("Busy", ignoreCase = true) || callStatus.equals(
            "Busy",
            ignoreCase = true
        ) -> ButtonStatus.BUSY_CHAT

        else -> ButtonStatus.ONLINE_CHAT
    }

    ConsultationButton(
        modifier = modifier,
        status = buttonStatus,  // Using the dynamically set buttonStatus
        onClick = onClick,
        isTextVisible = isTextVisible
    )
}

@Composable
fun CallConsultationButton(
    modifier: Modifier = Modifier,
    chatStatus: String?,
    callStatus: String?,
    onClick: () -> Unit,
    isTextVisible: Boolean = true
) {
    val buttonStatus = when {
        callStatus.equals("Offline", ignoreCase = true) -> ButtonStatus.OFFLINE_CALL
        chatStatus.equals("Busy", ignoreCase = true) || callStatus.equals(
            "Busy",
            ignoreCase = true
        ) -> ButtonStatus.BUSY_CALL

        else -> ButtonStatus.ONLINE_CALL
    }

    ConsultationButton(
        modifier = modifier,
        status = buttonStatus,  // Using the dynamically set buttonStatus
        onClick = onClick,
        isTextVisible = isTextVisible
    )
}


@Composable
fun ExpertPriceRow(
    isFreeCallApplicable: Boolean,
    callPerMinutePrice: String?,
    expertPrice: Int,
    winywayPrice: Int,
    flashPrice: String?,
    horizontalArrangement: Arrangement.Horizontal
) {
    val totalCallPerMinutePrice = callPerMinutePrice ?: (expertPrice + winywayPrice).toString()

    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier
            .fillMaxWidth(),
        horizontalArrangement = horizontalArrangement
    ) {
        // New User Tag
        if (isFreeCallApplicable || getIsLogin().not()) {
            Text(
                text = "New User",
                modifier = Modifier
                    .background(primaryColor)
                    .padding(horizontal = 4.dp, vertical = 2.dp),
                color = Color.White,
                textAlign = TextAlign.Center,
                fontSize = 10.sp
            )
            val notLoginOrNewUserCase = flashPrice ?: callPerMinutePrice
            Spacer(modifier = Modifier.width(8.dp))
            Text(
                text = "$currency$notLoginOrNewUserCase/Min",
                color = Color.Black,
                fontSize = 14.sp,
                modifier = Modifier.alpha(0.6f),
                style = TextStyle(
                    fontStyle = FontStyle.Italic,
                    textDecoration = TextDecoration.LineThrough
                )
            )
        }else{
            // Price Text
            if (isFreeCallApplicable) {
                FlashPrice(flashPrice, totalCallPerMinutePrice)
            } else {
                if (!flashPrice.isNullOrEmpty() && flashPrice != "0") {
                    FlashPrice(flashPrice, totalCallPerMinutePrice)
                } else {
                    Text(
                        text = "$currency$totalCallPerMinutePrice/Min",
                        color = Color.Black,
                        fontSize = 14.sp
                    )
                }
            }
        }


    }
}

@Composable
fun FlashPrice(flashPrice: String?, totalCallPerMinutePrice: String) {
    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier.wrapContentWidth(),
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        Text(
            text = "$currency$flashPrice/Min",
            color = Color.Black,
            fontSize = 14.sp
        )

        Spacer(modifier = Modifier.width(4.dp))

        Text(
            text = "$currency$totalCallPerMinutePrice/Min",
            color = Color.Black,
            fontSize = 14.sp,
            modifier = Modifier.alpha(0.6f),
            style = TextStyle(
                fontStyle = FontStyle.Italic,
                textDecoration = TextDecoration.LineThrough
            )
        )
    }
}



/* Expert Profile Icon with Customize Ring according to value
* use modifier to modify
* This used in this project all place
* if you want to change in this than if will reflect all place*/
@Composable
fun ExpertProfileIcon(
    profileImageUrl: String?, modifier: Modifier = Modifier.size(30.dp)
) {
    Box(
        modifier = modifier, contentAlignment = Alignment.Center
    ) {
        Box(
            modifier = Modifier.matchParentSize().border(
                width = 2.dp, color = Color.Gray.copy(alpha = 0.3f), shape = CircleShape
            ).clip(CircleShape).background(Color.White).padding(1.dp).clip(CircleShape)
        ) {
            AsyncImage(
                model = profileImageUrl,
                modifier = Modifier.size(85.dp).clip(CircleShape),
                contentScale = ContentScale.Crop,
                contentDescription = null,
            )
        }
    }
}

