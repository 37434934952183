package com.winiwayuser.core.remote

import io.ktor.client.HttpClient


abstract class KtorApi {

    companion object {
        //const val BASE_URL = "https://prod.winyway.com/api/v1/"
        const val BASE_URL = "https://live.winyway.com/api/v1/"
    }

    val client: HttpClient by lazy { createHttpClient() }
}

// Expect function for creating the HTTP client
expect fun createHttpClient(): HttpClient

// Define the expected function
expect fun getPlatformLogger(): io.ktor.client.plugins.logging.Logger
