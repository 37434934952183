package com.winiwayuser.feature_chat_consultation_new.persentation.chat_bottom_sheet_info.widget

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.animateColorAsState
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.*
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.scale
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.winiwayuser.core.persentation.CommonButton
import com.winiwayuser.core.persentation.ExpertProfileIcon
import com.winiwayuser.core.persentation.showToast
import com.winiwayuser.core.theme.currency
import com.winiwayuser.core.theme.currency_name
import com.winiwayuser.core.theme.primaryColor
import com.winiwayuser.feature_chat_consultation_new.data.response.chat_duration.ChatDurationData
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData


@Composable
fun RechargeForConsultation(
    modifier: Modifier,
    expertImage: String,
    expertName: String,
    expertSkills: String,
    data: ChatDurationData?,
    rechargeData: List<RechargeData>,
    onRechargeSelected: (RechargeData) -> Unit,
) {

    var selectedRecharge by remember { mutableStateOf<RechargeData?>(null) }

    Column(
        modifier = modifier
            .wrapContentWidth()
            .wrapContentHeight()
    ) {

        // Show warning if `data` is provided
        AnimatedVisibility(visible = data != null) {
            Text(
                text = "Minimum balance of ${data?.minimumDuration} minutes ($currency_name ${data?.minimumBalance}) is required to start ${data?.type}",
                color = MaterialTheme.colorScheme.error,
                fontSize = 14.sp,
                fontWeight = FontWeight.Bold,
                modifier = Modifier
                    .align(Alignment.CenterHorizontally)
                    .padding(vertical = 8.dp),
                textAlign = TextAlign.Center
            )
        }

        Spacer(modifier = Modifier.height(8.dp))

        // Tip Row
        ExpertCard(
            modifier = Modifier,
            expertName = expertName,
            expertImage = expertImage,
            expertSkills = expertSkills
        )

        Spacer(modifier = Modifier.height(8.dp))

        // Recharge options grid
        RechargeOptionsGrid(
            selectedRecharge = selectedRecharge,
            onSelect = { selectedRecharge = it },
            rechargeData = rechargeData
        )

        Spacer(modifier = Modifier.height(8.dp))

        // Recharge button
        CommonButton(
            buttonText = "Recharge Now",
            modifier = Modifier.fillMaxWidth(),
            onClick = {
                selectedRecharge?.let {
                    onRechargeSelected(it)
                } ?: run {

                    showToast("Please Select Recharge Value")
                    // Handle case where no amount is selected
                }
            }
        )
    }
}

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun RechargeOptionsGrid(
    selectedRecharge: RechargeData?,
    onSelect: (RechargeData) -> Unit,
    rechargeData: List<RechargeData>
) {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    // Check if the list is empty or null
    if (rechargeData.isEmpty()) {
        Text(
            text = "No recharge options available.",
            color = MaterialTheme.colorScheme.onSurface,
            //modifier = Modifier.align(Alignment.CenterHorizontally)
        )
        return
    }

    val itemInRow = if (windowSizeClass == WindowWidthSizeClass.Compact) 3
    else 4

    Column(
        modifier = Modifier.fillMaxWidth(),
        verticalArrangement = Arrangement.spacedBy(16.dp)
    ) {
        rechargeData.chunked(itemInRow).forEach { rowItems ->
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                rowItems.forEach { rechargeOption ->
                    RechargeOption(
                        rechargeOption = rechargeOption,
                        isSelected = rechargeOption == selectedRecharge,
                        onSelect = onSelect
                    )
                }
            }
        }
    }
}

@Composable
fun RechargeOption(
    rechargeOption: RechargeData,
    isSelected: Boolean,
    onSelect: (RechargeData) -> Unit
) {
    val backgroundColor by animateColorAsState(
        targetValue = if (isSelected) MaterialTheme.colorScheme.primaryContainer else MaterialTheme.colorScheme.surfaceVariant
    )
    val textColor by animateColorAsState(
        targetValue = if (isSelected) MaterialTheme.colorScheme.onPrimaryContainer else MaterialTheme.colorScheme.onSurface
    )
    val scale by animateFloatAsState(
        targetValue = if (isSelected) 1.1f else 1f
    )

    Column(
        modifier = Modifier
            .width(100.dp)
            .height(80.dp)
            .clip(RoundedCornerShape(8.dp))
            .background(backgroundColor)
            .border(
                2.dp,
                if (isSelected) MaterialTheme.colorScheme.primary else Color.Transparent,
                RoundedCornerShape(8.dp)
            )
            .clickable { onSelect(rechargeOption) }
            .scale(scale)
            .padding(8.dp),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        Text(text = "$currency${rechargeOption.amount}", fontWeight = FontWeight.Bold, color = textColor)
        rechargeOption.cupon?.cuponText?.let {
            Spacer(modifier = Modifier.height(4.dp))
            Text(text = it, fontSize = 10.sp, color = MaterialTheme.colorScheme.secondary)
        }
    }
}

@Composable
fun ExpertCard(
    modifier: Modifier,
    expertName: String,
    expertImage: String,
    expertSkills: String,
) {
    Row(
        modifier = modifier
            .fillMaxWidth()
            .border(
                width = 2.dp, // Thickness of the border
                color = primaryColor.copy(alpha = 0.15f), // Color of the stroke
                shape = RoundedCornerShape(8.dp) // Ensuring the border has rounded corners
            )
            .clip(RoundedCornerShape(8.dp))
            .padding(12.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        ExpertProfileIcon(
            profileImageUrl = expertImage, modifier = Modifier.size(50.dp)
        )
        Spacer(modifier = Modifier.width(16.dp))
        Column {
            Text(
                text = expertName,
                maxLines = 1,
                style = MaterialTheme.typography.titleMedium,
                modifier = Modifier.fillMaxWidth()
            )

            Text(
                text = expertSkills,
                maxLines = 2,
                style = MaterialTheme.typography.titleSmall,
                modifier = Modifier.fillMaxWidth()
            )
        }
    }
}

