package com.winiwayuser.user_feature_authentication.persentation.otp_validation_page

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import com.winiwayuser.core.persentation.CommonLoadingButton
import com.winiwayuser.core.persentation.showToast
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.ic_otp_screen
import kotlinx.coroutines.delay
import org.jetbrains.compose.resources.painterResource


@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun OtpValidationPage(
    component: OtpValidationComponent
) {
    val topConsultantData by component.state.collectAsState()
    var otpFields by remember { mutableStateOf(List(6) { "" }) }
    var resendEnabled by rememberSaveable { mutableStateOf(true) }
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass

    BoxWithConstraints(
        modifier = Modifier.fillMaxSize()
    ) {

        val widthModifier = when (windowSizeClass) {
            WindowWidthSizeClass.Compact -> Modifier.fillMaxWidth()
            WindowWidthSizeClass.Medium -> Modifier.fillMaxWidth(0.6f)
            else -> Modifier.fillMaxWidth(0.4f)
        }

        // Handle side effects like showing toasts or triggering events
        LaunchedEffect(topConsultantData) {
            when (topConsultantData) {
                is OtpValidationState.OnError -> {
                    showToast((topConsultantData as OtpValidationState.OnError).message)
                }

                is OtpValidationState.OnOtpSent -> {
                    showToast("OTP sent successfully")
                }

                is OtpValidationState.OnOtpValidated -> {
                    showToast((topConsultantData as OtpValidationState.OnOtpValidated).msg)
                    component.event(OtpValidationEvent.OnLoginRegister)
                }

                else -> {}
            }
        }

        Column(
            modifier = widthModifier
                .padding(horizontal = 16.dp, vertical = 20.dp)
                .align(Alignment.Center),
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Image(
                painter = painterResource(Res.drawable.ic_otp_screen),
                contentDescription = null,
                modifier = Modifier.fillMaxWidth()
            )
            Spacer(modifier = Modifier.height(40.dp))
            Header(component._mobileNumber)
            Spacer(modifier = Modifier.height(35.dp))
            OTPScreen(otpFields = otpFields, onOtpFieldsChange = { newOtpFields ->
                otpFields = newOtpFields
            }, onOtpComplete = {
                component.event(OtpValidationEvent.OnValidateOTP(otpFields))
            })
            Spacer(modifier = Modifier.height(16.dp))

            CommonLoadingButton(
                text = "Submit",
                isLoading = topConsultantData is OtpValidationState.OnLoading &&
                        (topConsultantData as OtpValidationState.OnLoading).isLoading,
                modifier = Modifier.fillMaxWidth(),
                onClick = {
                    component.event(OtpValidationEvent.OnValidateOTP(otpFields))
                }
            )


            Row(
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center,
                modifier = Modifier.padding(top = 16.dp)
            ) {
                Text(
                    text = if (resendEnabled) "Resend after" else "Request New OTP",
                    style = MaterialTheme.typography.titleMedium
                )

                Spacer(modifier = Modifier.width(6.dp))

                Timer { enabled ->
                    resendEnabled = enabled
                }

                TextButton(
                    onClick = {
                        component.event(OtpValidationEvent.OnResendOTP)
                        resendEnabled = true
                    },
                    enabled = !resendEnabled
                ) {
                    Text("Resend")
                }
            }
            Spacer(modifier = Modifier.height(30.dp))
        }
    }
}

@Composable
fun Header(mobileNumber: String?) {
    Column(horizontalAlignment = Alignment.CenterHorizontally) {
        Text(
            text = "OTP Verification",
            style = MaterialTheme.typography.titleMedium
        )
        Text(
            text = "Sent via SMS to $mobileNumber",
            style = MaterialTheme.typography.bodyMedium
        )
    }
}

//@Composable
//fun OTPScreen(
//    otpFields: List<String>,
//    onOtpFieldsChange: (List<String>) -> Unit
//) {
//    val focusRequesters = remember { List(6) { FocusRequester() } }
//
//    Row(
//        horizontalArrangement = Arrangement.SpaceBetween,
//        modifier = Modifier.fillMaxWidth()
//    ) {
//        otpFields.forEachIndexed { index, otp ->
//            OutlinedTextField(
//                value = otp,
//                textStyle = TextStyle(textAlign = TextAlign.Center),
//                onValueChange = { newValue ->
//                    if (newValue.length <= 1 && newValue.isNotEmpty() && newValue.first().isDigit()) {
//                        val updatedOtpFields = otpFields.toMutableList().apply {
//                            this[index] = newValue
//                        }
//                        onOtpFieldsChange(updatedOtpFields)
//                        if (newValue.length == 1 && index < otpFields.lastIndex) {
//                            focusRequesters[index + 1].requestFocus()
//                        }
//                    } else if (newValue.isEmpty()) {
//                        val updatedOtpFields = otpFields.toMutableList().apply {
//                            this[index] = newValue
//                        }
//                        onOtpFieldsChange(updatedOtpFields)
//                        if (index > 0) {
//                            focusRequesters[index - 1].requestFocus()
//                        }
//                    }
//                },
//                keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Number),
//                modifier = Modifier
//                    .width(50.dp)
//                    .focusRequester(focusRequesters[index]),
//                keyboardActions = KeyboardActions(
//                    onNext = {
//                        if (index < otpFields.lastIndex) {
//                            focusRequesters[index + 1].requestFocus()
//                        }
//                    }
//                ),
//                singleLine = true,
//            )
//        }
//    }
//}

@Composable
fun OTPScreen(
    otpFields: List<String>,
    onOtpFieldsChange: (List<String>) -> Unit,
    onOtpComplete: () -> Unit
) {
    val focusRequesters = remember { List(6) { FocusRequester() } }

    Row(
        horizontalArrangement = Arrangement.SpaceBetween,
        modifier = Modifier.fillMaxWidth()
    ) {
        otpFields.forEachIndexed { index, otp ->
            OutlinedTextField(
                value = otp,
                textStyle = TextStyle(textAlign = TextAlign.Center),
                onValueChange = { newValue ->
                    if (newValue.length <= 1 && newValue.isNotEmpty() && newValue.first()
                            .isDigit()
                    ) {
                        val updatedOtpFields = otpFields.toMutableList().apply {
                            this[index] = newValue
                        }
                        onOtpFieldsChange(updatedOtpFields)

                        if (newValue.length == 1) {
                            if (index < otpFields.lastIndex) {
                                focusRequesters[index + 1].requestFocus()
                            } else {
                                onOtpComplete()
                            }
                        }
                    } else if (newValue.isEmpty()) {
                        val updatedOtpFields = otpFields.toMutableList().apply {
                            this[index] = newValue
                        }
                        onOtpFieldsChange(updatedOtpFields)
                        if (index > 0) {
                            focusRequesters[index - 1].requestFocus()
                        }
                    }
                },
                keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Phone),
                modifier = Modifier
                    .width(50.dp)
                    .focusRequester(focusRequesters[index]),
                keyboardActions = KeyboardActions(
                    onNext = {
                        if (index < otpFields.lastIndex) {
                            focusRequesters[index + 1].requestFocus()
                        }
                    }
                ),
                singleLine = true,
            )
        }
    }
}

@Composable
fun Timer(onSubmitEnabledChange: (Boolean) -> Unit) {
    var seconds by rememberSaveable { mutableStateOf(60) }
    var timerRunning by remember { mutableStateOf(true) }

    LaunchedEffect(timerRunning) {
        if (timerRunning) {
            while (seconds > 0) {
                delay(1000)
                seconds--
            }
            onSubmitEnabledChange(false)
            timerRunning = false
        }
    }

    Text(
        text = "${seconds / 60}:${if (seconds % 60 < 10) "0" else ""}${seconds % 60}",
        style = TextStyle(fontWeight = FontWeight.Bold)
    )
}