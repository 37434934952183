package com.winiwayuser.feature_call_consultation.persentation.voip_call_page

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import com.winiwayuser.core.agora.AgoraRtcCallback
import com.winiwayuser.core.agora.AgoraRtmVoiceClientApp
import com.winiwayuser.core.data.CommonExpertInfo
import com.winiwayuser.core.date_time.getCurrentDateTime
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.core.timer.CountdownTimer
import com.winiwayuser.core.timer.formatTime
import com.winiwayuser.feature_call_consultation.data.request.EndVoipCallRequest
import com.winiwayuser.feature_call_consultation.data.request.GetVoiceAgoraTokenRequest
import com.winiwayuser.feature_call_consultation.data.request.VoIPCallStatus
import com.winiwayuser.feature_call_consultation.data.response.voip_agora_token.VoipAgoraTokenData
import com.winiwayuser.feature_call_consultation.domain.VoIPCallRepo
import com.winiwayuser.feature_common.persentation.more_tab.edit_profile.di.EditProfileState
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.async
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class VoipCallComponent(
    context: ComponentContext,
    private val expertInfo: CommonExpertInfo,
    private val _callDuration: String,
    private val onClose: () -> Unit,
) : ComponentContext by context, KoinComponent,AgoraRtcCallback {

   // private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
//    private val voipRepository: VoIPCallRepo by inject()
//    private var agoraClient: AgoraRtmVoiceClientApp? = null
//    val expertDetail get() = expertInfo
//
//    fun onClosePage() = onClose.invoke()
//
//    private var countdownTimer:CountdownTimer? = null
//    private var startTime: String? = null
//    private var endTime: String? = null
//    private var voIPData: VoipAgoraTokenData? = null
//
//    private val _callState: MutableStateFlow<String> =
//        MutableStateFlow("Connecting to Expert")
//    val callState get() = _callState.asStateFlow()
//
//    private fun setCallStatus(status: String) {
//        coroutineScope.launch {
//            _callState.emit(status)
//        }
//    }
//
//    private val _callEndedState = MutableStateFlow(false)
//    val callEndedState get() = _callEndedState.asStateFlow()
//
//    private val _networkStatus: MutableStateFlow<String> = MutableStateFlow("Good")
//    val networkStatusState get() = _networkStatus.asStateFlow()
//
//    private val _state: MutableStateFlow<Resources<VoipAgoraTokenData>> =
//        MutableStateFlow(Resources.Loading(true))
//    val state get() = _state.asStateFlow()
//
//
//    fun onMuteToggle(isMuted:Boolean) {
//        // Handle mute toggle logic
//        agoraClient?.onMicrophoneChange(isMuted)
//    }
//
//    fun onEndCall() {
//        onEndCallExpert("Call Ended!")
//        // Handle end call logic, could involve navigation
//    }
//
//    fun onSpeakerToggle(isSpeakerOn:Boolean) {
//        agoraClient?.onSpeakerChange(isSpeakerOn)
//    }
//
//    init {
////        context.lifecycle.doOnStart {
////            getAgoraToken()
////        }
//        context.lifecycle.doOnDestroy {
//            agoraClient = null
//            countdownTimer?.cleanup()
//            countdownTimer = null
//            coroutineScope.cancel()
//        }
//    }
//
//    private fun onUSerOnline(){
//        coroutineScope.launch {
//            countdownTimer = CountdownTimer(
//                durationMillis = _callDuration.toIntOrNull()?.times(1000)?.toLong() ?: 5000,
//                onTickPop = {millies ->
//                    setCallStatus(formatTime(millies))
//                },
//                onFinishPop = {
//                    onEndCallExpert("Low Amount")
//                }
//            )
//            countdownTimer?.start()
//            startTime = getCurrentDateTime()
//            setChangeStatus(VoIPCallStatus.IN_PROGRESS)
//        }
//    }
//    private fun onEndCallExpert(status:String){
//        coroutineScope.launch {
//            agoraClient?.logout()
//            endTime = getCurrentDateTime()
//            setCallStatus(status)
//            countdownTimer?.stop()
//            countdownTimer?.cleanup()
//            val job = async { setChangeStatus(VoIPCallStatus.END) }
//            job.await()
//            _callEndedState.emit(true)
//        }
//    }
//
//    private fun onChangeNetworkStatus(status: String) {
//        _networkStatus.value = status
//    }
//
//    private fun onError(error: String) {
//        coroutineScope.launch {
//            _state.emit(Resources.Error(msg = error))
//        }
//    }
//
//    private fun onLoading(isLoading: Boolean) {
//        coroutineScope.launch {
//            _state.emit(Resources.Loading(isLoading = isLoading))
//        }
//    }
//
//    private fun onSuccess(data: VoipAgoraTokenData) {
//        coroutineScope.launch {
//            _state.emit(Resources.Success(data = data))
//        }
//    }
//
////    private fun getAgoraToken() {
////        coroutineScope.launch {
////            val request = GetVoiceAgoraTokenRequest(expertInfo.expertId.toString())
////            voipRepository.getVoipAgoraToken(request).collectLatest { response ->
////                when (response) {
////                    is Resources.Error -> onError(response.msg ?: "Unexpected Error")
////                    is Resources.Loading -> onLoading(response.isLoading)
////                    is Resources.Success -> {
////                        voIPData = response.data
////                        agoraClient = AgoraRtmVoiceClientApp(
////                            appId = "414eb4458bcc43039fd697e672dd0284",this@VoipCallComponent
////                        )
////                        voIPData?.voipCallDetails?.let {
////                            agoraClient?.initialize(
////                                onLoginSuccess = {
////                                    agoraClient?.onJoinChannel(
////                                        peerId = it.expertId,
////                                        token = it.token,
////                                        channel = it.channelName,
////                                        onJoinChannelSuccess = {
////
////                                        },
////                                        onJoinChaError = { msg ->
////                                            onError(msg)
////                                        }
////                                    )
////                                    agoraClient?.getNetworkQuality { txQuality, _ ->
////                                        val text = when (txQuality?.toIntOrNull()) {
////                                            0, 1, 2 -> "GOOD"
////                                            3, 4, 5 -> "POOR"
////                                            else -> "DOWN"
////                                        }
////                                        onChangeNetworkStatus(text)
////                                    }
////                                },
////                                onLoginError = { msg ->
////                                    onError(msg)
////                                }
////                            )
////                        }
////
////                    }
////                }
////            }
////        }
////    }
//
//    private fun setChangeStatus(status: VoIPCallStatus) {
//        val request = EndVoipCallRequest(
//            consultationId = voIPData?.voipCallDetails?.voipCallId.toString(),
//            startConsultationTimeStamp = startTime,
//            endConsultationTimeStamp = endTime,
//            expertId = expertInfo.expertId.toString(),
//            status = status.name
//        )
//        voIPStatusLogToServer(request)
//    }
//
//    private fun voIPStatusLogToServer(request: EndVoipCallRequest) {
//        coroutineScope.launch {
//            voipRepository.endVoipCall(request).collectLatest {
//                Logger.d("VoIP Status Log Server")
//            }
//        }
//    }

    override fun onUserOnline(userId: Int) {
        Logger.d("on USer online")
//        if (countdownTimer != null) return
//        onUSerOnline()
    }

    override fun onUserOffline(userId: Int) {
        Logger.d("on User Offline")
//        onEndCallExpert("Expert Ended Call")
    }

    override fun onMicrophonePermissionGranted() {
        Logger.d("on Microphone Permission granted")
//        onSuccess(voIPData!!)
    }

    override fun onMicrophonePermissionDenied() {
//        onError("Microphone Permission Denied!")
        Logger.d("on Microphone Permission not Granted")
    }


}