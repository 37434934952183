package com.winiwayuser.feature_chat_consultation.persentation.chat_concern

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.winiwayuser.core.persentation.CommonButton
import com.winiwayuser.core.persentation.LoadingProgressDialog

@Composable
fun ChatConnectingScreen(
    concernComponent: ChatConcernComponent
) {

    val state by concernComponent.state.collectAsState()

    if (state is ChatConcernState.LoadingState) {
        val isLoading = (state as ChatConcernState.LoadingState).isLoading
        if (isLoading) LoadingProgressDialog { }
    }

    if (state is ChatConcernState.OnError) {
        val errorMsg = (state as ChatConcernState.OnError).msg
        ErrorScreen(
            errorMessage = errorMsg,
            onRetry = { concernComponent.onRetry() }
        )
    }

}

@Composable
fun ErrorScreen(
    errorMessage: String,
    onRetry: () -> Unit
) {
    // Center content vertically and horizontally
    Box(
        modifier = Modifier
            .fillMaxSize() // Fills the whole screen
            .padding(16.dp) // Padding around the content
    ) {
        Column(
            modifier = Modifier
                .align(Alignment.Center) // Align content to the center
                .padding(16.dp), // Optional padding inside the column
            horizontalAlignment = Alignment.CenterHorizontally // Align children horizontally centered
        ) {
            // Title
            Text(
                text = "Error",
                fontSize = 24.sp,
                color = MaterialTheme.colorScheme.error
            )

            Spacer(modifier = Modifier.height(16.dp)) // Space between title and error detail

            // Error details
            Text(
                text = errorMessage,
                fontSize = 16.sp,
                color = MaterialTheme.colorScheme.onBackground,
                style = MaterialTheme.typography.bodyMedium
            )

            Spacer(modifier = Modifier.height(32.dp)) // Space between error detail and button

            // Retry button
            CommonButton(
                buttonText = "Retry",
                onClick = { onRetry.invoke() },
                modifier = Modifier
            )
        }
    }
}
