package com.winiwayuser.feature_chat_consultation.persentation.chat_concern

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_chat_consultation.data.request.PlaceChatRequestBody
import com.winiwayuser.feature_chat_consultation.domain.ChatConcernRepo
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class ChatConcernComponent(
    private val context: ComponentContext,
    val expertName: String,
    val expertImage: String,
    val expertSkills: String,
    val expertId: String,
    val chatDuration:String,
    private val event:(ChatConcernEvent)->Unit,

) : ComponentContext by context, KoinComponent {

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    private val api: ChatConcernRepo by inject()
    private val _state = MutableStateFlow<ChatConcernState>(ChatConcernState.InitialState)
    val state get() = _state.asStateFlow()


    fun onClosePage() = event.invoke(ChatConcernEvent.CloseThis)
    private fun startConsultation(chatId:String,userUid:String) = event.invoke(ChatConcernEvent.OnStartConsultation(
        chatId, userUid
    ))

    fun onRetry(){
        coroutineScope.launch {
            getConsultationInfo()
        }
    }

    init {

        lifecycle.doOnStart {
            coroutineScope.launch {
                getConsultationInfo()
            }
        }

        lifecycle.doOnDestroy {
            coroutineScope.cancel() // Cancel all coroutines when the component is destroyed
        }
    }

    private suspend fun onErrorState(msg: String) {
        _state.emit(ChatConcernState.OnError(msg))
    }

    private suspend fun getConsultationInfo() {

        Logger.d("Consultation Info start here")
        val request = PlaceChatRequestBody(
            questionId = null,
            expertId = expertId,
            category = null,
            answers = null,
            flashOff = null,
            images = null,
            type = null
        )

        api.placeWithChatConcern(request).collect {

            when (it) {
                is Resources.Error -> {
                    Logger.d("On Error -> ${it.msg}")
                    onErrorState(it.msg ?: "Unexpected Error")
                }

                is Resources.Loading -> {
                    Logger.d("On Loading -> ${it.isLoading}")
                    _state.emit(ChatConcernState.LoadingState(it.isLoading))
                }

                is Resources.Success -> {
                    Logger.d("on Success -> ${it.data}")
                    it.data?.let { d ->

                        event.invoke(ChatConcernEvent.ConnectToExpert(
                            token = d.agoraToken!!, uid = d.agoraUserId!!,
                            onError = {msg->
                                coroutineScope.launch {
                                    onErrorState(msg)
                                }
                            },
                            onSuccess = {
                                startConsultation(
                                    chatId = d.chatId.toString(),
                                    userUid = d.agoraUserId
                                )
                            }
                        ))
                    }
                }
            }
        }
    }

}