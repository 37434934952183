package com.winiwayuser.core.local

import com.russhwolf.settings.set
import com.winiwayuser.core.persentation.settings

object TokenProvider {

    private const val AUTH_KEY_TOKEN_NEW = "new_token_winyway"
    private const val AUTH_KEY_TOKEN_REFRESH = "refresh_token_winyway"

    fun setNewToken(token: String){
        settings.putString(AUTH_KEY_TOKEN_NEW,token)
    }

    fun getNewToken(): String{
        return settings.getString(AUTH_KEY_TOKEN_NEW,"")
    }

    fun getRefreshToken(): String{
        return settings.getString(AUTH_KEY_TOKEN_REFRESH,"")
    }

    fun setRefreshToken(token: String){
        settings[AUTH_KEY_TOKEN_REFRESH] = token
    }

}