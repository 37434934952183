package com.winiwayuser.core.di

import com.winiwayuser.feature_authentication.di.authorizationModule
import com.winiwayuser.feature_call_consultation.di.callConsultationModule
import com.winiwayuser.feature_chat_consultation_new.di.chatConsultationModule
import com.winiwayuser.feature_common.di.homeCommonModule
import com.winiwayuser.feature_common.persentation.more_tab.edit_profile.di.editProfileCommonModule
import com.winiwayuser.feature_expert_consultation.feature_expert_search.data.searchExpertCommonModule
import com.winiwayuser.feature_expert_details.di.expertDetailsCommonModual
import com.winiwayuser.feature_winyway_wallet.di.walletModule
import org.koin.core.context.startKoin

fun initKoin() {
    startKoin {
        modules(
            authorizationModule,
            homeCommonModule,
            editProfileCommonModule,
            walletModule,
            searchExpertCommonModule,
            chatConsultationModule,
            callConsultationModule,
            expertDetailsCommonModual
        )
    }
}