package com.winiwayuser.user_feature_authentication.persentation.login_page

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.ui.Alignment
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.imePadding
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.platform.LocalSoftwareKeyboardController
import androidx.compose.ui.unit.dp
import com.winiwayuser.feature_authentication.persentation.login_page.widget.LoginInputBox
import com.winiwayuser.feature_authentication.persentation.login_page.widget.WelcomeSlide

@Composable
fun loginPage(
    component: LoginPageComponent
) {
    val keyboardController = LocalSoftwareKeyboardController.current
    val focusManager = LocalFocusManager.current

    Column(
        verticalArrangement = Arrangement.Bottom,
        horizontalAlignment = Alignment.CenterHorizontally,
        modifier = Modifier
            .padding(12.dp)
            .fillMaxSize()
            .imePadding()  // This will add padding when the keyboard is visible
    ) {
        Box(modifier = Modifier.weight(1f).fillMaxWidth()) {
            WelcomeSlide()
        }

        Spacer(modifier = Modifier.height(24.dp))

        Box(
            modifier = Modifier
                .wrapContentHeight()
                .fillMaxWidth()
                .align(Alignment.CenterHorizontally)
        ) {
            LoginInputBox(
                onClickTerms = {
                    keyboardController?.hide()
                    focusManager.clearFocus()
                    component.onPrivacyPolicyClick()
                },
                onClickSubmit = { mob, countryCode, isWhatsappUpdatesChecked ->
                    keyboardController?.hide()
                    focusManager.clearFocus()
                    component.onValidateOtp(
                        mobile = mob,
                        mobileCode = countryCode,
                        isWhatsappUpdatesChecked = isWhatsappUpdatesChecked
                    )
                }
            )
        }
    }
}


