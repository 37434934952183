package com.winiwayuser.feature_common.persentation.home_tab

import com.winiwayuser.feature_common.data.request.RequestCategory

sealed class HomePageEvent {
    data class OnClickTopConsultant(val expertCategory: Int) : HomePageEvent()
    data object OnClickLogin : HomePageEvent()
    data class OnClickCategory(val category: RequestCategory) : HomePageEvent()
    data class OnLiveSessionClick(val id: Int) : HomePageEvent()
    data class OnSearchClick(val searchText: String) : HomePageEvent()
    data object OnClickProfileIcon : HomePageEvent()
    data object OnClickPrivacyPolicy : HomePageEvent()
    data object OnClickWalletIcon : HomePageEvent()
    data object OnClickTermsAndConditions : HomePageEvent()
    data class OnChatConsultationStart(
        val consultantId: Int,
        val consultantName: String,
        val consultantImage: String,
        val consultantSkills: String
    ) : HomePageEvent()

    data class OnCallConsultationStart(
        val consultantId: Int,
        val consultantName: String,
        val consultantImage: String,
        val consultantSkills: String
    ) : HomePageEvent()
}