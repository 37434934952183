package com.winiwayuser.feature_expert_details.Data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RelatedExpertRequest(
    @SerialName("category_id") val category: String,
    @SerialName("limit") val expertCountRequired: Int,
)
