package com.winiwayuser.feature_call_consultation.persentation.call_bottomsheet
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import co.touchlab.kermit.Logger
import com.winiwayuser.core.persentation.LoadingProgressDialog
import com.winiwayuser.feature_call_consultation.call_bottom_sheet_info.CallInfoBottomSheetState
import com.winiwayuser.feature_call_consultation.persentation.call_bottomsheet.expert_busy.ErrorBottomSheetCallConsultation
import com.winiwayuser.feature_call_consultation.persentation.call_bottomsheet.widget.CallRechargeNowBottomSheetDialog
import com.winiwayuser.feature_call_consultation.persentation.call_bottomsheet.widget.CallRechargeNowDialog
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData


@Composable
fun CallConsultationBottomSheet(component: CallConsultationComponent) {
    val state by component.state.collectAsState()
   // val context = LocalContext.current

    when (val currentState = state) {
        is CallInfoBottomSheetState.Loading -> {
            if (currentState.isLoading) {
                LoadingProgressDialog { }
            }
        }

        is CallInfoBottomSheetState.NormalCallLowBalance -> {
           Logger.d("Call Low Balance State")
            CallRechargeNowDialog(
                rechargeData = currentState.rechargeData,
                onDismissRequest = {
                    component.onClosePage()
                },
                data = currentState.data,
                onRechargeSelected = { selectedAmount ->
                    Logger.d("selected amount - $selectedAmount")
                    component.event(CallConsultationEvent.onNavigateRechargeCheckOut(selectedAmount))
                }
            )
        }

        is CallInfoBottomSheetState.CallInfoSuccess -> {
            Logger.d("Chat Success State -> ${currentState.data}")
            CallInfoSuccessBottomSheet(
                consultationComponent = component,
                callDurationData = currentState.data,
                onDismiss = { component.onClosePage() }
            )
        }

        is CallInfoBottomSheetState.Error -> {
            Logger.d("Call Error State")
            ErrorBottomSheetCallConsultation(msg = currentState.msg, onDismiss = { component.onClosePage()})
        }

        is CallInfoBottomSheetState.FreeCallLowBalance -> {
           Logger.d("Call Free Call State")
            val planData = RechargeData(
                amount = currentState.data.plan_amount,
                id = currentState.data.planId,
                planName = currentState.data.plan_name,
                null
            )
            component.event(CallConsultationEvent.onNavigateRechargeCheckOut(planData))
//            CallRechargeNowDialog(
//                rechargeData = ,
//                onDismissRequest = {
//                    component.onClosePage()
//                },
//                data = currentState.data,
//                onRechargeSelected = { selectedAmount ->
//                    Logger.d("selected amount - $selectedAmount")
//                    component.event(CallConsultationEvent.onNavigateRechargeCheckOut(selectedAmount))
//                }
//            )
        }
    }
}
