package com.winiwayuser.feature_call_consultation.persentation.call_bottomsheet

import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.data.response.Cupon
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData
import kotlinx.serialization.SerialName

sealed class CallConsultationEvent {
    data class onNavigateRechargeCheckOut(
        val rechargeData: RechargeData
    ): CallConsultationEvent()

    data object onNavigateChoseRechargePack : CallConsultationEvent()
}