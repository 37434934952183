package com.winiwayuser.feature_chat_consultation_new.domain

import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_chat_consultation_new.data.request.GetChatDurationRequest
import com.winiwayuser.feature_chat_consultation_new.data.response.chat_duration.ChatDurationData
import com.winiwayuser.feature_chat_consultation_new.data.api.ChatConsultationApi
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow

class ChatConsultationInfoRepo(
    private val api: ChatConsultationApi
) {

    fun getChatConsultationInfo(chatDurationRequest: GetChatDurationRequest): Flow<Resources<ChatDurationData>> {
        return flow {
            emit(Resources.Loading())
            val response = api.getChatDurationInfo(chatDurationRequest)
            emit(response)
            emit(Resources.Loading(false))
        }
    }

}