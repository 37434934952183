package com.winiwayuser.core.theme

import com.winiwayuser.core.local.UserInfoProvider.getPhone


const val lowest_balance_to_chat_consultaion_txt = "Minimum balance of %s minutes (INR %s) is required to start Chat with %s"
const val pricing = "Pricing"

const val wallet_balance = "Wallet Balance"

const val maximum_call_duration = "Maximum Call Duration"

const val let_s_connect = "Let\'s Connect"
var userNumber = getPhone()
var incomingCallNumber = ""
val call_waiting_long_text = "You will receive a call shortly from\n $incomingCallNumber on your registered Mobile number (+91 $userNumber)"

const val under_development_toast = "Under Development"
const val default_number = "0000000000"

const val user_app_play_store_url = "https://play.google.com/store/apps/details?id=com.winiwayuser"
const val expert_app_play_store_url = "https://play.google.com/store/apps/details?id=com.winyway"
const val winyway_instagram_page_url = "https://www.instagram.com/winywayapp/"
const val winyway_linkedin_page_url = "https://www.linkedin.com/company/winyway/?viewAsMember=true/"
const val winyway_facebook_page_url = "https://www.facebook.com/winywayapp"
const val winyway_youtube_page_url = "https://www.youtube.com/@winyway"


val subtitles = listOf(
    "Personal information",
    "Credit card security",
    "Advertising",
    "What you should know",
    "Your general contact information",
    "The information user provides us",
    "Usage information",
    "Device information",
    "Location information",
    "Local storage",
    "Cookies and similar technologies",
    "Browser notification preference",
    "How we use the information, which we collect",
)
// Option Rows
val expert_list_sort_options = listOf(
    "Popularity",
    "Price High to Low",
    "Price Low to High",
    "Experience High to Low",
    "Rating High to Low"
)

val bodies = listOf(
    "In order to operate the Site in an efficient and effective manner and provide users with information on various services & products like legal advice, fitness experts CA etc. that may be of interest to them, Winyway.com may collect personal information, including contact information (such as an email address), from its users. In addition, this information allows us to provide users with relevant details. We also automatically track certain information based upon your behavior on the Site and use this information to do internal research on our users’ demographics, interests, and behavior to better understand, protect and serve users in general.\n" +
            "You agree that Winyway.com may use your personal information to contact you and deliver information to youthat, in some cases, is targeted to your interests or provides administrative notices or communications applicable to your use of the Site. By accepting this Privacy Policy, you expressly agree to receive this information. If you do not wish to receive these communications, we encourage you to opt-out of any further receipt by following the opt-out provisions provided in each such communication.\n" +
            "We do not sell our users’ personal information to anyone for any reason if the user has indicated a desire for us to keep the information private. When placing an order for an expert’s advice service, our users decide for themselves how much contact information they wish to display.\n" +
            "All users should be aware, however, that when they voluntarily display or distribute personal information (such as their email address), that information can be collected and used by others. This may result in unsolicited messages from third parties for which Winyway.com is not responsible. In addition, you may have arrived at this Web site by following a link from any other affiliate. If so, please be aware that Winyway.com may share your information with that affiliate and the affiliate may use the information consistent with its privacy policy instead of this one.\n" +
            "Winyway.com may also disclose specific user information when we determine that such disclosure is necessary to comply with the law, to cooperate with or seek assistance from law enforcement or to protect the interests or safety of Winyway.com or other users of the Site. In addition, personal information we have collected may be passed on to a third party in the event of a transfer of ownership or assets or a bankruptcy of Winyway.com",
    "Winyway.com employs encryption for secure credit card transactions. At Winyway.com, we use 128-bit SSL encryption, the highest level of SSL encryption currently available to consumers. This ensures the safety and security of your credit card transactions. SSL (“Secure Sockets Layer”) means that our server and your browser create and agree on an encryption key that will be used only for that particular session. Once established, this key encrypts all communication between our server and your browser.",
    "We use third-party advertising companies to serve ads when you visit our Web site. These companies may use information about you and your visits to this and other Web sites in order to provide advertisements on this site and other sites about goods and services of interest to you.",
    "Winyway.com cannot ensure that all of your private communications and other personal information will never be disclosed in ways not otherwise described in this Privacy Policy. Therefore, although we are committed to protecting your privacy, we do not promise, and you should not expect, that your personal information or private communications would always remain private. As a user of the Site, you understand and agree that you assume all responsibility and risk for your use of the Site, the internet generally, and the documents you post or access and for your conduct on and off the Site.\n" + "Logging into Winyway.com will enable the user to book appointment with an experts, to attain the live session given by different experts & check post.",
    "Managing your privacy is as simple as selecting which pieces of your contact information are displayed. This is done in the section of the resume form called “Contact Information.” Using option 3 above requires that you select the Apply Online Only option from the “Contact Information” section at the bottom of the edit form, as shown below.\n" +
            "If you think you have been a victim of fraud, immediately report the committed fraud to your local police and contact us.",
    "Many of our services require users to sign up for a Winyway Account. We would require some personal information, like user name, email address, phone number and gender.\n" +
            "For ensuring an enhanced and customized user experience, we also ask users to add his/her favorite categories of experts (Lawyer, Astrologers, Life coach etc.).",
    "We also track and collect information about user interaction, like when an app is installed, a Recharge is done to Wallet, a Consultation is made with Experts, a rating is provided to Experts. This information includes:",
    "We collect device-specific information (such as unique device identifiers, and mobile network information). Winyway may associate user device identifiers or phone number with their Winyway Account.",
    "When a user avail Winyway services, we may collect and process information about their actual location. We use various technologies to determine location, including IP address, GPS, and other sensors that may.",
    "We may collect and store information (including personal information) locally on the user’s device using mechanisms such as browser web storage (including HTML 5) and application data caches.",
    "We use various technologies to collect and store information when the user browses the website or app, and this may include using cookies or similar technologies to identify their browser or device and for marketing purposes. Our product Winyway keep track of the user’s session to retain their information for multiple days without being logged out.",
    "We ask for browser notification preference for delivering the browser-based notification directly to the device. The user is free to enable or disable this preference and permission at any point in time.",
    "We use the information we collect for: research and development, marketing, product analysis and to ensure the best user experience. We also use this information to offer user’s experts search content – like giving them relevant Top Experts, Experts domain content, Experts for appointment.\n" +
            "We may use the name user provide in their Winyway Profile across all of the services we offer that require a Winyway Account. In addition, we may replace multiple names associated with the user’s Winyway Account so that they are represented consistently across all our services. Other users of Experts cannot view or have a name, email, photo or other information that has already been claimed by a user.\n" +
            "User’s First Name, Birth Detail, and Interests will be shared with Experts for consultation.\n" +
            "When a user signs In Winyway, we keep a record of their communication with Experts to help, they reach the most relevant Experts who can provide consultation for specific issues or concerns. We may use a user’s email address to inform them about our services, such as letting them know about upcoming changes or improvements.\n" +
            "We use information collected from cookies and other technologies, like IP Address and Location to localize the overall quality of our services. We use the Current Location of the user in Winyway services to display our pricing in their preferred currency. For example, by saving the user’s current location or country preferences, we will be able to have shown their preferred payment gateway.",
)