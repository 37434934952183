package com.winiwayuser.feature_expert_consultation.presentation.expert_detail_page

import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData

sealed class ExpertDetailsPageEvent {
    data object onBackButtonClick: ExpertDetailsPageEvent()
    data class onCallButtonClick(
        val expertId: String,
        val  expertName: String,
        val expertImage: String,
        val expertSkills: String
    ): ExpertDetailsPageEvent()

    data class onChatButtonClick(
        val expertId: String,
        val  expertName: String,
        val expertImage: String,
        val expertSkills: String,
        val duration:String
    ): ExpertDetailsPageEvent()

    data class onRechargeRequired(val data: RechargeData):ExpertDetailsPageEvent()

    data object onClickLoginButton: ExpertDetailsPageEvent()

    data class onRelatedExpertCardClick(val expertId: Int) : ExpertDetailsPageEvent()
}