package com.winiwayuser.feature_common.persentation.home_tab.home_screen_widget

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import co.touchlab.kermit.Logger
import coil3.compose.AsyncImage
import com.winiwayuser.core.data.CommonExpertInfo
import com.winiwayuser.core.local.UserInfoProvider.getIsLogin
import com.winiwayuser.core.persentation.CallConsultationButton
import com.winiwayuser.core.persentation.ChatConsultationButton
import com.winiwayuser.core.persentation.ExpertPriceRow
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.theme.Scale.CORNER_SMALL
import com.winiwayuser.core.theme.star_rating_color
import com.winiwayuser.feature_call_consultation.persentation.call_starter.CallHostPage
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_duration_dailog.ChatConsultationStarter
import com.winiwayuser.feature_common.data.request.RequestConsultant
import com.winiwayuser.feature_common.persentation.home_tab.HomeScreenTabComponent
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.ic_filled_star
import org.jetbrains.compose.resources.painterResource
import kotlin.math.round


@Composable
fun ConsultantListItemUI(
    component: HomeScreenTabComponent,
    onItemClick: (RequestConsultant) -> Unit,
    onLoginClick: () -> Unit,
    onChatConsultation: (RequestConsultant, String, String) -> Unit,
    onCallConsultation: (RequestConsultant) -> Unit,
    onRechargeRequired: (RechargeData?) -> Unit,
    onVoIPClick: (String, CommonExpertInfo) -> Unit
) {
    val topConsultantData by component.topConsultant.collectAsState()
    var isChatClickSelected by remember { mutableStateOf<RequestConsultant?>(null) }
    var isCallClickSelected by remember { mutableStateOf<RequestConsultant?>(null) }

    if (topConsultantData.isNullOrEmpty()) return

    Column(
        modifier = Modifier
            .padding(horizontal = Scale.HORIZONTAL_PADDING)
            .fillMaxWidth()
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.fillMaxWidth()
        ) {
            Text(
                text = "Top Consultants",
                maxLines = 1,
                style = MaterialTheme.typography.titleMedium,
                modifier = Modifier
                    .padding(top = Scale.VERTICAL_PADDING)
                    .weight(1f)
            )
        }
        LazyRow(
            modifier = Modifier
                .fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceEvenly
        ) {
            items(topConsultantData!!) { consultant ->
                ConsultantListItem(
                    consultant = consultant,
                    onItemClick = onItemClick,
                    onCallClick = { callConsultant ->
                        isCallClickSelected = callConsultant
                    },
                    onChatClick = { chatConsultant ->
                        isChatClickSelected = chatConsultant
                    },
                    isFreeCallApplicable = component.is_free_consultation
                )
            }
        }
    }
    if (isCallClickSelected != null) {
        Logger.d("ifCallClicked")
        CallHostPage(
            callDetails = isCallClickSelected!!,
            onLoginClicked = {
                onLoginClick.invoke()
            },
            onDismiss = {
                isCallClickSelected = null
            },
            onStartCallConsultation = { onCallConsultation.invoke(isCallClickSelected!!) },
            onNavigateRechargeCheckOut = { rechargeData ->
                onRechargeRequired.invoke(rechargeData)
            },
            context = component,
            onVoIpCallStart = { duration, expertInfo -> onVoIPClick.invoke(duration, expertInfo) }
        )
    }

    if (isChatClickSelected != null) {
        Logger.d("ifChatClicked")
        ChatConsultationStarter(
            chatDetails = isChatClickSelected!!,
            onLoginClicked = {
                onLoginClick.invoke()
            },
            onDismiss = {
                isChatClickSelected = null
            },
            onStartChatConsultation = { duration, category ->
                onChatConsultation.invoke(
                    isChatClickSelected!!,
                    duration,
                    category
                )
            },
            context = component,
            onRechargeAgain = { rechargeData ->
                onRechargeRequired.invoke(rechargeData)
            }
        )
    }
}


@Composable
fun ConsultantListItem(
    consultant: RequestConsultant,
    onItemClick: (RequestConsultant) -> Unit,
    onCallClick: (RequestConsultant) -> Unit,
    onChatClick: (RequestConsultant) -> Unit,
    isFreeCallApplicable: Boolean
) {
    Column(
        modifier = Modifier
            .padding(4.dp)
            .width(160.dp)
            .wrapContentHeight()
    ) {
        Card(
            colors = CardDefaults.cardColors(Color.White),
            modifier = Modifier
                .clickable { onItemClick(consultant) }
                .height(220.dp),
            shape = RoundedCornerShape(CORNER_SMALL),
            elevation = CardDefaults.cardElevation(Scale.ELEVATION_SMALL)
        ) {
            Column(
                modifier = Modifier.padding(8.dp),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally // Center the content horizontally
            ) {
                RatingRow(consultant.consultant_rating!!)

                Spacer(modifier = Modifier.height(5.dp))

                ProfileImage(consultant.profile_image)

                Spacer(modifier = Modifier.height(5.dp))

                Text(
                    text = "+${consultant.year_of_experience} Years",
                    style = TextStyle(color = Color.Black),
                    fontSize = 12.sp,
                    textAlign = TextAlign.Center,
                    modifier = Modifier.fillMaxWidth()
                )

                Text(
                    text = consultant.full_name ?: "",
                    maxLines = 1,
                    style = MaterialTheme.typography.titleMedium,
                    modifier = Modifier.fillMaxWidth(),
                    textAlign = TextAlign.Center
                )

                Text(
                    text = consultant.skills ?: "",
                    maxLines = 2,
                    minLines = 2,
                    modifier = Modifier.fillMaxWidth(),
                    style = MaterialTheme.typography.bodySmall,
                    overflow = TextOverflow.Ellipsis,
                    textAlign = TextAlign.Center
                )

                Box(
                    modifier = Modifier.fillMaxWidth(),
                    contentAlignment = Alignment.Center // Center the CallPrice composable within the Box
                ) {
                    ExpertPriceRow(
                        isFreeCallApplicable = isFreeCallApplicable,
                        //isFreeCallApplicable = if(consultant.isFreeCall?.equals(1) == true) true else false,
                        callPerMinutePrice = consultant.callPerMinutePrice.toString(),
                        expertPrice = consultant.expert_price ?: 0,
                        winywayPrice = consultant.winyway_price ?: 0,
                        flashPrice = consultant.flashPerMinutePrice,
                        horizontalArrangement = Arrangement.Center
                    )
//                    CallPrice(consultant, isFreeCallApplicable)
                }
            }
        }
        val openDialog = remember { mutableStateOf(false) }
        if (openDialog.value) {
            com.winiwayuser.feature_expert_consultation.presentation.expert_list_page.widget.openDialog(
                openDialog
            )
        }
        ActionButtons(
            consultant,
            onCallClick = { onCallClick.invoke(consultant) },
            onChatClick = { onChatClick.invoke(consultant) }
        )
    }
}


@Composable
fun RatingRow(rating: Double) {
    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.Start,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Icon(
            painter = painterResource(Res.drawable.ic_filled_star),
            // painter = Icon(Icons.Default.Call),
            contentDescription = null,
            tint = star_rating_color,
            modifier = Modifier.size(14.dp)
        )
        ConsultantRating(rating)
    }
}

@Composable
fun ProfileImage(profileImageUrl: String?) {
    Box(
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.Center
    ) {
        Box(
            modifier = Modifier
                .size(85.dp)
                .border(
                    width = 2.dp,
                    color = Color.Gray.copy(alpha = 0.3f),
                    shape = CircleShape
                )
                .clip(CircleShape)
                .background(Color.White)
                .padding(1.dp)
                .clip(CircleShape)
        ) {
            AsyncImage(
                model = profileImageUrl,
                modifier = Modifier
                    .size(85.dp)
                    .clip(CircleShape),
                contentScale = ContentScale.Crop,
                contentDescription = null,
            )
        }
    }
}


@Composable
fun CallPrice(consultant: RequestConsultant, isFreeCallApplicable: Boolean) {
    val callPerMinutePrice = consultant.callPerMinutePrice.toString()
    val isExpertFreeCall = consultant.isFreeCall == 1
    val flashPrice = consultant.expertFlashPrice?.let { consultant.winywayFlashPrice?.plus(it) }
    val isFreeCallApplicableCalculated =
        if (!getIsLogin()) isExpertFreeCall else isExpertFreeCall && isFreeCallApplicable

    if (isFreeCallApplicableCalculated) {
        Row {
            Text(
                text = "NEW USER",
                fontSize = 14.sp,
                textAlign = TextAlign.Center,
                color = Color.Black,
                modifier = Modifier.padding(horizontal = 1.dp)
            )
            Text(
                text = "₹ $callPerMinutePrice/Min",
                fontSize = 12.sp,
                textAlign = TextAlign.Center,
                color = Color.Gray,
                textDecoration = TextDecoration.LineThrough,
                modifier = Modifier.padding(horizontal = 1.dp)
            )
        }
    } else {
        if (flashPrice != null && flashPrice.toInt() != 0) {
            Box(
                modifier = Modifier
                    .padding(horizontal = 2.dp, vertical = 1.dp),
                contentAlignment = Alignment.Center
            ) {
                Row(
                    // modifier = Modifier.padding(horizontal = 2.dp, vertical = 1.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Text(
                        text = "₹ ${flashPrice}/Min",
                        fontSize = 14.sp,
                        textAlign = TextAlign.Center,
                        color = Color.Black,
                        fontWeight = FontWeight.Bold,
                        modifier = Modifier.padding(horizontal = 1.dp)
                    )
                    Spacer(modifier = Modifier.width(1.dp))
                    Text(
                        text = "₹ $callPerMinutePrice/Min",
                        fontSize = 12.sp,
                        textAlign = TextAlign.Center,
                        color = Color.Gray,
                        textDecoration = TextDecoration.LineThrough,
                        modifier = Modifier.padding(horizontal = 1.dp)
                    )
                }
            }
        } else {
            Box(
                modifier = Modifier
                    .padding(horizontal = 2.dp, vertical = 1.dp),
                contentAlignment = Alignment.Center
            ) {
                Text(
                    text = "₹ $callPerMinutePrice /Min",
                    fontSize = 14.sp,
                    textAlign = TextAlign.Center,
                    fontWeight = FontWeight.Bold,
                    color = Color.Black,
                    maxLines = 1,
                    modifier = Modifier.padding(horizontal = 2.dp)
                )
            }
        }
    }
}

@Composable
fun ActionButtons(
    consultant: RequestConsultant,
    onCallClick: () -> Unit,
    onChatClick: () -> Unit
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .height(50.dp).padding(top = 4.dp),
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        // Call Button
        CallConsultationButton(
            modifier = Modifier.weight(1f).height(36.dp),
            chatStatus = consultant.chatButtonStatus,
            callStatus = consultant.callStatus,
            onClick = {
                onCallClick.invoke()
            },
            isTextVisible = false
        )

        Spacer(modifier = Modifier.weight(0.1f))

        // Chat Button
        ChatConsultationButton(
            modifier = Modifier
                .weight(1f)
                .height(36.dp),
            chatStatus = consultant.chatButtonStatus,
            callStatus = consultant.callStatus,
            onClick = {
                onChatClick.invoke()
            },
            isTextVisible = false

        )
    }
}


@Composable
fun ConsultantRating(rating: Double?) {
    val roundedRating = rating?.coerceIn(0.0, 5.0)?.let { round(it * 10) / 10 } ?: 5.0
    val ratingText = "$roundedRating"
    Text(
        text = ratingText,
        fontSize = 12.sp,
        style = TextStyle(fontWeight = FontWeight.Bold),
        textAlign = TextAlign.Center,
        color = Color.Black,
        modifier = Modifier.padding(horizontal = 4.dp)
    )
}