package com.winiwayuser.feature_chat_consultation.persentation.chat_concern

sealed class ChatConcernEvent {
    data class ConnectToExpert(
        val token: String,
        val uid: String,
        val onError: (String) -> Unit,
        val onSuccess: () -> Unit
    ) : ChatConcernEvent()

    data object CloseThis : ChatConcernEvent()
     data class OnStartConsultation(
         val chatId:String,
         val userUid:String
     ) : ChatConcernEvent()
}