
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.platform.LocalAccessibilityManager
import androidx.compose.ui.platform.LocalAutofill
import androidx.compose.ui.platform.LocalClipboardManager
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.platform.LocalHapticFeedback
import androidx.compose.ui.platform.LocalInputModeManager
import androidx.compose.ui.platform.LocalInspectionMode
import androidx.compose.ui.platform.LocalLayoutDirection
import androidx.compose.ui.platform.LocalSoftwareKeyboardController
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.platform.LocalViewConfiguration
import androidx.compose.ui.platform.LocalWindowInfo
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.LayoutDirection
import androidx.compose.ui.window.CanvasBasedWindow
import com.arkivanov.decompose.DefaultComponentContext
import com.arkivanov.decompose.ExperimentalDecomposeApi
import com.arkivanov.decompose.router.stack.webhistory.DefaultWebHistoryController
import com.arkivanov.essenty.lifecycle.LifecycleRegistry
import com.arkivanov.essenty.lifecycle.resume
import com.arkivanov.essenty.lifecycle.stop
import com.winiwayuser.core.decompose.DefaultRootComponent
import com.winiwayuser.core.di.initKoin
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.skiko.wasm.onWasmReady
import org.w3c.dom.events.Event


@OptIn(ExperimentalComposeUiApi::class, ExperimentalDecomposeApi::class)
fun main() {

    initKoin()
    val myLifecycleOwner = MyLifecycleOwner()
    val lifecycleRegistry = LifecycleRegistry()

    val root = DefaultRootComponent(
        componentContext = DefaultComponentContext(lifecycle = myLifecycleOwner.lifecycle),
        deepLink = DefaultRootComponent.DeepLink.Web(path = window.location.pathname),
        webHistoryController = DefaultWebHistoryController()
    )

    val prefersDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches

    onWasmReady {

        lifecycleRegistry.attachToDocument()
        var density by mutableStateOf(Density(1.0f))

        fun updateDensity() {
            val dpr = window.devicePixelRatio.toFloat()
            density = Density(dpr)
        }

        // Update density initially
        updateDensity()

        // Listen for window resize events to update density
        window.addEventListener("resize", {
            updateDensity()
        })

        CanvasBasedWindow(title = "Winyway - Expert Advice Instantly on Call/Chat 24X7") {
            CompositionLocalProvider(
                LocalDensity provides density,
                LocalLayoutDirection provides LayoutDirection.Ltr,
                LocalUriHandler provides LocalUriHandler.current,
                LocalViewConfiguration provides LocalViewConfiguration.current,
                LocalInputModeManager provides LocalInputModeManager.current,
                LocalClipboardManager provides LocalClipboardManager.current,
                LocalAutofill provides LocalAutofill.current,
                LocalFocusManager provides LocalFocusManager.current,
                LocalAccessibilityManager provides LocalAccessibilityManager.current,
                LocalHapticFeedback provides LocalHapticFeedback.current,
                LocalWindowInfo provides LocalWindowInfo.current,
                LocalSoftwareKeyboardController provides LocalSoftwareKeyboardController.current,
                LocalInspectionMode provides LocalInspectionMode.current,
            ) {

                App(
                    darkTheme = prefersDarkTheme,
                    dynamicColor = true,
                    rootComponent = root
                )
            }
        }
    }
}

private fun LifecycleRegistry.attachToDocument() {
    val visibilityState = document.asDynamic().visibilityState as String
    fun onVisibilityChanged() {
        if (visibilityState == "visible") {
            resume()
        } else {
            stop()
        }
    }

    onVisibilityChanged()

        document.addEventListener("visibilitychange", { _: Event ->
        onVisibilityChanged()
    })

}

//@OptIn(ExperimentalComposeUiApi::class, ExperimentalDecomposeApi::class)
//fun main() {
//    initKoin()
//    val myLifecycleOwner = MyLifecycleOwner()
//    val lifecycleRegistry = LifecycleRegistry()
//
//    val root = DefaultRootComponent(
//        componentContext = DefaultComponentContext(lifecycle = myLifecycleOwner.lifecycle),
//        deepLink = DefaultRootComponent.DeepLink.Web(path = window.location.search),
//        webHistoryController = DefaultWebHistoryController()
//    )
//
//    val prefersDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches
//
//    // Set loading state before WASM is ready
//    var isLoading by mutableStateOf(true)
//
//    // Force UI to render the loading screen before WASM is fully ready
//    CanvasBasedWindow(title = "Winyway - Expert Advice Instantly on Call/Chat 24X7") {
//        if (isLoading) {
//            LoadingScreen() // Show loading while WASM is loading
//        } else {
//            AppContent(prefersDarkTheme, root) // Show app once WASM is ready
//        }
//    }
//
//    // Once WASM is ready, switch loading state
//    onWasmReady {
//        lifecycleRegistry.attachToDocument()
//
//        isLoading = false // Once ready, set loading to false to display the app
//    }
//}
//
//@Composable
//fun LoadingScreen() {
//    Box(
//        modifier = Modifier.fillMaxSize(),
//        contentAlignment = Alignment.Center
//    ) {
//        CircularProgressIndicator() // Show a loading spinner
//    }
//}
//
//@OptIn(ExperimentalComposeUiApi::class)
//@Composable
//fun AppContent(prefersDarkTheme: Boolean, root: DefaultRootComponent) {
//    var density by remember { mutableStateOf(Density(1.0f)) }
//
//    fun updateDensity() {
//        val dpr = window.devicePixelRatio.toFloat()
//        density = Density(dpr)
//    }
//
//    // Initial density update
//    updateDensity()
//
//    // Listen for window resize to update density
//    window.addEventListener("resize", {
//        updateDensity()
//    })
//
//    CompositionLocalProvider(
//        LocalDensity provides density,
//        LocalLayoutDirection provides LayoutDirection.Ltr,
//        LocalUriHandler provides LocalUriHandler.current,
//        LocalViewConfiguration provides LocalViewConfiguration.current,
//        LocalInputModeManager provides LocalInputModeManager.current,
//        LocalClipboardManager provides LocalClipboardManager.current,
//        LocalAutofill provides LocalAutofill.current,
//        LocalFocusManager provides LocalFocusManager.current,
//        LocalAccessibilityManager provides LocalAccessibilityManager.current,
//        LocalHapticFeedback provides LocalHapticFeedback.current,
//        LocalWindowInfo provides LocalWindowInfo.current,
//        LocalSoftwareKeyboardController provides LocalSoftwareKeyboardController.current,
//        LocalInspectionMode provides LocalInspectionMode.current,
//    ) {
//        App(
//            darkTheme = prefersDarkTheme,
//            dynamicColor = true,
//            rootComponent = root
//        )
//    }
//}






