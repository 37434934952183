package com.winiwayuser.feature_expert_details.widget

import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Chat
import androidx.compose.material.icons.filled.Call
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage
import com.winiwayuser.core.persentation.SampleDialog
import com.winiwayuser.core.persentation.openPlayStore
import com.winiwayuser.core.theme.call_button_color
import com.winiwayuser.core.theme.call_button_offline_color
import com.winiwayuser.core.theme.chat_and_call_button_busy_color
import com.winiwayuser.core.theme.chat_button_online_color
import com.winiwayuser.core.theme.primaryColor
import com.winiwayuser.core.theme.user_app_play_store_url
import com.winiwayuser.feature_call_consultation.persentation.call_bottomsheet.CallHostPage
import com.winiwayuser.feature_common.data.request.RequestConsultant
import com.winiwayuser.feature_expert_consultation.presentation.ExpertCallPrice
import com.winiwayuser.feature_expert_consultation.presentation.expert_list_widget.openDialog
import com.winiwayuser.feature_expert_details.CallChartActionButton
import com.winiwayuser.feature_expert_details.ExpertDetailsPageComponent
import com.winiwayuser.feature_expert_details.ExpertDetailsPageEvent
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.baseline_language_24
import com.winiwayuser.resources.new_caht2
import com.winiwayuser.resources.new_call1
import org.jetbrains.compose.resources.painterResource

@Composable
fun ProfileBox(
    component: ExpertDetailsPageComponent,
    onLoginClick: () -> Unit,
    onChatConsultation: (RequestConsultant) -> Unit,
    onCallConsultation: (RequestConsultant) -> Unit,
    modifier: Modifier = Modifier,
    expertData: RequestConsultant
) {

//    val sheetState = rememberModalBottomSheetState()
    var isSheetOpen by rememberSaveable {
        mutableStateOf(false)
    }

    var isChatClickSelected by remember { mutableStateOf<RequestConsultant?>(null) }
    var isCallClickSelected by remember { mutableStateOf<RequestConsultant?>(null) }

    Column(modifier = modifier) {
        Box(
            modifier = Modifier.fillMaxWidth(),
            contentAlignment = Alignment.TopStart
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
//                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {
                // Profile Image

                AsyncImage(
                    model = expertData.profile_image_url,
                    contentDescription = null,
                    modifier = Modifier
                        .size(74.dp)
                        .clip(shape = CircleShape)
                        .border(2.dp, Color(0xFFDBA514), CircleShape),
                    contentScale = ContentScale.Fit
                )
//                Image(
//                    painter = painterResource(imageResource),
//                    contentDescription = null,
//
//                )

                Spacer(modifier = Modifier.width(16.dp))

                // Consultant Details Column
                Column {
                    // Consultant Name
                    Text(
                        text = expertData.full_name.toString(),
                        style = TextStyle(
                            fontSize = 20.sp,
                            fontWeight = FontWeight.Bold
                        ),
                        maxLines = 1
                    )

                    Spacer(modifier = Modifier.height(4.dp))


                    // Consultant Description
                    Text(
                        text = expertData.profile_description!!,
                        style = TextStyle(
                            fontSize = 16.sp,
                            color = Color.DarkGray
                        ),
                        overflow = TextOverflow.Ellipsis,
                        maxLines = 2
                    )

                    Spacer(modifier = Modifier.height(4.dp))
                    Text(
                        text = expertData.skills.toString(),
                        style = TextStyle(
                            fontSize = 14.sp
                        ),
                        maxLines = 1
                    )
                    // Language Text
                    Spacer(modifier = Modifier.height(4.dp))
                    Row(verticalAlignment = Alignment.CenterVertically) {
                        Icon(
                            painter = painterResource(Res.drawable.baseline_language_24),
                            contentDescription = null,
                            tint = Color.Black,
                            modifier = Modifier.size(14.dp)
                        )
                        Spacer(modifier = Modifier.width(4.dp))
                        Text(
                            text = expertData.language!!,
                            style = TextStyle(
                                fontSize = 14.sp
                            ),
                            maxLines = 1
                        )
                    }

                    Spacer(modifier = Modifier.height(4.dp))
                }
            }
        }

        // Pricing Text at the bottom of the card
        Column(
            modifier = Modifier
                .padding(vertical = 8.dp)
                .fillMaxWidth(),
            verticalArrangement = Arrangement.Bottom,
            horizontalAlignment = Alignment.Start
        ) {
            Text(
                text = "Best Consultation Price for you: ",
                style = TextStyle(
                    fontSize = 16.sp
                )
            )

            ExpertCallPrice(expertData, false)
//            if (isFreeCallApplicable) {
//                Row {
//                    Text(
//                        text = "FREE",
//                        style = TextStyle(
//                            fontSize = 16.sp,
//                            fontWeight = FontWeight.Bold,
//                            color = Color(0xFFEF5350)
//                        ),
//                        modifier = Modifier.padding(bottom = 8.dp)
//                    )
//                    Spacer(modifier = Modifier.width(4.dp))
//                    Text(
//                        text = "₹ $callPerMinutePrice /Min",
//                        style = TextStyle(
//                            fontSize = 16.sp,
//                            fontWeight = FontWeight.Bold,
//                            color = Color(0xFFEF5350),
//                            textDecoration = TextDecoration.LineThrough
//                        ),
//                        modifier = Modifier.padding(bottom = 8.dp)
//                    )
//
//                }
//
//            } else {
//                Text(
//                    text = "₹ $callPerMinutePrice /Min",
//                    style = TextStyle(
//                        fontSize = 16.sp
//                    )
//                )
//            }

            // Action Buttons
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {
                Box(
                    modifier = Modifier.weight(1f)
                ) {
                    CallChartActionButton(
                        consultant = expertData,
                        text = "CALL",
                        icon = Icons.Filled.Call,
                        //icon = painterResource(Res.drawable.new_call1),
                        onItemClick = { callConsultant ->
                            isCallClickSelected = callConsultant
//                            CallHostPage(
//                                callDetails = isCallClickSelected!!,
//                                onLoginClicked = {
//                                    onLoginClick.invoke()
//                                },
//                                onDismiss = {
//                                    isCallClickSelected = null
//                                },
//                                onStartCallConsultation = { onCallConsultation.invoke(isCallClickSelected!!) }
//                            )
                            //component.event(ExpertDetailsPageEvent.onCallButtonClick(expertId = expertData.id.toString(), expertName = expertData.full_name!!, expertImage = expertData.profile_image_url!!, expertSkills = expertData.skills!!))
                                      },
                        buttonColor = when (expertData.consultantCallAvailability() ?: "") {
                            "Online" -> call_button_color
                            "Busy" -> chat_and_call_button_busy_color
                            else -> call_button_offline_color
                        },
                        consultantCallAvailability = expertData.consultantCallAvailability()!! == "Online",
                    )
                }

                Spacer(modifier = Modifier.width(3.dp))
                val openDialog = remember { mutableStateOf(false) }
                if (openDialog.value) {
                    openDialog(openDialog)
                }
                Box(
                    modifier = Modifier.weight(1f)
                ) {
                    CallChartActionButton(
                        consultant = expertData,
                        text = "CHAT",
                        icon = Icons.AutoMirrored.Filled.Chat,
                        //icon = painterResource(Res.drawable.new_caht2),
                        onItemClick = { openDialog.value = true },
                        buttonColor = when (expertData.consultantCallAvailability() ?: "") {
                            "Online" -> primaryColor
                            "Busy" -> chat_and_call_button_busy_color
                            else -> call_button_offline_color
                        },
                        consultantCallAvailability = expertData.consultantChatAvailability()!! == "Online"
                    )
                }
            }

            if(isCallClickSelected != null){
                CallHostPage(
                    callDetails = isCallClickSelected!!,
                    onLoginClicked = {
                        onLoginClick.invoke()
                    },
                    onDismiss = {
                        isCallClickSelected = null
                    },
                    onStartCallConsultation = { onCallConsultation.invoke(isCallClickSelected!!) }
                )
            }

            if (isSheetOpen) {

//            NotLoginUser(sheetState, onDismissRequest = { isSheetOpen = false })
                //     ErrorBottomSheetCallConsultation(sheetState, onDismissRequest = { isSheetOpen = false })
//            LowBalanceOnFreeChat(sheetState, onDismissRequest = { isSheetOpen = false })
//                CallConsultationBottomSheet(consultantDescription,imageResource,consultantId,sheetState, onDismissRequest = { isSheetOpen = false })
//            BalanceNotEnoughLayout(sheetState, onDismissRequest = { isSheetOpen = false })
            }
        }
    }
}


@Composable
fun openDialog(sheet: MutableState<Boolean>) {
    if (sheet.value) {
        SampleDialog(
            onDismissRequest = { sheet.value = false },
            onPlayStore = {
                sheet.value = false
                openPlayStore(user_app_play_store_url)
                //showToast("Play Store")
            }
//            onAppStore = {
//                showToast("App Store")
//            }
        )
    }
}
