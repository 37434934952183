package com.winiwayuser.feature_chat_consultation_new.persentation.chat_host

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.arkivanov.decompose.extensions.compose.stack.Children
import com.arkivanov.decompose.extensions.compose.stack.animation.slide
import com.arkivanov.decompose.extensions.compose.stack.animation.stackAnimation
import com.arkivanov.decompose.extensions.compose.subscribeAsState
import com.winiwayuser.feature_chat_consultation.persentation.chat_concern.ChatConnectingScreen
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_host.decompose.HostDefaultComponent
import com.winiwayuser.feature_chat_consultation.persentation.chat_host.decompose.HostRootComponent
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_room_view.ChatRoomScreen
import com.winiwayuser.feature_chat_consultation.persentation.chat_waiting_room.ChatWaitingScreen

@Composable
fun ChatHostPage(
    component: HostDefaultComponent
) {

    val childStack by component.childStack.subscribeAsState()

    Children(
        stack = childStack,
        animation = stackAnimation(slide())
    ) { child ->
        when (val instance = child.instance) {
            is HostRootComponent.Child.ChatConcernChild -> ChatConnectingScreen(instance.component)
            is HostRootComponent.Child.ChatRoomChild -> ChatRoomScreen(instance.component)
            is HostRootComponent.Child.ChatWaitingChild -> ChatWaitingScreen(instance.component)
        }
    }

}