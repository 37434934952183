package com.winiwayuser.core.di


import com.winiwayuser.feature_authentication.di.authorizationModule
import com.winiwayuser.feature_call_consultation.di.callConsultationModule
import com.winiwayuser.feature_chat_consultation.di.chatConsultationModule
import com.winiwayuser.feature_common.di.homeCommonModule
import com.winiwayuser.feature_common.persentation.more_tab.edit_profile.di.editProfileCommonModule
import com.winiwayuser.feature_landing_page.data.di.landingPageModel
import com.winiwayuser.feature_expert_consultation.di.expertListCommonModule
import com.winiwayuser.feature_expert_consultation.di.searchExpertCommonModule
import com.winiwayuser.feature_expert_consultation.di.expertDetailsCommonModule
import com.winiwayuser.feature_winyway_wallet.di.walletModule
import org.koin.core.context.startKoin

fun initKoin() {
    startKoin {
        modules(
            authorizationModule,
            homeCommonModule,
            editProfileCommonModule,
            walletModule,
            searchExpertCommonModule,
            chatConsultationModule,
            callConsultationModule,
            expertDetailsCommonModule,
            expertListCommonModule,
            landingPageModel
        )
    }
}