package com.winiwayuser.core

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.router.stack.StackNavigation
import com.arkivanov.decompose.router.stack.childStack
import com.arkivanov.decompose.router.stack.pop
import com.arkivanov.decompose.router.stack.push
import com.arkivanov.decompose.router.stack.pushNew
import com.arkivanov.decompose.router.stack.replaceAll
import com.arkivanov.decompose.router.stack.replaceCurrent
import com.winiwayuser.feature_authentication.persentation.login_page.LoginPageComponent
import com.winiwayuser.feature_authentication.persentation.otp_validation_page.OtpValidationComponent
import com.winiwayuser.feature_authentication.persentation.register_page.RegisterComponent
import com.winiwayuser.feature_bottom_navigation.MainScreenComponent
import com.winiwayuser.feature_call_consultation.persentation.call_bottomsheet.CallConsultationComponent
import com.winiwayuser.feature_call_consultation.persentation.call_bottomsheet.call_consultation_options.CallConsultationOptionsComponent
import com.winiwayuser.feature_call_consultation.persentation.call_bottomsheet.call_waitting_sucess.CallWaitingSuccessComponent
import com.winiwayuser.feature_common.data.response.SubCategoriesModel
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_bottom_sheet_info.ChatConsultationComponent
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_concern.ChatConcernComponent
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_waiting_room.ChatWaitingComponent
import com.winiwayuser.feature_common.persentation.home_tab.HomePageEvent
import com.winiwayuser.feature_common.persentation.home_tab.HomeScreenTabComponent
import com.winiwayuser.feature_common.persentation.more_tab.component.EditProfileComponent
import com.winiwayuser.feature_common.persentation.more_tab.component.MoreScreenEvent
import com.winiwayuser.feature_common.persentation.more_tab.component.MoreScreenTabComponent
import com.winiwayuser.feature_common.persentation.more_tab.component.PrivacyPolicyComponent
import com.winiwayuser.feature_common.persentation.more_tab.component.PrivacyPolicyEvent
import com.winiwayuser.feature_common.persentation.more_tab.component.TermsAndConditionsComponent
import com.winiwayuser.feature_common.persentation.more_tab.component.TermsAndConditionsEvent
import com.winiwayuser.feature_expert_consultation.feature_expert_search.presentation.SearchScreenComponent
import com.winiwayuser.feature_expert_consultation.presentation.ExpertListPageComponent
import com.winiwayuser.feature_expert_details.ExpertDetailsPageComponent
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_checkout.RechargeCheckoutComponent
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_failure.RechargeFailureComponent
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_getway.PaymentGatewayArg
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_getway.PaymentGatewayComponent
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.ChoseRechargePackComponent
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_payment_success.RechargeSuccessComponent
import kotlinx.serialization.Serializable

class RootComponent(
    componentContext: ComponentContext
) : ComponentContext by componentContext {

    private val navigation = StackNavigation<Configuration>()

    val childStack = childStack(
        source = navigation,
        serializer = Configuration.serializer(),
        initialConfiguration = Configuration.HomeScreenTab,
        handleBackButton = true,
        childFactory = ::createChild
    )

    private fun createChild(
        configuration: Configuration,
        context: ComponentContext
    ): Child {
        return when (configuration) {

            Configuration.MoreScreenTab -> Child.MoreScreenTab(
                MoreScreenTabComponent(
                    context = context,
                    onMoreScreenEvent = { moreScreenEvent ->
                        when (moreScreenEvent) {
                            MoreScreenEvent.onClickWalletBlance -> navigation.pushNew(Configuration.ChoseRechargePage)
                            MoreScreenEvent.onPricayPolicyClick -> navigation.pushNew(Configuration.PrivacyPolicyPage)
                            MoreScreenEvent.onTermAndConditionClick -> navigation.pushNew(
                                Configuration.TermsAndConditions
                            )

                            MoreScreenEvent.onClickEditPrifile -> navigation.pushNew(Configuration.EditProfileScreen)
                            MoreScreenEvent.onBackButtonClick -> navigation.pop()
                            MoreScreenEvent.onSignOutButtonClick -> navigation.replaceAll(
                                Configuration.HomeScreenTab
                            )
                        }
                    }
                )
            )

            is Configuration.LoginPage -> Child.LoginPage(
                LoginPageComponent(
                    context = context,
                    navigateToOtpValidationPage = { firstString, secondString, isWhatsappUpdatesChecked ->
                        navigation.pushNew(
                            Configuration.OtpValidationPage(
                                firstString,
                                secondString,
                                isWhatsappUpdatesChecked
                            )
                        )
                    },
                    onPrivacyPolicyClick = {
                        navigation.pushNew(Configuration.MainScreen)
                    }
                )
            )

            is Configuration.OtpValidationPage -> Child.OtpValidationPage(
                OtpValidationComponent(
                    mobileNumber = configuration.mobileNumber,
                    mobileCode = configuration.mobileCode,
                    isWhatsappUpdatesChecked = false,
                    componentContext = context,
                    onRegisterEvent = {
                        navigation.pushNew(Configuration.RegistrationPage)
                    },
                    onDashboardEvent = {
                        navigation.replaceAll(Configuration.HomeScreenTab)
                    }
                )
            )

            is Configuration.RegistrationPage -> Child.RegistrationPage(
                RegisterComponent(
                    context = context
                ) {
                    navigation.push(Configuration.HomeScreenTab)
                }
            )

            is Configuration.ExpertListPage -> Child.ExpertListPage(
                ExpertListPageComponent(
                    context = context,
                    categoryName = configuration.categoryName,
                    categoryId = configuration.categoryId,
                    onBackPressed = { navigation.pop() },
                    subCategories = configuration.subCategory,
                    onSearchFieldClick = { navigation.pushNew(Configuration.DisplaySearchExpertList) },
                    onExpertListPageEvent = { navigation.pushNew(Configuration.ExpertDetailsPage(0)) },
                    onClickExpertCard = { expertId ->
                        navigation.pushNew(
                            Configuration.ExpertDetailsPage(
                                expertId
                            )
                        )
                    },
                    onCallButtonClick = { expertId, expertName, expertImage, expertSkills ->
                        navigation.pushNew(
                            Configuration.CallConsultationInfo(
                                expertId = expertId,
                                expertName = expertName,
                                expertImage = expertImage,
                                expertSkills = expertSkills
                            )
                        )
                    },
                    onChatButtonClick = { expetId, expertName, expertImage, expertSkills ->
                        navigation.pushNew(
                            Configuration.ChatConsultationInfo(
                                expertId = expetId,
                                expertName = expertName,
                                expertImage = expertImage,
                                expertSkills = expertSkills
                            )
                        )
                    }
//                    ExpertListPageEvent = {
//                        navigation.push(Configuration.ExpertDetailsPage(0))
//                    }
//                    OnSerachFieldClick = {navigation.push(Configuration.DisplaySearchExpertList)}
                )
            )


            is Configuration.ExpertDetailsPage -> Child.ExpertDetailsPage(
                ExpertDetailsPageComponent(
                    context = context,
                    expertId = configuration.expertId,
                    onBackPressed = {
                        navigation.pop()
                    },
                    onCallButtonClick = { expertId, expertName, expertImage, expertSkill ->
                        navigation.pushNew(
                            Configuration.CallConsultationInfo(
                                expertId = expertId,
                                expertName = expertName,
                                expertImage = expertImage,
                                expertSkills = expertSkill,
                            )
                        )
                    },
                    onLoginClick = {navigation.pushNew(Configuration.LoginPage)},
                    onClickRelatedExpert = { expertId ->
                        navigation.pushNew(Configuration.ExpertDetailsPage(expertId = expertId))
                    }
                )
            )

            is Configuration.ChoseRechargePage -> Child.ChoseRechargePage(
                ChoseRechargePackComponent(
                    context = context,
                    onBackPressed = { navigation.pop() },
                    navigateToRechargeCheckout = {
                        navigation.pushNew(Configuration.RechargeCheckout(it))
                    }
                )
            )

            Configuration.HomeScreenTab -> Child.HomeScreenTab(
                HomeScreenTabComponent(
                    context = context,
                    onHomePageEvent = { event ->
                        when (event) {
                            is HomePageEvent.OnClickTopConsultant -> {
                                navigation.pushNew(Configuration.ExpertDetailsPage(event.expertCategory))
                            }

                            is HomePageEvent.OnClickCategory -> {
                                navigation.pushNew(
                                    Configuration.ExpertListPage(
                                        event.category.name,
                                        event.category.id.toString(),
                                        event.category.subcategories!!
                                    )
                                )
//                                navigation.pushNew(
//                                    Configuration.ExpertListPage(
//                                        event.category.name,
//                                        event.category.id.toString(),
//                                        event.category.subcategories
//                                    )
//                                )
                            }

                            is HomePageEvent.OnLiveSessionClick -> Unit
                            is HomePageEvent.OnSearchClick -> Unit
                            is HomePageEvent.OnClickLogin -> navigation.pushNew(Configuration.LoginPage)
                            HomePageEvent.OnClickProfileIcon -> navigation.pushNew(Configuration.MoreScreenTab)
                            HomePageEvent.OnClickPrivacyPolicy -> navigation.pushNew(Configuration.PrivacyPolicyPage)
                            HomePageEvent.OnClickTermsAndConditions -> navigation.pushNew(
                                Configuration.TermsAndConditions
                            )

                            HomePageEvent.OnClickWalletIcon -> navigation.pushNew(Configuration.ChoseRechargePage)
                            is HomePageEvent.OnChatConsultationStart -> navigation.pushNew(
                                Configuration.ChatConsultationInfo(
                                    event.consultantId.toString(),
                                    event.consultantName,
                                    event.consultantImage,
                                    event.consultantSkills
                                )
                            )

                            is HomePageEvent.OnCallConsultationStart -> navigation.pushNew(
                                Configuration.CallConsultationInfo(
                                    event.consultantId.toString(),
                                    event.consultantName,
                                    event.consultantImage,
                                    event.consultantSkills
                                )
                            )
                        }
                    }
                )
            )

            Configuration.MainScreen -> Child.MainScreen(
                MainScreenComponent(context = context) { event ->
//                    handleNavigationEvent(event)

                }
            )

            Configuration.PrivacyPolicyPage -> Child.PrivacyPolicyPage(
                PrivacyPolicyComponent(context = context) { privacyPolicyEvent ->
                    when (privacyPolicyEvent) {
                        PrivacyPolicyEvent.OnBackKeyPress -> navigation.pop()
                    }

                }
            )

            Configuration.TermsAndConditions -> Child.TermsAndConditions(
                TermsAndConditionsComponent(context = context) { termsAndConditionsEvent ->
                    when (termsAndConditionsEvent) {
                        TermsAndConditionsEvent.OnBackKeyPress -> navigation.pop()
                    }
                }
            )

            Configuration.EditProfileScreen -> Child.EditProfileScreen(
                EditProfileComponent(context = context, onBackPress = {
                    navigation.pop()
                })
            )

            is Configuration.RechargeCheckout -> Child.RechargeCheckout(
                RechargeCheckoutComponent(
                    context = context,
                    _rechargeData = configuration.rechargeData,
                    onProceedToPayment = { paymentGatewayArg ->
                        navigation.replaceCurrent(Configuration.PaymentGateway(paymentGatewayArg))
                    },
                    onBackPressed = { navigation.pop() }
                )
            )

            is Configuration.PaymentGateway -> Child.PaymentGateway(
                PaymentGatewayComponent(
                    context = context,
                    paymentGatewayArg = configuration.paymentGatewayArg,
                    onRechargeSuccess = { topUpAmount ->
                        navigation.replaceCurrent(
                            Configuration.RechargeSuccess(
                                topUpAmount
                            )
                        )
                    },
                    onRechargeFailed = { topUpAmount ->
                        navigation.replaceCurrent(Configuration.RechargeFailure(topUpAmount = topUpAmount))
                    }
                )
            )

            is Configuration.RechargeFailure -> {
                Child.RechargeFailure(
                    RechargeFailureComponent(
                        context = context,
                        _topUpAmount = configuration.topUpAmount,
                        rechargeAgain = {
                            navigation.pop()
                        },
                        getSupport = {
                            navigation.replaceAll(Configuration.HomeScreenTab)
                        }
                    )
                )
            }

            is Configuration.RechargeSuccess -> {
                Child.RechargeSuccess(
                    RechargeSuccessComponent(
                        context = context,
                        _topUpAmount = configuration.topUpAmount,
                        onDone = {
                            navigation.replaceAll(Configuration.HomeScreenTab)
                        },
                        rechargeMore = {
                            navigation.pop()
                        }
                    )
                )
            }

            Configuration.DisplaySearchExpertList -> {
                Child.DisplaySearchExpertList(
                    component = SearchScreenComponent(
                        context = context,
                        onBackPress = { navigation.pop() })
                )
            }

            is Configuration.ChatConsultationInfo -> {
                Child.ChatConsultationInfo(
                    ChatConsultationComponent(
                        context = context,
                        _expertId = configuration.expertId,
                        _expertName = configuration.expertName,
                        _expertImage = configuration.expertImage,
                        _expertSkills = configuration.expertSkills,
                        onClose = {
                            navigation.pop()
                        },
                        onConnect = {
                            navigation.push(
                                Configuration.ChatConcernPage(
                                    expertSkills = configuration.expertSkills,
                                    expertImage = configuration.expertImage,
                                    expertName = configuration.expertName,
                                    expertId = configuration.expertId
                                )
                            )
                        }
                    )
                )
            }


            is Configuration.ChatWaitingPage -> {
                Child.ChatWaitingPage(
                    ChatWaitingComponent(
                        context = context,
                        _expertId = configuration.expertId,
                        _expertName = configuration.expertName,
                        _expertImage = configuration.expertImage,
                        _expertSkills = configuration.expertSkills,
                        onClose = {
                            navigation.pop()
                        },
                        isEmergencyChat = "",
                        maxChatDuration = "",
                        chatId = ""
                    )
                )
            }

            is Configuration.ExpertListPage -> TODO()
            // is Configuration.ExpertListPage -> TODO()
            // is Configuration.ExpertListPage -> TODO()
            is Configuration.CallConsultationInfo -> Child.CallConsultationInfo(
                CallConsultationComponent(
                    context = context,
                    _expertId = configuration.expertId,
                    _expertName = configuration.expertName,
                    _expertImage = configuration.expertImage,
                    _expertSkills = configuration.expertSkills,
                    onClose = {
                        navigation.pop()
                    },
                    onLetsConnectButtonClick = {
                        navigation.pushNew(
                            Configuration.CallConsultationOptions(
                                expertId = configuration.expertId,
                                expertName = configuration.expertName,
                                expertImage = configuration.expertImage,
                                expertSkills = configuration.expertSkills,
                            )
                        )
                    }
                )
            )

            is Configuration.ChatConcernPage -> {
                Child.ChatConcernPage(
                    ChatConcernComponent(
                        context = context,
                        expertName = configuration.expertName,
                        expertSkills = configuration.expertSkills,
                        expertImage = configuration.expertImage,
                        expertId = configuration.expertId,
                        onStartConsultation = {
                            navigation.push(
                                Configuration.ChatWaitingPage(
                                    expertId = configuration.expertId,
                                    expertName = configuration.expertName,
                                    expertImage = configuration.expertName,
                                    expertSkills = configuration.expertSkills
                                )
                            )
                        },
                        onClose = {
                            navigation.pop()
                        }
                    )
                )
            }

            is Configuration.CallConsultationOptions -> Child.CallConsultationOptions(
                CallConsultationOptionsComponent(
                    _expertId = configuration.expertId,
                    _expertName = configuration.expertName,
                    _expertImage = configuration.expertImage,
                    _expertSkills = configuration.expertSkills,
                    context = context,
                    onClosePage = { navigation.replaceAll(Configuration.HomeScreenTab) },
                    onCallWaittingPage = {
                        navigation.push(
                            Configuration.CallWaitingSuccessComponent(
                                expertId = configuration.expertId,
                                expertName = configuration.expertName,
                                expertImage = configuration.expertName,
                                expertSkills = configuration.expertSkills
                            )
                        )
                    }
                )
            )

//           is Configuration.CallPlaceSuccess -> Child.CallWaitingSuccess(
//
//           )

            is Configuration.CallPlaceSuccessval -> TODO()
            //Child.CallPlaceSuccess(
//                CallWaitingSuccessComponent(
//                    context = context,
//                    _expertId = configuration.expertId,
//                    _expertName = configuration.expertName,
//                    _expertImage = configuration.expertImage,
//                    _expertSkills = configuration.expertSkills,
//                )
            //)
            is Configuration.CallWaitingSuccessComponent -> Child.CallWaitingSuccess(
                CallConsultationOptionsComponent(
                    context = context,
                    _expertId = configuration.expertId,
                    _expertName = configuration.expertName,
                    _expertImage = configuration.expertImage,
                    _expertSkills = configuration.expertSkills,
                    onClosePage = { navigation.replaceAll(Configuration.HomeScreenTab) },
                    onCallWaittingPage = {}
                )
            )
        }
    }

    @Serializable
    sealed class Child {

        //        feature_authorization
        data class LoginPage(val component: LoginPageComponent) : Child()
        data class OtpValidationPage(val component: OtpValidationComponent) : Child()
        data class RegistrationPage(val component: RegisterComponent) : Child()

        data class ExpertListPage(val component: ExpertListPageComponent) : Child()
        data class ExpertDetailsPage(val component: ExpertDetailsPageComponent) : Child()
        data class ChoseRechargePage(val component: ChoseRechargePackComponent) : Child()

        data class MoreScreenTab(val component: MoreScreenTabComponent) : Child()
        data class HomeScreenTab(val component: HomeScreenTabComponent) : Child()
        data class MainScreen(val component: MainScreenComponent) : Child()
        data class PrivacyPolicyPage(val component: PrivacyPolicyComponent) : Child()
        data class TermsAndConditions(val component: TermsAndConditionsComponent) : Child()
        data class EditProfileScreen(val component: EditProfileComponent) : Child()

        //        feature_winyway_wallet
        data class RechargeCheckout(val component: RechargeCheckoutComponent) : Child()
        data class PaymentGateway(val component: PaymentGatewayComponent) : Child()
        data class RechargeSuccess(val component: RechargeSuccessComponent) : Child()
        data class RechargeFailure(val component: RechargeFailureComponent) : Child()


        // feature_expert_search
        data class DisplaySearchExpertList(val component: SearchScreenComponent) : Child()

        // feature Chat Consultation
        data class ChatConsultationInfo(
            val component: ChatConsultationComponent
        ) : Child()

        data class ChatConcernPage(
            val component: ChatConcernComponent
        ) : Child()

        data class ChatWaitingPage(
            val component: ChatWaitingComponent
        ) : Child()

        // feature Call Consultation
        data class CallConsultationInfo(
            val component: CallConsultationComponent
        ) : Child()

        data class CallConsultationOptions(
            val component: CallConsultationOptionsComponent
        ) : Child()

        data class CallPlaceSuccess(
            val component: CallConsultationOptionsComponent
        ) : Child()

        data class CallWaitingSuccess(
            val component:
            CallConsultationOptionsComponent
        ) : Child()
    }

    @Serializable
    sealed class Configuration {
        @Serializable
        data object LoginPage : Configuration()

        @Serializable
        data class OtpValidationPage(
            val mobileNumber: String,
            val mobileCode: String,
            val isWhatsappUpdatesChecked: Boolean
        ) : Configuration()

        @Serializable
        data object RegistrationPage : Configuration()

        @Serializable
        data class ExpertListPage(
            val categoryName: String,
            val categoryId: String,
            val subCategory: List<SubCategoriesModel>?
        ) : Configuration()

        @Serializable
        data class ExpertDetailsPage(val expertId: Int) : Configuration()

        @Serializable
        data object ChoseRechargePage : Configuration()

        @Serializable
        data class RechargeCheckout(val rechargeData: RechargeData) : Configuration()

        @Serializable
        data object MoreScreenTab : Configuration()

        @Serializable
        data object HomeScreenTab : Configuration()

        @Serializable
        data object MainScreen : Configuration()

        @Serializable
        data object PrivacyPolicyPage : Configuration()

        @Serializable
        data object TermsAndConditions : Configuration()

        @Serializable
        data object EditProfileScreen : Configuration()

        @Serializable
        data class PaymentGateway(val paymentGatewayArg: PaymentGatewayArg) : Configuration()

        @Serializable
        data class RechargeFailure(val topUpAmount: String) : Configuration()

        @Serializable
        data class RechargeSuccess(val topUpAmount: String) : Configuration()

        //Chat Consultation Feature
        @Serializable
        data class ChatConsultationInfo(
            val expertId: String,
            val expertName: String,
            val expertImage: String,
            val expertSkills: String
        ) : Configuration()

        @Serializable
        data object DisplaySearchExpertList : Configuration()

        @Serializable
        data class ChatWaitingPage(
            val expertId: String,
            val expertName: String,
            val expertImage: String,
            val expertSkills: String
        ) : Configuration()

        @Serializable
        data class ChatConcernPage(
            val expertId: String,
            val expertName: String,
            val expertImage: String,
            val expertSkills: String
        ) : Configuration()

        //Call Consultation Feature
        @Serializable
        data class CallConsultationInfo(
            val expertId: String,
            val expertName: String,
            val expertImage: String,
            val expertSkills: String
        ) : Configuration()

        @Serializable
        data class CallConsultationOptions(
            val expertId: String,
            val expertName: String,
            val expertImage: String,
            val expertSkills: String
        ) : Configuration()

        @Serializable
        data class CallPlaceSuccessval(
            val expertId: String,
            val expertName: String,
            val expertImage: String,
            val expertSkills: String
        ) : Configuration()

        @Serializable
        data class CallWaitingSuccessComponent(
            val expertId: String,
            val expertName: String,
            val expertImage: String,
            val expertSkills: String
        ) : Configuration()

    }

}