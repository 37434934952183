package com.winiwayuser.feature_expert_details

import com.winiwayuser.feature_expert_consultation.presentation.ExpertListPageEvent

sealed class ExpertDetailsPageEvent {
    data object onBackButtonClick:ExpertDetailsPageEvent()
    data class onCallButtonClick(
        val expertId: String,
        val  expertName: String,
        val expertImage: String,
        val expertSkills: String
    ):ExpertDetailsPageEvent()

    data class onChatButtonClick(
        val expertId: String,
        val  expertName: String,
        val expertImage: String,
        val expertSkills: String
    ):ExpertDetailsPageEvent()

    data object onClickLoginButton:ExpertDetailsPageEvent()

    data class onRelatedExpertCardClick(val expertId: Int) : ExpertDetailsPageEvent()
}