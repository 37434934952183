package com.winiwayuser.feature_call_consultation.persentation.call_bottomsheet



import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import co.touchlab.kermit.Logger
import com.winiwayuser.core.persentation.LoadingProgressDialog
import com.winiwayuser.feature_call_consultation.call_bottom_sheet_info.CallInfoBottomSheetState
import com.winiwayuser.feature_call_consultation.persentation.call_bottomsheet.expert_busy.ErrorBottomSheetCallConsultation
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_bottom_sheet_info.widget.RechargeNowBottomSheetDialog

@Composable
fun CallConsultationBottomSheet(component: CallConsultationComponent) {
    val state by component.state.collectAsState()
   // val context = LocalContext.current

    when (val currentState = state) {
        is CallInfoBottomSheetState.Loading -> {
            if (currentState.isLoading) {
                LoadingProgressDialog { }
            }
        }

        is CallInfoBottomSheetState.NormalCallLowBalance -> {
           // Logger.d("Chat Low Balance State")
            RechargeNowBottomSheetDialog(
                onDismissRequest = {
                    component.onClosePage()
                },
                data = currentState.data,
                onRechargeSelected = { selectedAmount ->
                    //Logger.d("selected amount - $selectedAmount")
                }
            )
        }

        is CallInfoBottomSheetState.CallInfoSuccess -> {
            Logger.d("Chat Success State -> ${currentState.data}")
            CallInfoSuccessBottomSheet(
                consultationComponent = component,
                callDurationData = currentState.data,
                onDismiss = { component.onClosePage() }
            )
        }

        is CallInfoBottomSheetState.Error -> {
            //Logger.d("Chat Error State")
            ErrorBottomSheetCallConsultation(msg = currentState.msg, onDismiss = { component.onClosePage()})
        }

        is CallInfoBottomSheetState.FreeCallLowBalance -> {
           // Logger.d("Chat Free Call State")
        }
    }
}
