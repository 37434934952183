package com.winiwayuser.core.persentation

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material.icons.automirrored.rounded.ArrowBack
import androidx.compose.material.icons.filled.Menu
import androidx.compose.material.icons.rounded.MoreVert
import androidx.compose.material.icons.rounded.Search
import androidx.compose.material3.CenterAlignedTopAppBar
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.DockedSearchBar
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TopAppBar
import androidx.compose.material3.TopAppBarDefaults
import androidx.compose.material3.TopAppBarScrollBehavior
import androidx.compose.material3.rememberTopAppBarState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.Dialog
import com.winiwayuser.feature_common.data.request.RequestConsultant
import com.winiwayuser.feature_common.persentation.favourite_tab.CategoryConsultListRowClickListener
import com.winiwayuser.feature_common.persentation.favourite_tab.ConsultantItem
import com.winiwayuser.feature_expert_consultation.data.remote.request.SearchExpertRequest
import com.winiwayuser.feature_expert_consultation.presentation.expert_search_page.SearchScreenComponent
import com.winiwayuser.feature_landing_page.persentation.LandingPageComponent
import kotlinx.coroutines.launch

@Composable
fun NoTitleTopAppBar(
    modifier: Modifier = Modifier,
    navigationIcon:() -> Unit,
){
    IconButton(onClick = navigationIcon) {
        Icon(
            imageVector = Icons.AutoMirrored.Filled.ArrowBack,
            contentDescription = "Localized description"
        )
    }
}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun NormalTopAppBar(
    modifier: Modifier = Modifier,
    title: @Composable () -> Unit,
    navigationIcon: @Composable () -> Unit = {},
    actions: @Composable () -> Unit = {},
    scrollBehavior: TopAppBarScrollBehavior? = null
) {
    val scrollBehavior = TopAppBarDefaults.pinnedScrollBehavior(rememberTopAppBarState())

    TopAppBar(
        colors = TopAppBarDefaults.topAppBarColors(
            containerColor = MaterialTheme.colorScheme.primaryContainer,
            titleContentColor = MaterialTheme.colorScheme.primary,
        ),
        title = {
            Text(
                "Centered Top App Bar",
                maxLines = 1,
                overflow = TextOverflow.Ellipsis
            )
        },
        navigationIcon = {
            IconButton(onClick = {

            }) {
                Icon(
                    imageVector = Icons.AutoMirrored.Filled.ArrowBack,
                    contentDescription = "Localized description"
                )
            }
        },

        scrollBehavior = scrollBehavior,
    )

}


@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun ToolBarLandingPage(
    title: String,
    component: LandingPageComponent
) {
    val scrollBehavior = TopAppBarDefaults.pinnedScrollBehavior(rememberTopAppBarState())

    TopAppBar(
        colors = TopAppBarDefaults.topAppBarColors(
            containerColor = MaterialTheme.colorScheme.primaryContainer,
            titleContentColor = MaterialTheme.colorScheme.primary,
        ),
        title = {
            Text(
                text = title,
                maxLines = 1,
                overflow = TextOverflow.Ellipsis
            )
        },
        navigationIcon = {
            IconButton(onClick = {
                component.onNavigationBack()
            }) {
                Icon(
                    imageVector = Icons.AutoMirrored.Filled.ArrowBack,
                    contentDescription = "Localized description"
                )
            }
        },

        scrollBehavior = scrollBehavior,
    )

}
@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun CenterAlignedTopAppBar(
    modifier: Modifier = Modifier,
    title: @Composable () -> Unit,
    navigationIcon: @Composable () -> Unit = {},
    actions: @Composable () -> Unit = {},
    scrollBehavior: TopAppBarScrollBehavior? = null
) {
    val scrollBehavior = TopAppBarDefaults.pinnedScrollBehavior(rememberTopAppBarState())

    CenterAlignedTopAppBar(
        colors = TopAppBarDefaults.topAppBarColors(
            containerColor = MaterialTheme.colorScheme.primaryContainer,
            titleContentColor = MaterialTheme.colorScheme.primary,
        ),
        title = {
            Text(
                "Centered Top App Bar",
                maxLines = 1,
                overflow = TextOverflow.Ellipsis
            )
        },
        navigationIcon = {
            IconButton(onClick = {

            }) {
                Icon(
                    imageVector = Icons.AutoMirrored.Filled.ArrowBack,
                    contentDescription = "Localized description"
                )
            }
        },
        actions = {
            IconButton(onClick = { /* do something */ }) {
                Icon(
                    imageVector = Icons.Filled.Menu,
                    contentDescription = "Localized description"
                )
            }
        },
        scrollBehavior = scrollBehavior,
    )
}

//@Composable
//@OptIn(ExperimentalMaterial3Api::class)
//fun SimpleDockedSearchBar(onBackClick: () -> Unit) {
//    var text by rememberSaveable { mutableStateOf("") }
//    var active by rememberSaveable { mutableStateOf(false) }
//
//    Box(Modifier.fillMaxSize()) {
//        // TopAppBar with back button
//        TopAppBar(
//            title = { Text("Search") },
//            navigationIcon = {
//                IconButton(onClick = onBackClick) {
//                    Icon(Icons.Rounded.ArrowBack, contentDescription = "Back")
//                }
//            },
//            modifier = Modifier
//                .fillMaxWidth()
//                .zIndex(1f),
//            colors = TopAppBarDefaults.mediumTopAppBarColors(
//                containerColor = MaterialTheme.colorScheme.primary
//            )
//        )
//
//        // DockedSearchBar
//        Box(Modifier
//            .semantics { isContainer = true }
//            .zIndex(0f)
//            .fillMaxWidth()) {
//            DockedSearchBar(
//                modifier = Modifier
//                    .align(Alignment.TopCenter)
//                    .padding(top = 72.dp), // Adjust padding to avoid overlap with TopAppBar
//                query = text,
//                onQueryChange = { text = it },
//                onSearch = { active = false },
//                active = active,
//                onActiveChange = { active = it },
//                placeholder = { Text("Hinted search text") },
//                leadingIcon = { Icon(Icons.Rounded.Search, contentDescription = null) },
//                trailingIcon = { Icon(Icons.Rounded.MoreVert, contentDescription = null) },
//            ) {
//                LazyColumn(
//                    modifier = Modifier.fillMaxWidth(),
//                    contentPadding = PaddingValues(16.dp),
//                    verticalArrangement = Arrangement.spacedBy(4.dp)
//                ) {
//                    items(4) { idx ->
//                        val resultText = "Suggestion $idx"
//                        ListItem(
//                            modifier = Modifier.clickable {
//                                text = resultText
//                                active = false
//                            },
//                            headlineContent = {
//                                Text(
//                                    text = resultText,
//                                    style = MaterialTheme.typography.titleMedium
//                                )
//                            },
//                            supportingContent = {
//                                Text(
//                                    text = "Additional info",
//                                    style = MaterialTheme.typography.titleMedium
//                                )
//                            },
//                            leadingContent = { Icon(Icons.Rounded.Star, contentDescription = null) },
//                        )
//                    }
//                }
//            }
//        }
//
//        // Main content
//        LazyColumn(
//            contentPadding = PaddingValues(start = 16.dp, top = 72.dp, end = 16.dp, bottom = 16.dp),
//            verticalArrangement = Arrangement.spacedBy(8.dp)
//        ) {
//            val list = List(100) { "Text $it" }
//            items(count = list.size) {
//                Text(
//                    modifier = Modifier
//                        .fillMaxWidth()
//                        .padding(horizontal = 16.dp),
//                    text = list[it],
//                    style = MaterialTheme.typography.titleMedium.copy(Color.White)
//                )
//            }
//        }
//    }
//}


@Composable
@OptIn(ExperimentalMaterial3Api::class)
fun SimpleDockedSearchBar(
    onBackClick: () -> Unit,
    categoryId: String,
    searchScreenComponent: SearchScreenComponent
) {
    var text by rememberSaveable { mutableStateOf("") }
    var active by rememberSaveable { mutableStateOf(false) }
    var isLoading by remember { mutableStateOf(false) }
    val coroutineScope = rememberCoroutineScope()
    val state by searchScreenComponent.state.collectAsState(emptyList())

    // Function to handle search request
    fun performSearch(query: String) {
        val request = SearchExpertRequest(type = "All", searchQuery = query)
        isLoading = true
        coroutineScope.launch {
            searchScreenComponent.getExpertByRemote(request)
            isLoading = false
        }
    }

    Column(modifier = Modifier.fillMaxSize()) {
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .padding(top = 8.dp) // Adjust padding to ensure no overlap
        ) {
            Column {
                // Back Button Row
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(start = 16.dp, top = 8.dp, end = 16.dp)
                        .background(MaterialTheme.colorScheme.background)
                        .clickable(onClick = onBackClick)
                        .padding(8.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Icon(Icons.AutoMirrored.Rounded.ArrowBack, contentDescription = "Back")
                }

                // DockedSearchBar
                DockedSearchBar(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(horizontal = 16.dp),
                    query = text,
                    onQueryChange = { newText ->
                        text = newText
                        performSearch(newText)
                    },
                    onSearch = { active = false },
                    active = active,
                    onActiveChange = { active = it },
                    placeholder = { Text("Hinted search text") },
                    leadingIcon = { Icon(Icons.Rounded.Search, contentDescription = null) },
                    trailingIcon = { Icon(Icons.Rounded.MoreVert, contentDescription = null) }
                ) {
                    // Ensure LazyColumn is visible and fits properly
                    LazyColumn(
                        modifier = Modifier
                            .fillMaxWidth()
                            .background(MaterialTheme.colorScheme.background),
                        contentPadding = PaddingValues(16.dp),
                        verticalArrangement = Arrangement.spacedBy(4.dp)
                    ) {
                        items(state.size) { expertIndex ->
                            val expert = state[expertIndex]
                            ConsultantItem(
                                expert = expert,
                                position = state.indexOf(expert),
                                //position = expertIndex,
                                listener = object : CategoryConsultListRowClickListener {
                                    override fun onItemClicks(position: Int, expert: RequestConsultant) {
                                        // TODO: Implement item click action
                                    }

                                    override fun onChatClick(position: Int, expert: RequestConsultant) {
                                        // TODO: Implement chat click action
                                    }

                                    override fun onCallClick(position: Int, expert: RequestConsultant) {
                                        // TODO: Implement call click action
                                    }

                                    override fun onConsultNowClick(position: Int, expert: RequestConsultant) {
                                        // TODO: Implement consult now click action
                                    }
                                },
                                isFreeCallApplicable = true
                            )
                        }
                    }
                }
            }
        }

        // Main content below the search bar
        LazyColumn(
            modifier = Modifier
                .fillMaxSize()
                .padding(top = 72.dp), // Adjust top padding to avoid overlap with the search bar
            verticalArrangement = Arrangement.spacedBy(8.dp)
        ) {
            val list = List(100) { "Text $it" }
            items(count = list.size) {
                Text(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(horizontal = 16.dp),
                    text = list[it],
                    style = MaterialTheme.typography.titleMedium.copy(Color.White)
                )
            }
        }

        // Show LoadingProgressDialog if loading
        if (isLoading) {
            ExpertLoadingProgressDialog(onDismissRequest = { /* No action needed for dismiss */ })
        }
    }
}

@Composable
fun ExpertLoadingProgressDialog(
    onDismissRequest: () -> Unit,
) {
    Dialog(
        onDismissRequest = onDismissRequest,
        content = {
            Box(
                modifier = Modifier.size(80.dp),
                contentAlignment = Alignment.Center
            ) {
                CircularProgressIndicator(
                    modifier = Modifier.size(40.dp),
                    color = Color.Blue
                )
            }
        }
    )
}

