package com.winiwayuser.feature_common.persentation.more_tab.transaction_history.persentation

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.size
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import co.touchlab.kermit.Logger
import com.winiwayuser.core.persentation.HomePageLoadingProgressDialog
import com.winiwayuser.core.persentation.showToast
import com.winiwayuser.core.theme.background_color
import com.winiwayuser.feature_chat_consultation.persentation.chat_bottom_sheet_info.ChatInfoBottomSheetState

@Composable
fun TransactionHistory(component: TransactionHistoryComponent) {
    val state by component.state.collectAsState()

    when (val currentState = state) {
        is TransactionHistoryState.Error -> {
            Logger.d { "TransactionHistory on error state: ${currentState.msg}" }
            showToast(currentState.msg)
        }
        is TransactionHistoryState.Loading -> {
            if (currentState.isLoading) {
                Box(
                    modifier = Modifier.fillMaxSize().background(background_color),
                    contentAlignment = Alignment.Center
                ) {
                    HomePageLoadingProgressDialog(
                        onDismissRequest = { /* Handle dismiss if necessary */ },
                        backgroundColor = Color(0xFF2A2A2A),
                        loadingColor = Color.White
                    )
                }
                Logger.d { "TransactionHistory on loading state: ${currentState.isLoading}" }
            }
        }
        is TransactionHistoryState.TransactionInfoSuccess -> {
            Logger.d { "TransactionHistory on success state" }
            TransactionHistorySuccess(component, currentState.data)
        }
    }
}


