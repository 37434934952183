package com.winiwayuser.feature_call_consultation.persentation.call_starter

import androidx.compose.runtime.Composable
import com.arkivanov.decompose.ComponentContext
import com.winiwayuser.core.data.CommonExpertInfo
import com.winiwayuser.core.local.UserInfoProvider
import com.winiwayuser.core.persentation.LoginDialog
import com.winiwayuser.feature_call_consultation.persentation.call_bottom_sheet_info.CallConsultationBottomSheet
import com.winiwayuser.feature_call_consultation.persentation.call_bottom_sheet_info.CallConsultationComponent
import com.winiwayuser.feature_common.data.request.RequestConsultant
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData

@Composable
fun CallHostPage(
    callDetails: RequestConsultant,
    onLoginClicked: () -> Unit,
    onDismiss: () -> Unit,
    onStartCallConsultation: () -> Unit,
    context: ComponentContext,
    onNavigateRechargeCheckOut: (RechargeData?) -> Unit,
    onVoIpCallStart: (String, CommonExpertInfo) -> Unit
) {

    val isUserLogin = UserInfoProvider.getIsLogin()
    if (isUserLogin.not()) {
        LoginDialog(onLoginClicked = {
            onLoginClicked.invoke()
        }, onSignUpClicked = {
            onLoginClicked.invoke()
        }, onDismiss = {
            onDismiss.invoke()
        })
        return
    }

    CallConsultationBottomSheet(CallConsultationComponent(context = context,
        _expertId = callDetails.id.toString(),
        _expertName = callDetails.full_name.toString(),
        _expertImage = callDetails.profile_image_url.toString(),
        _expertSkills = callDetails.skills.toString(),
        onClose = {
            onDismiss.invoke()
        },
        onNavigateRechargeCheckOut = { rechargeData ->
            onNavigateRechargeCheckOut.invoke(rechargeData)
        },
        onLetsConnectButtonClick = {
            onStartCallConsultation.invoke()
        },
        onNavigateChoseRechargePack = {
            onNavigateRechargeCheckOut.invoke(null)
        },
        onVoIpCallStart = {
            onVoIpCallStart.invoke(
                it, CommonExpertInfo(
                    expertId = callDetails.id.toString(),
                    expertName = callDetails.full_name.toString(),
                    expertProfileImageUrl = callDetails.profile_image_url.toString(),
                    expertSkills = callDetails.skills.toString()
                )
            )
        }

    ))

}