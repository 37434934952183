package com.winiwayuser.core.agora

actual fun connectToAgora(
    userId: String,
    channelName: String,
    token: String,
    onLogin: () -> Unit,
    onJoin: () -> Unit,
    onError: (String) -> Unit
) {

//    val appId = "414eb4458bcc43039fd697e672dd0284"//"ef00091272b04468949c56ef7aaee6c3"
//    val peerId = "peer-user-id"
//    val messageText = "Hello, this is a test message"
//
//    initializeAgoraRTM(appId, token, userId)
//
//    window.setTimeout({
//        val client = AgoraRTM.createInstance(appId)
//        sendMessage(client, peerId, messageText)
//    }, 5000)
}

// commonMain/src/commonMain/kotlin/AgoraRtmClient.kt
actual class AgoraRtmClientApp actual constructor(appId: String) {

    private val agoraClient = AgoraRtmClientJS(appId)

    actual fun logout() {
        agoraClient.logout()
    }

    actual fun destroy() {
        agoraClient.destroy()
    }

    // Set the message receive callback
    actual fun onReceiveMessage(callback: (String, String) -> Unit) {
        agoraClient.onReceiveMessage = callback
    }

    actual fun initialize(
        token: String, userId: String, onLoginSuccess: () -> Unit, onLoginError: (String) -> Unit
    ) {

        agoraClient.initialize(
            token = token,
            userId = userId,
            onLoginSuccess = onLoginSuccess,
            onLoginError = onLoginError
        )
    }

    actual fun sendMessage(
        peerId: String,
        messageText: String,
        onMessageSentSuccess: () -> Unit,
        onMessageSentError: (String) -> Unit
    ) {

        agoraClient.sendMessage(
            peerId = peerId,
            messageText = messageText,
            onMessageSentSuccess = onMessageSentSuccess,
            onMessageSentError = onMessageSentError
        )
    }
}