package com.winiwayuser.feature_chat_consultation_new.persentation.chat_bottom_sheet_info.widget

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Info
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.winiwayuser.feature_call_consultation.data.response.CallDurationData

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun RechargeNowBottomSheetDialog(
    onRechargeSelected: (Int) -> Unit,
    data: CallDurationData?,
    onDismissRequest: () -> Unit
) {
    var selectedAmount by remember { mutableStateOf<Int?>(null) } // State to track selected amount

    ModalBottomSheet(
        onDismissRequest = { onDismissRequest() },
        shape = RoundedCornerShape(topStart = 16.dp, topEnd = 16.dp)
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp)
        ) {
            Spacer(modifier = Modifier.height(8.dp))

            // Warning Text
            Text(
                text = "Minimum balance of ${data?.minimumDuration} minutes (INR ${data?.minimumBalance}) is required to start ${data?.type}",
                color = Color.Red,
                fontSize = 14.sp,
                fontWeight = FontWeight.Bold,
                modifier = Modifier.align(Alignment.CenterHorizontally),
                textAlign = TextAlign.Center
            )

            Spacer(modifier = Modifier.height(16.dp))

//            // Profile Info Row
//            Row(
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .clip(RoundedCornerShape(8.dp))
//                    .background(Color(0xFFF5F5F5))
//                    .padding(16.dp),
//                verticalAlignment = Alignment.CenterVertically
//            ) {
//                // Profile image placeholder
//                Box(
//                    modifier = Modifier
//                        .size(48.dp)
//                        .clip(RoundedCornerShape(8.dp))
//                        .background(Color.LightGray)
//                )
//                //Spacer(modifier = Modifier.width(8.dp))
////                Column {
////                    Text(text = "Tarot Sima", fontWeight = FontWeight.Bold, fontSize = 18.sp)
////                    Text(text = "Tarot Reader", fontSize = 14.sp, color = Color.Gray)
////                }
//            }

            Spacer(modifier = Modifier.height(16.dp))

            // Tip Text
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Icon(Icons.Default.Info, contentDescription = null, tint = Color(0xFF6200EE))
                Spacer(modifier = Modifier.width(8.dp))
                Text(text = "Tip - 90% User Recharge More than 8 Minutes", color = Color.Gray, fontSize = 12.sp)
            }

            Spacer(modifier = Modifier.height(16.dp))

            // Recharge Options Grid
            Column(
                modifier = Modifier.fillMaxWidth(),
                verticalArrangement = Arrangement.spacedBy(8.dp)
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    RechargeOption(amount = 50, selectedAmount = selectedAmount, onSelect = { selectedAmount = it })
                    RechargeOption(amount = 100, selectedAmount = selectedAmount, onSelect = { selectedAmount = it })
                    RechargeOption(amount = 200, extra = "Get 10% Extra", selectedAmount = selectedAmount, onSelect = { selectedAmount = it })
                }
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    RechargeOption(amount = 500, extra = "Get 15% Extra", selectedAmount = selectedAmount, onSelect = { selectedAmount = it })
                    RechargeOption(amount = 1000, extra = "Get 20% Extra", selectedAmount = selectedAmount, onSelect = { selectedAmount = it })
                    RechargeOption(amount = 2000, extra = "Get 30% Extra", selectedAmount = selectedAmount, onSelect = { selectedAmount = it })
                }
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.Center
                ) {
                    RechargeOption(amount = 5000, extra = "Get 40% Extra", selectedAmount = selectedAmount, onSelect = { selectedAmount = it })
                }
            }

            Spacer(modifier = Modifier.height(16.dp))

            // Recharge Now Button
            Button(
                onClick = {
                    onRechargeSelected(selectedAmount ?: 0) // Handle the recharge action with the selected amount
                },
                modifier = Modifier.fillMaxWidth(),
                colors = ButtonDefaults.buttonColors(containerColor = Color(0xFF6200EE))
            ) {
                Text(text = "Recharge Now", color = Color.White, fontWeight = FontWeight.Bold)
            }
        }
    }
}

@Composable
fun RechargeOption(
    amount: Int,
    extra: String? = null,
    selectedAmount: Int?,
    onSelect: (Int) -> Unit
) {
    val isSelected = amount == selectedAmount
    val backgroundColor = if (isSelected) Color(0xFFEDE7F6) else Color(0xFFF5F5F5)
    val borderColor = if (isSelected) Color(0xFF6200EE) else Color.Transparent

    Column(
        modifier = Modifier
            .width(100.dp)
            .height(80.dp)
            .clip(RoundedCornerShape(8.dp))
            .background(backgroundColor)
            .border(2.dp, borderColor, RoundedCornerShape(8.dp))
            .padding(8.dp)
            .clickable { onSelect(amount) },
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        Text(text = "₹$amount", fontWeight = FontWeight.Bold)
        if (extra != null) {
            Spacer(modifier = Modifier.height(4.dp))
            Text(text = extra, fontSize = 10.sp, color = Color(0xFF6200EE))
        }
    }
}

