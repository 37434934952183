package com.winiwayuser.core.persentation

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonColors
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.ElevatedButton
import androidx.compose.material3.FilledTonalButton
import androidx.compose.material3.Icon
import androidx.compose.material3.OutlinedButton
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.unit.dp
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.theme.seed
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource

/*Normal Button*/
@Composable
fun CommonButton(
    buttonText: String,
    modifier: Modifier,
    onClick: () -> Unit
) {
    Button(
        onClick = onClick,
        modifier = modifier,
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        elevation = ButtonDefaults.buttonElevation(Scale.ELEVATION_SMALL),
    ) {
        Text(buttonText)
    }
}

/*Tonal Button*/
@Composable
fun AppTonalButton(
    buttonText: String,
    modifier: Modifier,
    onClick: () -> Unit,
    icon: DrawableResource? = null,
    isLoading: Boolean = false
) {
    FilledTonalButton(
        onClick = onClick,
        modifier = modifier,
        colors = ButtonDefaults.buttonColors(
            containerColor = Color.Blue,
            contentColor = Color.White,
            disabledContainerColor = Color.Gray,
            disabledContentColor = Color.LightGray
        ),
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        contentPadding = PaddingValues(16.dp),
        elevation = ButtonDefaults.buttonElevation(Scale.ELEVATION_SMALL),
        border = BorderStroke(1.dp, Color.Black)
    ) {
        if (isLoading) {
            CircularProgressIndicator(
                modifier = Modifier
                    .size(24.dp)
                    .align(Alignment.CenterVertically),
                color = Color.White,
                strokeWidth = 5.dp
            )
            return@FilledTonalButton
        }

        if (icon != null) {
            Icon(painterResource(icon), contentDescription = "Favorite")
            Spacer(modifier = Modifier.width(8.dp))
        }
        Text(buttonText)
    }
}

/*Outline Button */
@Composable
fun AppOutlineButton(
    buttonText: String,
    modifier: Modifier,
    onClick: () -> Unit,
    icon: DrawableResource? = null,
    isLoading: Boolean = false
) {
    OutlinedButton(
        onClick = onClick,
        modifier = modifier,
        colors = ButtonDefaults.buttonColors(
            containerColor = seed,
            contentColor = Color.White,
            disabledContainerColor = Color.Gray,
            disabledContentColor = Color.LightGray
        ),
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        contentPadding = PaddingValues(16.dp),
        elevation = ButtonDefaults.buttonElevation(Scale.ELEVATION_SMALL),
        border = BorderStroke(1.dp, Color.Black)
    ) {
        if (isLoading) {
            CircularProgressIndicator(
                modifier = Modifier
                    .size(24.dp)
                    .align(Alignment.CenterVertically),
                color = Color.White,
                strokeWidth = 5.dp
            )
            return@OutlinedButton
        }

        if (icon != null) {
            Icon(
                painterResource(icon),
                modifier = Modifier.size(10.dp),
                contentDescription = "Favorite"
            )
            Spacer(modifier = Modifier.width(8.dp))
        }
        Text(buttonText)
    }
}

/*Elevated Button*/
@Composable
fun AppElevatedButton(
    buttonText: String,
    modifier: Modifier,
    onClick: () -> Unit,
    icon: DrawableResource? = null,
    isLoading:Boolean = false
) {
    ElevatedButton(
        onClick = onClick,
        modifier = modifier,
        colors = ButtonDefaults.buttonColors(
            containerColor = Color.Blue,
            contentColor = Color.White,
            disabledContainerColor = Color.Gray,
            disabledContentColor = Color.LightGray
        ),
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        contentPadding = PaddingValues(16.dp),
        elevation = ButtonDefaults.buttonElevation(Scale.ELEVATION_SMALL),
        border = BorderStroke(1.dp, Color.Black)
    ) {
        if (isLoading) {
            CircularProgressIndicator(
                modifier = Modifier
                    .size(24.dp)
                    .align(Alignment.CenterVertically),
                color = Color.White,
                strokeWidth = 5.dp
            )
            return@ElevatedButton
        }

        if (icon != null) {
            Icon(painterResource(icon), contentDescription = "Favorite")
            Spacer(modifier = Modifier.width(8.dp))
        }
        Text(buttonText)
    }
}

@Composable
fun AppTextButton(
    buttonText: String,
    modifier: Modifier,
    onClick: () -> Unit,
    icon: DrawableResource? = null,
    isLoading: Boolean = false
) {
    TextButton(
        onClick = onClick,
        modifier = modifier,
        colors = ButtonDefaults.buttonColors(
            containerColor = Color.Blue,
            contentColor = Color.White,
            disabledContainerColor = Color.Gray,
            disabledContentColor = Color.LightGray
        ),
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        contentPadding = PaddingValues(16.dp),
        elevation = ButtonDefaults.buttonElevation(Scale.ELEVATION_SMALL),
        border = BorderStroke(1.dp, Color.Black)
    ) {
        if (isLoading) {
            CircularProgressIndicator(
                modifier = Modifier
                    .size(24.dp)
                    .align(Alignment.CenterVertically),
                color = Color.White,
                strokeWidth = 5.dp
            )
            return@TextButton
        }

        if (icon != null) {
            Icon(painterResource(icon), contentDescription = "Favorite")
            Spacer(modifier = Modifier.width(8.dp))
        }
        Text(buttonText)
    }
}

@Composable
fun CommonLoadingButton(
    text: String,
    isLoading: Boolean,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    buttonColors: ButtonColors = ButtonDefaults.buttonColors(),
    shape: Shape = RoundedCornerShape(Scale.CORNER_SMALL)
) {
    Button(
        onClick = {
            if (!isLoading) {
                onClick()
            }
        },
        colors = buttonColors,
        shape = shape,
        modifier = modifier,
    ) {
        if (isLoading) {
            CircularProgressIndicator(
                modifier = Modifier.size(24.dp),
                color = Color.White,
                strokeWidth = 2.dp
            )
        } else {
            Text(text = text)
        }
    }
}