package com.winiwayuser.feature_common.persentation.more_tab.component

import androidx.compose.runtime.Composable
import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import com.winiwayuser.core.RootComponent
import com.winiwayuser.feature_authentication.persentation.otp_validation_page.OtpValidationEvent
import com.winiwayuser.feature_common.persentation.home_tab.HomePageEvent
import kotlinx.coroutines.cancel

class MoreScreenTabComponent(
    context: ComponentContext,
    private val onMoreScreenEvent: (MoreScreenEvent) -> Unit
) : ComponentContext by context {

    init {
        Logger.d { "More Screen Tab Component initialized" }

        lifecycle.doOnStart {
            Logger.d { "More Screen Tab Component onStart" }
            //fetchData()
        }

        lifecycle.doOnDestroy {
            Logger.d { "More Screen Tab Component onDestroy" }
            //coroutineScope.cancel() // Cancel all coroutines when the component is destroyed
        }
    }
    fun event(event: MoreScreenEvent) {
        onMoreScreenEvent.invoke(event)
    }
}