package com.winiwayuser.feature_expert_details.Data.api

import com.winiwayuser.core.remote.BaseModeL
import com.winiwayuser.core.remote.KtorApi
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_common.data.request.RequestConsultant
import com.winiwayuser.feature_expert_consultation.data.remote.response.DataCat
import com.winiwayuser.feature_expert_consultation.data.remote.response.FetchConsultantResponse
import com.winiwayuser.feature_expert_details.Data.request.Data
import com.winiwayuser.feature_expert_details.Data.request.RelatedExpertRequest
import com.winiwayuser.feature_expert_details.Data.request.RequestConsultInfo
import com.winiwayuser.feature_expert_details.Data.request.RequestReviewSubmit
import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.ServerResponseException
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext

class ExpertDetailsApi: KtorApi() {

    companion object {
        const val GET_REQUEST_EXPERT_DETAILS = "consultant/"
        const val POST_SAVE_REVIEW_API = "save-review"
        const val GET_RELATED_EXPERT = "sort-by-expert-category"
    }

    private suspend fun expertDetailsInfApi(id:String)= client.get{
        url(GET_REQUEST_EXPERT_DETAILS+id)
    }.body<RequestConsultInfo>()

    private suspend fun submitExpertReviewApi(request:RequestReviewSubmit) = client.post {
        url(POST_SAVE_REVIEW_API)
        setBody(request)
    }.body<BaseModeL>()

    private suspend fun getRelatedExpert(request: RelatedExpertRequest) = client.post {
        url(GET_RELATED_EXPERT)
        setBody(request)
    }.body<FetchConsultantResponse>()


    suspend fun getExpertDetails(id:String):Resources<Data>{
        return withContext(Dispatchers.Default){
            try {
                val expertDetailsResponse = expertDetailsInfApi(id)
                if(expertDetailsResponse.status.not()){
                    return@withContext Resources.Error(msg = expertDetailsResponse.message ?: "Unexpected Error")
                }
                return@withContext Resources.Success(expertDetailsResponse.data)

            }catch (e: ClientRequestException){
                return@withContext Resources.Error(msg = "Client Request Error ${e.message}")
            }catch (e: ServerResponseException){
                return@withContext Resources.Error(msg = "Server Response Error ${e.message}")
            }catch (e: Exception){
                return@withContext Resources.Error(msg = "Unexpected Error ${e.message}")
            }
        }
    }


    suspend fun postSubmitExpertReview(request: RequestReviewSubmit):Resources<Boolean>{
        return withContext(Dispatchers.Default){
            try {
                val submitExpertReviewResponse = submitExpertReviewApi(request)
                if(submitExpertReviewResponse.status.not()){
                    return@withContext Resources.Error(msg = submitExpertReviewResponse.message ?: "Unexpected Error")
                }
                return@withContext  Resources.Success(submitExpertReviewResponse.status)
            }catch (e: ClientRequestException){
                return@withContext  Resources.Error(msg = "Unexpected Error ${e.message}")
            }catch (e: ServerResponseException){
                return@withContext  Resources.Error(msg = "Unexpected Error ${e.message}")
            }catch (e: Exception){
                return@withContext  Resources.Error(msg = "Unexpected Error ${e.message}")
            }
        }
    }


    suspend fun getRelatedExpertList(request: RelatedExpertRequest):Resources<DataCat>{
        return  withContext(Dispatchers.Default){
            try {
                val getRelatedExpertList = getRelatedExpert(request)
                if(getRelatedExpertList.status.not()){
                    return@withContext Resources.Error(msg = getRelatedExpertList.message ?: "Unexpected Error")
                }
                return@withContext  Resources.Success(getRelatedExpertList.data)
            }catch (e: ClientRequestException){
                return@withContext Resources.Error(msg = "Unexpected Error ${e.message}")
            }catch (e: ServerResponseException){
                return@withContext Resources.Error(msg = "Unexpected Error ${e.message}")
            }catch (e: Exception){
                return@withContext Resources.Error(msg = "Unexpected Error ${e.message}")
            }
        }
    }
}