package com.winiwayuser.feature_common.persentation.more_tab.transaction_history.persentation

import com.winiwayuser.feature_chat_consultation.persentation.chat_bottom_sheet_info.ChatInfoBottomSheetState
import com.winiwayuser.feature_chat_consultation_new.data.response.chat_duration.ChatDurationData
import com.winiwayuser.feature_common.persentation.more_tab.transaction_history.data.response.TransactionOrderData

sealed class TransactionHistoryState {
    data class Error(val msg: String) : TransactionHistoryState()
    data class Loading(val isLoading: Boolean) : TransactionHistoryState()
    data class TransactionInfoSuccess(val data: List<TransactionOrderData>) : TransactionHistoryState()
}