package com.winiwayuser.feature_expert_consultation.presentation.expert_list_page

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_common.data.response.SubCategoriesModel
import com.winiwayuser.feature_expert_consultation.domain.ExpertListRepo
import com.winiwayuser.feature_expert_consultation.data.remote.ExpertApi
import com.winiwayuser.feature_expert_consultation.data.remote.request.ExpertListRequest
import com.winiwayuser.feature_expert_consultation.presentation.expert_list_page.expert_list_state_info.ExpertListState
import com.winiwayuser.feature_expert_consultation.presentation.expert_list_page.expert_list_state_info.MarkFeavoriteState
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class ExpertListPageComponent(
    context: ComponentContext,
    private val categoryId: String?,
    private val categoryName: String?,
    private val subCategoriesGiven: List<SubCategoriesModel>?,
    private val onExpertListPageEvent: () -> Unit,
    private val onBackPressed: () -> Unit,
    private val onSearchFieldClick: () -> Unit,
    private val onClickExpertCard: (Int) -> Unit,
    private val onCallButtonClick: (String, String, String, String) -> Unit,
    private val onChatButtonClick: (String, String, String, String, String) -> Unit,
    private val onLoginClick: () -> Unit,
    private val onRechargeRequired: (RechargeData) -> Unit
) : ComponentContext by context, KoinComponent {

    val _categoryName get() = categoryName

    private val _subCategories = MutableStateFlow(subCategoriesGiven)
    val subCategories get() = _subCategories.asStateFlow()

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    private val expertApi: ExpertApi by inject()
    private val api: ExpertListRepo by inject()

    private val _state = MutableStateFlow<ExpertListState>(ExpertListState.Loading)
    val state: StateFlow<ExpertListState> get() = _state

    private val _markedFevoriteState =
        MutableStateFlow<MarkFeavoriteState>(MarkFeavoriteState.Loading(false))
    val markedFavoriteState: StateFlow<MarkFeavoriteState> get() = _markedFevoriteState.asStateFlow()

    init {
        lifecycle.doOnStart {
            Logger.d { "Expert List Screen Tab Component onStart" }

            coroutineScope.launch {
                fetchExpertList()
            }
            coroutineScope.launch {
                if (subCategoriesGiven.isNullOrEmpty()) {
                    getCategoryList()
                }
            }
        }

        lifecycle.doOnDestroy {
            Logger.d { "Expert List Screen Tab Component onDestroy" }
            coroutineScope.cancel() // Cancel all coroutines when the component is destroyed
        }
    }

    fun event(event: ExpertListPageEvent) {
        when (event) {
            is ExpertListPageEvent.OnExpertDetailsPage -> onExpertListPageEvent.invoke()
            is ExpertListPageEvent.OnErrorBottomSheetCallConsultation -> onExpertListPageEvent.invoke()
            is ExpertListPageEvent.OnBackPressed -> onBackPressed.invoke()
            is ExpertListPageEvent.OnSubCategoryClick -> {
                coroutineScope.launch { fetchExpertList() }
            }

            is ExpertListPageEvent.OnSearchFieldClick -> onSearchFieldClick.invoke()
            is ExpertListPageEvent.onExpertCardClick -> onClickExpertCard.invoke(event.expertId)

            is ExpertListPageEvent.onCallClick -> onCallButtonClick.invoke(
                event.expertId, event.expertName, event.expertImage, event.expertSkills
            )

            ExpertListPageEvent.onLoginClick -> onLoginClick.invoke()
            is ExpertListPageEvent.OnChatClick -> onChatButtonClick.invoke(
                event.expertId,
                event.expertName,
                event.expertImage,
                event.expertSkills,
                event.duration
            )

            is ExpertListPageEvent.RequiredRecharge -> onRechargeRequired.invoke(event.rechargeData)
        }
    }

    private suspend fun fetchExpertList() {
        coroutineScope.launch {
            val request = ExpertListRequest(
                categoryId = categoryId, type = "All"
            )
            expertApi.getExpertListRemote(request).collect { state ->
                _state.value = state
            }
        }
    }

    private fun onCategoriesFetched(subCategories: List<SubCategoriesModel>?) {
        coroutineScope.launch {
            _subCategories.emit(subCategories)
        }
    }

    private suspend fun getCategoryList() {
        coroutineScope.launch {
            expertApi.getCategoryList().collect { state ->
                when (state) {
                    is Resources.Error -> {}
                    is Resources.Loading -> {}
                    is Resources.Success -> {
                        val data = state.data?.categories?.find { it.id == categoryId?.toInt() }
                        onCategoriesFetched(data?.subcategories)
                    }
                }
            }
        }
    }

    suspend fun markExpertFavorite(id: String) {
        coroutineScope.launch {
            try {
                expertApi.postMarkFavorite(id).collect { state ->
                    _markedFevoriteState.value = state
                }
            } catch (e: Exception) {
                _markedFevoriteState.value =
                    MarkFeavoriteState.Error(e.message ?: "An error occurred")
            }
        }
    }

}


