package com.winiwayuser.feature_landing_page.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CourseDetailsSubmitForm(
    @SerialName("first_name") val firstName: String,
    @SerialName("last_name") val  lastName: String,
    @SerialName("mobile") val mobile: String,
    @SerialName("email") val email: String,
    @SerialName("course_interested_in") val courseInterestedIn: String,
    @SerialName("reference_code") val referenceCode: String,
)
