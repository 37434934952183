package com.winiwayuser.feature_call_consultation.persentation.widget

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.animateColorAsState
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Info
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.ModalBottomSheet
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.scale
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.winiwayuser.core.persentation.RechargeButton
import com.winiwayuser.core.theme.Scale.CORNER_LARGE
import com.winiwayuser.core.theme.currency
import com.winiwayuser.core.theme.currency_name
import com.winiwayuser.feature_call_consultation.data.response.CallDurationData
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData


@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun CallRechargeNowDialog(
    rechargeData: List<RechargeData>,
    onRechargeSelected: (RechargeData) -> Unit,
    data: CallDurationData?,
    onDismissRequest: () -> Unit
) {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass

    if (windowSizeClass == WindowWidthSizeClass.Compact) {
        // Show AlertDialog for compact window size
        CallRechargeNowBottomSheetDialog(
            rechargeData = rechargeData,
            onRechargeSelected = onRechargeSelected,
            data = data,
            onDismissRequest = onDismissRequest
        )

    } else {
        // Show ModalBottomSheet for medium and expanded window sizes
        CallRechargeNowAlertDialog(
            rechargeData = rechargeData,
            onRechargeSelected = onRechargeSelected,
            data = data,
            onDismissRequest = onDismissRequest
        )
    }
}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun CallRechargeNowBottomSheetDialog(
    rechargeData: List<RechargeData>,
    onRechargeSelected: (RechargeData) -> Unit,
    data: CallDurationData?,
    onDismissRequest: () -> Unit
) {
    var selectedRecharge by remember { mutableStateOf<RechargeData?>(null) }

    ModalBottomSheet(
        onDismissRequest = { onDismissRequest() },
        shape = RoundedCornerShape(topStart = CORNER_LARGE, topEnd = CORNER_LARGE),
        modifier = Modifier
            .fillMaxWidth()
            .wrapContentHeight()
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(horizontal = 16.dp, vertical = 24.dp)
        ) {
            Spacer(modifier = Modifier.height(8.dp))

            // Warning Text
            AnimatedVisibility(visible = data != null) {
                Text(
                    text = "Minimum balance of ${data?.minimumDuration} minutes ($currency_name ${data?.minimumBalance}) is required to start ${data?.type}",
                    color = MaterialTheme.colorScheme.error,
                    fontSize = 14.sp,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier
                        .align(Alignment.CenterHorizontally)
                        .padding(vertical = 8.dp),
                    textAlign = TextAlign.Center
                )
            }

            Spacer(modifier = Modifier.height(16.dp))

            // Tip Row
            TipRow()

            Spacer(modifier = Modifier.height(24.dp))

            // Recharge options grid
            RechargeOptionsGrid(
                selectedRecharge = selectedRecharge,
                onSelect = { selectedRecharge = it },
                rechargeData = rechargeData
            )

            Spacer(modifier = Modifier.height(24.dp))

            // Recharge button
            RechargeButton(
                selectedRecharge = selectedRecharge,
                onRechargeSelected = onRechargeSelected
            )
        }
    }
}

@Composable
fun CallRechargeNowAlertDialog(
    rechargeData: List<RechargeData>,
    onRechargeSelected: (RechargeData) -> Unit,
    data: CallDurationData?,
    onDismissRequest: () -> Unit
) {
    var selectedRecharge by remember { mutableStateOf<RechargeData?>(null) }

    AlertDialog(
        onDismissRequest = { onDismissRequest() },
        title = {
            //Text(text = "Recharge Now", fontWeight = FontWeight.Bold)
        },
        text = {
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .wrapContentHeight()
            ) {
                Spacer(modifier = Modifier.height(8.dp))

                // Warning Text
                AnimatedVisibility(visible = data != null) {
                    Text(
                        text = "Minimum balance of ${data?.minimumDuration} minutes ($currency_name ${data?.minimumBalance}) is required to start ${data?.type}",
                        color = MaterialTheme.colorScheme.error,
                        fontSize = 14.sp,
                        fontWeight = FontWeight.Bold,
                        modifier = Modifier
                            .align(Alignment.CenterHorizontally)
                            .padding(vertical = 8.dp),
                        textAlign = TextAlign.Center
                    )
                }

                Spacer(modifier = Modifier.height(16.dp))

                // Tip Row
                TipRow()

                Spacer(modifier = Modifier.height(24.dp))

                // Recharge options grid
                RechargeOptionsGrid(
                    selectedRecharge = selectedRecharge,
                    onSelect = { selectedRecharge = it },
                    rechargeData = rechargeData
                )

                Spacer(modifier = Modifier.height(24.dp))
            }
        },
        confirmButton = {
            RechargeButton(
                selectedRecharge = selectedRecharge,
                onRechargeSelected = onRechargeSelected
            )
        },
        dismissButton = {
//            TextButton(onClick = onDismissRequest) {
//                Text(text = "Cancel")
//            }
        }
    )
}

@Composable
fun TipRow() {
    Row(
        modifier = Modifier.fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Icon(
            Icons.Default.Info,
            contentDescription = "Information Icon",
            tint = MaterialTheme.colorScheme.primary
        )
        Spacer(modifier = Modifier.width(8.dp))
        Text(
            text = "Tip: 90% users recharge more than 8 minutes.",
            color = MaterialTheme.colorScheme.onSurfaceVariant,
            fontSize = 12.sp
        )
    }
}

@Composable
fun RechargeOptionsGrid(
    selectedRecharge: RechargeData?,
    onSelect: (RechargeData) -> Unit,
    rechargeData: List<RechargeData>
) {
    if (rechargeData.isEmpty()) {
        Text(
            text = "No recharge options available.",
            color = MaterialTheme.colorScheme.onSurface
        )
        return
    }

    Column(
        modifier = Modifier.fillMaxWidth(),
        verticalArrangement = Arrangement.spacedBy(16.dp)
    ) {
        rechargeData.chunked(3).forEach { rowItems ->
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceEvenly
            ) {
                rowItems.forEach { rechargeOption ->
                    RechargeOption(
                        rechargeOption = rechargeOption,
                        isSelected = rechargeOption == selectedRecharge,
                        onSelect = onSelect
                    )
                }
            }
        }
    }
}

@Composable
fun RechargeOption(
    rechargeOption: RechargeData,
    isSelected: Boolean,
    onSelect: (RechargeData) -> Unit
) {
    val backgroundColor by animateColorAsState(
        targetValue = if (isSelected) MaterialTheme.colorScheme.primaryContainer else MaterialTheme.colorScheme.surfaceVariant
    )
    val textColor by animateColorAsState(
        targetValue = if (isSelected) MaterialTheme.colorScheme.onPrimaryContainer else MaterialTheme.colorScheme.onSurface
    )
    val scale by animateFloatAsState(
        targetValue = if (isSelected) 1.1f else 1f
    )

    Column(
        modifier = Modifier
            .width(100.dp)
            .height(80.dp)
            .clip(RoundedCornerShape(8.dp))
            .background(backgroundColor)
            .border(2.dp, if (isSelected) MaterialTheme.colorScheme.primary else Color.Transparent, RoundedCornerShape(8.dp))
            .clickable { onSelect(rechargeOption) }
            .scale(scale)
            .padding(8.dp),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        Text(
            text = "$currency${rechargeOption.amount}",
            fontWeight = FontWeight.Bold,
            color = textColor
        )
        rechargeOption.cupon?.cuponText?.let {
            Spacer(modifier = Modifier.height(4.dp))
            Text(text = it, fontSize = 10.sp, color = MaterialTheme.colorScheme.secondary)
        }
    }
}



