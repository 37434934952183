package com.winiwayuser.feature_chat_consultation_new.di

import com.winiwayuser.feature_chat_consultation_new.data.api.ChatConsultationApi
import com.winiwayuser.feature_chat_consultation_new.domain.ChatConcernRepo
import com.winiwayuser.feature_chat_consultation_new.domain.ChatConsultationInfoRepo
import com.winiwayuser.feature_chat_consultation_new.domain.ChatRoomRepo
import com.winiwayuser.feature_chat_consultation.domain.ChatWaitingRepo
import com.winiwayuser.feature_chat_consultation_new.data.api.AstrologyApi
import org.koin.dsl.module

val chatConsultationModule = module {
    single { ChatConsultationApi() }
    single { AstrologyApi() }
    single { ChatConsultationInfoRepo(get()) }
    single { ChatWaitingRepo(get()) }
    single { ChatConcernRepo(get(),get()) }
    single { ChatRoomRepo(get()) }
}