package com.winiwayuser.feature_chat_consultation_new.persentation.chat_concern

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import com.winiwayuser.core.agora.AgoraRtmClientApp
import com.winiwayuser.core.agora.connectToAgora
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_chat_consultation_new.data.request.PlaceChatRequestBody
import com.winiwayuser.feature_chat_consultation_new.domain.ChatConcernRepo
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class ChatConcernComponent(
    context: ComponentContext,
    val expertName: String,
    val expertImage: String,
    val expertSkills: String,
    val expertId: String,
    private val onStartConsultation: () -> Unit,
    private val onClose: () -> Unit
) : ComponentContext by context, KoinComponent {

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    private val api: ChatConcernRepo by inject()
    private val _state = MutableStateFlow<ChatConcernState>(ChatConcernState.InitialState)
    val state get() = _state.asStateFlow()

    private var client: AgoraRtmClientApp? = null

    fun onClosePage() = onClose.invoke()
    fun startConsultation() = onStartConsultation.invoke()

    init {

        client?.onReceiveMessage { peerId, message ->
            println("Received message from $peerId: $message")
        }

        lifecycle.doOnStart {
            Logger.d { "Chat Concern Component onStart" }
            coroutineScope.launch {
                getConsultationInfo()
            }
        }

        lifecycle.doOnDestroy {
            Logger.d { "Chat Concern Component onDestroy" }
            coroutineScope.cancel() // Cancel all coroutines when the component is destroyed
        }
    }

    private suspend fun onError(msg: String) {
        _state.emit(ChatConcernState.OnError(msg))
    }


    private suspend fun getConsultationInfo() {

        Logger.d("Consultation Info start here")
        val request = PlaceChatRequestBody(
            questionId = null,
            expertId = expertId,
            category = null,
            answers = null,
            flashOff = null,
            images = null,
            type = null
        )

        api.placeWithChatConcern(request).collect {

            when (it) {
                is Resources.Error -> {
                    Logger.d("On Error -> ${it.msg}")
                    onError(it.msg ?: "Unexpected Error")
                }

                is Resources.Loading -> {
                    Logger.d("On Loading -> ${it.isLoading}")
                    _state.emit(ChatConcernState.LoadingState(it.isLoading))
                }

                is Resources.Success -> {
                    Logger.d("on Success -> ${it.data}")
                    it.data?.let { d ->

                        val appId =
                            "414eb4458bcc43039fd697e672dd0284"//"ef00091272b04468949c56ef7aaee6c3"
                        client = AgoraRtmClientApp(appId)
                        client?.initialize(d.agoraToken!!,
                            d.agoraUserId!!,
                            onLoginSuccess = {
                                Logger.d("Component Loggin Success")
                                client?.sendMessage(peerId = d.agoraUserId,
                                    messageText = "This is Test Msg", onMessageSentError = { msg ->
                                        Logger.d("Component Message Error -> $msg")
                                    }, onMessageSentSuccess = {
                                        Logger.d(
                                            "Component Message Sent"
                                        )
                                    })
                            },
                            onLoginError = { msg ->
                                Logger.d("Component Loggin Error -> $msg")
                            })
//                        client?.sendMessage("")


//                        connectToAgora(userId = d.agoraUserId!!,
//                            token = d.agoraToken!!,
//                            channelName = d.channelName!!,
//                            onJoin = {
//                                Logger.d("Joined Success")
//                            },
//                            onLogin = {
//                                Logger.d("Login Success")
//                            },
//                            onError = {
//                                Logger.d("On Error ")
//                            })
                    }
                }
            }
        }
    }

//    fun initializeAgoraClient(appId: String, token: String, userId: String): AgoraRtmClientApp {
//        val client = AgoraRtmClientApp(appId)
//        client.initialize(token, userId)
//
//        // Set the message receive callback
//        client.onReceiveMessage { peerId, message ->
//            println("Received message from $peerId: $message")
//        }
//
//        return client
//    }


}