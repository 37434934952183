package com.winiwayuser.feature_call_consultation.persentation.call_success_page

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import co.touchlab.kermit.Logger
import com.winiwayuser.core.persentation.LoadingProgressDialog
import com.winiwayuser.feature_call_consultation.persentation.call_bottom_sheet_info.CallPlaceInfoState
import com.winiwayuser.feature_call_consultation.persentation.call_consultation_options.CallConsultationOptionsComponent
import com.winiwayuser.feature_call_consultation.persentation.call_waitting_sucess.CallWaitingSuccess
import com.winiwayuser.feature_call_consultation.persentation.expert_busy.ErrorBottomSheetCallConsultation

@Composable
fun CallPlaceSuccess(component: CallSuccessComponent){
    val state by component.placeCallState.collectAsState()

    when(val currentState = state){
        is CallPlaceInfoState.Error -> {
            ErrorBottomSheetCallConsultation(currentState.msg, onDismiss = {component.onClose()})
        }
        is CallPlaceInfoState.Loading -> LoadingProgressDialog { }
        is CallPlaceInfoState.PlaceCallSuccess -> {
            CallWaitingSuccess(inComingCallMobileNumber = currentState.data.emergencyNumber ,component.expertId, component.expertName, component.expertImage, component.expertSkills,component.onClosePage)
        }
    }

}