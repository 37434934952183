package com.winiwayuser.feature_authentication.data

import co.touchlab.kermit.Logger
import co.touchlab.kermit.NoTagFormatter
import co.touchlab.kermit.loggerConfigInit
import co.touchlab.kermit.platformLogWriter
import com.winiwayuser.core.remote.KtorApi
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_authentication.data.request.RegisterRequest
import com.winiwayuser.feature_authentication.data.request.RequestOtpRequest
import com.winiwayuser.feature_authentication.data.request.VerifyOtpRequest
import com.winiwayuser.feature_authentication.data.response.RequestOtpData
import com.winiwayuser.feature_authentication.data.response.RequestOtpResponse
import com.winiwayuser.feature_authentication.data.response.VerifyOtpResponse
import com.winiwayuser.feature_authentication.data.response.VerifyOtpResponseData
import io.ktor.client.call.body
import io.ktor.client.request.header
import io.ktor.client.request.post
import io.ktor.client.request.request
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import io.ktor.client.statement.HttpResponse
import io.ktor.http.ContentType
import io.ktor.http.HttpHeaders
import io.ktor.http.contentType
import io.ktor.http.path
import io.ktor.util.InternalAPI
import io.ktor.utils.io.errors.IOException
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonConfiguration

class AuthApiService : KtorApi() {

    private val storeLogger = Logger(
        loggerConfigInit(platformLogWriter(NoTagFormatter)),
        "StoreApi"
    )

    companion object {
        const val POST_REQUEST_OTP = "request-otp"
        const val POST_VERIFY_OTP = "verify-otp"
        const val POST_REGISTER = "user/consultant-on-demand"
    }

    private suspend fun requestOtp(request: RequestOtpRequest) = client.post {
        url(POST_REQUEST_OTP)
        contentType(ContentType.Application.Json)
        setBody(request)
    }.body<RequestOtpResponse>()

    private suspend fun validateOtpApi(request: VerifyOtpRequest) = client.post {
        url(POST_VERIFY_OTP)
        contentType(ContentType.Application.Json)
        setBody(request)
    }.body<VerifyOtpResponse>()

    suspend fun registerApi(request: RegisterRequest) = client.post {
        url(POST_REGISTER)
        contentType(ContentType.Application.Json)
        setBody(request)
    }.body<VerifyOtpResponse>()


    suspend fun requestLoginOtp(request: RequestOtpRequest): Flow<Resources<RequestOtpData>> {
        return flow {
            emit(Resources.Loading(true))
            try {

                val productResponse = requestOtp(request)
                if (productResponse.status.not()) {
                    return@flow emit(
                        Resources.Error(
                            productResponse.message ?: "API ERROR RESPONSE"
                        )
                    )
                }

                emit(Resources.Success(productResponse.data))

            } catch (apiError: Exception) {
                storeLogger.d { apiError.message.toString() }
                emit(Resources.Error(apiError.message ?: "API ERROR RESPONSE"))
            } catch (io: IOException) {
                storeLogger.d { io.message.toString() }
                emit(Resources.Error(io.message ?: "API ERROR RESPONSE"))
            } finally {
                emit(Resources.Loading(false))
            }
        }
    }


    suspend fun validateOtp(request: VerifyOtpRequest): Flow<Resources<VerifyOtpResponseData>> {

        return flow {
            emit(Resources.Loading(true))
            try {
                val response = validateOtpApi(request)

                if (response.status.not()) {
                    return@flow emit(Resources.Error(response.message))
                }

                emit(Resources.Success(response.data))

            } catch (apiError: Exception) {
                emit(Resources.Error(apiError.message))
            } catch (httpException: IOException) {
                emit(Resources.Error(httpException.message))
            } finally {
                emit(Resources.Loading(false))
            }

        }
    }

    suspend fun registerUser(request: RegisterRequest): Flow<Resources<VerifyOtpResponseData>> {

        return flow {
            emit(Resources.Loading(true))
            try {
                val response = registerApi(request)

                if (response.status.not()) {
                    return@flow emit(Resources.Error(response.message))
                }

                emit(Resources.Success(response.data))

            } catch (apiError: Exception) {
                emit(Resources.Error(apiError.message))
            } catch (httpException: IOException) {
                emit(Resources.Error(httpException.message))
            } finally {
                emit(Resources.Loading(false))
            }

        }
    }

}