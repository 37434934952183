package com.winiwayuser.feature_authentication.persentation.login_page

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.ui.Alignment
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.winiwayuser.core.persentation.BrowserType
import com.winiwayuser.core.persentation.getDeviceType
import com.winiwayuser.core.theme.getBrowserTypeScaleDegrade
import com.winiwayuser.feature_authentication.persentation.login_page.widget.LoginInputBox
import com.winiwayuser.feature_authentication.persentation.login_page.widget.WelcomeSlide

@Composable
fun loginPage(
    component: LoginPageComponent
) {
    Column(
        verticalArrangement = Arrangement.Bottom,
        horizontalAlignment = Alignment.CenterHorizontally,
        modifier = Modifier
            .padding(12.dp)
            .fillMaxSize()
    ) {

        Box(modifier = Modifier.weight(1f).fillMaxWidth()) {
            WelcomeSlide()
        }

        Spacer(modifier = Modifier.height(24.dp))

        Box(
            modifier = Modifier.wrapContentHeight()
                .fillMaxWidth()
                .align(Alignment.CenterHorizontally)
        ) {
            LoginInputBox(
                onClickTerms = { component.onPrivacyPolicyClick() },
                onClickSubmit = { mob, countryCode, isWhatsappUpdatesChecked ->
                    component.onValidateOtp(
                        mobile = mob,
                        mobileCode = countryCode,
                        isWhatsappUpdatesChecked = isWhatsappUpdatesChecked
                    )
                })
        }

    }
}

