package com.winiwayuser.feature_chat_consultation_new.persentation.chat_waiting_room

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_chat_consultation_new.data.request.GetChatDurationRequest
import com.winiwayuser.feature_chat_consultation_new.data.response.chat_duration.ChatDurationData
import com.winiwayuser.feature_chat_consultation_new.domain.ChatConsultationInfoRepo
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_bottom_sheet_info.ChatInfoBottomSheetState
import com.winiwayuser.feature_winyway_wallet.data.api.WalletRechargeApi
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class ChatWaitingComponent(
    context: ComponentContext,
    private val _expertId: String,
    private val _expertName: String,
    private val _expertImage: String,
    private val _expertSkills: String,
    private val maxChatDuration:String,
    private val chatId:String,
    private val isEmergencyChat:String,
    private val onClose: () -> Unit,
) : ComponentContext by context, KoinComponent {


    val expertId get() = _expertId
    val expertName get() = _expertName
    val expertImage get() = _expertImage
    val expertSkills get() = _expertSkills
    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    private val api: ChatConsultationInfoRepo by inject()
    private val _state =
        MutableStateFlow<ChatInfoBottomSheetState>(ChatInfoBottomSheetState.Loading(false))
    val state get() = _state.asStateFlow()

    fun onClosePage() = onClose.invoke()
    fun startConsultation() = onClose.invoke()

    init {
        lifecycle.doOnStart {
            Logger.d { "Home Screen Tab Component onStart" }
            coroutineScope.launch {
                getConsultationInfo()
            }
        }

        lifecycle.doOnDestroy {
            Logger.d { "Home Screen Tab Component onDestroy" }
            coroutineScope.cancel() // Cancel all coroutines when the component is destroyed
        }
    }

    private suspend fun onError(msg: String) {
        _state.emit(ChatInfoBottomSheetState.Error(msg))
    }


    private suspend fun getConsultationInfo() {

        val request = GetChatDurationRequest(
            expertId = expertId
        )

        api.getChatConsultationInfo(request).collect {
            when (it) {
                is Resources.Error -> onError(it.msg ?: "Unexpected Error")
                is Resources.Loading -> _state.emit(ChatInfoBottomSheetState.Loading(it.isLoading))
                is Resources.Success -> {

                }
            }
            Logger.d("component state -> $it")
        }

    }

    private suspend fun onRechargeListLoaded(data: ChatDurationData, list: List<RechargeData>) {
        _state.emit(ChatInfoBottomSheetState.NormalChatLowBalance(data, list))
    }

    private suspend fun getRechargePlans(chatDurationData: ChatDurationData) {
        val rechargePackState = WalletRechargeApi().requestRechargePlanList().data?.data

        if (rechargePackState.isNullOrEmpty()) {
            onError("Something Went Wrong While Loading")
            return
        }

        onRechargeListLoaded(chatDurationData, rechargePackState)

    }

}