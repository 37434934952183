package com.winiwayuser.feature_authentication.persentation.login_page

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.winiwayuser.core.persentation.settings

class LoginPageComponent(
    context: ComponentContext,
    private val navigateToOtpValidationPage: (String, String,Boolean) -> Unit,
    private val onPrivacyPolicyClick: () -> Unit
) : ComponentContext by context {


    init {
        Logger.d(
            tag = "LOGIN_PAGE_COMPONENT",
            messageString = "Login Page Component Initialized!"
        )
        settings.putString("Test_key", "Hi this is Test key added!")
    }

    fun onPrivacyPolicyClick() {
        Logger.d(
            tag = "LOGIN_PAGE_COMPONENT",
            messageString = "On Privacy Policy Clicked!"
        )
        onPrivacyPolicyClick.invoke()
    }

    fun onValidateOtp(mobile: String, mobileCode: String, isWhatsappUpdatesChecked: Boolean) {
        Logger.d(
            tag = "LOGIN_PAGE_COMPONENT",
            messageString = "On Validate Otp Clicked! -> mobile -> $mobile"
        )
        navigateToOtpValidationPage(mobile, mobileCode, isWhatsappUpdatesChecked)
    }

}