package com.winiwayuser.feature_call_consultation.call_bottom_sheet_info

import com.winiwayuser.feature_call_consultation.data.response.Data

sealed class CallPlaceInfoState {
    data class Error(val msg: String) : CallPlaceInfoState()
    data class Loading(val isLoading: Boolean) : CallPlaceInfoState()
    data class PlaceCallSuccess(val data: Data) : CallPlaceInfoState()
//    data class FreeCallLowBalance(val data: CallDurationData) : CallPlaceInfoState()
//    data class NormalCallLowBalance(
//        val data: CallDurationData,
//        val rechargeData: List<RechargeData>
//    ) : CallPlaceInfoState()
}