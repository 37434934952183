package com.winiwayuser.feature_winyway_wallet.persentation.recharge_getway

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import com.winiwayuser.core.local.UserInfoProvider
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_winyway_wallet.core.startPayment
import com.winiwayuser.feature_winyway_wallet.data.api.WalletRechargeApi
import com.winiwayuser.feature_winyway_wallet.data.request.GetRazorPayRequest
import com.winiwayuser.feature_winyway_wallet.data.request.VerifyRechargeFailRequest
import com.winiwayuser.feature_winyway_wallet.data.request.VerifyRechargeStatusRequest
import com.winiwayuser.feature_winyway_wallet.data.response.razorpay_order_id.RazorPayOrderData
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class PaymentGatewayComponent(
    context: ComponentContext,
    private val paymentGatewayArg: PaymentGatewayArg,
    private val onRechargeSuccess: (String) -> Unit, //Required Top-Up Amount
    private val onRechargeFailed: (String) -> Unit // Required Top-Up Amount
) : ComponentContext by context, KoinComponent {

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    private val api: WalletRechargeApi by inject()
    private val _state = MutableStateFlow<RazorPayOrderData?>(null)
    val state get() = _state.asStateFlow()

    init {
        lifecycle.doOnStart {
//            onRechargeFailed.invoke(paymentGatewayArg.cashPayment)
            Logger.d { "PaymentGatewayComponent on start" }
            getRazorpayOrderId()
        }

        lifecycle.doOnDestroy {
            Logger.d { "PaymentGatewayComponent on destroy" }
            coroutineScope.cancel() // Cancel all coroutines when the component is destroyed
        }
    }

//    private fun initRazorpayPayment(razorpayOrderId: String) {
//        startPayment(
//            razorpayOrderId,
//            onPaymentSuccess = { paymentId, orderId, signature ->
//                verifySuccessStatus(orderId, paymentId, signature)
//            },
//            onPaymentFailed = { throwable ->
//                verifyFailStatus(
//                    throwable.message ?: "Unexpected Payment Error"
//                )
//            })
//    }
private fun initRazorpayPayment(razorpayOrderId: String) {
    startPayment(
        razorpayOrderId,
        onPaymentSuccess = { paymentId, orderId, signature ->
            verifySuccessStatus(orderId, paymentId, signature)
        },
        onPaymentFailed = { throwable ->
            Logger.d { "onPaymentFailed ${throwable.message}" }
            // Handle user-canceled payment and log the error
            val errorMessage = throwable.message ?: "Unexpected Payment Error"
            if (errorMessage.contains("Payment cancelled", ignoreCase = true)) {
                //Timber.e("Payment was canceled by the user.")
                Logger.d {  "initRazorpayPayment:-  Payment was canceled by the user" }
                verifyFailStatus("User canceled the payment.")
            } else {
                Logger.d { "initRazorpayPayment:- Payment failed: $errorMessage" }
                verifyFailStatus(errorMessage)
            }
        }
    )
}


    private fun getRazorpayOrderId() {
        val getRazorPayRequest = GetRazorPayRequest(
            taxnId = paymentGatewayArg.txnId,
            isCoinUsed = if (paymentGatewayArg.coinUsed > 0) 1 else 0,
        )
        coroutineScope.launch {
            api.getRazorPayOrderId(getRazorPayRequest).collectLatest {
                when (it) {
                    is Resources.Success -> {
                        if (it.data?.razorpayOrderId == null) {
                            onRechargeFailed.invoke(paymentGatewayArg.cashPayment)
                            return@collectLatest
                        }
                        initRazorpayPayment(it.data.razorpayOrderId)
                        _state.value = it.data
                        Logger.d { "getRazorpayOrderId success part:- ${it.data.razorpayOrderId}" }
                    }

                    is Resources.Error -> {
                        Logger.d { "getRazorpayOrderId error part :- ${paymentGatewayArg.cashPayment}" }
                        onRechargeFailed.invoke(paymentGatewayArg.cashPayment)
                    }
                    else -> {
                        Logger.d { "getRazorpayOrderId else part :- ${it.data?.razorpayOrderId}" }
                    }
                }
            }
        }
    }

    private fun verifySuccessStatus(orderId: String, paymentId: String, signature: String) {
        val getRazorPayRequest = VerifyRechargeStatusRequest(
            razorPaySignature = signature,
            razorPayOrderId = orderId,
            paymentId = paymentId
        )
        coroutineScope.launch {
            api.getVerifyRechargeStatus(getRazorPayRequest).collectLatest {
                when (it) {
                    is Resources.Success -> {
                        it.data?.totalWalletBalance?.let { totalMoney ->
                            UserInfoProvider.setTotalWalletMoney(totalMoney)
                        }
                        onRechargeSuccess.invoke(paymentGatewayArg.cashPayment)
                    }

                    is Resources.Error -> onRechargeFailed.invoke(
                        it.msg ?: "Unexpected Error Happening"
                    )

                    else -> Unit
                }
            }
        }
    }

    private fun verifyFailStatus(status: String) {
        val getRazorPayRequest = VerifyRechargeFailRequest(
            orderId = paymentGatewayArg.orderId,
            status = status
        )
        coroutineScope.launch {
            api.getRechargeStatusFailed(getRazorPayRequest).collectLatest {

                when (it) {
                    is Resources.Success -> onRechargeFailed.invoke(paymentGatewayArg.cashPayment)
                    else -> onRechargeFailed.invoke(paymentGatewayArg.cashPayment)
                }
            }
        }
    }

}