package com.winiwayuser.feature_expert_details

import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.graphics.Color
import co.touchlab.kermit.Logger
import com.winiwayuser.core.persentation.HomePageLoadingProgressDialog
import com.winiwayuser.core.persentation.showToast
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_expert_details.Data.ExpertDetailsApiService
import com.winiwayuser.feature_expert_details.Data.request.RequestConsultInfo
import com.winiwayuser.feature_expert_details.expert_details_state_info.ExpertDetailsState
import com.winiwayuser.feature_expert_details.expert_details_state_info.SubmitExpertReviewState
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch


@Composable
fun ExpertDetails(component: ExpertDetailsPageComponent) {

    val state by component.state.collectAsState()
    val submitExpertReviewState by component.submitReviewState.collectAsState()
    val relatedExpertState by component.relatedExpertState.collectAsState()
    val expertDetailsInfoState = remember { mutableStateOf<Resources<RequestConsultInfo>>(Resources.Loading()) }

    // Fetch Expert Details on Component Initialization
    LaunchedEffect(key1 = true) {
        expertDetailsInfoState.value = ExpertDetailsApiService().requestExpertDetailsInfo(component._expertId.toString())
    }

    // Handle Expert Details State
    when (val result = expertDetailsInfoState.value) {
        is Resources.Error -> {
            // Handle error state, e.g., show error message
        }
        is Resources.Loading -> {
            HomePageLoadingProgressDialog(
                onDismissRequest = { /* Handle dismiss if necessary */ },
                backgroundColor = Color(0xFF2A2A2A),
                loadingColor = Color.White
            )
        }
        is Resources.Success -> {
            val expertData = result.data?.data?.consultant!!
            ExpertDetailsSuccess(component, expertData = expertData)
        }
    }

    // Handle Submit Review State
    when (val result = submitExpertReviewState) {
        is SubmitExpertReviewState.Error -> {
            // Handle error state, e.g., show error message
            showToast(result.msg)
        }
        is SubmitExpertReviewState.Loading -> {
            if (result.isLoading) {
                HomePageLoadingProgressDialog(
                    onDismissRequest = { /* Handle dismiss if necessary */ },
                    backgroundColor = Color(0xFF2A2A2A),
                    loadingColor = Color.White
                )
            }
        }
        is SubmitExpertReviewState.Success -> {
            LaunchedEffect(key1 = true) {
                // Refresh expert details after successful review submission
                expertDetailsInfoState.value = ExpertDetailsApiService().requestExpertDetailsInfo(component._expertId.toString())
            }
        }
    }


//    when(val result = relatedExpertState){
//        is ExpertDetailsState.Error -> {}
//        is ExpertDetailsState.Loading -> {
//            if(result.isLoading){
//                HomePageLoadingProgressDialog(
//                    onDismissRequest = { /* Handle dismiss if necessary */ },
//                    backgroundColor = Color(0xFF2A2A2A),
//                    loadingColor = Color.White
//                )
//            }
//        }
//        is ExpertDetailsState.Success -> {
//            val expertData = result.consultantDetails
//            ExpertDetailsSuccess(component, expertData = expertData)
//        }
//    }
}

//    LaunchedEffect(key1 = true) {
//        when (val currentState = state) {
//            is ExpertDetailsState.Error -> {}
//            is ExpertDetailsState.Loading -> {
//                CoroutineScope(Dispatchers.Default).launch {  }
//                HomePageLoadingProgressDialog(
//                    onDismissRequest = { /* Handle dismiss if necessary */ },
//                    backgroundColor = Color(0xFF2A2A2A),
//                    loadingColor = Color.White
//                )
//            }
//
//            is ExpertDetailsState.Success -> {
//                val expertData = currentState.consultantDetails
//                CoroutineScope(Dispatchers.Default).launch {  }
//                ExpertDetailsSuccess(component, expertData = expertData)
//            }
//        }
//    }
//Smart cast to 'ExpertDetailsState.Success' is impossible, because 'state' is a property that has open or custom getter


//    when (state) {
//        is ExpertDetailsState.Loading -> {
//            Logger.d(tag = "ExpertDetails State:-", messageString = "On Loading State")
//            HomePageLoadingProgressDialog(
//                onDismissRequest = { /* Handle dismiss if necessary */ },
//                backgroundColor = Color(0xFF2A2A2A),
//                loadingColor = Color.White
//            )
////            if (state.isLoading) {
////
////            }
//        }
//        is ExpertDetailsState.Success -> {
//            Logger.d(tag = "ExpertDetails State:-", messageString = "On Success State")
//            val expert = (state as ExpertDetailsState.Success).consultantDetails
//            ExpertDetailsSuccess(component, expertData = expert)
//        }
//        is ExpertDetailsState.Error -> {
//            Logger.d(tag = "ExpertDetails State:-", messageString = "On Error State")
//
//        }
//    }
//}