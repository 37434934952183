package com.winiwayuser.feature_call_consultation.persentation.call_bottomsheet

import androidx.compose.runtime.Composable
import com.winiwayuser.core.local.UserInfoProvider
import com.winiwayuser.core.persentation.LoginDialog
import com.winiwayuser.feature_common.data.request.RequestConsultant

@Composable
fun CallHostPage(
    callDetails: RequestConsultant,
    onLoginClicked: () -> Unit,
    onDismiss: () -> Unit,
    onStartCallConsultation: () -> Unit
){
    val isUserLogin = UserInfoProvider.getIsLogin()
    if (isUserLogin.not()) {
        LoginDialog(
            onLoginClicked = {
                onLoginClicked.invoke()
            },
            onSignUpClicked = {
                onLoginClicked.invoke()
            },
            onDismiss = {
                onDismiss.invoke()
            }
        )
        return
    }

    onStartCallConsultation.invoke()
    onDismiss.invoke()
}