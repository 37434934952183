package com.winiwayuser.core.remote

import co.touchlab.kermit.Logger
import com.winiwayuser.core.platform.encodeBase64
import io.ktor.client.HttpClient


abstract class KtorApi {

    fun create(username: String, password: String): String {
        val credentials = "$username:$password"
        val base64Credentials = encodeBase64(credentials)
        return "Basic $base64Credentials"
    }

    companion object {
        const val BASE_URL = "https://live.winyway.com/api/v1/"
       //const val BASE_URL = "https://prod.winyway.com/api/v1/"
    }

    val client: HttpClient by lazy { createHttpClient(null) }
    val astrologyClient by lazy {
        createHttpClient(
            "astrology"
        )
    }

}

// Expect function for creating the HTTP client
expect fun createHttpClient(customAuthorization: String?): HttpClient

