package com.winiwayuser.feature_common.persentation.more_tab.edit_profile.domain

import co.touchlab.kermit.Logger
import com.winiwayuser.core.local.UserInfoProvider
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_authentication.data.request.VerifyOtpRequest
import com.winiwayuser.feature_authentication.data.response.RequestOtpData
import com.winiwayuser.feature_authentication.data.response.VerifyOtpResponseData
import com.winiwayuser.feature_authentication.domain.CurrentScreen
import com.winiwayuser.feature_common.data.remote.HomeApiService.Companion.GET_REQUEST_LIVE_SESSIONS
import com.winiwayuser.feature_common.data.response.RequestUpcomingLiveSessionResponse
import com.winiwayuser.feature_common.data.response.UpcomingLiveData
import com.winiwayuser.feature_common.persentation.more_tab.edit_profile.data.EditProfileApiService
import com.winiwayuser.feature_common.persentation.more_tab.edit_profile.data.EditProfileModel
import com.winiwayuser.feature_common.persentation.more_tab.edit_profile.data.EditProfileRequest
import com.winiwayuser.feature_common.persentation.more_tab.edit_profile.data.ProfileDetails
import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.ServerResponseException
import io.ktor.client.request.post
import io.ktor.utils.io.errors.IOException
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.withContext

class EditProfileUserUseCases(private val editProfileApiService: EditProfileApiService){
    suspend fun onEditProfile(request: EditProfileRequest): Flow<Resources<ProfileDetails>> {
        return flow {
            editProfileApiService.onUpdateUserProfile(request).collect { state ->
                Logger.d("returnedFlow -> $state")
                when (state) {
                    is Resources.Error -> emit(Resources.Error(state.msg ?: "Unexpected Error"))
                    is Resources.Loading -> emit(Resources.Loading(state.isLoading))
                    is Resources.Success -> {
                        state.data.let {
                            UserInfoProvider.setFullName(it?.full_name.toString())
                            UserInfoProvider.setEmail(it?.email ?: "")
                            UserInfoProvider.setImage(it?.profileimg.toString())
                            UserInfoProvider.setId(it?.user_id.toString())
                        }
                        emit(Resources.Success(state.data))
                    }
                }
            }
        }
    }
}