package com.winiwayuser.feature_common.persentation.home_tab.home_screen_widget

import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.lazy.grid.items
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.feature_common.data.request.RequestCategory
import com.winiwayuser.feature_common.persentation.home_tab.HomeScreenTabComponent
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.astrologer_icon
import com.winiwayuser.resources.science_astro_icon
import com.winiwayuser.resources.therpy_icon
import org.jetbrains.compose.resources.painterResource

//@Composable
//fun TopCategoryList(
//    onCategoryClick: (RequestCategory) -> Unit,
//    component: HomeScreenTabComponent // Replace with your actual data type
//) {
//
//    val categoryData by component.topCategory.collectAsState()
//
//    if (categoryData.isNullOrEmpty()) return
//
//    val spanCount = if (categoryData?.size!! > 2) 3 else 2
//
//    LazyVerticalGrid(
//        columns = GridCells.Fixed(spanCount),
//        contentPadding = PaddingValues(8.dp),
//        modifier = Modifier
//            .fillMaxWidth()
//            .wrapContentHeight()
//            .height(120.dp)
//            .padding(horizontal = 12.dp)
////            .fillMaxSize() // Ensure the grid fills the available space within the Box
//    ) {
//        items(categoryData!!) { category ->
//            CategoryCard(
//                categoryName = category,
//                onCategoryClick = onCategoryClick
//            )
//        }
//    }
//}

@Composable
fun TopCategoryList(
    onCategoryClick: (RequestCategory) -> Unit,
    component: HomeScreenTabComponent // Replace with your actual data type
) {
    val categoryData by component.topCategory.collectAsState()

    if (categoryData.isNullOrEmpty()) return

    Row(
        modifier = Modifier
            .fillMaxWidth()
            .wrapContentHeight()
            .padding(horizontal = Scale.HORIZONTAL_PADDING),
        horizontalArrangement = Arrangement.spacedBy(8.dp)
    ) {
        categoryData!!.forEach { category ->
            Box(
                modifier = Modifier
                    .weight(1f)
            ) {
                CategoryCard(
                    categoryName = category,
                    onCategoryClick = onCategoryClick
                )
            }
        }
    }
}



@Composable
fun CategoryCard(
    categoryName: RequestCategory,
    onCategoryClick: (RequestCategory) -> Unit,
) {
    Card(
        shape = RoundedCornerShape(Scale.CORNER_SMALL),  // Adjust the corner radius as needed
        elevation = CardDefaults.elevatedCardElevation(Scale.ELEVATION_LARGE),  // Adjust the elevation as needed
        modifier = Modifier
            .fillMaxWidth()
            //.width(100.dp)
            //.height(120.dp)
            .wrapContentHeight()
            .clickable { onCategoryClick(categoryName) }
    ) {
        val resImage = when (categoryName.name) {
            "Mental Wellness Therapist" -> Res.drawable.therpy_icon
            "Astrologer" -> Res.drawable.science_astro_icon
            "Scientific Astrology" -> Res.drawable.astrologer_icon
            else -> Res.drawable.astrologer_icon
        }

        Image(
            painter = painterResource(resource = resImage),
            contentDescription = "",
            contentScale = ContentScale.FillBounds,
            modifier = Modifier
                .fillMaxSize()
                .clip(RoundedCornerShape(8.dp))  // Adjust the corner radius if needed
        )
    }
}