package com.winiwayuser.core.remote

import com.winiwayuser.core.local.TokenProvider
import com.winiwayuser.core.remote.KtorApi.Companion.BASE_URL
import io.ktor.client.HttpClient
import io.ktor.client.engine.js.Js
import io.ktor.client.plugins.HttpTimeout
import io.ktor.client.plugins.auth.Auth
import io.ktor.client.plugins.auth.providers.BearerTokens
import io.ktor.client.plugins.auth.providers.bearer
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.plugins.defaultRequest
import io.ktor.client.plugins.logging.DEFAULT
import io.ktor.client.plugins.logging.LogLevel
import io.ktor.client.plugins.logging.Logger
import io.ktor.client.plugins.logging.Logging
import io.ktor.client.request.header
import io.ktor.serialization.kotlinx.json.json
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.json.Json

actual fun getPlatformLogger(): Logger= object : Logger {
    override fun log(message: String) {
        console.log("Browser Log: $message")
    }
}

@OptIn(ExperimentalSerializationApi::class)
actual fun createHttpClient(): HttpClient {

    return HttpClient(Js) {
        //Timeout plugin to set up timeout milliseconds for client
        install(HttpTimeout) {
            socketTimeoutMillis = 60_000
            requestTimeoutMillis = 60_000
        }
        //Logging plugin combined with kermit(KMP Logger library)
        install(Logging) {
            logger = Logger.DEFAULT
            level = LogLevel.ALL
            logger = object: Logger {
                override fun log(message: String) {
                    console.log("HTTP_CLIENT", message)
                }
            }
        }
        //We can configure the BASE_URL and also
        //the deafult headers by defaultRequest builder
        defaultRequest {
            header("Content-Type", "application/json")
//            header("Authorization", "Bearer ${BuildKonfig.OPENAI_API_KEY}")
            url(BASE_URL)
        }
        //ContentNegotiation plugin for negotiationing media types between the client and server
        install(ContentNegotiation) {
            json(Json {
                prettyPrint = true
                isLenient = true
                ignoreUnknownKeys = true
                explicitNulls = false
            })
        }
        install(Auth) {
            bearer {
                loadTokens {
                    console.log("auth token -< ${TokenProvider.getNewToken()}")
                    BearerTokens(
                        accessToken = TokenProvider.getNewToken(),
                        refreshToken = TokenProvider.getRefreshToken()
                    )
                }
            }
        }
    }
}