
import com.arkivanov.essenty.lifecycle.LifecycleOwner
import com.arkivanov.essenty.lifecycle.LifecycleRegistry
import kotlinx.browser.window

class MyLifecycleOwner : LifecycleOwner {
    private val lifecycleRegistry = LifecycleRegistry()

    init {
        window.addEventListener("load", {
            lifecycleRegistry.onCreate()
            lifecycleRegistry.onStart()
        })
        window.addEventListener("unload", {
            lifecycleRegistry.onStop()
            lifecycleRegistry.onDestroy()
        })
    }

    override val lifecycle = lifecycleRegistry
}