package com.winiwayuser.core.persentation

import com.russhwolf.settings.Settings
import com.winiwayuser.core.platform.PlatformNavigator

const val WINYWAY_IMAGE_URL =
    "https://live.winyway.com/images/logo/winyway_logo_icon.png"

expect fun getDeviceType(): DeviceType

enum class DeviceType {
    ANDROID, IOS, DESKTOP, NATIVE, WEB_HD, WEB_FULL_HD, WEB_2K, WEB_4K, WEB_UD
}

enum class BrowserType {
    WEB_HD, WEB_FULL_HD, WEB_2K, WEB_4K, WEB_UD
}

expect fun showToast(message: String)
expect fun openPlayStore(url: String)
expect fun clearCaptchaData()

expect fun clearSessionOrCache()
/*Shared Precedence save and retirve data*/
expect val settings: Settings

fun onPlayStoreLinkClick(platformNavigator: PlatformNavigator) {
    platformNavigator.openPlayStore()
}

fun onAppStoreLinkClick(platformNavigator: PlatformNavigator) {
    platformNavigator.openAppStore()
}