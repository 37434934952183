package com.winiwayuser.feature_expert_consultation.presentation


import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.winiwayuser.core.local.UserInfoProvider.getIsLogin
import com.winiwayuser.feature_common.data.request.RequestConsultant

@Composable
fun ExpertCallPrice(consultant: RequestConsultant, isFreeCallApplicable: Boolean) {

    val callPerMinutePrice = consultant.callPerMinutePrice.toString()
    val isExpertFreeCall = consultant.isFreeCall == 1
    val flashPrice = consultant.expertFlashPrice?.let { consultant.winywayFlashPrice?.plus(it) }
    val isFreeCallApplicableCalculated =
        if (!getIsLogin()) isExpertFreeCall else isExpertFreeCall && isFreeCallApplicable

    if (isFreeCallApplicableCalculated) {
        Row {
            Text(
                text = "NEW USER",
                fontSize = 14.sp,
                textAlign = TextAlign.Center,
                color = Color.Black,
                modifier = Modifier.padding(horizontal = 1.dp)
            )
            Text(
                text = "₹ $callPerMinutePrice/Min",
                fontSize = 12.sp,
                textAlign = TextAlign.Center,
                color = Color.Gray,
                textDecoration = TextDecoration.LineThrough,
                modifier = Modifier.padding(horizontal = 1.dp)
            )
        }
    } else {
        if (flashPrice != null && flashPrice.toInt() != 0) {
            Box(
                modifier = Modifier
                    .padding(horizontal = 2.dp, vertical = 1.dp),
                contentAlignment = Alignment.Center
            ) {
                Row(
                    // modifier = Modifier.padding(horizontal = 2.dp, vertical = 1.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Text(
                        text = "₹ ${flashPrice}/Min",
                        fontSize = 14.sp,
                        textAlign = TextAlign.Center,
                        color = Color.Black,
                        fontWeight = FontWeight.Bold,
                        modifier = Modifier.padding(horizontal = 1.dp)
                    )
                    Spacer(modifier = Modifier.width(1.dp))
                    Text(
                        text = "₹ $callPerMinutePrice/Min",
                        fontSize = 12.sp,
                        textAlign = TextAlign.Center,
                        color = Color.Gray,
                        textDecoration = TextDecoration.LineThrough,
                        modifier = Modifier.padding(horizontal = 1.dp)
                    )
                }
            }
        } else {
            Box(
                modifier = Modifier
                    .padding(horizontal = 2.dp, vertical = 1.dp),
                contentAlignment = Alignment.Center
            ) {
                Text(
                    text = "₹ $callPerMinutePrice /Min",
                    fontSize = 14.sp,
                    textAlign = TextAlign.Center,
                    fontWeight = FontWeight.Bold,
                    color = Color.Black,
                    maxLines = 1,
                    modifier = Modifier.padding(horizontal = 2.dp)
                )
            }
        }
    }
//    val callPerMinutePrice = consultant.callPerMinutePrice.toString()
//    val isExpertFreeCall = consultant.isFreeCall == 1
//    val flashPrice = consultant.expertFlashPrice?.let { consultant.winywayFlashPrice?.plus(it) }
//    val isFreeCallApplicableCalculated =
//        if (!getIsLogin()) isExpertFreeCall else isExpertFreeCall && isFreeCallApplicable
//
//    if (isFreeCallApplicableCalculated) {
//        if(isFreeCallApplicable){
//            Row(
//                modifier = Modifier.padding(horizontal = 2.dp, vertical = 2.dp),
//                verticalAlignment = Alignment.CenterVertically
//            ) {
//                if(flashPrice!=null && flashPrice.toInt() !=0){
//                    Text(
//                        text = "₹ ${flashPrice}/Min",
//                        fontSize = 15.sp,
//                        textAlign = TextAlign.Center,
//                        color = Color.Black,
//                        textDecoration = TextDecoration.LineThrough,
//                        fontWeight = FontWeight.Bold,
//                        modifier = Modifier.padding(horizontal = 1.dp)
//                    )
//                    Spacer(modifier = Modifier.width(4.dp))
//                    Text(
//                        text = "₹ ${consultant.callPerMinutePrice}/Min",
//                        fontSize = 15.sp,
//                        textAlign = TextAlign.Center,
//                        color = Color.Gray,
//                        textDecoration = TextDecoration.LineThrough,
//                        modifier = Modifier.padding(horizontal = 1.dp)
//                    )
//                }else{
//                    //Spacer(modifier = Modifier.width(4.dp))
//                    Text(
//                        text = "₹ $callPerMinutePrice/Min",
//                        fontSize = 15.sp,
//                        textAlign = TextAlign.Center,
//                        color = Color.Gray,
//                        textDecoration = TextDecoration.LineThrough,
//                        modifier = Modifier.padding(horizontal = 1.dp)
//                    )
//                }
//            }
//        }else{
//            Row {
//                Text(
//                    text = "NEW USER",
//                    fontSize = 15.sp,
//                    textAlign = TextAlign.Center,
//                    color = Color.Black,
//                    modifier = Modifier.padding(horizontal = 1.dp)
//                )
//                Text(
//                    text = "₹ $callPerMinutePrice/Min",
//                    fontSize = 15.sp,
//                    textAlign = TextAlign.Center,
//                    color = Color.Gray,
//                    textDecoration = TextDecoration.LineThrough,
//                    modifier = Modifier.padding(horizontal = 1.dp)
//                )
//
//            }
//        }
//    } else {
//        if(flashPrice!=null && flashPrice.toInt() !=0){
//            Box(
//                modifier = Modifier
//                    .padding(horizontal = 2.dp, vertical = 2.dp),
//                contentAlignment = Alignment.Center
//            ) {
//                Row(
//                    modifier = Modifier.padding(horizontal = 1.dp, vertical = 1.dp),
//                    verticalAlignment = Alignment.CenterVertically
//                ) {
//                    Text(
//                        text = "₹ ${flashPrice}/Min",
//                        fontSize = 15.sp,
//                        textAlign = TextAlign.Center,
//                        color = Color.Black,
//                        fontWeight = FontWeight.Bold,
//                        modifier = Modifier.padding(horizontal = 1.dp)
//                    )
//                    Spacer(modifier = Modifier.width(4.dp))
//                    Text(
//                        text = "₹ ${consultant.callPerMinutePrice}/Min",
//                        fontSize = 15.sp,
//                        textAlign = TextAlign.Center,
//                        color = Color.Gray,
//                        textDecoration = TextDecoration.LineThrough,
//                        modifier = Modifier.padding(horizontal = 1.dp)
//                    )
//                }
//            }
//        }else{
//            Box(
//                modifier = Modifier
//                    .padding(horizontal = 2.dp, vertical = 2.dp),
//                contentAlignment = Alignment.Center
//            ) {
//                Text(
//                    text = "₹ $callPerMinutePrice /Min",
//                    fontSize = 15.sp,
//                    textAlign = TextAlign.Center,
//                    fontWeight = FontWeight.Bold,
//                    color = Color.Black,
//                    modifier = Modifier.padding(horizontal = 1.dp)
//                )
//            }
//        }
//    }
}