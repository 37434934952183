package com.winiwayuser.core.persentation

import androidx.compose.animation.animateColorAsState
import androidx.compose.animation.core.animateDpAsState
import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonColors
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.ElevatedButton
import androidx.compose.material3.FilledTonalButton
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedButton
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.input.pointer.pointerHoverIcon
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.input.pointer.positionChange
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.theme.seed
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource

/*Normal Button*/
@Composable
fun CommonButton(
    buttonText: String,
    modifier: Modifier,
    onClick: () -> Unit
) {
    Button(
        onClick = onClick,
        modifier = modifier,
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        elevation = ButtonDefaults.buttonElevation(Scale.ELEVATION_SMALL),
    ) {
        Text(buttonText)
    }
}

/*Tonal Button*/
@Composable
fun AppTonalButton(
    buttonText: String,
    modifier: Modifier,
    onClick: () -> Unit,
    icon: DrawableResource? = null,
    isLoading: Boolean = false
) {
    FilledTonalButton(
        onClick = onClick,
        modifier = modifier,
        colors = ButtonDefaults.buttonColors(
            containerColor = Color.Blue,
            contentColor = Color.White,
            disabledContainerColor = Color.Gray,
            disabledContentColor = Color.LightGray
        ),
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        contentPadding = PaddingValues(16.dp),
        elevation = ButtonDefaults.buttonElevation(Scale.ELEVATION_SMALL),
        border = BorderStroke(1.dp, Color.Black)
    ) {
        if (isLoading) {
            CircularProgressIndicator(
                modifier = Modifier
                    .size(24.dp)
                    .align(Alignment.CenterVertically),
                color = Color.White,
                strokeWidth = 5.dp
            )
            return@FilledTonalButton
        }

        if (icon != null) {
            Icon(painterResource(icon), contentDescription = "Favorite")
            Spacer(modifier = Modifier.width(8.dp))
        }
        Text(buttonText)
    }
}

/*Outline Button */
@Composable
fun AppOutlineButton(
    buttonText: String,
    modifier: Modifier,
    onClick: () -> Unit,
    icon: DrawableResource? = null,
    isLoading: Boolean = false
) {
    OutlinedButton(
        onClick = onClick,
        modifier = modifier,
        colors = ButtonDefaults.buttonColors(
            containerColor = seed,
            contentColor = Color.White,
            disabledContainerColor = Color.Gray,
            disabledContentColor = Color.LightGray
        ),
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        contentPadding = PaddingValues(16.dp),
        elevation = ButtonDefaults.buttonElevation(Scale.ELEVATION_SMALL),
        border = BorderStroke(1.dp, Color.Black)
    ) {
        if (isLoading) {
            CircularProgressIndicator(
                modifier = Modifier
                    .size(24.dp)
                    .align(Alignment.CenterVertically),
                color = Color.White,
                strokeWidth = 5.dp
            )
            return@OutlinedButton
        }

        if (icon != null) {
            Icon(
                painterResource(icon),
                modifier = Modifier.size(10.dp),
                contentDescription = "Favorite"
            )
            Spacer(modifier = Modifier.width(8.dp))
        }
        Text(buttonText)
    }
}

/*Elevated Button*/
@Composable
fun AppElevatedButton(
    buttonText: String,
    modifier: Modifier,
    onClick: () -> Unit,
    icon: DrawableResource? = null,
    isLoading:Boolean = false
) {
    ElevatedButton(
        onClick = onClick,
        modifier = modifier,
        colors = ButtonDefaults.buttonColors(
            containerColor = Color.Blue,
            contentColor = Color.White,
            disabledContainerColor = Color.Gray,
            disabledContentColor = Color.LightGray
        ),
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        contentPadding = PaddingValues(16.dp),
        elevation = ButtonDefaults.buttonElevation(Scale.ELEVATION_SMALL),
        border = BorderStroke(1.dp, Color.Black)
    ) {
        if (isLoading) {
            CircularProgressIndicator(
                modifier = Modifier
                    .size(24.dp)
                    .align(Alignment.CenterVertically),
                color = Color.White,
                strokeWidth = 5.dp
            )
            return@ElevatedButton
        }

        if (icon != null) {
            Icon(painterResource(icon), contentDescription = "Favorite")
            Spacer(modifier = Modifier.width(8.dp))
        }
        Text(buttonText)
    }
}

@Composable
fun AppTextButton(
    buttonText: String,
    modifier: Modifier,
    onClick: () -> Unit,
    icon: DrawableResource? = null,
    isLoading: Boolean = false
) {
    TextButton(
        onClick = onClick,
        modifier = modifier,
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
    ) {
        if (isLoading) {
            CircularProgressIndicator(
                modifier = Modifier
                    .size(24.dp)
                    .align(Alignment.CenterVertically),
                color = Color.White,
                strokeWidth = 5.dp
            )
            return@TextButton
        }

        if (icon != null) {
            Icon(painterResource(icon), contentDescription = "Favorite")
            Spacer(modifier = Modifier.width(8.dp))
        }
        Text(buttonText)
    }
}

@Composable
fun CommonLoadingButton(
    text: String,
    isLoading: Boolean,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    buttonColors: ButtonColors = ButtonDefaults.buttonColors(),
    shape: Shape = RoundedCornerShape(Scale.CORNER_SMALL)
) {
    Button(
        onClick = {
            if (!isLoading) {
                onClick()
            }
        },
        colors = buttonColors,
        shape = shape,
        modifier = modifier,
    ) {
        if (isLoading) {
            CircularProgressIndicator(
                modifier = Modifier.size(24.dp),
                color = Color.White,
                strokeWidth = 2.dp
            )
        } else {
            Text(text = text)
        }
    }
}

@Composable
fun RechargeButton(
    selectedRecharge: RechargeData?,
    onRechargeSelected: (RechargeData) -> Unit
) {
    Button(
        onClick = {
            selectedRecharge?.let {
                onRechargeSelected(it)
            } ?: run {
                // Handle case where no amount is selected
                // Show a Toast, Snackbar, or any appropriate feedback
            }
        },
        modifier = Modifier
            .fillMaxWidth()
            .height(50.dp),
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        colors = ButtonDefaults.buttonColors(containerColor = MaterialTheme.colorScheme.primary)
    ) {
        Text(
            text = "Recharge Now",
            color = MaterialTheme.colorScheme.onPrimary,
            fontWeight = FontWeight.Bold
        )
    }
}


@Composable
fun AnimatedButton() {
    var isHovered by remember { mutableStateOf(false) }
    val buttonColor by animateColorAsState(
        targetValue = if (isHovered) Color(0xFFE91E63) else Color(0xFFF057A6)
    )

    val buttonPadding by animateDpAsState(targetValue = if (isHovered) 12.dp else 8.dp)
    val cornerRadius by animateDpAsState(targetValue = if (isHovered) 24.dp else 16.dp)

    Button(
        onClick = { /* Handle click */ },
        colors = ButtonDefaults.buttonColors(containerColor = buttonColor),
        shape = RoundedCornerShape(cornerRadius),
        modifier = Modifier
            .padding(vertical = buttonPadding)
            .pointerInput(Unit) {
                awaitPointerEventScope {
                    while (true) {
                        val event = awaitPointerEvent()
                        isHovered = event.changes.any { it.pressed.not() && it.positionChange() != null }
                    }
                }
            }
    ) {
        Text(text = "Get Course Details", color = Color.White)
    }
}